import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "./Form";

import { add } from "~/states/modules/agents";

const Add = () => {
	console.log("Add agent rendered");
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		data: addedAgent,
		loading,
		error,
	} = useSelector(({ agents }) => agents.added);
	const [actionFeedback, setActionFeedback] = useState(false);

	const returnToAgentsList = () =>
		history.push(`${process.env.PUBLIC_URL}/company/integrations/agents`);

	const addAgent = (form) => {
		setActionFeedback(true);
		dispatch(add(form));
	};

	useEffect(() => {
		if (actionFeedback && !loading) {
			if (error) {
				toast.error(error.message);
			} else if (addedAgent) {
				toast.success("Agente Adicionado Com Sucesso.");
				returnToAgentsList();
			}
			setActionFeedback(false);
		}
	}, [loading, error, addedAgent]);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<Form onSubmit={addAgent} loading={loading} required={true} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Add;
