import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import Breadcrumb from "../../../common/breadcrumb";
import EditDatabaseForm from "./editDatabaseForm";

import validate from "~/components/common/validate";

import {
	getDatabase,
	editDatabase,
	eraseSelectedDatabase,
} from "~/states/modules/databases";
import CompanyAccess from "~/components/common/companyAccess";

const EditDatabase = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const databaseId = id;
	const history = useHistory();

	const selectedDatabase = useSelector(
		({ databases }) => databases.database.data
	);
	const editedDatabase = useSelector(
		({ databases }) => databases.editedDatabase
	);

	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		dispatch(getDatabase({ databaseId }));
	}, [databaseId, dispatch]);

	// Listening for submition failures:
	useEffect(() => {
		if (showMessage) {
			if (editedDatabase && editedDatabase.error) {
				toast.error(editedDatabase.error.message);
			}
		}
	}, [editedDatabase?.error]);

	// Listening for submition success:
	useEffect(() => {
		if (showMessage) {
			if (editedDatabase && editedDatabase.data) {
				toast.success(
					`Banco de Dados ${editedDatabase.data.database} Atualizado com Sucesso.`
				);
				history.goBack();
			}
		}
	}, [editedDatabase?.data]);

	console.log("selectedDatabase:", selectedDatabase);

	const onSubmit = (data) => {
		if (!validate.anyNew(data, selectedDatabase)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			const { id, ...rest } = data;
			setShowMessage(true);
			dispatch(editDatabase({ databaseId: id, data: rest }));
		}
	};

	return (
		<CompanyAccess
			action={eraseSelectedDatabase}
			companyId={selectedDatabase?.companyApiInfo?.company?.id}
		>
			{/* <Breadcrumb
				parent="Bancos de Dados"
				current="Edição de Banco de Dados"
				title="Edição de Banco de Dados"
			/> */}
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						{selectedDatabase && (
							<EditDatabaseForm
								database={selectedDatabase}
								onSubmit={onSubmit}
							/>
						)}
					</div>
				</div>
			</div>
		</CompanyAccess>
	);
};

export default EditDatabase;
