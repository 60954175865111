export const selectClientConsent = (clientConsent) => ({
	id: parseInt(clientConsent.id, 10),
	name: clientConsent.name,
	phone: clientConsent.phone,
	email: clientConsent.email,
	cpf: clientConsent.cpf,
});

export const selectClientConsents = (clientConsents) =>
	clientConsents.map(selectClientConsent);

export const selectClientWithCompanyClientId = ({
	clientConsents,
	consentRequests,
}) => ({ ...clientConsents, consentRequests });
