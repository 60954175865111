import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

import sdkConfigConstants from "~/states/modules/sdkConfig/sdkConfigConstants";

const { TO_FETCH, TO_EDIT, EDITED, FETCHED, FAILED } =
	sdkConfigConstants.status;

const initialState = {
	data: null,
	status: null,
	loading: false,
	error: null,
};

const { actions, reducer } = createSlice({
	name: "sdkConfig",
	initialState,
	reducers: {
		getSdkConfig: (state) =>
			extend(state, {
				loading: true,
				error: null,
			}),
		getSdkConfigFailure: (state, { payload }) =>
			extend(state, {
				loading: false,
				error: payload,
			}),
		getSdkConfigSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
				loading: false,
				error: null,
			}),
		editSdkConfig: (state) =>
			extend(state, {
				status: TO_EDIT,
				loading: true,
				error: null,
			}),
		editSdkConfigFailure: (state, { payload }) =>
			extend(state, {
				status: FAILED,
				loading: false,
				error: payload,
			}),
		editSdkConfigSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
				status: EDITED,
				loading: false,
				error: null,
			}),
	},
});

export const {
	getSdkConfig,
	getSdkConfigFailure,
	getSdkConfigSuccess,
	editSdkConfig,
	editSdkConfigFailure,
	editSdkConfigSuccess,
} = actions;

export { default as sdkConfigSaga } from "./sagas";

export default reducer;
