import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import sessionConstants from "../../../states/modules/session/sessionConstants";
import ValidTokenCard from "./validTokenCard";
//import InvalidTokenCard from "./invalidTokenCard";

const ChangePassword = () => {
	const { token } = useParams();

	const { errorSources } = sessionConstants;
	const error = useSelector(({ session }) => session.error);

	useEffect(() => {
		if (error && error.source === errorSources.CHANGE_PASSWORD) {
			console.log("error: ", error.message);
		}
	}, [error, errorSources]);

	// const ChangePasswordCard = () =>
	// 	validToken ? (
	// 		<ValidTokenCard refreshToken />
	// 	) : (
	// 		<InvalidTokenCard />
	// 	);

	return (
		<Fragment>
			{/* <ChangePasswordCard /> */}
			<ValidTokenCard token={token} />
		</Fragment>
	);
};

export default ChangePassword;
