import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useResizeColumns,
	useFlexLayout,
} from "react-table";

import "./style.scss";

import CTHead from "./CTHead";
import CTNav from "./CTNav";
import CTBody from "./CTBody";
import CTLoading from "./CTLoading";
import CTEmpty from "./CTEmpty";

function DefaultColumnFilter({ column }) {
	const { filterValue, setFilter } = column;
	return (
		<div className="input-group input-group-sm mb-1">
			<input
				type="text"
				className="form-control"
				aria-label="Username"
				aria-describedby="basic-addon1"
				value={filterValue || ""}
				onClick={(e) => e.stopPropagation()}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
				placeholder={`Filtrar...`}
			/>
		</div>
	);
}

const ControlledTable = ({
	columns,
	data,
	isLoadingData,
	loadingMessage,
	emptyMessage,
	pageCount,
	totalCount,
	disableOrdenation,
	disableFilters,
	pageSizes,
	globalTableParams,
	getData,
	isPaginated = true,
}) => {
	pageSizes = pageSizes || [5, 10, 20, 50, 100];

	const defaultColumn = React.useMemo((data) => {
		return {
			Header: (data) => {
				return <div>{data.column.id.toUpperCase()}</div>;
			},
			Filter: DefaultColumnFilter,
		};
	}, []);

	const defaultState = { pageIndex: 0, pageSize: pageSizes[1] };

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		state,
		setPageSize,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: { ...defaultState, ...globalTableParams },
			pageCount,
			//pageCount: Math.ceil(totalCount / defaultState.pageSize),	//! TODO >>> Falar com @pedropalma
			manualPagination: true,
			manualSortBy: true,
			manualFilters: true,
			autoResetFilters: false,
			disableSortBy: disableOrdenation,
			disableFilters: disableFilters,
		},
		useFilters,
		useSortBy,
		usePagination,
		useResizeColumns,
		useFlexLayout
	);

	const dispatch = useDispatch();

	const { pageIndex, sortBy, filters, pageSize } = state;

	useEffect(() => {
		setPageSize(pageSize);
		if (typeof getData === "function") {
			dispatch(getData(state));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, pageSize, sortBy, pageIndex]);

	return (
		<>
			<div className="ct-table-wrapper p-4">
				<table
					className="table table-striped table-borderless table-sm mt-0 dl-controlled-table"
					{...getTableProps()}
				>
					<CTHead headerGroups={headerGroups} />
					{!isLoadingData && (
						<CTBody
							getTableBodyProps={getTableBodyProps}
							page={page}
							prepareRow={prepareRow}
							fewItems={totalCount < 4 && totalCount > 0}
						/>
					)}
				</table>
				{isLoadingData ? (
					<CTLoading loadingMessage={loadingMessage} />
				) : (
					!totalCount && isPaginated && <CTEmpty emptyMessage={emptyMessage} />
				)}
			</div>
			{!isLoadingData && isPaginated && (
				<CTNav
					totalCount={totalCount}
					nextPage={nextPage}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageIndex={pageIndex}
					pageSize={pageSize}
					pageSizes={pageSizes}
					setPageSize={setPageSize}
				/>
			)}
		</>
	);
};

export default ControlledTable;
