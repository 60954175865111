import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import api, { policyTypeService } from "~/services/api";
import { formatPayload } from "~/utils/CTPayloadFormatter";
// import generateSearchRequest from "sequelize-search-builder-client";
// import qs from "qs";

import {
	getPolicyTypes,
	getPolicyTypesFailure,
	getPolicyTypesSuccess,
	createPolicyType,
	createPolicyTypeSuccess,
	createPolicyTypeFailure,
	editPolicyType,
	editPolicyTypeSuccess,
	editPolicyTypeFailure,
} from ".";

function* rootSaga() {
	yield takeLatest(getPolicyTypes, getPolicyTypesSaga);
	yield takeEvery(createPolicyType, createPolicyTypeSaga);
	yield takeEvery(editPolicyType, editPolicyTypeSaga);
}

function* getPolicyTypesSaga({ payload }) {
	try {
		const formattedPayload = formatPayload(payload);
		const policyTypes = yield call(policyTypeService.getPolicyTypes, {
			tableParams: {
				page: formattedPayload.page || 0,
				size: formattedPayload.pageSize || 10,
				// ...request,
			},
		});
		yield delay(200);
		yield put(getPolicyTypesSuccess(policyTypes));
	} catch (error) {
		yield put(getPolicyTypesFailure(error));
	}
}
function* createPolicyTypeSaga({ payload }) {
	try {
		const policyType = yield call(policyTypeService.createPolicyType, payload);
		yield delay(200);
		yield put(createPolicyTypeSuccess(policyType));
	} catch (error) {
		yield put(createPolicyTypeFailure(error));
	}
}

function* editPolicyTypeSaga({ payload }) {
	try {
		const policyType = yield call(policyTypeService.editPolicyType, payload);
		yield delay(200);
		yield put(editPolicyTypeSuccess(policyType));
	} catch (error) {
		yield put(editPolicyTypeFailure(error));
	}
}

export default rootSaga;
