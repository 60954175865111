import React from "react";
import { CheckCircle, Users, Smartphone, Send, Inbox } from "react-feather";

export const widgetsTotals = (data) => ({
	regular: [
		{
			icon: <Users />,
			label: "Pessoas enviadas",
			count: data.clients,
			iconBg: <Send className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Inbox />,
			label: "Envios por E-MAIL",
			count: data.EMAIL?.sended || 0,
			iconBg: <Send className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
		{
			icon: <Smartphone />,
			label: "Envios por SMS",
			count: data.SMS?.sended || 0,
			iconBg: <Send className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Users />,
			label: "Total de aceites",
			count: data.accepted,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
		{
			icon: <Inbox />,
			label: "Aceites por E-MAIL",
			count: data.EMAIL?.accepted || 0,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Smartphone />,
			label: "Aceites por SMS",
			count: data.SMS?.accepted || 0,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
	],
	different: [
		{
			icon: <Users />,
			label: "Pessoas enviadas",
			count: data.clients,
			iconBg: <Send className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Users />,
			label: "Total de aceites",
			count: data.accepted,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
		{
			icon: <Inbox />,
			label: "Envios por E-MAIL",
			count: data.EMAIL?.sended || 0,
			iconBg: <Send className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
		{
			icon: <Inbox />,
			label: "Aceites por E-MAIL",
			count: data.EMAIL?.accepted || 0,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Smartphone />,
			label: "Envios por SMS",
			count: data.SMS?.sended || 0,
			iconBg: <Send className="icon-bg" />,
			className: "bg-primary b-r-4 card-body",
		},
		{
			icon: <Smartphone />,
			label: "Aceites por SMS",
			count: data.SMS?.accepted || 0,
			iconBg: <CheckCircle className="icon-bg" />,
			className: "bg-secondary b-r-4 card-body",
		},
	],
});
