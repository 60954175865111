import React from "react";

import "./style.scss";

function CTNav({
	totalCount,
	pageIndex,
	previousPage,
	canPreviousPage,
	gotoPage,
	pageOptions,
	nextPage,
	canNextPage,
	pageSizes,
	setPageSize,
	pageSize,
}) {
	const renderPageSelect = () => {
		const options = pageSizes.map((size, index) => {
			return (
				<option key={index} value={size}>
					{`${size} linhas`}
				</option>
			);
		});
		return (
			<select
				className="form-select form-select-sm"
				name="pageSize"
				id="pageSize"
				onChange={(e) => setPageSize(+e.target.value)}
				defaultValue={pageSize}
			>
				{options}
			</select>
		);
	};
	return (
		<nav aria-label="Page navigation" className="dl-page-navigation mt-3">
			<ul
				className="pagination justify-content-between"
				style={{ paddingLeft: "24px", paddingRight: "24px" }}
			>
				<li className="page-item">
					<button
						className="page-link dl-controlled-table-button"
						href="#"
						aria-label="Previous"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						<span aria-hidden="true">Página anterior</span>
						<span className="sr-only">Previous</span>
					</button>
				</li>
				<li className="page-item">
					<div className="page-selector-container">
						<span className="page-selector-text">{"Página "}</span>
						<input
							type="number"
							className="page-selector-input"
							aria-label="Página"
							aria-describedby="basic-addon1"
							value={pageIndex + 1}
							onClick={(e) => e.stopPropagation()}
							onChange={(e) => {
								gotoPage(e.target.value - 1);
								e.target.select();
							}}
							placeholder={"1"}
						/>
						<span className="page-selector-text">
							{" de "} {pageOptions.length}
						</span>
						{renderPageSelect()}
					</div>
				</li>
				<li className="page-item">
					<button
						className="page-link dl-controlled-table-button"
						href="#"
						aria-label="Next"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						<span aria-hidden="true">Próxima página</span>
						<span className="sr-only">Next</span>
					</button>
				</li>
			</ul>

			<p className="mt-3 mb-1">{totalCount} itens encontrados.</p>
		</nav>
	);
}

export default CTNav;
