import React, { useState, useEffect } from "react";
import Breadcrumb from "~/components/common/breadcrumb";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import PolicyTypesListTable from "./policyTypesListTable";
import AddOrEditPolicyTypeModal from "../AddOrEditTypeModal";
import { getPolicyTypes } from "~/states/modules/policyTypes";

function PolicyTypesList() {
	const dispatch = useDispatch();

	const { data, totalCount, tableParams, loading } = useSelector(
		({ policyTypes }) => policyTypes.policyTypes
	);

	const editedPolicyType = useSelector(
		({ policyTypes }) => policyTypes.editedPolicyType
	);

	const createdPolicyType = useSelector(
		({ policyTypes }) => policyTypes.createdPolicyType
	);

	const [addPolicyTypeModal, setAddPolicyTypeModal] = useState(false);

	const [showMessage, setShowMessage] = useState(false);

	// displays toast on a successful creation
	useEffect(() => {
		if (showMessage) {
			if (createdPolicyType && createdPolicyType.data) {
				toast.success(
					`Tipo de política ${createdPolicyType.data.label} criado com sucesso!`
				);
			}
		}
	}, [createdPolicyType?.data]);

	// Reloads the table on a successful creation
	useEffect(() => {
		dispatch(getPolicyTypes(tableParams));
	}, [createdPolicyType?.data, editedPolicyType?.data]);

	// displays error toast on a failed creation
	useEffect(() => {
		if (showMessage) {
			if (createdPolicyType && createdPolicyType.error) {
				if (createdPolicyType.error.name === "SequelizeUniqueConstraintError") {
					toast.error(
						"Sua companhia já possui um tipo de política com esse nome!"
					);
				} else {
					toast.error(createdPolicyType.error.message);
				}
			}
		}
	}, [createdPolicyType?.error]);

	const openAddPolicyTypeModal = () => {
		setAddPolicyTypeModal(true);
	};

	return (
		<>
			<div className="container-fluid">
				<Breadcrumb
					current="Tipos de Políticas"
					title="Listagem de Tipos de Políticas"
					parent="Gestão de Políticas"
				/>
				<div className="card">
					<div className="card-header">
						<div className="row justify-content-between">
							<div className="col">
								<h4>Listagem de Tipos de Políticas</h4>
							</div>
							<div className="col text-right align-middle">
								<button
									className={"btn btn-primary"}
									id="openAddPolicyModal"
									onClick={openAddPolicyTypeModal}
								>
									Adicionar novo tipo de política
								</button>
							</div>
						</div>
					</div>
					<div className="card-body p-0 datatable-react card-table">
						<PolicyTypesListTable
							policyTypes={data}
							totalCount={totalCount}
							tableParams={tableParams}
							isLoadingData={loading}
						/>
					</div>
				</div>
			</div>
			<AddOrEditPolicyTypeModal
				isOpen={addPolicyTypeModal}
				onAccept={() => {
					setShowMessage(true);
					setAddPolicyTypeModal(false);
				}}
				onClose={() => {
					setAddPolicyTypeModal(false);
				}}
			/>
		</>
	);
}

export default PolicyTypesList;
