import React, { Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
	editorFormatOnReady,
	configToolBar,
} from "~/components/common/ckeditorTools";
import { nanoid } from "nanoid";

import "./style.scss";

const CKEditorWrappered = ({
	id,
	name,
	data,
	onChange,
	ref,
	removeToolbar = false,
	isReadOnly = false,
}) => {
	const uniqueId = `toolbar-container-${nanoid(3)}`;

	return (
		<>
			<div style={{ border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
				{removeToolbar ? (
					<div id={uniqueId} style={{ display: "none" }} />
				) : (
					<div id={uniqueId} />
				)}
				<div className="ck-wrapper">
					<CKEditor
						id={id}
						name={name}
						data={data}
						onChange={onChange}
						ref={ref}
						editor={DecoupledEditor}
						onReady={(editor) => {
							if (editor) {
								configToolBar(editor, uniqueId);
								editorFormatOnReady(editor);
								editor.isReadOnly = isReadOnly;
							}
						}}
					/>
				</div>
			</div>
		</>
	);
};
export default CKEditorWrappered;
