import api from "./api";
import apiService from "./ApiService";
import companyService from "./CompanyService";
import sdkService from "./SdkService";
import consentDataService from "./ConsentDataService";
import policyTypeService from "./PolicyTypeService";
import policyService from "./PolicyService";
import ticketsService from "./TicketsService";

export {
	apiService,
	companyService,
	sdkService,
	consentDataService,
	policyTypeService,
	policyService,
	ticketsService,
};

export default api;
