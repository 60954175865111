import React, { useState, useEffect } from "react";
import Modal from "~/components/common/modal";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useDispatch } from "react-redux";

import useYupResolver from "../../common/yupResolver";

import { createPolicyType, editPolicyType } from "~/states/modules/policyTypes";

import yupSchema from "./yupSchema";

const AddOrEditPolicyTypeModal = ({
	isOpen,
	onClose,
	onAccept,
	policyType,
}) => {
	const dispatch = useDispatch();

	const [updateFrequencyIntervalValue, setUpdateFrequencyIntervalValue] =
		useState(1);

	const { register, handleSubmit, errors, watch, setValue } = useForm({
		mode: "onChange",
		resolver: useYupResolver(yupSchema),
	});

	const submitAddPolicyHandler = (data) => {
		dispatch(createPolicyType(data));
		onAccept();
	};

	const submitEditPolicyHandler = (data) => {
		data.id = policyType.id;
		dispatch(editPolicyType(data));
		onAccept();
	};

	const watchedUpdateFrequency = watch("updateFrequency");

	useEffect(() => {
		if (watchedUpdateFrequency === "NEVER") {
			setValue("updateFrequencyInterval", 0);
		} else {
			setValue("updateFrequencyInterval", updateFrequencyIntervalValue);
		}
	}, [watchedUpdateFrequency]);

	const disableFieldWhenUpdateFrequencyIsSetToNever = () => {
		return watchedUpdateFrequency === "NEVER";
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onAccept={
				policyType
					? handleSubmit(submitEditPolicyHandler)
					: handleSubmit(submitAddPolicyHandler)
			}
			title={policyType ? "Editar Tipo de Política" : "Novo Tipo de Política"}
		>
			<div className="input-container container  " id="add-policy-type-form">
				<form className="needs-validation">
					{/* LABEL */}
					<div className="form-row">
						<div className="form-group col-12">
							<label htmlFor="label">
								Rótulo
								<span className="auth--required-symbol">*</span>
							</label>
							<div className="input-group">
								<input
									className="form-control"
									type="text"
									id="label"
									name="label"
									placeholder="Rótulo para Política"
									defaultValue={policyType ? policyType.label : ""}
									ref={register}
								/>
								<ErrorMessage
									errors={errors}
									name="name"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
					</div>

					{/* NAME */}
					<div className="form-row">
						<div className="form-group col-12">
							<label htmlFor="name">Nome</label>
							<div className="input-group">
								<input
									className="form-control"
									type="text"
									name="name"
									id="name"
									placeholder="nome_política"
									defaultValue={policyType ? policyType.name : ""}
									ref={register}
								/>
								<ErrorMessage
									errors={errors}
									name="name"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
					</div>

					{/* FREQUENCYINTERVAL e FREQUENCY */}
					<fieldset className="form-group">
						<legend>Frequência de atualização</legend>
						<div className="form-row">
							<div className="form-group col-7">
								<label htmlFor="updateFrequencyInterval">Intervalo</label>
								<div className="input-group">
									<input
										id="updateFrequencyInterval"
										name="updateFrequencyInterval"
										className="form-control"
										type="number"
										min="0"
										readOnly={disableFieldWhenUpdateFrequencyIsSetToNever()}
										defaultValue={
											policyType
												? policyType.updateFrequencyInterval
												: updateFrequencyIntervalValue
										}
										onChange={(e) =>
											setUpdateFrequencyIntervalValue(e.target.value)
										}
										ref={register}
									/>
									<ErrorMessage
										errors={errors}
										name="name"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>

							<div className="form-group col-5">
								<label htmlFor="updateFrequency">Frequência</label>
								<div className="input-group">
									<select
										className="form-control"
										id="updateFrequency"
										name="updateFrequency"
										form="add-policy-type-form"
										defaultValue={
											policyType ? policyType.updateFrequency : "YEAR"
										}
										ref={register}
										selected
									>
										<option value="DAY">Dia(s)</option>
										<option value="WEEK">Semana(s)</option>
										<option value="MONTH">Mês(s)</option>
										<option value="YEAR">Ano(s)</option>
										<option value="NEVER">Nunca atualizar</option>
									</select>
									<ErrorMessage
										errors={errors}
										name="name"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		</Modal>
	);
};

export default AddOrEditPolicyTypeModal;
