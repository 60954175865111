import {
	call,
	put,
	takeEvery,
	takeLatest,
} from "redux-saga/effects";

import { formatPayload } from "~/utils/CTPayloadFormatter";

import {
	getConsentTypes,
	getConsentTypesSuccess,
	getConsentTypesFailure,
	createConsentTypes,
	createConsentTypesSuccess,
	createConsentTypesFailure,
} from ".";

import api from "~/services/api";

function* rootSaga() {
	yield takeLatest(getConsentTypes, getConsentTypesSaga);
	yield takeEvery(createConsentTypes, createConsentTypesSaga);
}

function* getConsentTypesSaga({ payload = {} }) {
	try {
		const formattedPayload = formatPayload(payload);
		const { data } = yield call(api.get, "/consentTypes", {
			params: {
				page: formattedPayload?.page || 0,
				size: formattedPayload?.pageSize || 10,
			},
		});
		yield put(getConsentTypesSuccess(data));
	} catch (error) {
		yield put(getConsentTypesFailure(error.response?.data?.error));
	}
}

function* createConsentTypesSaga({ payload }) {
	try {
		const { data } = yield call(api.post, "/consentTypes", payload);

		yield put(createConsentTypesSuccess(data.data));
	} catch (error) {
		yield put(createConsentTypesFailure(error.response?.data?.error));
	}
}

export default rootSaga;
