import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import ControlledTable from "~/components/common/controlledTable";

import { getClientConsents } from "~/states/modules/dataConsent";

import { states, regularColumns } from "./ClientConsentsConstants";

function ClientConsentsTable() {
	const history = useHistory();

	const {
		data: clientConsents,
		loading: isLoadingData,
		tableParams,
		totalCount,
	} = useSelector(({ dataConsent }) => dataConsent.clientConsents);

	// Construct information of table
	const data = clientConsents?.map(({ consentData, ...clientConsent }) => {
		// Initialize custom fields
		const acceptChoices = {
			accepted: "-",
			requestAt: "-",
			acceptedAt: "-",
			smsRequest: "-",
			emailRequest: "-",
			status: "-",
		};

		clientConsent.requests.forEach(
			({ requestAt, status, updatedAt, channel }) => {
				// Fullfill channel for SMS and EMAIL cases
				if (channel === "SMS")
					acceptChoices.smsRequest = states(status, "request");
				else if (channel === "EMAIL")
					acceptChoices.emailRequest = states(status, "request");

				// Since both would be sent at the same time, either way it would work
				if (requestAt) {
					if (acceptChoices.requestAt === "-") {
						acceptChoices.requestAt = requestAt;
					} else {
						if (acceptChoices.requestAt < requestAt) {
							acceptChoices.requestAt = requestAt;
						}
					}
				}

				// Only changes accepted status if it's not already closed
				if (acceptChoices.status !== "CLOSED") {
					// Rewrite accepted status
					acceptChoices.status = status;

					// If it failed, accepted should stay '-'
					if (status !== "FAILED")
						acceptChoices.accepted = states(status, "accept");

					// Only changes  if it's closed'
					if (updatedAt && status === "CLOSED")
						acceptChoices.acceptedAt =
							moment(updatedAt).format("DD/MM/YYYY HH:mm");
				}
			}
		);
		if (acceptChoices.requestAt !== "-") {
			acceptChoices.requestAt = moment(acceptChoices.requestAt).format(
				"DD/MM/YYYY HH:mm"
			);
		}

		return { ...acceptChoices, ...clientConsent };
	});

	function createGotoView(clientConsentId) {
		return () => {
			history.push(
				`${process.env.PUBLIC_URL}/clientConsents/${clientConsentId}`
			);
		};
	}

	const cell = (data, column) => (
		<div
			onClick={createGotoView(data.row.original.id)}
			style={{ cursor: "pointer", textAlign: "left" }}
		>
			{data.row.original[column]}
		</div>
	);

	// Get all columns from constants and add createGotoView
	const columns = regularColumns.map((column) => ({
		...column,
		Cell: (data) => cell(data, column.accessor),
	}));

	return (
		<ControlledTable
			data={data}
			isLoadingData={isLoadingData}
			columns={columns}
			pageCount={Math.ceil(totalCount / tableParams.pageSize)}
			totalCount={totalCount}
			globalTableParams={tableParams}
			getData={getClientConsents}
		/>
	);
}

export default ClientConsentsTable;
