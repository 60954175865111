import React, { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

const DetailsModal = ({ data }) => {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<>
			<Button color="link" onClick={toggle}>
				ver detalhes
			</Button>
			<Modal centered fade={false} isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>
					Detalhes do consentimento
				</ModalHeader>
				<ModalBody>
					<ul>
						<li>
							<b>Consent ID:</b> {data.id}
						</li>
						<li>
							<b>Data do consentimento:</b>{" "}
							{moment(data.date)
								.format("DD/MM/YYYY HH:mm")
								.toString()}
						</li>
						<li>
							<b>Cookies consentidos:</b>
							<ul>
								{data.consents.map((c, index) => (
									<Fragment key={index}>
										{c.active && <li>{c.name}</li>}
									</Fragment>
								))}
							</ul>
						</li>
						<li>
							<b>Cookies não consentidos:</b>
							<ul>
								{data.consents.map((c, index) => (
									<Fragment key={index}>
										{!c.active && <li>{c.name}</li>}
									</Fragment>
								))}
							</ul>
						</li>
					</ul>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DetailsModal;
