import api from "./api";

import { selectCompany } from "../../states/modules/company/selectors";

class CompanyService {
	getCurrentCompany() {
		return api
			.get("/companies/current")
			.then((response) => response.data)
			.then((data) => data.data)
			.then((companyData) => selectCompany(companyData))
			.catch((error) => {
				console.log(
					"fail when getCurrentCompany in CompanyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	getCompanyBySlug(slug) {
		return api
			.get(`/companies/slug/${slug}`)
			.then((response) => response.data)
			.then((data) => data.data)
			.then(selectCompany)
			.catch((error) => {
				console.log(
					"fail when getCompanyBySlug in CompanyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	hasCompanyBySlug(slug) {
		return api
			.head(`/companies/slug/${slug}`)
			.then(() => true)
			.catch((error) => {
				if (error.response.status === 404) {
					return false;
				}
				console.log(
					"error in hasCompanyBySlug in CompanyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	editCompany(payload) {
		return api
			.patch("companies/", payload)
			.then((response) => response.data)
			.then((companyData) => selectCompany(companyData.data))
			.catch((error) => {
				console.log(
					"fail when editCompany in CompanyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	createCompany(payload) {
		return api
			.post("companies/", payload)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"fail when createCompany in CompanyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
}

export default new CompanyService();
