import {
	configureStore,
	getDefaultMiddleware,
	combineReducers,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { persistStore } from "redux-persist";

import persistReducers from "./persistReducer";
import session, { sessionSaga } from "./modules/session";
import tickets, { ticketsSaga } from "./modules/tickets";
import users, { usersSaga } from "./modules/users";
import company, { companySaga } from "./modules/company";
import sdkConfig, { sdkConfigSaga } from "./modules/sdkConfig";
import emailConfig, { emailConfigSaga } from "./modules/emailConfig";
import emailTemplates, { emailTemplatesSaga } from "./modules/emailTemplates";
import apiInfo, { apiInfoSaga } from "./modules/apiInfo";
import databases, { databasesSaga } from "./modules/databases";
import customizer from "./modules/customizer";
import fonts, { fontsSaga } from "./modules/fonts";
import roles, { rolesSaga } from "./modules/roles";
import agents, { sagas as agentsSaga } from "./modules/agents";
import appConfig, { appConfigSaga } from "./modules/appConfig";
import dataConsent, { dataConsentSaga } from "./modules/dataConsent";
import cookiesCategories, {
	cookiesCategoriesSaga,
} from "./modules/cookiesCategories";
import consentTypes, { consentTypesSaga } from "./modules/consentTypes";

import policies, { policiesSaga } from "./modules/policies";

import policyTypes, { policyTypesSaga } from "./modules/policyTypes";

const sagaMiddleware = createSagaMiddleware();

const middleware = [
	...getDefaultMiddleware({ serializableCheck: false }),
	sagaMiddleware,
];

const reducers = combineReducers({
	session,
	tickets,
	users,
	company,
	sdkConfig,
	emailConfig,
	emailTemplates,
	apiInfo,
	databases,
	fonts,
	roles,
	agents,
	appConfig,
	customizer,
	cookiesCategories,
	dataConsent,
	consentTypes,
	policies,
	policyTypes,
});

const store = configureStore({
	reducer: persistReducers(reducers),
	middleware,
});

const persistor = persistStore(store);

const rootSaga = function* () {
	yield all([
		sessionSaga(),
		ticketsSaga(),
		usersSaga(),
		companySaga(),
		sdkConfigSaga(),
		emailConfigSaga(),
		emailTemplatesSaga(),
		apiInfoSaga(),
		databasesSaga(),
		fontsSaga(),
		rolesSaga(),
		agentsSaga(),
		appConfigSaga(),
		cookiesCategoriesSaga(),
		dataConsentSaga(),
		consentTypesSaga(),
		policiesSaga(),
		policyTypesSaga(),
	]);
};

sagaMiddleware.run(rootSaga);

export { persistor, store };
