import React from "react";

import {
	AlertCircle,
	XCircle,
	CheckCircle,
	Clock,
	ArrowUpCircle,
	MinusCircle,
} from "react-feather";

import moment from "moment";

export const widgetsTotals = [
	{
		icon: <CheckCircle />,
		label: "Fechados no prazo",
		count: 0,
		iconBg: <CheckCircle className="icon-bg" />,
		className: "bg-primary b-r-4 card-body",
	},
	{
		icon: <MinusCircle />,
		label: "Fechados fora do prazo",
		count: 0,
		iconBg: <MinusCircle className="icon-bg" />,
		className: "bg-secondary b-r-4 card-body",
	},
	{
		icon: <XCircle />,
		label: "Pedidos abertos expirados",
		count: 0,
		iconBg: <XCircle className="icon-bg" />,
		className: "bg-primary b-r-4 card-body",
	},
	{
		icon: <AlertCircle />,
		label: "A vencer em 1 dia",
		count: 0,
		iconBg: <AlertCircle className="icon-bg" />,
		className: "bg-secondary b-r-4 card-body",
	},
	{
		icon: <Clock />,
		label: "A vencer em 3 dias",
		count: 0,
		iconBg: <Clock className="icon-bg" />,
		className: "bg-primary b-r-4 card-body",
	},
	{
		icon: <ArrowUpCircle />,
		label: "A vencer em 5 dias",
		count: 0,
		iconBg: <ArrowUpCircle className="icon-bg" />,
		className: "bg-secondary b-r-4 card-body",
	},
];

export function countCorrect(ticket, object) {
	const expirationDate = moment(ticket.expirationDate);
	const updatedAt = moment(ticket.updatedAt);

	const timeBetweenExpirationAndLastUpdate = expirationDate.diff(updatedAt);

	const timeBetweenExpirationAndNow = expirationDate.diff(moment(), "days");

	if (ticket.status === "CLOSED") {
		if (timeBetweenExpirationAndLastUpdate > 0) object[0].count++;
		else object[1].count++;
	} else {
		if (timeBetweenExpirationAndNow < 0) object[2].count++;
		else if (timeBetweenExpirationAndNow <= 1) object[3].count++;
		else if (timeBetweenExpirationAndNow <= 3) object[4].count++;
		else if (timeBetweenExpirationAndNow <= 5) object[5].count++;
	}
}

export function resetCounters(array) {
	array.forEach((obj) => (obj.count = 0));
}
