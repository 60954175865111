export const selectCompany = (company) => ({
	id: parseInt(company.id, 10),
	name: company.name,
	slug: company.slug,
	logo: company.logo,
	require_cpf: company.require_cpf,
	require_email: company.require_email,
	require_phoneNumber: company.require_phoneNumber,
	require_name: company.require_name,
	offeredRequests: company.offeredRequests,
	privacyPolicyLink: company.privacyPolicyLink,
	appConfig: company.appConfig,
	authQuestions: company.authQuestions,
	siteUrl: company.siteUrl,
	autoEventsFlow: company.autoEventsFlow,
	enableAuthQuestions: company.enableAuthQuestions,
});

export const selectCompanies = (companies) => companies.map(selectCompany);

export const selectCompanyTag = (tag) => ({
	name: tag.name,
	value: tag.value,
	domain: tag.domain,
	path: tag.path,
	expires: tag.expires.toString(),
	size: tag.size,
	httpOnly: tag.httpOnly,
	secure: tag.secure,
	session: tag.session,
	sameSite: tag.smaSite,
	priority: tag.priority,
	sameParty: tag.sameParty,
	sourceScheme: tag.sourceScheme,
	sourcePort: tag.sourcePort,
	category: tag.category.label,
});

export const selectAllCompanyTags = (tags) => tags.map(selectCompanyTag);

export const selectTag = (tag) => ({
	id: tag.id,
	companyId: tag.companyId,
	name: tag.name,
	value: tag.value,
	domain: tag.domain,
	path: tag.path,
	expires: tag.expires,
	size: tag.size,
	httpOnly: tag.httpOnly,
	secure: tag.secure,
	session: tag.session,
	sameSite: tag.smaSite,
	priority: tag.priority,
	sameParty: tag.sameParty,
	sourceScheme: tag.sourceScheme,
	sourcePort: tag.sourcePort,
	category: tag.category.label,
});

export const selectTags = (tags) => tags.map(selectTag);

export const selectAllConsents = (payload) =>
	payload.map((consent) => ({
		id: consent.id,
		ip: consent.ip,
		date: consent.updatedAt ? new Date(consent.updatedAt) : null,
		consents: consent.consents,
	}));
