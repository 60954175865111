import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getTicketChart } from "~/states/modules/tickets";

import {
	countCorrect,
	widgetsTotals,
	resetCounters,
} from "./WidgetTotalsConstants";

import Widgets from "~components/common/Widgets";

const WidgetTotals = ({ dateRange }) => {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: dateRange ? dateRange : "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);

	resetCounters(widgetsTotals);

	ticketsForChart.forEach((month) =>
		month.tickets.forEach((ticket) => {
			countCorrect(ticket, widgetsTotals);
		})
	);

	return <Widgets info={widgetsTotals} />;
};

export default WidgetTotals;
