import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Trash2, Edit } from "react-feather";
import { Button, Badge } from "reactstrap";

import Modal from "~/components/common/modal";
import CopyToClipboard from "~/components/common/copyToClipboard";

import { remove } from "~/states/modules/agents";

import "./table.scss";

function List({ agents }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [modal, setModal] = useState(false);
	const [selectedAgentId, setSelectedAgentId] = useState(null);
	const [actionFeedback, setActionFeedback] = useState(false);

	const {
		data: removedAgent,
		loading,
		error,
	} = useSelector(({ agents }) => agents.removed);
	useEffect(() => {
		if (actionFeedback && !loading) {
			if (error) {
				toast.error(error.message);
			} else if (removedAgent) {
				toast.success("Agente Removido Com Sucesso.");
			}
			setActionFeedback(false);
		}
	}, [loading, error, removedAgent]);

	const goToEditAgent = (id) => () => {
		history.push(`${process.env.PUBLIC_URL}/company/integrations/agents/${id}`);
	};

	const enableConfirmationModal = (id) => () => {
		setSelectedAgentId(id);
		setModal(true);
	};

	const removeAgent = () => {
		setModal(false);
		setActionFeedback(true);
		dispatch(remove(selectedAgentId));
	};

	return (
		<Fragment>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="url">URL</th>
							<th className="username">Usuário</th>
							<th className="password">Senha</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{agents.map(({ id, companyId, url, username, password }) => (
							<tr key={id}>
								<td>{url}</td>
								<td>{username}</td>
								<td>
									<CopyToClipboard text={password}></CopyToClipboard>
								</td>
								<td>
									<Button color="link" onClick={goToEditAgent(id)}>
										<Edit size={18} />
									</Button>
									<Button color="link" onClick={enableConfirmationModal(id)}>
										<Trash2 size={18} />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Modal
				isOpen={modal}
				onClose={() => setModal(false)}
				onAccept={removeAgent}
				title="Remover Agente"
			>
				Tem certeza de que deseja excluir{" "}
				<Badge color="primary">permanentemente</Badge> esse agente?
			</Modal>
		</Fragment>
	);
}

export default List;
