import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import { updatePolicy, getPolicyById } from "~/states/modules/policies";

import Breadcrumb from "~/components/common/breadcrumb";

import { ErrorMessage } from "@hookform/error-message";

function EditPolicy() {
	const dispatch = useDispatch();
	const history = useHistory();
	let { policyId } = useParams();
	const [showMessage, setShowMessage] = useState(false);

	const { register, handleSubmit, setValue, errors, control, watch } = useForm({
		mode: "onChange",
	});

	const majorVersionField = watch("majorVersion");

	const handleContentChange = (event, editor) =>
		setValue("content", editor.getData());

	const { data, error } = useSelector(({ policies }) => policies.updatedPolicy);

	const policy = useSelector(({ policies }) => policies.policy.data);

	useEffect(() => {
		dispatch(getPolicyById({ id: policyId }));
	}, [policyId, dispatch]);

	useEffect(() => {
		if (showMessage) {
			if (data) {
				toast.success(`Politica atualizada com sucesso!`);
				history.push(`${process.env.PUBLIC_URL}/policies`);
			} else if (error) {
				toast.error(error.message);
			}
		}
	}, [data, error]);

	const onSubmit = (data) => {
		setShowMessage(true);
		dispatch(updatePolicy({ id: policyId, ...data }));
	};

	return (
		<>
			<Breadcrumb
				parent="Listagem De Politicas"
				title="Edição da Política"
				current="Edição da Política"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<form
							className="needs-validation"
							onSubmit={handleSubmit(onSubmit)}
						>
							{policy && (
								<div className="card">
									<div className="card-header">
										<h4>Editar {policy.name}</h4>
									</div>
									<div className="card-body">
										<div className="form-group col-md-12 col-sm-12">
											<label className="col-form-label" htmlFor="inputVersion">
												Versão da Política:
												<span className="auth--required-symbol">*</span>
											</label>
											<div className="form-row">
												<div className="form-group col-md-2">
													<input
														id="majorVersion"
														name="majorVersion"
														className="form-control"
														type="number"
														min={policy && policy.majorVersion}
														defaultValue={policy && policy.majorVersion}
														onChange={(e) => {
															if (policy.majorVersion < e.target.value)
																setValue("minorVersion", 0);
														}}
														ref={register}
													/>
												</div>
												<div className="form-group col-md-2">
													<input
														id="minorVersion"
														name="minorVersion"
														className="form-control"
														type="number"
														min={
															policy && majorVersionField == policy.majorVersion
																? policy.minorVersion + 1
																: 0
														}
														onChange={(e) => {
															if (policy.majorVersion < majorVersionField)
																e.target.value = 0;
														}}
														defaultValue={policy && policy.minorVersion}
														ref={register}
													/>
												</div>
											</div>
											<ErrorMessage
												errors={errors}
												name="majorVersion"
												as={<span className="auth--validation-alert" />}
											/>
											<ErrorMessage
												errors={errors}
												name="minorVersion"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
										<div className="form-group col-md-12 col-sm-12">
											<label className="col-form-label" htmlFor="inputContent">
												Conteúdo da Política:
												<span className="auth--required-symbol">*</span>
											</label>
											<Controller
												name="content"
												control={control}
												rules={{ required: true }}
												defaultValue={""}
												render={({ name }) => (
													<CKEditorWrappered
														id="content"
														name={name}
														data={policy ? policy.content : ""}
														onChange={handleContentChange}
													/>
												)}
											/>
											<ErrorMessage
												errors={errors}
												name="content"
												render={({ message }) => <p>{message}</p>}
											/>
										</div>
									</div>
									<div className="card-footer">
										<div className="text-right col-sm-12">
											<button className={"btn btn-primary"} type="submit">
												Atualizar
											</button>
										</div>
									</div>
								</div>
							)}
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditPolicy;
