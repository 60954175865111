import React from "react";
import Modal from "~/components/common/modal";
import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

function ViewHTMLContentModal({ isOpen, onClose, content, title }) {
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} removeButtons title={title}>
				<CKEditorWrappered
					editor={DecoupledEditor}
					data={content ? content : ""}
					removeToolbar
					isReadOnly
				/>
			</Modal>
		</>
	);
}

export default ViewHTMLContentModal;
