import React from "react";
import ControlledTable from "~/components/common/controlledTable";
import { columns } from "./PoliciesListTableConstants";
import { getPolicies } from "~/states/modules/policies";
import { useSelector } from "react-redux";

function PoliciesListTable() {
	const {
		data: policies,
		totalCount,
		tableParams,
	} = useSelector(({ policies }) => policies.list);

	return (
		<ControlledTable
			data={policies}
			columns={columns}
			pageCount={Math.ceil(totalCount / tableParams.pageSize)}
			totalCount={totalCount}
			disableOrdenation={true}
			disableFilters={true}
			globalTableParams={tableParams}
			getData={getPolicies}
		/>
	);
}

export default PoliciesListTable;
