import moment from "moment";

export const getcolors = () => [
	"#4FF8E5",
	"#04EACf",
	"#12CCB8",
	"#01AA9D",
	"#007F7F",
	"#046070",
	"#002C5E",
	"#070F56",
];

export const getInfoBar = (chartData) => {
	const dataset = [],
		colors = getcolors(),
		datalabels = chartData.map((item) =>
			moment(item.yearMonth).format("MMM-YY")
		);

	let readingMonth = 0;

	chartData.forEach((chartItem) => {
		chartItem.tickets.forEach((ticket) => {
			const found = dataset.find(
				(item) => item.label === ticket.ticketType.label
			);

			if (!found) {
				const datasetItem = {
					label: ticket.ticketType.label,
					data: chartData.map((item) => 0),
					backgroundColor: colors.pop(),
				};

				datasetItem.data[readingMonth] = 1;
				dataset.push(datasetItem);
			} else {
				found.data[readingMonth]++;
			}
		});

		readingMonth++;
	});

	// Setting up total for each dataset item:
	dataset.forEach((datasetItem) => {
		datasetItem.count = datasetItem.data.reduce(
			(total, currentValue) => total + currentValue
		);
	});

	return {
		datalabels: datalabels,
		dataset: dataset,
	};
};
