import React, { useState } from "react";
import { useForm } from "react-hook-form";
import validate from "../../../common/validate";
import { ErrorMessage } from "@hookform/error-message";
import { X } from "react-feather";
import { toast } from "react-toastify";
import { Collapse } from "reactstrap";
import RoundedAddButton from "~/components/common/roundedAddButton";

const AuthQuestionsForm = ({
	onSubmit,
	enableAuthQuestions,
	company,
	companyAuthQuestions,
}) => {
	const [authQuestions, setAuthQuestions] = useState(companyAuthQuestions);
	/**
	 * Format array of authQuestions to an object mapped to the form structure
	 */
	const buildFormObject = (authQuestions) =>
		authQuestions.reduce(
			(defaultAuthQuestions, { question }, index) => ({
				...defaultAuthQuestions,
				[`question ${index}`]: question,
			}),
			{}
		);
	// We must build the object with stored authQuestions in order to show the filled form to the user.
	const defaultAuthQuestionsObject = buildFormObject(authQuestions);

	const { register, handleSubmit, errors, reset, getValues, watch } = useForm({
		defaultValues: {
			enableAuthQuestions: enableAuthQuestions,
			require_cpf: company.require_cpf,
			require_email: company.require_email,
			require_name: company.require_name,
			require_phoneNumber: company.require_phoneNumber,
			...defaultAuthQuestionsObject,
		},
	});

	const handleNewQuestion = () => {
		setAuthQuestions([
			...authQuestions,
			{
				question: "",
			},
		]);
	};

	const watchedSwitch = watch("enableAuthQuestions");

	const handleDeleteQuestion = (removedIndex) => {
		// Get current questions in the form to prevent the loss of unsubmited questions
		const currentFormValues = getValues();
		const getAuthQuestionsFromFormValues = () => {
			var authQuestions = {};
			var i = 0;
			for (let prop in currentFormValues) {
				if (prop === "enableAuthQuestions") {
					authQuestions["enableAuthQuestions"] = currentFormValues[prop];
				}
				if (prop === "question " + i) {
					authQuestions["question " + i] = currentFormValues[prop];
					i++;
				}
			}
			return authQuestions;
		};
		const authQuestions = getAuthQuestionsFromFormValues();

		const [, ...currentAuthQuestions] = Object.values(authQuestions).map(
			(question) => ({
				question,
			})
		);
		currentAuthQuestions.splice(removedIndex, 1);
		setAuthQuestions(currentAuthQuestions);
		// Reset to reinject default form values
		// Takes an array containing multiple objects creates a single object with multiple fields
		const newValues = buildFormObject(currentAuthQuestions);
		newValues.enableAuthQuestions = !!watchedSwitch;
		reset(newValues);
	};

	// verifying if there was any change in authQuestions
	const anyChange = (currentValues, oldValues) => {
		if (!currentValues?.length && !oldValues?.length) return false;
		if (currentValues?.length != oldValues?.length) return true;
		return currentValues.some((element, index) => {
			return oldValues[index].question != element.question;
		});
	};

	/* Create the array of objects with each authQuestion and its order.
	 * We also take the value of enableAuthQuestions from checkbox.
	 *	Then, we verify if there was any changes in checkbox or in authQuestions.
	 * We don't submit the form if there was no change.
	 * Otherwise we submit the changes with the right settings.
	 */
	const onSubmitWrapper = (input) => {
		const getNewAuthQuestionsFromInput = () => {
			const newAuthQuestionsFromInput = {};
			let i = 0;
			for (let prop in input) {
				if (prop === "enableAuthQuestions") {
					newAuthQuestionsFromInput["enableAuthQuestions"] = input[prop];
				}
				if (prop === "question " + i) {
					newAuthQuestionsFromInput["question " + i] = input[prop];
					i++;
				}
			}
			return newAuthQuestionsFromInput;
		};

		const newAuthQuestionsFromInput = getNewAuthQuestionsFromInput();

		const [enabled, ...newAuthQuestions] = Object.values(
			newAuthQuestionsFromInput
		).map((question, order) =>
			order
				? {
						question,
						order: order - 1,
				  }
				: question
		);
		if (
			input.require_email === company.require_email &&
			input.require_name === company.require_name &&
			input.require_phoneNumber === company.require_phoneNumber &&
			input.require_cpf === company.require_cpf &&
			input.autoEventsFlow === company.autoEventsFlow &&
			enabled === enableAuthQuestions &&
			!anyChange(newAuthQuestions, companyAuthQuestions)
		) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			return onSubmit({
				require_cpf: input.require_cpf,
				require_email: input.require_email,
				require_name: input.require_name,
				require_phoneNumber: input.require_phoneNumber,
				autoEventsFlow: input.autoEventsFlow,
				enableAuthQuestions: enabled,
				authQuestions: newAuthQuestions,
			});
		}
	};

	const requiredIfFieldIsOpen = (isOpen) =>
		isOpen ? validate.required() : validate.notRequired();

	return (
		<>
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(onSubmitWrapper)}
			>
				<div className="card">
					<div className="card-body">
						{/* =============== CONFIG QUESTIONS =============== */}
						<div className="form-group col-12">
							<h5>Dados Coletados</h5>
							<p>Dados requeridos na abertura de um pedido de dados.</p>
							<div className="form-row">
								<div className="checkbox checkbox-primary col-md-3 col-sm-12">
									<input
										name="require_email"
										id="require_email"
										type="checkbox"
										checked
										readOnly
										ref={register}
									/>
									<label htmlFor="require_email">
										E-Mail
										<span className="auth--required-symbol">*</span>
									</label>
								</div>
								<div className="checkbox checkbox-primary col-md-3 col-sm-12">
									<input
										name="require_name"
										id="require_name"
										type="checkbox"
										ref={register}
									/>
									<label htmlFor="require_name">Nome</label>
								</div>
								<div className="checkbox checkbox-primary col-md-3 col-sm-12">
									<input
										name="require_phoneNumber"
										id="require_phoneNumber"
										type="checkbox"
										ref={register}
									/>
									<label htmlFor="require_phoneNumber">Celular</label>
								</div>
								<div className="checkbox checkbox-primary col-md-3 col-sm-12">
									<input
										name="require_cpf"
										id="require_cpf"
										type="checkbox"
										ref={register}
									/>
									<label htmlFor="require_cpf">CPF</label>
								</div>
							</div>
						</div>
						<hr className="mb-4 mt-4"></hr>
						<div className="form-group col-12">
							<h5>Processamento de pedidos</h5>
							<p>
								Escolha se a plataforma deve agir de forma automática ou manual.
							</p>
							<div className="checkbox checkbox-primary col-12">
								<input
									name="autoEventsFlow"
									id="autoEventsFlow"
									type="checkbox"
									defaultChecked={company.autoEventsFlow}
									ref={register}
								/>
								<label htmlFor="autoEventsFlow">Plataforma automática</label>
							</div>
						</div>
						{/* =============== END CONFIG QUESTIONS =============== */}
						<hr className="mb-4 mt-4"></hr>
						<div className="form-group col-12">
							<h5 className="mb-4">Perguntas de Verificação</h5>
							<p className="card-subtitle my-2 text-muted">
								Insira perguntas para verificar a identidade do cliente que abre
								o ticket. Essas perguntas serão exibidas para o cliente após a
								confirmação do código do e-mail. Também haverá uma etapa a mais
								para responder o ticket, na qual o usuário responsável deve
								verificar a correta resposta das perguntas para prosseguir com o
								fluxo.
							</p>
							<div className="form-row">
								<div className="checkbox checkbox-primary col-12">
									<input
										name="enableAuthQuestions"
										id="enableAuthQuestions"
										type="checkbox"
										ref={register(requiredIfFieldIsOpen(watchedSwitch))}
									/>
									<label htmlFor="enableAuthQuestions">
										Utilizar Perguntas de Verificação
									</label>
								</div>
							</div>
							<hr className="my-4" />
							{/* =============== AUTH QUESTIONS =============== */}
							<Collapse isOpen={!!watchedSwitch}>
								{authQuestions.map((item, index) => {
									return (
										<div key={index}>
											<div className="form-row">
												<div className="form-group col-12">
													<div className="row">
														<label
															className="col-form-label col-9"
															htmlFor="authQuestions"
														>
															Pergunta {index + 1}:
														</label>
														<div className="col-3 text-right">
															<button
																className="btn bg-white p-0 mb-1"
																type="button"
																onClick={() => {
																	handleDeleteQuestion(index);
																}}
															>
																<X style={{ color: "#E53E3E" }} size="22px" />
															</button>
														</div>
													</div>
													<input
														className="form-control"
														type="text"
														name={"question " + index}
														placeholder="Pergunta de verificação"
														ref={register(validate.required())}
													/>
													<ErrorMessage
														errors={errors}
														name="authQuestion"
														as={<span className="auth--validation-alert" />}
													/>
												</div>
											</div>
											<hr className="my-4" />
										</div>
									);
								})}
								<div className="row justify-content-center">
									<div className="col-auto">
										<RoundedAddButton
											toolTipMsg={"Adicionar uma nova pergunta"}
											onClick={handleNewQuestion}
										/>
									</div>
								</div>
							</Collapse>
							{/* =============== END AUTH QUESTIONS =============== */}
						</div>
					</div>
					<div className="card-footer text-right">
						<button className="btn btn-primary">Salvar</button>
					</div>
				</div>
			</form>
		</>
	);
};
export default AuthQuestionsForm;
