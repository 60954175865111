import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Collapse } from "reactstrap";

import validate from "~/components/common/validate";

import { createDatabase } from "~/states/modules/databases";

import constants from "../constants";
const { DIALECTS } = constants;

const NewDatabase = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { register, handleSubmit, errors, reset, watch, getValues, setValue } =
		useForm({
			mode: "onChange",
		});

	const isSshTunnel = watch("isSshTunnel");

	const dialect = watch("dialect");

	const createdDatabase = useSelector(
		({ databases }) => databases.createdDatabase
	);

	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		if (showMessage) {
			if (createdDatabase && createdDatabase.error) {
				toast.warn(createdDatabase.error.message);
			}
		}
	}, [createdDatabase?.error]);

	useEffect(() => {
		if (showMessage) {
			if (createdDatabase && createdDatabase.data) {
				toast.success(
					`Banco de dados ${createdDatabase.data.database} cadastrado com sucesso!`
				);
				history.goBack();
			}
		}
	}, [createdDatabase?.data]);

	// const hasErrors = () => Object.keys(errors).length > 0;

	const onSubmit = (data) => {
		setShowMessage(true);
		dispatch(createDatabase(data));
		reset();
	};

	const isSqlite = () => dialect == DIALECTS[2];

	const goBack = () => history.goBack();

	const encodeText = () => {
		const inputedSshKey = getValues().encodedSshPrivateKey;

		if (!!inputedSshKey) setValue("sshPrivateKey", inputedSshKey);

		const savedSshKey = getValues().sshPrivateKey;
		setValue("encodedSshPrivateKey", hideText(savedSshKey));
	};
	const removeText = () => {
		setValue("encodedSshPrivateKey", "");
	};
	const getText = () => {
		setValue("encodedSshPrivateKey", hideText(getValues().sshPrivateKey));
	};

	const hideText = (txt) => {
		let hiddenTxt = "";
		for (let i = 0; i < txt.length; i++) hiddenTxt += "*";
		return hiddenTxt;
	};

	const onSubmitWrapper = (data) => {
		delete data.encodedSshPrivateKey;
		onSubmit(data);
	};

	return (
		<Fragment>
			{/* <Breadcrumb
				parent="Empresa / Bancos de Dados"
				current="Novo Banco de Dados"
				title="Novo Banco de Dados"
			/> */}
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form
							className="needs-validation"
							noValidate=""
							onSubmit={handleSubmit(onSubmitWrapper)}
						>
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col">
											<h5>
												Cadastre um novo banco de dados preenchendo as
												informações abaixo.
											</h5>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="database">
													Nome
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													type="text"
													name="database"
													id="database"
													ref={register(validate.required())}
												/>
												<span className="auth--validation-alert">
													{errors.database?.message}
												</span>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="host">
													Host
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													type="text"
													name="host"
													id="host"
													ref={register(validate.ipOrDomain())}
												/>
												<span className="auth--validation-alert">
													{errors.host?.message}
												</span>
											</div>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="port">
													Porta
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													type="text"
													name="port"
													id="port"
													ref={register(validate.onlyNumbers())}
												/>
												<span className="auth--validation-alert">
													{errors.port?.message}
												</span>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="dialect">
													Dialeto
													<span className="auth--required-symbol">*</span>
												</label>
												<select
													className="form-control digits"
													id="dialect"
													name="dialect"
													ref={register(validate.required())}
												>
													{DIALECTS.map((dialect, index) => (
														<option key={`dialect-${index}`}>{dialect}</option>
													))}
												</select>
												<span className="auth--validation-alert">
													{errors.dialect?.message}
												</span>
											</div>
										</div>
									</div>
									<Collapse isOpen={isSqlite()}>
										<div className="form-row">
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<label className="col-form-label" htmlFor="storage">
														Armazenamento
														<span className="auth--required-symbol">*</span>
													</label>
													{isSqlite() && (
														<input
															className="form-control"
															type="text"
															name="storage"
															id="storage"
															ref={register(validate.required())}
														/>
													)}
													<span className="auth--validation-alert">
														{errors.storage?.message}
													</span>
												</div>
											</div>
										</div>
									</Collapse>
									<div className="form-row">
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="username">
													Usuário
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													type="text"
													name="username"
													id="username"
													ref={register(validate.required())}
												/>
												<span className="auth--validation-alert">
													{errors.username?.message}
												</span>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="col-form-label" htmlFor="password">
													Senha
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													type="password"
													name="password"
													id="password"
													ref={register(validate.required())}
												/>
												<span className="auth--validation-alert">
													{errors.password?.message}
												</span>
											</div>
										</div>
									</div>

									<hr className="mt-4" />
									<div className="form-row">
										<div className="checkbox  checkbox-primary col-3 ml-3">
											<input
												className="form-control"
												type="checkbox"
												name="isSshTunnel"
												id="isSshTunnel"
												ref={register}
											/>
											<label className="col-form-label" htmlFor="isSshTunnel">
												Conexão via SSH
											</label>
										</div>
									</div>
									<Collapse isOpen={isSshTunnel}>
										{isSshTunnel && (
											<div className="form-row">
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label
															className="col-form-label"
															htmlFor="sshUsername"
														>
															Usuário SSH
															<span className="auth--required-symbol">*</span>
														</label>
														<input
															className="form-control"
															type="text"
															name="sshUsername"
															id="sshUsername"
															ref={register(validate.required())}
														/>
														<span className="auth--validation-alert">
															{errors.sshUsername?.message}
														</span>
													</div>
												</div>
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label className="col-form-label" htmlFor="sshHost">
															Host SSH
															<span className="auth--required-symbol">*</span>
														</label>
														<input
															className="form-control"
															type="text"
															name="sshHost"
															id="sshHost"
															ref={register(validate.ipOrDomain())}
														/>
														<span className="auth--validation-alert">
															{errors.sshHost?.message}
														</span>
													</div>
												</div>
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label
															className="col-form-label"
															htmlFor="sshPrivateKey"
														>
															Chave SSH
															<span className="auth--required-symbol">*</span>
														</label>
														<textarea
															className="form-control d-none"
															id="sshPrivateKey"
															name="sshPrivateKey"
															wrap="hard"
															ref={register(validate.required())}
														></textarea>
														<textarea
															className="form-control"
															id="encodedSshPrivateKey"
															rows="4"
															name="encodedSshPrivateKey"
															wrap="hard"
															ref={register(validate.required())}
															onFocus={removeText}
															onBlur={() => {
																encodeText();
																getText();
															}}
														></textarea>
														<span className="auth--validation-alert">
															{errors.sshPrivateKey?.message}
														</span>
													</div>
												</div>
											</div>
										)}
									</Collapse>
								</div>
								<div className="card-footer text-right">
									<button
										className="btn btn-link mr-4"
										type="button"
										onClick={goBack}
									>
										Voltar
									</button>
									<button
										// className={`btn btn-primary ${
										// 	hasErrors() ? "disabled" : ""
										// }`}
										className={"btn btn-primary"}
										type="submit"
									>
										Cadastrar
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NewDatabase;
