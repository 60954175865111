import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPolicyById } from "~/states/modules/policies";
import Breadcrumb from "~/components/common/breadcrumb";
import PolicyDetailsCard from "./PolicyDetailsCard";
import PolicyHistoryCard from "./PolicyHistoryCard/PolicyHistoryCard";

/*
	policy = authorId,
		companyId,
		content,
		createdAt,
		emailMessage,
		emailSubject,
		id,
		majorVersion,
		minorVersion,
		name,
		policyTypeId,
		updatedAt,
*/

function PolicyDetails() {
	const { policyId } = useParams();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPolicyById({ id: policyId }));
	}, [dispatch]);

	const policy = useSelector(({ policies }) => policies.policy.data);

	return (
		<>
			<Breadcrumb
				current="Detalhes da Política"
				title={`Detalhes da Política -`}
				parent="Gestão de Políticas"
			/>
			{policy && (
				<div className="container-fluid">
					<PolicyDetailsCard policy={policy} />
					<PolicyHistoryCard histories={policy.histories} />
				</div>
			)}
		</>
	);
}

export default PolicyDetails;
