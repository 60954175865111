import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	current: { data: null, loading: false, error: null },
};

const { actions, reducer } = createSlice({
	name: "appConfig",
	initialState,
	reducers: {
		getAppConfig: (state) =>
			extend(state, {
				current: { ...state.current, loading: true, error: null },
			}),
		getAppConfigSuccess: (state, { payload }) =>
			extend(state, {
				current: {
					...state.current,
					loading: false,
					data: payload.data,
				},
			}),
		getAppConfigFailure: (state, { payload }) =>
			extend(state, {
				current: { ...state.current, loading: false, error: payload },
			}),
		editAppConfig: (state) =>
			extend(state, {
				current: { ...state.current, loading: true, error: null },
			}),
		editAppConfigSuccess: (state, { payload }) =>
			extend(state, {
				current: {
					...state.current,
					loading: false,
					data: payload.data,
				},
			}),
		editAppConfigFailure: (state, { payload }) =>
			extend(state, {
				current: { ...state.current, loading: false, error: payload },
			}),
	},
});

export const {
	getAppConfig,
	getAppConfigSuccess,
	getAppConfigFailure,
	editAppConfig,
	editAppConfigFailure,
	editAppConfigSuccess,
} = actions;

export { default as appConfigSaga } from "./sagas";

export default reducer;
