import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AddTicketButton from "../AddButton/AddTicketButton";
import RefreshButton from "../RefreshButton/RefreshButton";
import CardTable from "~/components/common/cardTable";

import { getTickets } from "~/states/modules/tickets";
import Breadcrumb from "../../common/breadcrumb";
import TicketTable from "./ticketTable";
import { translate } from "../../../assets/customPackages/react-switch-lang";

const TicketsList = ({ t }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const loading = useSelector(({ tickets }) => tickets.tickets?.loading);
	const tableParams = useSelector(
		({ tickets }) => tickets.tickets?.tableParams
	);

	const _addticketBtnOnclickHandler = () => {
		history.push(`${process.env.PUBLIC_URL}/tickets/add`);
	};

	return (
		<Fragment>
			<Breadcrumb
				current={t("PAGE.TICKET_LIST.TITLE")}
				title={t("PAGE.TICKET_LIST.TITLE")}
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col">
										<h5>{t("PAGE.TICKET_LIST.DESCRIPTION")}</h5>
									</div>
									<div className="col text-right">
										<RefreshButton
											onClick={() => dispatch(getTickets(tableParams))}
											isLoading={loading}
										/>
										<AddTicketButton onClick={_addticketBtnOnclickHandler} />
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								<TicketTable />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default translate(TicketsList);
