import React from "react";
import TabHead from "./tabHead";

const TabList = ({ tabs }) => {
	const createTabHeadList = tabs
		.filter((tab) => !!tab.header)
		.map((tab) => {
			tab.header.path = tab.header.path || tab.path;
			return tab.header;
		})
		.map((header, index) => <TabHead header={header} key={index} />);

	return (
		<div className="dl-routed-tabs-tablist-container">
			<ul className="dl-routed-tabs-tablist">{createTabHeadList}</ul>
		</div>
	);
};

export default TabList;
