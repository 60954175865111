import React from "react";
import { columns } from "./policyTypesTableListConstants";
import ControlledTable from "~/components/common/controlledTable/controlledTable";
import { getPolicyTypes } from "~/states/modules/policyTypes";

function PolicyTypesListTable({
	policyTypes,
	isLoadingData,
	tableParams,
	totalCount,
}) {
	return (
		<ControlledTable
			isLoadingData={isLoadingData}
			data={policyTypes}
			columns={columns}
			pageCount={Math.ceil(totalCount / tableParams.pageSize)}
			totalCount={totalCount}
			disableOrdenation={true}
			disableFilters={true}
			getData={getPolicyTypes}
			globalTableParams={tableParams}
		/>
	);
}

export default PolicyTypesListTable;
