import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import AccessControl from "~/components/common/accessControl";
import validate from "~/components/common/validate";
import {
	ckeditorRegexReplacer,
	editorFormatOnReady,
	configToolBar,
} from "~/components/common/ckeditorTools";

const EmailTemplateForm = ({
	emailTemplates = [],
	onSubmit,
	resetTemplate,
}) => {
	const [currentTemplate, setCurrentTemplate] = useState(emailTemplates[0]);
	const { register, handleSubmit, setValue, errors, control } = useForm({
		defaultValues: currentTemplate,
	});

	// Watch for eventuals changes in the list of email templates in order to update the form
	useEffect(() => {
		handleTemplateNameChange(currentTemplate.name);
	}, [emailTemplates]);

	const handleTemplateNameChange = (targetName) => {
		const targetEmailTemplate = emailTemplates.find(
			({ name }) => name === targetName
		);
		Object.keys(targetEmailTemplate).forEach((field) =>
			setValue(field, targetEmailTemplate[field])
		);
		setCurrentTemplate(targetEmailTemplate);
	};

	const handleTemplateHtmlChange = (event, editor) =>
		setValue("html", editor.getData());

	const onSubmitWrapper = (data) => {
		if (!!data.html) {
			// Remove whitespace from both sides of a string - Replace all 3 types of line breaks with a space - Replace all double white spaces with single spaces
			data.html = ckeditorRegexReplacer(data.html);
		}
		onSubmit(data);
	};

	return (
		<div className="tab-content active default" id="tab-1">
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(onSubmitWrapper)}
			>
				<div className="card">
					<div className="card-body">
						<div className="form-row">
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="name">
									Email
									<span className="auth--required-symbol">*</span>
									<br />
									<small>Selecione o email que deseja editar.</small>
								</label>
								<select
									className="form-control"
									id="name"
									name="name"
									ref={register}
									onChange={(e) =>
										handleTemplateNameChange(e.currentTarget.value)
									}
								>
									{emailTemplates.map((template, index) => (
										<option key={index}>{template.name}</option>
									))}
								</select>
							</div>
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="title">
									Título do Email
									<span className="auth--required-symbol">*</span>
									<br />
									<small>
										Texto que aparece logo abaixo do cabeçalho do email.
									</small>
								</label>
								<input
									className="form-control"
									type="text"
									id="title"
									name="title"
									ref={register(validate.required())}
								/>
								<ErrorMessage
									errors={errors}
									name="title"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="subject">
									Assunto
									<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="text"
									id="subject"
									name="subject"
									ref={register(validate.required())}
								/>
								<ErrorMessage
									errors={errors}
									name="subject"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="htmlEditor">
									HTML
									<span className="auth--required-symbol">*</span>
									<br />
									<small>Conteúdo para renderizar o corpo do email.</small>
								</label>
								<Controller
									name="html"
									defaultValue=""
									control={control}
									rules={validate.required()}
									render={({ name, ref }) => (
										<CKEditorWrappered
											id="html"
											name={name}
											ref={ref}
											data={currentTemplate.html}
											onChange={handleTemplateHtmlChange}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="html"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="text">
									Texto
									<br />
									<small>
										Conteúdo alternativo caso não seja possível exibir o
										conteúdo HTML.
									</small>
								</label>
								<textarea
									className="form-control"
									rows="10"
									id="text"
									name="text"
									ref={register}
								/>
								<ErrorMessage
									errors={errors}
									name="text"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
					</div>
					<div className="card-footer text-right">
						<AccessControl requiredPermissions={[`emailTemplate:delete`]}>
							<button
								className="btn btn-outline-primary mr-4"
								type="button"
								onClick={() => resetTemplate(currentTemplate)}
							>
								Resetar
							</button>
						</AccessControl>
						<AccessControl requiredPermissions={[`emailTemplate:upsert`]}>
							<button className="btn btn-primary" type="submit">
								Salvar
							</button>
						</AccessControl>
					</div>
				</div>
			</form>
		</div>
	);
};
export default EmailTemplateForm;
