import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./file-upload.css";
import { editCurrentUser } from "~/states/modules/users";
import { getApiUrl } from "../../../services/envUtils";

const ProfileSummary = () => {
	const dispatch = useDispatch();
	const token = useSelector(({ session }) => session.token);

	//const currentUser = useSelector(({ users }) => users.currentUser);
	const currentUser = useSelector(({ users }) => users.currentUser?.data);

	const photoData = {
		src: `${getApiUrl()}/users/me/photo?authorization=${token}`,
		hash: Date.now(),
	};

	const handleChangePhoto = (file) => {
		const formData = new FormData();
		formData.append("photo", file);
		dispatch(
			editCurrentUser({
				userId: currentUser.id,
				data: formData,
			})
		);
	};

	const handleFile = (event) => {
		if (event.target.files.length === 0) return;

		var mimeType = event.target.files[0].type;

		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		handleChangePhoto(event.target.files[0]);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col my-2 text-center">
					<img
						className="img-fluid rounded-circle"
						src={`${photoData.src}&v=${photoData.hash}`}
						alt="Profile"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col my-2 text-center">
					<h3>{currentUser?.name}</h3>
					{currentUser?.roles?.map((r) => (
						<span
							key={r.id}
							className="badge badge-secondary"
							style={{ backgroundColor: "#808080" }}
						>
							{r.name}
						</span>
					))}
				</div>
			</div>
			<div className="row">
				<div className="col my-2 text-center">
					<button type="button" className="btn btn-primary btn-block">
						<label
							htmlFor="file-upload"
							className="custom-file-upload my-1 btn-block"
						>
							Alterar Imagem
						</label>
						<input
							id="file-upload"
							type="file"
							onChange={(e) => handleFile(e)}
						/>
					</button>
				</div>
			</div>
		</div>
	);
};
export default ProfileSummary;
