import {
	call,
	put,
	takeEvery,
	takeLatest,
	takeLeading,
} from "redux-saga/effects";

import api from "~/services/api";
import {
	login,
	loginFailure,
	loginSuccess,
	logout,
	logoutFailure,
	logoutSuccess,
	forgetPassword,
	forgetPasswordSuccess,
	forgetPasswordFailure,
	changePassword,
	changePasswordSuccess,
	changePasswordFailure,
	changeCompanyTo,
	setNewRefreshTokenSuccess,
	setError,
} from ".";

export default function* rootSaga() {
	yield takeLatest("persist/REHYDRATE", setToken);
	yield takeEvery(login, loginSaga);
	yield takeEvery(logout, logoutSaga);
	yield takeEvery(forgetPassword, forgetPasswordSaga);
	yield takeEvery(changePassword, changePasswordSaga);
	yield takeEvery(changeCompanyTo, changeCompanyToSaga);
}

function* loginSaga({ payload }) {
	const { email, password, history, next } = payload;
	try {
		const { data } = yield call(api.post, "/users/signin", {
			email,
			password,
		});

		api.defaults.headers.Authorization = `Bearer ${data.data.token}`;
		yield put(loginSuccess(data));
		history.push(next || "/");
	} catch (error) {
		yield put(loginFailure(error));
	}
}
function* logoutSaga() {
	try {
		yield put(logoutSuccess());
	} catch (error) {
		yield put(logoutFailure(error));
	}
}

export function setToken({ payload }) {
	if (!payload) return;

	const { token } = payload.session;
	if (token) {
		api.defaults.headers.Authorization = `Bearer ${token}`;
	}
}

function* changeCompanyToSaga({ payload }) {
	const { id } = payload;
	try {
		const { data } = yield call(api.put, "/users/me/currentCompany", {
			currentCompanyId: id,
		});
		yield put(setNewRefreshTokenSuccess(data));
		api.defaults.headers.Authorization = `Bearer ${data.token}`;
		window.location.reload();
	} catch (error) {
		yield put(setError(error));
	}
}

function* forgetPasswordSaga({ payload }) {
	const { email } = payload;
	try {
		yield call(api.post, "/users/forgot", {
			email,
			origin: window.location.origin,
		});

		yield put(forgetPasswordSuccess());
	} catch (error) {
		yield put(forgetPasswordFailure(error));
	}
}

function* changePasswordSaga({ payload }) {
	const { password, token } = payload,
		path = token ?  "/users/reset" : "/users/resetProfile",
		_payload = token ? { password, token } : { password };

	try {
		const { data } = yield call(api.post, path, _payload);
		yield put(changePasswordSuccess(data));
	} catch (error) {
		yield put(changePasswordFailure(error));
	}
}
