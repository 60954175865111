import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	list: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
		},
	},
	createdConsentTypes: {
		error: null,
		loading: true,
		data: null,
	},
};

// TODO Rename consentTypes to best encapsulation - also needs the in store.js
const { actions, reducer } = createSlice({
	name: "consentTypes",
	initialState,
	reducers: {
		getConsentTypes: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					tableParams: { ...state.list.tableParams, ...payload },
					error: null,
					loading: true,
				},
			}),
		getConsentTypesSuccess: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					error: null,
					loading: false,
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			}),
		getConsentTypesFailure: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					error: payload,
					loading: false,
				},
			}),

		createConsentTypes: (state) =>
			extend(state, {
				createdConsentTypes: {
					error: null,
					loading: true,
				},
			}),
		createConsentTypesSuccess: (state, { payload }) =>
			extend(state, {
				createdConsentTypes: {
					error: null,
					loading: false,
					data: payload,
				},
			}),
		createConsentTypesFailure: (state, { payload }) =>
			extend(state, {
				createdConsentTypes: {
					loading: false,
					error: payload,
				},
			}),
	},
});

export const {
	getConsentTypes,
	getConsentTypesSuccess,
	getConsentTypesFailure,
	createConsentTypes,
	createConsentTypesSuccess,
	createConsentTypesFailure,
} = actions;
export { default as consentTypesSaga } from "./sagas";
export default reducer;
