import React, { useState, useMemo } from "react";
import { Plus } from "react-feather";
import { Tooltip } from "reactstrap";
import "./style.scss";

const getId = (function () {
	let i = 0;
	return () => ++i;
})();

const RoundedAddButton = (props) => {
	let { id, toolTipMsg, ...restProps } = props;
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);
	
	const _id = `rounded-btn-add-${useMemo(() => getId(), [toolTipMsg])}`; 
	id = id || _id;
	

	return (
		<>
			<button
				id={id}
				className="add-item-button"
				type="button"
				{...restProps}
			>
				<Plus className="plus-icon" />
			</button>
			{toolTipMsg && (
				<Tooltip
					target={id}
					delay={{ show: 1200, hide: 0 }}
					placement="top"
					isOpen={tooltipOpen}
					toggle={toggle}
				>
					{toolTipMsg}
				</Tooltip>
			)}
		</>
	);
};

export default RoundedAddButton;
