import merge from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	all: { data: [], error: null, loading: false },
	edited: { data: null, error: null, loading: false },
	deleted: { data: null, error: null, loading: false },
};

const { actions, reducer } = createSlice({
	name: "emailTemplates",
	initialState,
	reducers: {
		// Get
		getEmailTemplates: (state) =>
			merge(state, { all: { loading: true, error: null } }),
		getEmailTemplatesFailure: (state, { payload }) =>
			merge(state, { all: { loading: false, error: payload } }),
		getEmailTemplatesSuccess: (state, { payload }) =>
			merge(state, { all: { loading: false, data: payload.data } }),
		// Edit
		editEmailTemplate: (state) =>
			merge(state, { edited: { loading: true, error: null } }),
		editEmailTemplateFailure: (state, { payload }) =>
			merge(state, { edited: { loading: false, error: payload } }),
		editEmailTemplateSuccess: (state, { payload }) => {
			const editedTemplateIndex = state.all.data.findIndex(
				({ name }) => name == payload.data.name
			);
			state.all.data[editedTemplateIndex] = payload.data;
			merge(state, { edited: { loading: false, data: payload.data } });
		},
		// Delete
		deleteEmailTemplate: (state) =>
			merge(state, { deleted: { loading: true, error: null } }),
		deleteEmailTemplateFailure: (state, { payload }) =>
			merge(state, { deleted: { loading: false, error: payload.error } }),
		deleteEmailTemplateSuccess: (state, { payload }) => {
			const deletedTemplateIndex = state.all.data.findIndex(
				({ name }) => name == payload.data.name
			);
			state.all.data[deletedTemplateIndex] = payload.data;
			merge(state, { deleted: { loading: false, data: payload.data } });
		},
	},
});

export const {
	getEmailTemplates,
	getEmailTemplatesSuccess,
	getEmailTemplatesFailure,
	editEmailTemplate,
	editEmailTemplateFailure,
	editEmailTemplateSuccess,
	deleteEmailTemplate,
	deleteEmailTemplateFailure,
	deleteEmailTemplateSuccess,
} = actions;

export { default as emailTemplatesSaga } from "./sagas";

export default reducer;
