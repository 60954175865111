import React from "react";
import Breadcrumb from "~/components/common/breadcrumb";
import RoutedTabs from "../../common/routedTabs";
import tabs from "./tabs-config";

const Integrations = () => {
	return (
		<>
			<Breadcrumb parent="Empresa" current="Integrações" title="Integrações" />
			<RoutedTabs tabs={tabs} basePath="/company/integrations" />
		</>
	);
};

export default Integrations;
