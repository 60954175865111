const regexTagsString = "(p)*(h1)*(h2)*(h3)*(h4)*";
const regexTagsTrim = "(<" + regexTagsString + "></" + regexTagsString + ">)*";

export const ckeditorRegexReplacer = (string) =>
	string
		.replace(/(&nbsp;)*/gm, "")
		// .replace(/(&gt;)+/g, '>')
		// .replace(/( )+<|(&lt;)+/g, '<')
		.replace(
			new RegExp("^" + regexTagsTrim + "|" + regexTagsTrim + "$", "g"),
			""
		)
		.replace(/(\r\n|\n|\r|( )+|)\s+/gm, " ");

export const editorFormatOnReady = (editor) => {
	// You can store the "editor" and use when it is needed.
	editor &&
		editor.editing.view.change((writer) => {
			// Setting the min-height
			writer.setStyle(
				"min-height",
				"200px",
				editor.editing.view.document.getRoot()
			);
		});
};

export const configToolBar = (editor, toolBarId) => {
	// Add the toolbar to the container
	editor &&
		document
			.querySelector(`#${toolBarId}`)
			.appendChild(editor.ui.view.toolbar.element);
};

export default { ckeditorRegexReplacer, editorFormatOnReady, configToolBar };
