import { ErrorMessage } from "@hookform/error-message";

import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import validate from "../../../common/validate";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Collapse } from "reactstrap";

import ColorPicker from "~/components/common/colorPicker/index";
import colorPickerHexConversor from "~/components/common/colorPicker/colorPickerHexConversor";

const AppSelectionForm = ({ onSubmit, appConfig, fonts }) => {
	// Default Values
	const defaultAppConfig = { ...appConfig };

	defaultAppConfig.backgroundPrimaryColor =
		defaultAppConfig.backgroundPrimaryColor || "#ffffff";
	defaultAppConfig.backgroundSecondaryColor =
		defaultAppConfig.backgroundSecondaryColor || "#ffffff";
	defaultAppConfig.textColor = defaultAppConfig.textColor || "#000000";
	defaultAppConfig.headerAndFooterColor =
		defaultAppConfig.headerAndFooterColor || "#ffffff";
	defaultAppConfig.inputAndDotColor =
		defaultAppConfig.inputAndDotColor || "#000000";
	defaultAppConfig.lineColor = defaultAppConfig.lineColor || "#000000";
	defaultAppConfig.borderColor = defaultAppConfig.borderColor || "#000000";
	defaultAppConfig.linkColor = defaultAppConfig.linkColor || "#000000";
	defaultAppConfig.errorColor = defaultAppConfig.errorColor || "#ff0000";

	const { register, handleSubmit, watch, errors, control, setValue } = useForm({
		defaultValues: defaultAppConfig,
		mode: "onChange",
	});

	const defaultColor = "#000000";

	const [backgroundPrimaryColor, setBackgroundPrimaryColor] = useState(
		defaultAppConfig?.backgroundPrimaryColor || defaultColor
	);

	const [backgroundSecondaryColor, setBackgroundSecondaryColor] = useState(
		defaultAppConfig?.backgroundSecondaryColor || defaultColor
	);

	const [textColor, setTextColor] = useState(
		defaultAppConfig?.textColor || defaultColor
	);

	const [headerAndFooterColor, setHeaderAndFooterColor] = useState(
		defaultAppConfig?.headerAndFooterColor || defaultColor
	);

	const [inputAndDotColor, setInputAndDotColor] = useState(
		defaultAppConfig?.inputAndDotColor || defaultColor
	);

	const [lineColor, setLineColor] = useState(
		defaultAppConfig?.lineColor || defaultColor
	);

	const [borderColor, setBorderColor] = useState(
		defaultAppConfig?.borderColor || defaultColor
	);

	const [linkColor, setLinkColor] = useState(
		defaultAppConfig?.linkColor || defaultColor
	);

	const [errorColor, setErrorColor] = useState(
		defaultAppConfig?.errorColor || defaultColor
	);

	const watchedSwitch = watch("showFAQ");
	const watchedLogo = watch("logo");

	const layoutFormatSelect = useRef();
	const textFontSelect = useRef();
	const titleFontSelect = useRef();

	const onSubmitController = (input) => {
		// Pop out selected font since typeahead stores an array
		if (Array.isArray(input.layoutFormat))
			input.layoutFormat = input.layoutFormat[0];
		if (Array.isArray(input.textFontFamily))
			input.textFontFamily = input.textFontFamily[0];
		if (Array.isArray(input.titleFontFamily))
			input.titleFontFamily = input.titleFontFamily[0];
		// Remove empty fields
		Object.keys(input).forEach(
			(field) => input[field] === "" && delete input[field]
		);
		onSubmit(input);
	};

	const options = ["Circle", "Square"];

	return (
		<div className="tab-content active default" id="tab-1">
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(onSubmitController)}
			>
				<div className="card">
					<div className="card-body">
						<div className="form-row col-12 text-left">
							<div className="col-12">
								<h5>Formatação</h5>
								<p>
									Escolha a imagem a ser usada no rodapé do aplicativo de
									direitos e o layout da estrutura do mesmo. Deve ser no formato
									de url.
								</p>
							</div>
							<div className="form-group col-md-8 col-sm-12">
								<label className="col-form-label" htmlFor="logo">
									Logo
								</label>
								<input
									className="form-control"
									id="logo"
									type="text"
									name="logo"
									ref={register(validate.url())}
								/>

								<ErrorMessage
									errors={errors}
									name="logo"
									as={<span className="auth--validation-alert" />}
								/>
								<div style={{ marginTop: "10px" }}>
									<label onClick={() => layoutFormatSelect.current?.focus()}>
										Layout do App
									</label>
									<Controller
										render={(
											{ onChange, onBlur, value, name, ref },
											{ invalid, isTouched, isDirty }
										) => (
											<Typeahead
												id="layoutFormat"
												defaultSelected={
													appConfig?.layoutFormat
														? [appConfig.layoutFormat]
														: []
												}
												clearButton
												options={options}
												maxResults={10}
												ref={layoutFormatSelect}
												onChange={onChange}
												onBlur={onBlur}
											/>
										)}
										name="layoutFormat"
										defaultValue={appConfig?.layoutFormat}
										control={control}
										onFocus={() => layoutFormatSelect.current?.focus()}
									/>
									{/* <Select
										id="layoutFormat"
										ref={layoutFormatSelect}
										fieldName="layoutFormat"
										name={"layoutFormat"}
										onFocus={() =>
											layoutFormatSelect.current?.focus()
										}
										registerField={{ name: "layoutFormat" }}
										options={options}
									/> */}
								</div>
							</div>
							<div className="form-group col-md-4 col-sm-12 text-center align-self-end">
								<img
									className="img-fluid"
									src={watchedLogo}
									width="250px"
									height="125px"
								/>
							</div>
						</div>
						<br />
						{/*************************************************/}
						<hr className="mt-4 mb-4" />
						<div className="form-row col-12 text-left">
							<div className="col-12">
								<h5>Fontes</h5>
								<p>
									Escolha a fonte, entre as opções disponíveis, para o texto e
									título do aplicativo de direitos.
								</p>
							</div>
						</div>
						<br />
						<div className="form-row col-md-12 col-sm-12">
							<div className="col-md-6 col-sm-12">
								<div>
									<label onClick={() => textFontSelect.current?.focus()}>
										Texto
									</label>
									<Controller
										render={(
											{ onChange, onBlur, value, name, ref },
											{ invalid, isTouched, isDirty }
										) => (
											<Typeahead
												id="textFontFamily"
												defaultSelected={
													appConfig?.textFontFamily
														? [appConfig.textFontFamily]
														: []
												}
												clearButton
												options={fonts}
												maxResults={10}
												ref={textFontSelect}
												onChange={onChange}
												onBlur={onBlur}
											/>
										)}
										name="textFontFamily"
										defaultValue={appConfig?.textFontFamily || ""}
										control={control}
										onFocus={() => textFontSelect.current?.focus()}
									/>
									{/* <label>Texto</label>
								<Select
									id="textFontFamily"
									ref={register}
									name="textFontFamily"
									options={fontsApp}
								/>
							</div>
									/> */}
								</div>
							</div>

							<div className="col-md-6 col-sm-12">
								<div>
									<label onClick={() => titleFontSelect.current?.focus()}>
										Título
									</label>
									<Controller
										render={(
											{ onChange, onBlur, value, name, ref },
											{ invalid, isTouched, isDirty }
										) => (
											<Typeahead
												id="titleFontFamily"
												defaultSelected={
													appConfig?.titleFontFamily
														? [appConfig.titleFontFamily]
														: []
												}
												clearButton
												options={fonts}
												maxResults={10}
												ref={titleFontSelect}
												onChange={onChange}
												onBlur={onBlur}
											/>
										)}
										name="titleFontFamily"
										defaultValue={appConfig?.titleFontFamily || ""}
										control={control}
										onFocus={() => titleFontSelect.current?.focus()}
									/>
									{/*
									<label>Título</label>
							<Select
								id="titleFontFamily"
								ref={register}
								name="titleFontFamily"
								options={fontsApp}
							/>
						</div>
					</div>
									/> */}
								</div>
							</div>
						</div>
						<br />
						<br />
						{/************************************************************/}
						<hr className="mt-4 mb-4" />
						<div className="form-row col-md-12 col-sm-12 text-left">
							<div className="col-12">
								<h5>Cores</h5>
								<p>Escolha as cores que vão compor o aplicativo de direitos.</p>
								<p
									style={{
										fontSize: "11px",
										marginTop: "-5px",
									}}
								>
									<b>Observação:</b> aconselhado que se for customizada a cor do
									rodapé, que seja customizada a cor do link do mesmo para
									evitar dificuldade de leitura.
								</p>
							</div>
						</div>
						<br />
						<div className="form-row col-md-12 col-sm-12 text-center">
							<div className="col-md-4 col-sm-12">
								<Controller
									name="backgroundPrimaryColor"
									defaultValue={backgroundPrimaryColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor primária"
											color={backgroundPrimaryColor}
											onChange={(color) => {
												setBackgroundPrimaryColor(
													colorPickerHexConversor(color)
												);
												setValue(
													"backgroundPrimaryColor",
													colorPickerHexConversor(color)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="backgroundPrimaryColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="backgroundSecondaryColor"
									defaultValue={backgroundSecondaryColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor secundária"
											color={backgroundSecondaryColor}
											onChange={(color) => {
												setBackgroundSecondaryColor(
													colorPickerHexConversor(color)
												);
												setValue(
													"backgroundSecondaryColor",
													colorPickerHexConversor(color)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="backgroundSecondaryColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="textColor"
									defaultValue={textColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do texto"
											color={textColor}
											onChange={(color) => {
												setTextColor(colorPickerHexConversor(color));
												setValue("textColor", colorPickerHexConversor(color));
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="textColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
						<br />
						<div className="form-row col-md-12 col-sm-12 text-center">
							<div className="col-md-4 col-sm-12">
								<Controller
									name="headerAndFooterColor"
									defaultValue={headerAndFooterColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do rodapé"
											color={headerAndFooterColor}
											onChange={(color) => {
												setHeaderAndFooterColor(colorPickerHexConversor(color));
												setValue(
													"headerAndFooterColor",
													colorPickerHexConversor(color)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="headerAndFooterColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="inputAndDotColor"
									defaultValue={inputAndDotColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do checkbox"
											color={inputAndDotColor}
											onChange={(color) => {
												setInputAndDotColor(colorPickerHexConversor(color));
												setValue(
													"inputAndDotColor",
													colorPickerHexConversor(color)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="inputAndDotColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="lineColor"
									defaultValue={lineColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor da linha pontilhada"
											color={lineColor}
											onChange={(color) => {
												setLineColor(colorPickerHexConversor(color));
												setValue("lineColor", colorPickerHexConversor(color));
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="lineColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
						<br />
						<div className="form-row col-md-12 col-sm-12 text-center">
							<div className="col-md-4 col-sm-12">
								<Controller
									name="borderColor"
									defaultValue={borderColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor da borda do rodapé"
											color={borderColor}
											onChange={(color) => {
												setBorderColor(colorPickerHexConversor(color));
												setValue("borderColor", colorPickerHexConversor(color));
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="borderColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="linkColor"
									defaultValue={linkColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do link do rodapé"
											color={linkColor}
											onChange={(color) => {
												setLinkColor(colorPickerHexConversor(color));
												setValue("linkColor", colorPickerHexConversor(color));
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="linkColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="col-md-4 col-sm-12">
								<Controller
									name="errorColor"
									defaultValue={errorColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor da mensagem de erro"
											color={errorColor}
											onChange={(color) => {
												setErrorColor(colorPickerHexConversor(color));
												setValue("errorColor", colorPickerHexConversor(color));
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="errorColor"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
						<hr className="mt-4 mb-4" />
						<div className="col-12">
							<h5>Informações opcionais</h5>
							<div className="form-row checkbox checkbox-primary col-12">
								<input
									name="showFAQ"
									id="showFAQ"
									type="checkbox"
									className="form-check-input"
									ref={register}
								/>
								<label className="col-form-label" htmlFor="showFAQ">
									Ativar link para o FAQ
								</label>
							</div>
							<Collapse isOpen={watchedSwitch}>
								<div className="form-row">
									<label className="col-form-label" htmlFor="faqLink">
										Link para FAQ da empresa
									</label>
									<input
										placeholder="Insira o link para o FAQ da empresa"
										className="form-control"
										id="faqLink"
										type="text"
										name="faqLink"
										ref={register(validate.mailtoOrUrl(false))}
									/>
									<span className="auth--validation-alert">
										{errors.faqLink?.message}
									</span>
								</div>
							</Collapse>
						</div>

						<br />
						<div className="card-footer text-right">
							<button className="btn btn-primary" type="submit">
								Salvar
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
export default AppSelectionForm;
