import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import Breadcrumb from "~/components/common/breadcrumb";
import PoliciesListTable from "./PoliciesListTable";
import { useHistory } from "react-router-dom";

function PoliciesList() {
	const history = useHistory();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const _goToAddPolicy = () => {
		history.push(`${process.env.PUBLIC_URL}/policies/add`);
	};
	return (
		<>
			<Breadcrumb title="Listagem de Políticas" current="Gestão de Políticas" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="row justify-content-between">
									<div className="col">
										<h4>Listagem de Políticas</h4>
									</div>
									<div className="col text-right align-middle">
										<button
											className={"btn btn-primary"}
											id="goToAddPolicy"
											onClick={_goToAddPolicy}
										>
											Adicionar nova política
										</button>
										<Tooltip
											target="goToAddPolicy"
											isOpen={tooltipOpen}
											toggle={toggle}
										>
											Clique aqui para cadastrar uma nova política.
										</Tooltip>
									</div>
								</div>
							</div>
							<div className="card-body p-0 datatable-react card-table">
								<PoliciesListTable />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PoliciesList;
