import React, { Fragment } from "react";
import { Info, AlertTriangle, Users } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

import Breadcrumb from "../common/breadcrumb";

import HomeComponent from "./homeComponent";
import TicketsComponent from "./ticketsComponents";
import AssignedRank from "./assignedRank";

const Dashboard = () => {
	// const [tabIndex, setTabIndex] = useState(0);

	// const _onSelectHandler = (index) => {
	// 	setTabIndex(index);
	// };

	return (
		<Fragment>
			<Breadcrumb current="Dashboard" title="Dashboard" />
			<div className="container-fluid">
				<div className="row theme-tab">
					<Tabs className="col-sm-12" /*onSelect={_onSelectHandler}*/>
						<TabList className="tabs tab-title">
							<Tab className="current">
								<Info />
								Informações Gerais
							</Tab>
							<Tab>
								<AlertTriangle />
								Pedidos em Atraso
							</Tab>
							<Tab>
								<Users />
								Membros
							</Tab>
						</TabList>
						<div className="tab-content-cls">
							<TabPanel>
								<HomeComponent />
							</TabPanel>
							<TabPanel>
								<TicketsComponent />
							</TabPanel>
							<TabPanel>
								<AssignedRank />
							</TabPanel>
						</div>
					</Tabs>
				</div>
			</div>
		</Fragment>
	);
};

export default Dashboard;
