import api from "./api";
import qs from "qs";

class PolicyService {
	getPolicies(params) {
		return api
			.get("/policies", {
				paramsSerializer: function (params) {
					return qs.stringify(params, { arrayFormat: "brackets" });
				},
				params: {
					page: params.tableParams.page,
					size: params.tableParams.size,
					// order: params.tableParams.order,
					// filter: params.tableParams.filter,
				},
			})
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to get Policies in PolicyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
	createPolicy(policy) {
		return api
			.post("/policies", policy)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to create Policy in PolicyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
	editPolicy(policy) {
		return api
			.patch(`/policies/${policy.id}`, policy)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to edit Policy in PolicyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	getPolicyById(policy) {
		return api
			.get(`/policies/${policy.id}`, policy)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to edit Policy in PolicyService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
}

export default new PolicyService();
