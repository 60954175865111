import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AccessControl from "~/components/common/accessControl";
import AddButton from "~/components/common/AddButton";
import Breadcrumb from "~/components/common/breadcrumb";

import ControlledTable from "~/components/common/controlledTable";

import { getConsentTypes } from "~/states/modules/consentTypes";

const ConsentType = () => {
	const history = useHistory();

	const {
		data: consentTypesList,
		loading: isLoadingData,
		totalCount: consentTypesListTotal,
		tableParams,
	} = useSelector(({ consentTypes }) => consentTypes.list);

	const goToAddTypeConsent = () =>
		history.push(`${process.env.PUBLIC_URL}/consentType/add`);

	//Config ControlledTable

	const data = consentTypesList.map((consentType) => ({
		name: consentType.name,
		description: consentType.description,
		message: consentType.message,
	}));

	const columns = [
		{
			Header: <span>{"Nome"}</span>,
			accessor: "name",
			disableFilters: true,
			minWidth: 30,
			width: 60,
			Cell: (data) => {
				return <div>{data.row.original["name"]}</div>;
			},
		},

		{
			Header: <span>{"Descrição"}</span>,
			accessor: "description",
			disableFilters: true,
			minWidth: 30,
			width: 120,
			Cell: (data) => {
				return (
					<div className="scrollable-content">
						{data.row.original["description"]}
					</div>
				);
			},
		},
		{
			Header: <span>{"Mensagem"}</span>,
			accessor: "message",
			disableFilters: true,
			minWidth: 30,
			width: 120,
			Cell: (data) => {
				return (
					<div className="scrollable-content">
						{data.row.original["message"]}
					</div>
				);
			},
		},
	];

	return (
		<>
			<Breadcrumb
				title="Tipos de Consentimento"
				current="Tipos de Consentimento"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col">
										<h5>Gestão de Tipo de Consentimento da sua empresa.</h5>
									</div>
									<div className="col text-right">
										<AccessControl requiredPermissions={[`consentType:create`]}>
											<AddButton
												onClick={goToAddTypeConsent}
												toolTipMsg={"Adicionar Um Novo Tipo de Consentimento"}
											/>
										</AccessControl>
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								<ControlledTable
									data={data}
									isLoadingData={isLoadingData}
									columns={columns}
									pageCount={Math.ceil(
										consentTypesListTotal / tableParams.pageSize
									)}
									totalCount={consentTypesListTotal}
									disableOrdenation={true}
									globalTableParams={tableParams}
									getData={getConsentTypes}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConsentType;
