import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";

import {
	getEmailTemplates,
	getEmailTemplatesSuccess,
	getEmailTemplatesFailure,
	editEmailTemplate,
	editEmailTemplateFailure,
	editEmailTemplateSuccess,
	deleteEmailTemplate,
	deleteEmailTemplateFailure,
	deleteEmailTemplateSuccess,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getEmailTemplates, getEmailTemplatesSaga);
	yield takeEvery(editEmailTemplate, editEmailTemplateSaga);
	yield takeEvery(deleteEmailTemplate, deleteEmailTemplateSaga);
}

function* getEmailTemplatesSaga() {
	try {
		const { data } = yield call(api.get, `/emailTemplates/`);
		yield put(getEmailTemplatesSuccess(data));
	} catch (error) {
		yield put(getEmailTemplatesFailure(error.response?.data?.error));
	}
}

function* editEmailTemplateSaga({ payload }) {
	try {
		const { data } = yield call(
			api.put,
			`/emailTemplates/${payload.name}`,
			payload
		);
		yield put(editEmailTemplateSuccess(data));
	} catch (error) {
		console.log(error);
		yield put(editEmailTemplateFailure(error.response?.data?.error));
	}
}

function* deleteEmailTemplateSaga({ payload }) {
	try {
		const { data } = yield call(
			api.delete,
			`/emailTemplates/${payload.name}`,
			payload
		);
		yield put(deleteEmailTemplateSuccess(data));
	} catch (error) {
		console.log(error);
		yield put(deleteEmailTemplateFailure(error.response?.data?.error));
	}
}
