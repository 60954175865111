import React, { Fragment } from "react";

import TicketTypeBar from "./ticketTypeBar";
import TicketTypeDoughnut from "./ticketTypeDoughnut";
import TicketInfoSlider from "./ticketInfoSlider";

const HomeComponent = () => {
	return (
		<Fragment>
			<div className="tab-content active default" id="tab-1">
				<div className="row">
					<div className="col">
						<TicketTypeBar />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-6">
						<TicketInfoSlider />
					</div>
					<div className="col-sm-12 col-md-6">
						<TicketTypeDoughnut />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomeComponent;
