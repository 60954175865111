import {
	call,
	put,
	takeEvery,
	takeLatest,
	takeLeading,
	delay,
} from "redux-saga/effects";

import fileDownload from "js-file-download";

import api, { ticketsService } from "~/services/api";

import {
	getTickets,
	getTicketsSuccess,
	getTicketsFailure,
	showTicket,
	showTicketSuccess,
	showTicketFailure,
	createTicket,
	createTicketFailure,
	createTicketSuccess,
	getStatusFlow,
	getStatusFlowFailure,
	getStatusFlowSuccess,
	updateTicket,
	updateTicketFailure,
	updateTicketSuccess,
	getTicketsPerAssigned,
	getTicketsPerAssignedSuccess,
	getTicketsPerAssignedFailure,
	getTicketHistory,
	getTicketHistorySuccess,
	getTicketHistoryFailure,
	handleEvent,
	handleEventSuccess,
	handleEventFailure,
	downloadTicketReport,
	downloadTicketReportSuccess,
	downloadTicketReportFailure,
	getTicketChart,
	getTicketChartSuccess,
	getTicketChartFailure,
	getTicketsTypes,
	getTicketsTypesFailure,
	getTicketsTypesSuccess,
	getTicketsStatus,
	getTicketsStatusFailure,
	getTicketsStatusSuccess,
} from ".";

function* rootSaga() {
	yield takeLatest(getTickets, getTicketsSaga);
	yield takeEvery(showTicket, showTicketSaga);
	yield takeEvery(createTicket, createTicketSaga);
	yield takeEvery(getStatusFlow, getStatusFlowSaga);
	yield takeEvery(updateTicket, updateTicketSaga);
	yield takeLatest(getTicketChart, getTicketChartSaga);
	yield takeEvery(getTicketsPerAssigned, getTicketsPerAssignedSaga);
	yield takeEvery(getTicketHistory, getTicketHistorySaga);
	yield takeLeading(handleEvent, handleEventSaga);
	yield takeLeading(downloadTicketReport, downloadTicketReportSaga);
	yield takeLatest(getTicketsTypes, getTicketsTypesSaga);
	yield takeLatest(getTicketsStatus, getTicketsStatusSaga);
}

function* getTicketsSaga({ payload }) {
	try {
		yield delay(300);
		const data = yield call(ticketsService.getTickets, payload);
		yield put(getTicketsSuccess(data));
	} catch (error) {
		yield put(getTicketsFailure(error.response?.data?.error));
	}
}

function* showTicketSaga({ payload }) {
	const { ticketId } = payload;
	try {
		const { data } = yield call(api.get, `/tickets/${ticketId}`);
		yield put(showTicketSuccess(data));
	} catch (error) {
		yield put(showTicketFailure(error.response?.data?.error));
	}
}

function* createTicketSaga({ payload }) {
	try {
		const { data } = yield call(api.post, `/tickets/admin`, payload);

		yield put(createTicketSuccess(data));
	} catch (error) {
		yield put(createTicketFailure(error.response?.data?.error));
	}
}

function* getStatusFlowSaga({ payload }) {
	try {
		const { data } = yield call(api.get, `/tickets/${payload}/status-flow`);
		yield put(getStatusFlowSuccess(data.data));
	} catch (error) {
		yield put(getStatusFlowFailure(error.response?.data?.error));
	}
}

function* updateTicketSaga({ payload }) {
	try {
		const { data } = yield call(
			api.patch,
			`/tickets/${payload.ticketId}`,
			payload.body
		);
		yield put(updateTicketSuccess(data.data));
	} catch (error) {
		yield put(updateTicketFailure(error.response?.data?.error));
	}
}

function* getTicketsPerAssignedSaga() {
	try {
		yield delay(300);
		const { data } = yield call(api.get, "/tickets/chart/assigned");
		yield put(getTicketsPerAssignedSuccess(data));
	} catch (error) {
		yield put(getTicketsPerAssignedFailure(error.response?.data?.error));
	}
}

function* getTicketChartSaga({ payload }) {
	try {
		yield delay(300);
		const { data } = yield call(
			api.get,
			`/tickets/chart?date=${payload.date}&period=${payload.period}`
		);
		yield put(getTicketChartSuccess(data));
	} catch (error) {
		yield put(getTicketChartFailure(error.response?.data?.error));
	}
}

function* getTicketHistorySaga({ payload }) {
	try {
		const { data } = yield call(api.get, `/tickets/${payload}/histories`);
		yield put(getTicketHistorySuccess(data));
	} catch (error) {
		yield put(getTicketHistoryFailure(error.response?.data?.error));
	}
}

function* handleEventSaga({ payload }) {
	try {
		const { data } = yield call(
			api.post,
			`/tickets/${payload.ticketId}/handle-event`,
			payload.body
		);
		yield put(handleEventSuccess(data.data));
	} catch (error) {
		yield put(handleEventFailure(error.response?.data?.error));
	}
}

function* downloadTicketReportSaga({ payload }) {
	try {
		const { data: blob } = yield call(api.get, `/tickets/${payload.id}/report`, { responseType: 'blob' });
		const url = URL.createObjectURL(blob);

		// Repetição!
		//
		let link = document.createElement("a");
		link.setAttribute("href", url);
		link.setAttribute("download", `ticket-${payload.ticketNumber}-report.pdf`);
		let clickEvent = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: false,
		});
		link.dispatchEvent(clickEvent);
		//
		//

		yield put(downloadTicketReportSuccess());
	} catch (error) {
		yield put(downloadTicketReportFailure(error.response?.data?.error));
	}
}
function* getTicketsTypesSaga({ payload }) {
	try {
		const { data } = yield call(api.get, "/tickets/types");
		yield put(getTicketsTypesSuccess(data));
	} catch (error) {
		yield put(getTicketsTypesFailure(error.response?.data?.error));
	}
}

function* getTicketsStatusSaga({ payload }) {
	try {
		const { data } = yield call(api.get, "/tickets/status");
		yield put(getTicketsStatusSuccess(data));
	} catch (error) {
		yield put(getTicketsStatusFailure(error.response?.data?.error));
	}
}

export default rootSaga;
