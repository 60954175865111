import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import { changePassword, setError, setStatus } from "~/states/modules/session";
import sessionConstants from "../../../states/modules/session/sessionConstants";
import validate from "../../common/validate";

const ValidTokenCard = (props) => {
	const dispatch = useDispatch();
	const { handleSubmit, register, errors, watch } = useForm({
		mode: "onChange",
	});

	const currentStatus = useSelector(({ session }) => session.status);
	const watchedPassword = watch("password");
	const history = useHistory();
	const { token } = props;

	const [feedbackMessage, setFeedbackMessage] = useState(null);
	const error = useSelector(({ session }) => session.error);
	const { errorSources, status } = sessionConstants;

	useEffect(() => {
		if (error && error.source === errorSources.VALID_CHANGE_PASSWORD) {
			console.log("error: ", error.response);
			setFeedbackMessage({
				type: "danger",
				text:
					error.response?.status === 404
						? "Link Expirado"
						: "Um Erro Inesperado Ocorreu",
			});
			dispatch(setError(null));
		}
	}, [error, errorSources]);

	useEffect(() => {
		if (currentStatus == status.CHANGED_PASSWORD) {
			setFeedbackMessage({
				type: "success",
				text: "Senha Alterada com Sucesso",
			});
			dispatch(setStatus(null));
		}
	}, [currentStatus, status]);

	const onSubmit = (data, e) => {
		dispatch(
			changePassword({
				password: data.password,
				token,
			})
		);
	};

	const redirect = (route) => {
		history.push(route);
	};

	const hideAlert = () => setFeedbackMessage(null);

	const onConfirm = () => {
		hideAlert();
		return redirect("/login");
	};

	return (
		<Fragment>
			<div className="card mt-4 auth--card">
				<div className="card-body">
					<div className="text-center">
						<h4>CRIE SUA SENHA</h4>
						<h6>
							Ela deve conter entre 6 e 30 caracteres, pelo menos uma letra
							maiúscula, uma minúscula e um número
						</h6>
					</div>
					{/*<h5 className="f-16 mb-3 f-w-600">CRIE SUA SENHA</h5>*/}
					<br />
					<form
						className="theme-form"
						noValidate=""
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="form-group">
							<label htmlFor="passwordInput" className="col-form-label">
								Nova Senha
								<span className="auth--required-symbol">*</span>
							</label>
							<input
								className="form-control"
								type="password"
								name="password"
								id="passwordInput"
								placeholder="********"
								ref={register(validate.password())}
							/>
							<span className="auth--validation-alert">
								{errors.password?.message}
							</span>
						</div>
						<div className="form-group">
							<label htmlFor="confirmPasswordInput" className="col-form-label">
								Confirme sua Senha
								<span className="auth--required-symbol">*</span>
							</label>
							<input
								className="form-control"
								type="password"
								name="confirmPassword"
								id="confirmPasswordInput"
								placeholder="********"
								ref={register(validate.confirmPassword(watchedPassword))}
							/>
							<span className="auth--validation-alert">
								{errors.confirmPassword?.message}
							</span>
						</div>
						<div className="form-group form-row mt-3 mb-0">
							<button className="btn btn-primary btn-block" type="submit">
								Pronto
							</button>
						</div>
						<br />
						<div className="text-center">
							<Link className="btn btn-link text-capitalize" to="/login">
								Voltar para Login
							</Link>
						</div>
					</form>
				</div>
			</div>
			<SweetAlert
				show={feedbackMessage && feedbackMessage.type === "success"}
				type={"success"}
				title={"Senha Alterada com Sucesso"}
				onConfirm={onConfirm}
			></SweetAlert>
			<SweetAlert
				show={feedbackMessage && feedbackMessage.type === "danger"}
				type={"danger"}
				title={feedbackMessage?.text || "Dummy Text"}
				onConfirm={hideAlert}
			></SweetAlert>
		</Fragment>
	);
};

export default ValidTokenCard;
