import LastRow from "../lastRow";

import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getTicketChart } from "~/states/modules/tickets";

import "./ticketTable.css";

const TicketTable = ({ dateRange }) => {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: dateRange ? dateRange : "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);

	function countHelper(count) {
		return count === "-" ? 1 : count + 1;
	}

	function countCorrect(ticket, object) {
		const expirationDate = moment(ticket.expirationDate);
		const updatedAt = moment(ticket.updatedAt);

		const timeBetweenExpirationAndLastUpdate =
			expirationDate.diff(updatedAt);

		const timeBetweenExpirationAndNow = expirationDate.diff(
			moment(),
			"days"
		);

		if (ticket.status === "CLOSED") {
			if (timeBetweenExpirationAndLastUpdate > 0)
				object.closedTickets.onTime = countHelper(
					object.closedTickets.onTime
				);
			else
				object.closedTickets.expired = countHelper(
					object.closedTickets.expired
				);
		} else {
			if (timeBetweenExpirationAndNow < 0)
				object.expiredTickets = countHelper(object.expiredTickets);
			else if (timeBetweenExpirationAndNow <= 1)
				object.toBeExpiredIn.oneDay = countHelper(
					object.toBeExpiredIn.oneDay
				);
			else if (timeBetweenExpirationAndNow <= 3)
				object.toBeExpiredIn.threeDays = countHelper(
					object.toBeExpiredIn.threeDays
				);
			else if (timeBetweenExpirationAndNow <= 5)
				object.toBeExpiredIn.fiveDays = countHelper(
					object.toBeExpiredIn.fiveDays
				);
		}
	}

	const typeCounterArray = [];

	ticketsForChart.forEach((month) =>
		month.tickets.forEach((ticket) => {
			const found = typeCounterArray.find(
				(item) => item.label === ticket.ticketType.label
			);

			if (!found) {
				const object = {
					id: ticket.type,
					label: ticket.ticketType.label,
					closedTickets: {
						onTime: "-",
						expired: "-",
					},
					toBeExpiredIn: {
						oneDay: "-",
						threeDays: "-",
						fiveDays: "-",
					},
					expiredTickets: "-",
				};
				countCorrect(ticket, object);
				typeCounterArray.push(object);
			} else {
				countCorrect(ticket, found);
			}
		})
	);
	return (
		<Fragment>
			<table className="table">
				<thead>
					<tr>
						<th className="header" scope="col" rowSpan="2">
							Tipo de pedido
						</th>
						<th
							className="header headerUnique"
							scope="col"
							colSpan="2"
						>
							Finalizados
						</th>
						<th className="header" scope="col" colSpan="4">
							Abertos
						</th>
					</tr>
					<tr>
						<th className="header headerDown" scope="col">
							No prazo
						</th>
						<th className="header headerDown" scope="col">
							Fora do prazo
						</th>
						<th className="header headerDown" scope="col">
							5 dias
						</th>
						<th className="header headerDown" scope="col">
							3 dias
						</th>
						<th className="header headerDown" scope="col">
							1 dia
						</th>
						<th className="header" scope="col">
							Expirados
						</th>
					</tr>
				</thead>
				<tbody>
					{typeCounterArray.map((type) => (
						<tr key={type.id}>
							<td className="toRight">{type.label}</td>
							<td className="digits">
								{type.closedTickets.onTime}
							</td>
							<td className="digits toRight color-darkgold">
								{type.closedTickets.expired}
							</td>
							<td className="digits">
								{type.toBeExpiredIn.fiveDays}
							</td>
							<td className="digits">
								{type.toBeExpiredIn.threeDays}
							</td>
							<td className="digits">
								{type.toBeExpiredIn.oneDay}
							</td>
							<td className="digits text-danger">
								{type.expiredTickets}
							</td>
						</tr>
					))}
					<tr className="lastRow">
						<td className="toRight">
							<strong>Total</strong>
						</td>
						<LastRow />
					</tr>
				</tbody>
			</table>
		</Fragment>
	);
};

export default TicketTable;
