import React from "react";
import { NavLink, withRouter, useRouteMatch } from "react-router-dom";

const TabHead = ({ header }) => {
	const { path } = useRouteMatch();
	return (
		<li className="dl-routed-tabs-tabhead">
			<NavLink to={`${path}${header.path}`} activeClassName="active">
				{header.component}
			</NavLink>
		</li>
	);
};

export default withRouter(TabHead);
