import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import Breadcrumb from "../../common/breadcrumb";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { createUser, getCurrentUser } from "~/states/modules/users";
import { getAllRoles } from "~/states/modules/roles";
import validate from "../../common/validate";

const NewUser = () => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, reset, watch, control } = useForm({
		mode: "onChange",
	});

	const currentUser = useSelector(({ users }) => users.currentUser?.data);
	const createdUser = useSelector(({ users }) => users.createdUser);
	const allRoles = useSelector(({ roles }) => roles.roles?.data);

	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);

	let typeahead = useRef();

	const resetForm = () => {
		reset();
		typeahead.current && typeahead.current.clear();
	};

	useEffect(() => {
		dispatch(getCurrentUser());
		dispatch(getAllRoles());
	}, [dispatch]);

	// Listening for submition failures:
	useEffect(() => {
		if (showMessage) {
			if (createdUser && createdUser.error) {
				setLoading(false);
				toast.warn(createdUser.error.message);
			}
		}
	}, [createdUser?.error]);

	// Listening for submition success:
	useEffect(() => {
		if (showMessage) {
			if (createdUser && createdUser.data) {
				setLoading(false);
				resetForm();
				toast.success(
					`Usuário ${createdUser.data.name} convidado com sucesso!`
				);
			}
		}
	}, [createdUser?.data]);

	const hasErrors = () => Object.keys(errors).length > 0;

	const onSubmit = (data) => {
		setLoading(true);
		if (currentUser) {
			const { email, name, password, roles } = data;

			setShowMessage(true);

			dispatch(
				createUser({
					currentCompanyId: currentUser.currentCompanyId,
					email,
					roles,
					rights: [],
				})
			);
		}
	};

	return (
		<Fragment>
			<Breadcrumb
				parent="Gestão De Usuários"
				current="Novo Usuário"
				title="Novo Usuário"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form
							className="needs-validation"
							noValidate=""
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col">
											<h5>
												Convide um novo usuário preenchendo as informações
												abaixo.
											</h5>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="col-sm-12 mb-3">
											<label htmlFor="inputEmail">
												E-mail:
												<span className="auth--required-symbol">*</span>
											</label>
											<input
												className="form-control"
												id="inputEmail"
												name="email"
												type="email"
												placeholder="email@website.com"
												ref={register(validate.email())}
											/>
											<span>{errors.email?.message}</span>
										</div>
									</div>
									<div className="form-row">
										<div className="col-sm-12 mb-3">
											<label onClick={() => typeahead.current?.focus()}>
												Grupos:
												<span className="auth--required-symbol">*</span>
											</label>
											<Controller
												render={({ onChange, onBlur }) => (
													<Typeahead
														id="newUserTypeahead"
														//defaultSelected={options.slice(0, 2)}
														labelKey="label"
														clearButton
														multiple
														options={Array.isArray(allRoles) ? allRoles : []}
														placeholder="Escolha um ou mais grupos..."
														ref={typeahead}
														onChange={onChange}
														onBlur={onBlur}
													/>
												)}
												name="roles"
												defaultValue=""
												control={control}
												onFocus={() => typeahead.current?.focus()}
												rules={validate.requiredTypeahead()}
											/>
											<input type="hidden"></input>
											<span role="alert">{errors.roles?.message}</span>
										</div>
									</div>
								</div>
								<div className="card-footer text-right">
									<button
										className={`btn btn-primary ${
											hasErrors() || loading ? "disabled" : ""
										}`}
										type="submit"
									>
										{!loading ? (
											"Convidar"
										) : (
											<div className="loader">
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
											</div>
										)}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NewUser;
