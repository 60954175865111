import React from "react";
import BallLoader from "../../ballLoader";

import "./style.scss";

function CTLoading({ loadingMessage }) {
	const customLoadingMessage =
		loadingMessage ||
		"Por favor, aguarde enquanto carregamos os dados solicitados.";

	return (
		<div className="ct-loading-data mt-2">
			<span className="mb-4">{customLoadingMessage}</span>
			<BallLoader className="mb-2" />
		</div>
	);
}

export default CTLoading;
