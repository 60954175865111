import React from "react";
import CountUp from "react-countup";

const Widgets = ({ info }) => (
	<div className="row">
		{info?.map((widget, id) => (
			<div key={id} className="col-xs-12 col-md-6 col-xl-4 col-lg-4">
				<div className="card o-hidden">
					<div className={widget.className}>
						<div className="media static-top-widget">
							<div className="align-self-center text-center">
								{widget.icon}
							</div>
							<div className="media-body">
								<span className="m-0">{widget.label}</span>
								<h4 className="mb-0 counter">
									<CountUp
										className="counter"
										end={widget.count}
									/>
								</h4>
								{widget.iconBg}
							</div>
						</div>
					</div>
				</div>
			</div>
		))}
	</div>
);

export default Widgets;
