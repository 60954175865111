import React from "react";
import ControlledTable from "~/components/common/controlledTable";
import { useSelector } from "react-redux";

import { getConsentRequests } from "~/states/modules/dataConsent";
import { columns } from "./requestListConstants";

const RequestListTable = () => {
	const {
		data: requests,
		totalCount,
		tableParams: globalTableParams,
	} = useSelector(({ dataConsent }) => dataConsent.requests);

	const data = requests?.map(
		({
			requestAt,
			channel,
			createdAt,
			status,
			companiesClientConsents: {
				clientConsents: { name },
			},
			updatedAt,
		}) => {
			const acceptedAt = status === "CLOSED" ? updatedAt : null;
			return {
				name,
				requestAt,
				channel,
				createdAt,
				status,
				acceptedAt,
			};
		}
	);

	return (
		<ControlledTable
			data={data}
			columns={columns}
			pageCount={Math.ceil(totalCount / globalTableParams.pageSize)}
			totalCount={totalCount}
			disableOrdenation={false}
			globalTableParams={globalTableParams}
			getData={getConsentRequests}
		/>
	);
};
export default RequestListTable;
