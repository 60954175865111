const sessionConstants = {
	errorSources: {
		CHANGE_PASSWORD: "changePassword",
		VALID_CHANGE_PASSWORD: "changePassword/validTokenCard",
		INVALID_CHANGE_PASSWORD: "changePassword/validTokenCard",
		FORGET_PASSWORD: "forgetPassword",
		LOGIN: "login",
		REFRESH_TOKEN: "refreshToken",
	},
	status: {
		TO_CHANGE_PASSWORD: "toChangePassword",
		TO_RECLAIM_PASSWORD: "toForgetPassword",
		TO_REFRESH_TOKEN: "toRefreshToken",
		GOT_NEW_TOKEN: "gotNewToken",
		EMAIL_SENT: "emailSent",
		CHANGED_PASSWORD: "changedPassword",
		FAILED: "failed",
	},
};

export default sessionConstants;
