import extend from "lodash/extend";
import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	database: { error: null, loading: false, data: null },
	editedDatabase: { error: null, loading: false, data: null },
	removedDatabase: { error: null, loading: false, data: null },
	createdDatabase: { error: null, loading: false, data: null },
	databases: [],
};

const { actions, reducer } = createSlice({
	name: "databases",
	initialState,
	reducers: {
		eraseSelectedDatabase: (state) =>
			extend(state, {
				database: { error: null, loading: false, data: null },
			}),
		//
		getDatabases: (state) => state,
		getDatabasesFailure: (state) => state,
		getDatabasesSuccess: (state, { payload }) =>
			extend(state, {
				databases: payload.data,
			}),
		//
		createDatabase: (state) =>
			extend(state, {
				createdDatabase: {
					loading: true,
					error: null,
				},
			}),
		createDatabaseFailure: (state, { payload }) =>
			extend(state, {
				createdDatabase: {
					loading: false,
					error: payload,
					data: null,
				},
			}),
		createDatabaseSuccess: (state, { payload }) => {
			return extend(state, {
				createdDatabase: {
					loading: false,
					error: null,
					data: payload.data,
				},
			});
		},
		//
		removeDatabase: (state) => {
			return extend(state, {
				removedDatabase: {
					loading: true,
					error: null,
				},
			});
		},
		removeDatabaseFailure: (state, { payload }) => {
			return extend(state, {
				removedDatabase: {
					removedDatabase: false,
					error: payload,
					data: null,
				},
			});
		},
		removeDatabaseSuccess: (state, { payload }) => {
			const id = payload; // the id deleted
			const databases = state.databases.filter(
				(database) => database.id !== id
			);
			return extend(state, {
				removedDatabase: {
					loading: false,
					error: null,
					data: id,
				},
				databases,
			});
		},
		//
		getDatabase: (state, { payload }) =>
			extend(state, {
				database: {
					loading: true,
					error: null,
				},
			}),
		getDatabaseFailure: (state, { payload }) =>
			extend(state, {
				database: {
					loading: false,
					error: payload,
				},
			}),
		getDatabaseSuccess: (state, { payload }) =>
			extend(state, {
				database: {
					loading: false,
					error: null,
					data: payload.data,
				},
			}),
		//
		editDatabase: (state, { payload }) =>
			extend(state, {
				editedDatabase: {
					loading: true,
					error: null,
				},
			}),
		editDatabaseFailure: (state, { payload }) =>
			extend(state, {
				editedDatabase: {
					loading: false,
					error: payload,
				},
			}),
		editDatabaseSuccess: (state, { payload }) =>
			extend(state, {
				editedDatabase: {
					loading: false,
					error: null,
					data: payload.data,
				},
			}),
	},
});

export const {
	eraseSelectedDatabase,
	getDatabases,
	getDatabasesFailure,
	getDatabasesSuccess,
	createDatabase,
	createDatabaseFailure,
	createDatabaseSuccess,
	removeDatabase,
	removeDatabaseFailure,
	removeDatabaseSuccess,
	getDatabase,
	getDatabaseFailure,
	getDatabaseSuccess,
	editDatabase,
	editDatabaseFailure,
	editDatabaseSuccess,
} = actions;

export { default as databasesSaga } from "./sagas";

export default reducer;
