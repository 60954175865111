import { call, put, takeLeading } from "redux-saga/effects";

import api from "~/services/api";
import { getAllFonts, getAllFontsFailure, getAllFontsSuccess } from ".";

export default function* rootSaga() {
	yield takeLeading(getAllFonts, getAllFontsSaga);
}

function* getAllFontsSaga() {
	try {
		const { data } = yield call(api.get, "/fonts/");
		yield put(getAllFontsSuccess(data));
	} catch (error) {
		yield put(getAllFontsFailure(error.response?.data?.error));
	}
}
