import React, { useState } from "react";
import moment from "moment";

import "./style.scss";
import ViewHTMLContentModal from "~/components/common/viewHTMLContentModal";

function PolicyDetailsCard({ policy }) {
	const [isContentModalOpen, setIsContentModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalTitle, setModalTitle] = useState("");

	return (
		<>
			<div className="card">
				<div className="card-header">
					<h4>{policy.name}</h4>
				</div>
				<div className="card-body">
					<ul className="list-unstyled">
						<div className="row mb-3">
							<li className="col-4">
								<label className="bold" htmlFor="version">
									Nome do Autor
								</label>
								<p name="authorName">{policy.author.name}</p>
							</li>
							<li className="col-4">
								<label className="bold" htmlFor="version">
									Email do Autor
								</label>
								<p name="authorEmail">{policy.author.email}</p>
							</li>
							<li className="col-4">
								<label className="bold" htmlFor="policyTypeLabel">
									Rótulo do Tipo de Política
								</label>
								<p name="policyTypeLabel">{policy.type.label}</p>
							</li>
						</div>
						<div className="row mb-3">
							<li className="col-4">
								<label className="bold" htmlFor="version">
									Versão atual
								</label>
								<p name="version">
									{policy.majorVersion}.{policy.minorVersion}
								</p>
							</li>
							<li className="col-4">
								<label className="bold" htmlFor="updatedAt">
									Data da Última Atualização
								</label>
								<p name="updatedAt">
									{moment(policy.updatedAt).format("DD/MM/YY HH:mm")}
								</p>
							</li>
							<li className="col-4">
								<label className="bold" htmlFor="createdAt">
									Data de Criação
								</label>
								<p name="createdAt">
									{moment(policy.createdAt).format("DD/MM/YY HH:mm")}
								</p>
							</li>
						</div>
						<div className="row mb-5">
							<li className="col-4">
								<label className="bold" htmlFor="visibility">
									Visibilidade
								</label>
								<p name="visibility">
									{policy.visibility === "PUBLIC" ? "Pública" : "Privada"}
								</p>
							</li>
						</div>
					</ul>
				</div>
				<div className="card-footer">
					<div className="d-flex flex-row justify-content-end">
						<button
							onClick={() => {
								setIsContentModalOpen(true);
								setModalContent(policy.emailMessage);
								setModalTitle(policy.emailSubject);
							}}
							className="btn btn-primary mr-2"
						>
							Ver Email
						</button>
						<button
							onClick={() => {
								setIsContentModalOpen(true);
								setModalContent(policy.content);
								setModalTitle(policy.name);
							}}
							className="btn btn-primary"
						>
							Ver Conteúdo
						</button>
					</div>
				</div>
			</div>
			<ViewHTMLContentModal
				isOpen={isContentModalOpen}
				onClose={() => {
					setIsContentModalOpen(false);
					setModalContent("");
					setModalTitle("");
				}}
				content={modalContent}
				title={modalTitle}
			/>
		</>
	);
}

export default PolicyDetailsCard;
