import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Collapse, Alert } from "reactstrap";
import validate from "../../../common/validate";

const RequestSelectionForm = ({ company, onSubmit }) => {
	const defaultObject = {
		...company,
		offeredRequests: {
			title: company.offeredRequests ? company.offeredRequests.title : "",
			// From array to object with ticketTypesIds as keys
			options: company.offeredRequests
				? company.offeredRequests.options.reduce((acc, { value, ...cur }) => {
						acc[value] = cur;
						return acc;
				  }, {})
				: {},
		},
	};

	const { register, handleSubmit, watch, errors } = useForm({
		defaultValues: defaultObject,
	});
	const watchedConfirmaTratamento = watch("offeredRequests.options[1].active");
	const watchedPesquisaDados = watch("offeredRequests.options[2].active");
	// const watchedAtualizaCadastro = watch("offeredRequests.options[3].active");
	const watchedInfoProcessamento = watch("offeredRequests.options[4].active");
	const watchedPortabilidade = watch("offeredRequests.options[5].active");
	// const watchedEliminacaoParcial = watch("offeredRequests.options[6].active");
	const watchedEliminacaoIntegral = watch("offeredRequests.options[7].active");

	const hasACheckboxSelected =
		watchedConfirmaTratamento ||
		watchedPesquisaDados ||
		// watchedAtualizaCadastro ||
		watchedInfoProcessamento ||
		watchedPortabilidade ||
		// watchedEliminacaoParcial ||
		watchedEliminacaoIntegral;

	const [visible, setVisible] = useState(false);

	// Watch for warnings
	useEffect(() => {
		hasACheckboxSelected ? setVisible(false) : setVisible(true);
	}, [hasACheckboxSelected]);

	const filterCommentedOptions = (input) => {
		input.offeredRequests.options = input.offeredRequests.options
			.filter((option) => !!option)
			// Add manually open atribute to every option since a hidden field adds it as string
			.map((option) => ({ ...option, open: false }));
		onSubmit(input);
	};
	const requiredIfFieldIsOpen = (isOpen) =>
		isOpen ? validate.required() : validate.notRequired();

	return (
		<div className="tab-content active default" id="tab-1">
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(filterCommentedOptions)}
			>
				<Alert color="warning" isOpen={visible}>
					Pelo menos um tipo de pedido deve estar ativo
				</Alert>
				<div className="card">
					<div className="card-body">
						<div className="form-row">
							<div className="form-group col-md-12 col-sm-12">
								<label className="col-form-label" htmlFor="pageTitle">
									Título da Página
									<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									id="pageTitle"
									type="text"
									ref={register(validate.required())}
									name="offeredRequests.title"
								/>
								<span className="auth--validation-alert">
									{errors.offeredRequests?.title?.message}
								</span>
							</div>
						</div>
						<div>
							<label className="form-switch">
								<input
									id="confirmaTratamento"
									name="offeredRequests.options[1].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
								Confirmação de Tratamento e Utilização
							</label>
							<Collapse isOpen={watchedConfirmaTratamento}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[1].value"
										value="1"
										ref={register(
											requiredIfFieldIsOpen(watchedConfirmaTratamento)
										)}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label0">
											Título
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label0"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedConfirmaTratamento)
											)}
											name="offeredRequests.options[1].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[1]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need0">
											Necessidade
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need0"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedConfirmaTratamento)
											)}
											name="offeredRequests.options[1].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[1]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective0">
											Objetivo
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective0"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedConfirmaTratamento)
											)}
											name="offeredRequests.options[1].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[1]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="consultationResult0"
										>
											Resultado
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult0"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedConfirmaTratamento)
											)}
											name="offeredRequests.options[1].consultationResult"
										/>
										<span className="auth--validation-alert">
											{
												errors.offeredRequests?.options?.[1]?.consultationResult
													?.message
											}
										</span>
									</div>
								</div>
							</Collapse>
						</div>
						<div>
							<label className="form-switch">
								<input
									id="pesquisaDados"
									name="offeredRequests.options[2].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
								Pesquisa de Dados Pessoais
							</label>
							<Collapse isOpen={watchedPesquisaDados}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[2].value"
										value="2"
										ref={register(requiredIfFieldIsOpen(watchedPesquisaDados))}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label1">
											Título
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label1"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPesquisaDados)
											)}
											name="offeredRequests.options[2].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[2]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need1">
											Necessidade
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need1"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPesquisaDados)
											)}
											name="offeredRequests.options[2].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[2]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective1">
											Objetivo
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective1"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPesquisaDados)
											)}
											name="offeredRequests.options[2].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[2]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="consultationResult1"
										>
											Resultado
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult1"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPesquisaDados)
											)}
											name="offeredRequests.options[2].consultationResult"
										/>
										<span className="auth--validation-alert">
											{
												errors.offeredRequests?.options?.[2]?.consultationResult
													?.message
											}
										</span>
									</div>
								</div>
							</Collapse>
						</div>
						{/* <div>
							<label className="form-switch">
								<input
									id="atualizaCadastro"
									name="offeredRequests.options[3].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
							Atualização de Cadastro
						</label>
							<Collapse isOpen={watchedAtualizaCadastro}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[3].value"
										value="3"
										ref={register(requiredIfFieldIsOpen(watchedAtualizaCadastro))}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label2">
											Título
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label2"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedAtualizaCadastro))}
											name="offeredRequests.options[3].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[3]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need2">
											Necessidade
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need2"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedAtualizaCadastro))}
											name="offeredRequests.options[3].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[3]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective2">
											Objetivo
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective2"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedAtualizaCadastro))}
											name="offeredRequests.options[3].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[3]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="consultationResult2">
											Resultado
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult2"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedAtualizaCadastro))}
											name="offeredRequests.options[3].consultationResult"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[3]?.consultationResult?.message}
										</span>
									</div>
								</div>
							</Collapse>
						</div> */}
						<div>
							<label className="form-switch">
								<input
									id="infoProcessamento"
									name="offeredRequests.options[4].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
								Informações sobre Processamento
							</label>
							<Collapse isOpen={watchedInfoProcessamento}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[4].value"
										value="4"
										ref={register(
											requiredIfFieldIsOpen(watchedInfoProcessamento)
										)}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label3">
											Título
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label3"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedInfoProcessamento)
											)}
											name="offeredRequests.options[4].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[4]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need3">
											Necessidade
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need3"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedInfoProcessamento)
											)}
											name="offeredRequests.options[4].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[4]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective3">
											Objetivo
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective3"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedInfoProcessamento)
											)}
											name="offeredRequests.options[4].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[4]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="consultationResult3"
										>
											Resultado
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult3"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedInfoProcessamento)
											)}
											name="offeredRequests.options[4].consultationResult"
										/>
										<span className="auth--validation-alert">
											{
												errors.offeredRequests?.options?.[4]?.consultationResult
													?.message
											}
										</span>
									</div>
								</div>
							</Collapse>
						</div>
						<div>
							<label className="form-switch">
								<input
									id="portabilidade"
									name="offeredRequests.options[5].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
								Portabilidade
							</label>
							<Collapse isOpen={watchedPortabilidade}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[5].value"
										value="5"
										ref={register(requiredIfFieldIsOpen(watchedPortabilidade))}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label4">
											Título
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label4"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPortabilidade)
											)}
											name="offeredRequests.options[5].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[5]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need4">
											Necessidade
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need4"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPortabilidade)
											)}
											name="offeredRequests.options[5].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[5]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective4">
											Objetivo
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective4"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPortabilidade)
											)}
											name="offeredRequests.options[5].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[5]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="consultationResult4"
										>
											Resultado
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult4"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedPortabilidade)
											)}
											name="offeredRequests.options[5].consultationResult"
										/>
										<span className="auth--validation-alert">
											{
												errors.offeredRequests?.options?.[5]?.consultationResult
													?.message
											}
										</span>
									</div>
								</div>
							</Collapse>
						</div>
						{/* <div>
							<label className="form-switch">
								<input
									id="eliminacaoParcial"
									name="offeredRequests.options[6].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
							Eliminação Parcial
						</label>
							<Collapse isOpen={watchedEliminacaoParcial}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[6].value"
										value="6"
										ref={register(requiredIfFieldIsOpen(watchedEliminacaoParcial))}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label5">
											Título
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label5"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedEliminacaoParcial))}
											name="offeredRequests.options[6].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[6]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need5">
											Necessidade
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need5"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedEliminacaoParcial))}
											name="offeredRequests.options[6].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[6]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective5">
											Objetivo
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective5"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedEliminacaoParcial))}
											name="offeredRequests.options[6].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[6]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="consultationResult5">
											Resultado
										<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult5"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedEliminacaoParcial))}
											name="offeredRequests.options[6].consultationResult"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[6]?.consultationResult?.message}
										</span>
									</div>
								</div>
							</Collapse>
						</div>*/}
						<div>
							<label className="form-switch">
								<input
									id="eliminacaoIntegral"
									name="offeredRequests.options[7].active"
									type="checkbox"
									ref={register}
								/>
								<i></i>
								Eliminação
							</label>
							<Collapse isOpen={watchedEliminacaoIntegral}>
								<div className="form-row">
									<input
										type="hidden"
										name="offeredRequests.options[7].value"
										value="7"
										ref={register(
											requiredIfFieldIsOpen(watchedEliminacaoIntegral)
										)}
									></input>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="label6">
											Título
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="label6"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedEliminacaoIntegral)
											)}
											name="offeredRequests.options[7].label"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[7]?.label?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="need6">
											Necessidade
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="need6"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedEliminacaoIntegral)
											)}
											name="offeredRequests.options[7].need"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[7]?.need?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label className="col-form-label" htmlFor="objective6">
											Objetivo
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="objective6"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedEliminacaoIntegral)
											)}
											name="offeredRequests.options[7].objective"
										/>
										<span className="auth--validation-alert">
											{errors.offeredRequests?.options?.[7]?.objective?.message}
										</span>
									</div>
									<div className="form-group col-md-12 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="consultationResult6"
										>
											Resultado
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="consultationResult6"
											type="text"
											ref={register(
												requiredIfFieldIsOpen(watchedEliminacaoIntegral)
											)}
											name="offeredRequests.options[7].consultationResult"
										/>
										<span className="auth--validation-alert">
											{
												errors.offeredRequests?.options?.[7]?.consultationResult
													?.message
											}
										</span>
									</div>
								</div>
							</Collapse>
						</div>
					</div>
					<div className="card-footer text-right">
						<button
							id="btnSave2"
							className="btn btn-primary"
							type="submit"
							disabled={!hasACheckboxSelected}
						>
							Salvar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
export default RequestSelectionForm;
