import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "~/states/modules/users";

const AdminUserPanel = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(({ users }) => users.currentUser?.data);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	return (
		<Fragment>
			<style></style>
			<div className="sidebar-user text-center">
				<h6 className="mt-3 f-14">{currentUser && currentUser.name}</h6>
				{/* <p>{currentUser && currentUser.admin ? "ADMINISTRATOR" : "ANALYST"}</p> */}
				{currentUser?.roles?.map((r) => (
					<span key={r.id} className="badge badge-light">
						{r.name}
					</span>
				))}
			</div>
		</Fragment>
	);
};

export default AdminUserPanel;
