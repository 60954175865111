import React, { useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import { store, persistor } from "./states/store";
import App from "./components/app";
import AdminRoute from "~/services/AdminRoute";

// Import custom Components

import AuthWrapper from "./components/authentication";
import Login from "./components/authentication/login";
import ForgetPasword from "./components/authentication/forgetPassword";
import ChangePassword from "./components/authentication/changePassword";
import Dashboard from "./components/dashboard";
import UserList from "./components/users/UsersList";
import EditUser from "./components/users/EditUser";
import NewUser from "./components/users/NewUser";
import CompanyConfig from "./components/company/config";
import CreateCompany from "./components/company/create";
import TicketsList from "./components/tickets/ticketsList";
import Ticket from "./components/tickets/ticket";
import NewTicket from "./components/tickets/NewTicket";
import Profile from "./components/profile";
import Tags from "./components/consents/tags";
import CookieConsents from "./components/consents/cookieConsents";
import CookiebarConfig from "./components/consents/config";
import SDKInstall from "./components/sdk/install";
import NotFound from "./components/NotFound";
import ConsentType from "./components/consents/consentType";
import ConsentTypeForm from "./components/consents/consentType/consentTypeForm";
import ImportClientConsents from "./components/consents/importClientConsents";
import Integrations from "./components/company/integrations";
import ClientConsents from "./components/consents/dataConsents";

import ClientConsentsInformation from "./components/consents/dataConsents/ClientConsentsInformation";
import AddClientConsent from "./components/consents/dataConsents/AddClientConsent";
import CompanyInfo from "./components/company/info";
import {
	setTranslations,
	setDefaultLanguage,
	setLanguageCookie,
} from "./assets/customPackages/react-switch-lang";

import en from "./assets/i18n/en.json";
import pt from "./assets/i18n/pt.json";

import { EventEmitter } from "./utils/event-emitter";
import eventEmitterConstants from "./utils/eventEmitterConstants";
import {
	setNewRefreshTokenSuccess,
	logoutSuccess,
} from "./states/modules/session";
import RequestList from "./components/consents/requestList";
import AddPolicy from "./components/policyManagement/AddPolicy";
import PolicyTypesList from "./components/policyManagement/policyTypesList";
import PoliciesList from "./components/policyManagement/PoliciesList";
import EditPolicy from "./components/policyManagement/EditPolicy";
import PolicyDetails from "./components/policyManagement/PolicyDetails";

// configuring I18N:
setTranslations({ pt, en });
setDefaultLanguage("pt");
setLanguageCookie();

//firebase Auth
function Root() {
	useEffect(() => {
		const layout = localStorage.getItem("layout_version");
		document.body.classList.add(layout);
	}, []);

	useEffect(() => {
		const onEmit = (data) => store.dispatch(setNewRefreshTokenSuccess(data)),
			logOut = () => store.dispatch(logoutSuccess());

		const { events } = eventEmitterConstants;

		EventEmitter.on(events.SET_REFRESH_TOKEN, onEmit);
		EventEmitter.on(events.LOGOUT, logOut);

		return () => {
			EventEmitter.off(events.SET_REFRESH_TOKEN, onEmit);
			EventEmitter.off(events.LOGOUT, logOut);
		};
	}, []);

	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<BrowserRouter basename={"/"}>
						<ScrollContext>
							<Switch>
								<AdminRoute
									exact
									path={`${process.env.PUBLIC_URL}/login`}
									render={() => (
										<AuthWrapper>
											<Login />
										</AuthWrapper>
									)}
								/>
								<AdminRoute
									exact
									path={`${process.env.PUBLIC_URL}/login/esqueci-senha`}
									render={() => (
										<AuthWrapper>
											<ForgetPasword />
										</AuthWrapper>
									)}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/login/alterar-senha/:token`}
									render={() => (
										<AuthWrapper>
											<ChangePassword />
										</AuthWrapper>
									)}
								/>
								<AdminRoute
									exact
									path={`${process.env.PUBLIC_URL}/404`}
									render={() => <NotFound />}
								/>
								<Fragment>
									<App>
										<Switch>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/`}
												component={Dashboard}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/tickets`}
												component={TicketsList}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/tickets/add`}
												component={NewTicket}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/tickets/:ticketId(\\d+)`}
												component={Ticket}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/clientConsents`}
												component={ClientConsents}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/clientConsents/:id(\\d+)`}
												component={ClientConsentsInformation}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/clientConsents/add`}
												component={AddClientConsent}
												requiredPermissions={["clientConsent:create"]}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/consentType`}
												component={ConsentType}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/consentType/add`}
												component={ConsentTypeForm}
												requiredPermissions={["consentType:create"]}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/clientConsents/requestList`}
												component={RequestList}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/importClientConsents`}
												component={ImportClientConsents}
												isPrivate
											/>

											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/policies`}
												component={PoliciesList}
												//requiredPermissions={["clientConsent:create"]}
												//!TODO: add permissions

												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/policies/add`}
												component={AddPolicy}
												//requiredPermissions={["clientConsent:create"]}
												//!TODO: add permissions
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/policies/edit/:policyId(\\d+)`}
												component={EditPolicy}
												//requiredPermissions={["clientConsent:create"]}
												//!TODO: add permissions
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/policies/:policyId/details`}
												component={PolicyDetails}
												//requiredPermissions={["clientConsent:create"]}
												//!TODO: add permissions
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/policies/types`}
												component={PolicyTypesList}
												//requiredPermissions={["clientConsent:create"]}
												//!TODO: add permissions
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/users`}
												component={UserList}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/users/add`}
												component={NewUser}
												isPrivate
												requiredPermissions={["user:create"]}
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/users/:userId(\\d+)`}
												component={EditUser}
												isPrivate
												requiredPermissions={["user:edit:{{userId}}"]}
											/>
											<AdminRoute
												path={`${process.env.PUBLIC_URL}/company/config`}
												component={CompanyConfig}
												requiredPermissions={["company:config"]}
												isPrivate
											/>
											<AdminRoute
												path={`${process.env.PUBLIC_URL}/company/integrations`}
												component={Integrations}
												requiredPermissions={["company:config"]}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/consents/tags`}
												component={Tags}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/consents/cookie-consents`}
												component={CookieConsents}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/consents/config`}
												//requiredPermissions={["company:config"]}
												component={CookiebarConfig}
												isPrivate
											/>
											<AdminRoute
												path={`${process.env.PUBLIC_URL}/profile`}
												component={Profile}
												isPrivate
											/>
											<AdminRoute
												path={`${process.env.PUBLIC_URL}/company/add`}
												component={CreateCompany}
												requiredPermissions={["company:create"]}
												isPrivate
											/>
											<AdminRoute
												exact
												path={`${process.env.PUBLIC_URL}/sdk/install`}
												component={SDKInstall}
												isPrivate
											/>
											<AdminRoute
												path={`${process.env.PUBLIC_URL}/company/company-info`}
												component={CompanyInfo}
												//requiredPermissions={["company:config"]}
												isPrivate
											/>
											<AdminRoute render={() => <Redirect to="/404" />} />
										</Switch>
									</App>
								</Fragment>
							</Switch>
						</ScrollContext>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</div>
	);
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
