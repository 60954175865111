import api from "./api";
import qs from "qs";

class PolicyTypeService {
	getPolicyTypes(params) {
		return api
			.get("/policyTypes", {
				paramsSerializer: function (params) {
					return qs.stringify(params, { arrayFormat: "brackets" });
				},
				params: {
					page: params.tableParams.page,
					size: params.tableParams.size,
					// order: params.tableParams.order,
					// filter: params.tableParams.filter,
				},
			})
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to get PolicyTypes in PolicyTypesService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
	createPolicyType(policyType) {
		return api
			.post("/policyTypes", policyType)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to create PolicyType in PolicyTypesService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
	editPolicyType(policyType) {
		return api
			.put(`/policyTypes/${policyType.id}`, policyType)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"Failed to edit PolicyType in PolicyTypesService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
}

export default new PolicyTypeService();
