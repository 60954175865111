import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "~/states/modules/company";
import Breadcrumb from "../common/breadcrumb";
import { sdkService } from "../../services/api";

import { toast } from "react-toastify";
import { CopyBlock, monoBlue } from "react-code-blocks";
import "./copyBlock.scss";
import LoadingButton from "../common/LoadingButton";

const SDKInstall = () => {
	const company = useSelector(({ company }) => company.currentCompany.data);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(getCompany());
	}, [dispatch]);

	const url = `//${window.location.hostname}/api/sdk/js?id=${company?.id}`
		.replace("admin", "api")
		.replace("localhost", "localhost:4000");

	const bodySDKInstallationText = `<!-- Inside Head -->
<script
type="text/javascript"
src="${url}"
async
></script>
<!-- Inside Head -->`;

	const bodyModalText = `<a href="javascript:void(0)" onclick="DLController.openModal()">Seus Dados</a>`;

	const testInstall = async () => {
		setLoading(true);
		if (company?.siteUrl) {
			const answer = await sdkService.getSDKIntallationCheck();
			if (await answer) toast.success("SDK foi instalada com sucesso");
			else
				toast.error(
					"SDK não está instalada, por favor verifique se o script de intalação foi implementado no <head> do site"
				);
		} else
			toast.error(
				"Não foi possível fazer a verificação, por favor verifique o campo de site da empresa nas configurações de companhia"
			);
		setLoading(false);
	};

	const ColoredSpan = ({ text, options = {} }) => (
		<span style={{ color: "#2596AB", ...options }}>{text}</span>
	);

	return (
		<Fragment>
			<Breadcrumb current="Instalações" title="Instalações" />
			<div className="card">
				<div className="card-header">
					<h5>Código de Instalação da SDK</h5>
					<br />
					<div className="d-block">
						<span>
							Esse é o código do aviso de cookies, essencial para
							as funcionalidades da Nimbus Privacy. Use o botão
							abaixo para copiar o código e cole esta tag no{" "}
						</span>
						<ColoredSpan text={`<head>`} />
						<span>
							{" "}
							do seu site, ou instale-a via Tag Manager. Após a
							instalação, não deixe de testar a sua tag.
						</span>
					</div>
				</div>
				<div className="card-body">
					<CopyBlock
						text={bodySDKInstallationText}
						language="html"
						showLineNumbers={true}
						theme={monoBlue}
						wrapLines={true}
						codeBlock
					/>
				</div>
				<div className="card-footer">
					<LoadingButton
						style={{ float: "right" }}
						loading={loading}
						color="primary"
						onClick={testInstall}
						child={"Testar instalação"}
					/>
				</div>
			</div>
			<div className="card">
				<div className="card-header">
					<h5>
						Código de instalação do App de Direitos dos Titulares
					</h5>
					<br />
					<div className="d-block">
						<span>
							Opcionalmente, pode-se adicionar ao site o modal
							para o Aplicativo de Direito dos Titulares. Utilize
							o código abaixo para adicionar o evento{" "}
						</span>
						<ColoredSpan text={"onclick"} />
						<span> ao elemento que irá abrir o modal.</span>
					</div>
				</div>
				<div className="card-body">
					<CopyBlock
						text={bodyModalText}
						language="html"
						showLineNumbers={true}
						theme={monoBlue}
						wrapLines={true}
						codeBlock
					/>
					<div className="row col mt-5 d-block">
						<span>
							Lembrando que o texto “Seus Dados” pode ser
							substituído pelo de sua preferência. Além disso,
							pode-se utilizar o componente que preferir, de modo
							geral, basta que a função{" "}
						</span>
						<ColoredSpan
							text={"DLController.openModal"}
							options={{ backgroundColor: "#EDFDFD" }}
						/>
						<span>
							{" "}
							seja executada para que o modal seja renderizado.
						</span>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SDKInstall;
