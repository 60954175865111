import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";

const TicketApproval = ({ authAnswers, ticketStatus }) => {
	const [collapse, setCollapse] = useState(
		ticketStatus == "WAITING_VALIDATION"
	);
	const toggle = () => {
		setCollapse(!collapse);
	};

	return (
		<div className="card">
			<div className="card-header">
				<div className="row">
					<div className="col">
						<h5 className="card-title mt-3">Formulário de Aprovação</h5>
					</div>
					<div className="col-auto text-right">
						<button className="btn px-2 text-center" onClick={() => toggle()}>
							{/*<i></i>*/}
							{collapse ? (
								<ChevronUp style={{ marginTop: "5px", marginLeft: "1px" }} />
							) : (
								<ChevronDown style={{ marginTop: "5px", marginLeft: "1px" }} />
							)}
						</button>
					</div>
				</div>
				<p className="card-subtitle my-2 text-muted">
					Encontre as respostas do cliente abaixo, para o formulário de
					aprovação de abertura de chamado. Caso as respostas sejam válida,
					aprovar a abertura do ticket abaixo. Caso uma ou mais respostas
					estejam erradas, favor reprovar a abertura do ticket.
				</p>
			</div>
			<div className="card-body">
				<Collapse isOpen={collapse ? true : false}>
					{authAnswers.map(({ answer, question, order }) => (
						<div key={order}>
							<div className="card border border-light my-2">
								<div className="card-body py-3">
									<label>
										<h6>
											<strong>{order + 1}</strong>. {question}
										</h6>
										<strong>R: {answer}</strong>
									</label>
								</div>
							</div>
						</div>
					))}
				</Collapse>
			</div>
		</div>
	);
};

export default TicketApproval;
