import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "~/states/modules/users";
import shiroTrie from "shiro-trie";

const verifyPermissions = (
	user,
	requiredPermissions,
	requiredRoles,
	{ every }
) => {
	const _getName = ({ name }) => name;
	const _getRights = ({ rights }) => (rights ? rights.map(_getName) : []);

	const userAuthz = {
		roles: user.roles.map(_getName),
		rights: shiroTrie.newTrie().add(
			// Removing duplicated permissions since a user can have the same permission of his role
			Array.from(
				new Set([
					...user.roles.map(_getRights).flat(),
					...user.rights.map(_getName),
				])
			)
		),
	};
	// Or there is no required permission or user has all (or some, depending on param every) the required permissions
	const has = (required, checkFn) =>
		every
			? !required || required.every(checkFn)
			: !!required && required.some(checkFn);

	const hasPermission = has(
		requiredPermissions,
		userAuthz.rights.check.bind(userAuthz.rights)
	);

	const hasRole = has(
		requiredRoles,
		userAuthz.roles.includes.bind(userAuthz.roles)
	);

	const isAllowed = every
		? hasPermission && hasRole
		: hasPermission || hasRole || (!requiredPermissions && !requiredRoles);

	return isAllowed;
};

/**
 * Verifies if some user has the determinated permissions, based on it's rights and roles. It will return true if the user
 * has all of the required permissions and roles passed as parameter. If the user is not valid, it will return false.
 * If one of the requirements is ommited, only the other will be evaluated, but if none is passed, it will return true.
 * @typedef {Object<string, any>} User Instance of user model.
 * @param {User} user The User with rights and roles to evaluate his permissions.
 * @param {Array<String>} [requiredPermissions] Required rights to access the resource.
 * @param {Array<String>} [requiredRoles] Required roles to access the resource.
 * @param {Object} [options] Optional parameters to customize the validation.
 * @param {Boolean} [options.every] If all the permissions are required, default is true.
 */
const isAllowed = (user, requiredPermissions, requiredRoles, options) =>
	// Here you can do parameter validations
	!user
		? false
		: verifyPermissions(user, requiredPermissions, requiredRoles, {
				every: true,
				...options,
		  });

const AccessControl = ({
	children,
	requiredPermissions,
	requiredRoles,
	options,
}) => {
	const dispatch = useDispatch();
	const currentUser = useSelector(({ users }) => users.currentUser?.data);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	return (
		<Fragment>
			{isAllowed(currentUser, requiredPermissions, requiredRoles, options)
				? children
				: null}
		</Fragment>
	);
};

export { isAllowed };

export default AccessControl;
