import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";
import {
	getDatabases,
	getDatabasesFailure,
	getDatabasesSuccess,
	createDatabase,
	createDatabaseFailure,
	createDatabaseSuccess,
	removeDatabase,
	removeDatabaseFailure,
	removeDatabaseSuccess,
	getDatabase,
	getDatabaseFailure,
	getDatabaseSuccess,
	editDatabase,
	editDatabaseFailure,
	editDatabaseSuccess,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getDatabases, getDatabasesSaga);
	yield takeEvery(createDatabase, createDatabaseSaga);
	yield takeEvery(removeDatabase, removeDatabaseSaga);
	yield takeEvery(getDatabase, getDatabaseSaga);
	yield takeEvery(editDatabase, editDatabaseSaga);
}

function* getDatabasesSaga() {
	try {
		const { data } = yield call(api.get, "/databases");
		yield put(getDatabasesSuccess(data));
	} catch (error) {
		yield put(getDatabasesFailure(error.response?.data?.error));
	}
}

function* createDatabaseSaga({ payload }) {
	try {
		const { data } = yield call(api.post, `/databases`, payload);
		yield put(createDatabaseSuccess(data));
	} catch (error) {
		yield put(createDatabaseFailure(error.response?.data?.error));
	}
}

function* removeDatabaseSaga({ payload }) {
	const { databaseId } = payload;
	try {
		const { data } = yield call(api.delete, `/databases/${databaseId}`);
		yield put(removeDatabaseSuccess(databaseId));
	} catch (error) {
		yield put(removeDatabaseFailure(error.response?.data?.error));
	}
}

function* getDatabaseSaga({ payload }) {
	const { databaseId } = payload;
	try {
		const { data } = yield call(api.get, `/databases/${databaseId}`);
		yield put(getDatabaseSuccess(data));
	} catch (error) {
		yield put(getDatabaseFailure(error.response?.data?.error));
	}
}

function* editDatabaseSaga({ payload }) {
	const { databaseId, data: body } = payload;
	try {
		const { data } = yield call(
			api.patch,
			`/databases/${databaseId}`,
			body
		);
		yield put(editDatabaseSuccess(data));
	} catch (error) {
		yield put(editDatabaseFailure(error.response?.data?.error));
	}
}
