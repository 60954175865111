import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCompany } from "~/states/modules/company";

const CompanyAccess = ({ companyId, children, action }) => {
	const dispatch = useDispatch();
	const company = useSelector(({ company }) => company.currentCompany.data);
	const history = useHistory();

	useEffect(() => {
		dispatch(getCompany());
	}, [dispatch]);

	useEffect(() => {
		isNotValid() && changeWorkspace();
	}, [company, companyId]);

	const isNotValid = () => companyId && company && companyId !== company?.id;

	const changeWorkspace = () => {
		dispatch(action());
		history.push(`${process.env.PUBLIC_URL}/`);
	};

	return children;
};

export default CompanyAccess;
