import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "~/states/modules/company";
// import logo from "../../../assets/images/endless-logo.png";
import logo from "../../../assets/images/logo-blue.png";
// import Language from './language';
import UserMenu from "./userMenu";
//import Notification from "./notification";
//import SearchHeader from "./searchHeader";
import { Link } from "react-router-dom";
import { AlignLeft, MoreHorizontal, ChevronDown } from "react-feather";
import CompanyChangeDropdownMenu from "../companyDropdownMenu";
import { getCompanies } from "~/states/modules/users";
import { DropdownToggle, Dropdown, Tooltip } from "reactstrap";
import AccessControl from "../accessControl";

const Header = () => {
	const dispatch = useDispatch();
	const company = useSelector(({ company }) => company.currentCompany.data);
	const companies = useSelector(({ users }) => users.companies.data);
	const [sidebar, setSidebar] = useState(false);
	//const [rightSidebar, setRightSidebar] = useState(true);
	const [headerbar, setHeaderbar] = useState(true);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen(!dropdownOpen);

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

	const openCloseSidebar = () => {
		if (sidebar) {
			setSidebar(!sidebar);
			document
				.querySelector(".page-main-header")
				.classList.remove("open");
			document.querySelector(".page-sidebar").classList.remove("open");
		} else {
			setSidebar(!sidebar);
			document.querySelector(".page-main-header").classList.add("open");
			document.querySelector(".page-sidebar").classList.add("open");
		}
	};

	useEffect(() => {
		dispatch(getCompany());
		dispatch(getCompanies());
	}, [dispatch]);

	// function showRightSidebar() {
	// 	if (rightSidebar) {
	// 		setRightSidebar(!rightSidebar);
	// 		document.querySelector(".right-sidebar").classList.add("show");
	// 	} else {
	// 		setRightSidebar(!rightSidebar);
	// 		document.querySelector(".right-sidebar").classList.remove("show");
	// 	}
	// }

	// full screen function
	// function goFull() {
	// 	if (
	// 		(document.fullScreenElement && document.fullScreenElement !== null) ||
	// 		(!document.mozFullScreen && !document.webkitIsFullScreen)
	// 	) {
	// 		if (document.documentElement.requestFullScreen) {
	// 			document.documentElement.requestFullScreen();
	// 		} else if (document.documentElement.mozRequestFullScreen) {
	// 			document.documentElement.mozRequestFullScreen();
	// 		} else if (document.documentElement.webkitRequestFullScreen) {
	// 			document.documentElement.webkitRequestFullScreen(
	// 				Element.ALLOW_KEYBOARD_INPUT
	// 			);
	// 		}
	// 	} else {
	// 		if (document.cancelFullScreen) {
	// 			document.cancelFullScreen();
	// 		} else if (document.mozCancelFullScreen) {
	// 			document.mozCancelFullScreen();
	// 		} else if (document.webkitCancelFullScreen) {
	// 			document.webkitCancelFullScreen();
	// 		}
	// 	}
	// }

	return (
		<Fragment>
			<div className="page-main-header">
				<div className="main-header-right row">
					<div className="main-header-left d-lg-none">
						<div className="text-center">
							<div className="logo-wrapper">
								<Link to="/">
									<img
										className="img-fluid"
										src={
											company?.logo ? company?.logo : logo
										}
										alt="blue logo"
										style={{ maxHeight: "40px" }}
									/>
								</Link>
								{companies?.length > 1 && (
									<Dropdown
										isOpen={dropdownOpen}
										toggle={toggle}
										style={{ float: "right" }}
									>
										<DropdownToggle
											color="#FFFFFF"
											style={{
												backgroundColor: "transparent",
												padding: 0,
												marginTop: "5px",
											}}
										>
											<ChevronDown />
										</DropdownToggle>
										<CompanyChangeDropdownMenu
											currentCompany={company}
											companies={companies}
										/>
									</Dropdown>
								)}
							</div>
						</div>
					</div>
					<div className="mobile-sidebar d-block">
						<div className="media-body text-right switch-sm">
							<label className="switch">
								<a href="#!" onClick={() => openCloseSidebar()}>
									<AlignLeft />
								</a>
							</label>
						</div>
					</div>
					<div className="nav-right col p-0">
						<ul className={`nav-menus ${headerbar ? "" : "open"}`}>
							<li />
							<AccessControl
								requiredPermissions={["company:create"]}
							>
								<li>
									<Link
										className="btn btn-sm btn-primary"
										id="newCompanyBtn"
										to="/company/add"
										style={{ color: "#fff" }}
									>
										Criar Companhia
									</Link>
								</li>
							</AccessControl>
							<UserMenu />
						</ul>
						<div
							className="d-lg-none mobile-toggle pull-right"
							onClick={() => setHeaderbar(!headerbar)}
						>
							<MoreHorizontal />
						</div>
					</div>
					{/* <script id="result-template" type="text/x-handlebars-template">
						<div className="ProfileCard u-cf">
							<div className="ProfileCard-avatar">
								<User />
							</div>
							<div className="ProfileCard-details">
								<div className="ProfileCard-realName"></div>
							</div>
						</div>
					</script>
					<script id="empty-template" type="text/x-handlebars-template">
						<div className="EmptyMessage">
							Your search turned up 0 results. This most likely means the
							backend is down, yikes!
						</div>
					</script> */}
				</div>
			</div>
		</Fragment>
	);
};
export default Header;
