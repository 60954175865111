import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useState, useEffect } from "react";
import slugify from "@sindresorhus/slugify";

const CompanyForm = ({ register, errors, watch, setValue }) => {
	const companyName = watch("name");

	useEffect(() => {
		const slug = slugify(companyName || "", {
			customReplacements: [["&", "e"]],
		});
		setValue("slug", slug);
	}, [companyName]);

	return (
		<>
			<div className="form-row col-12">
				{/* Name */}
				<div className="form-group col-md-8">
					<label className="col-form-label" htmlFor="companyName">
						Nome
						<span className="auth--required-symbol">*</span>
					</label>
					<input
						className="form-control"
						id="companyName"
						type="text"
						name="name"
						ref={register}
					/>
					<ErrorMessage
						errors={errors}
						name="name"
						as={<span className="auth--validation-alert" />}
					/>
				</div>
				{/* Slug */}
				<div className="form-group col-md-4">
					<label className="col-form-label" htmlFor="slug">
						Slug
						<span className="auth--required-symbol">*</span>
					</label>
					<input
						className="form-control"
						id="slug"
						type="text"
						name="slug"
						ref={register}
					/>
					<ErrorMessage
						errors={errors}
						name="slug"
						as={<span className="auth--validation-alert" />}
					/>
				</div>
			</div>
			{/* SiteUrl */}
			<div className="form-row form-group col-12">
				<label className="col-form-label" htmlFor="siteUrl">
					Link do Site
					<span className="auth--required-symbol">*</span>
				</label>
				<input
					className="form-control"
					id="siteUrl"
					type="text"
					name="siteUrl"
					ref={register}
				/>
				<ErrorMessage
					errors={errors}
					name="siteUrl"
					as={<span className="auth--validation-alert" />}
				/>
			</div>
			<div className="form-row form-group col-12">
				<label className="col-form-label" htmlFor="privacyPolicyLink">
					Link da Política de Privacidade
					<span className="auth--required-symbol">*</span>
				</label>
				<input
					className="form-control"
					id="privacyPolicyLink"
					type="text"
					name="privacyPolicyLink"
					ref={register}
				/>
				<ErrorMessage
					errors={errors}
					name="privacyPolicyLink"
					as={<span className="auth--validation-alert" />}
				/>
			</div>
			{/* Logo */}
			<div className="form-row form-group col-12">
				<label className="col-form-label" htmlFor="logo">
					Logo
					<span className="auth--required-symbol">*</span>
				</label>
				<p className="col-12">
					Imagem institucional, estará presente nos emails, no portal de
					administração e relatórios. Deve ser no formato de url.
				</p>
				<input
					className="form-control"
					id="logo"
					type="text"
					name="logo"
					ref={register}
				/>
				<ErrorMessage
					errors={errors}
					name="logo"
					as={<span className="auth--validation-alert" />}
				/>
			</div>
			<hr className="mt-4 mb-4" />
			<div className="form-row form-group col-12">
				Dados a serem requisitados no Aplicativo de Direitos
				<div className="form-row col-12">
					<div className="checkbox checkbox-primary col-md-3 col-sm-12">
						<input
							name="require_email"
							id="require_email"
							type="checkbox"
							checked
							readOnly
							ref={register}
						/>
						<label htmlFor="require_email">
							E-Mail
							<span className="auth--required-symbol">*</span>
						</label>
					</div>
					<div className="checkbox checkbox-primary col-md-3 col-sm-12">
						<input
							name="require_name"
							id="require_name"
							type="checkbox"
							ref={register}
						/>
						<label htmlFor="require_name">Nome</label>
					</div>
					<div className="checkbox checkbox-primary col-md-3 col-sm-12">
						<input
							name="require_phoneNumber"
							id="require_phoneNumber"
							type="checkbox"
							ref={register}
						/>
						<label htmlFor="require_phoneNumber">Celular</label>
					</div>
					<div className="checkbox checkbox-primary col-md-3 col-sm-12">
						<input
							name="require_cpf"
							id="require_cpf"
							type="checkbox"
							ref={register}
						/>
						<label htmlFor="require_cpf">CPF</label>
					</div>
				</div>
			</div>
			<hr className="mt-4 mb-4" />
			<div className="form-row form-group col-12">
				<div className="col-12">
					Escolha se a plataforma deve agir de forma automática ou manual
				</div>
				<div className="checkbox checkbox-primary col-12">
					<input
						name="autoEventsFlow"
						id="autoEventsFlow"
						type="checkbox"
						ref={register}
					/>
					<label htmlFor="autoEventsFlow">Plataforma automática</label>
				</div>
			</div>
		</>
	);
};

export default CompanyForm;
