import api from "./api";
import qs from "qs";
import generateSearchRequest from "../../utils/sequelize-search-builder-client";
import {
	formatFilterValuesForILikeFilter,
	formatPayload,
} from "~/utils/CTPayloadFormatter";
class ConsentDataService {
	async getClientConsents(payload) {
		const formattedPayload = formatPayload(payload);
		const settings = {
			where: {
				name: {
					as: "name",
					type: "iLike",
				},
				email: {
					as: "email",
					type: "like",
				},
				phone: {
					as: "phone",
					type: "like",
				},
				"companiesClientConsents.consentRequests.requestAt": {
					as: "companiesClientConsents.consentRequests.requestAt",
					type: "gte",
				},
			},
			order: formattedPayload?.order,
		};
		const request = generateSearchRequest(formattedPayload?.filters, settings);

		let params = {
			page: formattedPayload?.page || 0,
			size: formattedPayload?.pageSize || 20,
			...request,
		};

		params = formatFilterValuesForILikeFilter(params, ["name"]);

		return api
			.get("/clientConsents", {
				paramsSerializer: function (params) {
					return qs.stringify(params, { arrayFormat: "brackets" });
				},
				params,
			})
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"fail when getClientConsents in ConsentDataService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	getClientConsentsInformation(params) {
		const { id } = params;
		return api
			.get(`/clientConsents/${id}`)
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"fail when get a clientConsent's information in ConsentDataService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	getCounter() {
		return api
			.get("consentRequest/widgets")
			.then((response) => response.data)
			.catch((error) => {
				console.log(
					"fail when getCounter in ConsentDataService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}

	downloadClientConsentCSV() {
		return api
			.get("clientConsents/download")
			.then(
				(response) => new Blob([response.data], { type: "application/csv" })
			)
			.then((blob) => URL.createObjectURL(blob))
			.then((uril) => {
				let link = document.createElement("a");
				link.setAttribute("href", uril);
				link.setAttribute("download", "clientConsent.csv");
				let clickEvent = new MouseEvent("click", {
					view: window,
					bubbles: true,
					cancelable: false,
				});
				link.dispatchEvent(clickEvent);
			})
			.catch((error) => {
				throw error.response.data.error;
			});
	}

	async getConsentRequests(payload) {
		const formattedPayload = formatPayload(payload);

		const settings = {
			where: {
				"companiesClientConsents.clientConsents.name": {
					as: "companiesClientConsents.clientConsents.name",
					type: "iLike",
				},
				requestAt: {
					as: "requestAt",
					type: "gte",
				},
				channel: {
					as: "channel",
					type: "in",
					itemBy: "type",
				},
				status: {
					as: "status",
					type: "in",
					itemBy: "type",
				},
				updatedAt: {
					as: "updatedAt",
					type: "gte",
				},
				createdAt: {
					as: "createdAt",
					type: "gte",
				},
			},
			order: formattedPayload?.order,
		};

		const request = generateSearchRequest(formattedPayload?.filters, settings);

		let params = {
			page: formattedPayload?.page || 0,
			size: formattedPayload?.pageSize || 20,
			...request,
		};

		params = formatFilterValuesForILikeFilter(params, [
			"companiesClientConsents.clientConsents.name",
		]);
		return api
			.get("/consentRequest", {
				paramsSerializer: function (params) {
					return qs.stringify(params, { arrayFormat: "brackets" });
				},
				params,
			})
			.then((response) => response)
			.catch((error) => {
				console.log(
					"fail when getClientConsents in ConsentDataService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
}

export default new ConsentDataService();
