//import React, { useEffect } from "react";
import React, { Fragment } from 'react';
import sad from '../assets/images/other-images/sad.png';
import { Link } from "react-router-dom";

const NotFound = () => {

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <div className="container"><img className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <h2 className="headline font-info">404</h2>
                        </div>
                        <div className="col-md-8 offset-md-2">
                            <p className="sub-content">A página que você está tentando acessar não está disponível no momento. Isso pode ser porque a página não existe ou foi movida.</p>
                        </div>
                        <div><Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-info-gradien"> VOLTAR PARA A HOME PAGE</Link></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default NotFound;