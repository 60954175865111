import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";
import {
	getApiInfo,
	getApiInfoFailure,
	getApiInfoSuccess,
	editApiInfo,
	editApiInfoFailure,
	editApiInfoSuccess,
	checkRdStationConnection,
	checkRdStationConnectionSuccess,
	checkRdStationConnectionFailure,
	disconnectRdStation,
	disconnectRdStationSuccess,
	disconnectRdStationFailure,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getApiInfo, getApiInfoSaga);
	yield takeEvery(editApiInfo, editApiInfoSaga);
	yield takeEvery(checkRdStationConnection, checkRdStationConnectionSaga);
	yield takeEvery(disconnectRdStation, disconnectRdStationSaga);
}

function* getApiInfoSaga() {
	try {
		const { data } = yield call(api.get, "/apiInfo/");
		yield put(getApiInfoSuccess(data));
	} catch (error) {
		yield put(getApiInfoFailure(error.response?.data?.error));
	}
}

function* editApiInfoSaga({ payload }) {
	try {
		const { data } = yield call(api.post, "/apiInfo/", payload);
		yield put(editApiInfoSuccess(data));
	} catch (error) {
		yield put(editApiInfoFailure(error.response?.data?.error));
	}
}

function* checkRdStationConnectionSaga() {
	try {
		yield call(api.head, "/oauth/rd-station/check-connection");
		yield put(checkRdStationConnectionSuccess(true));
	} catch (error) {
		const response = error.response;
		if (response.status == 404) {
			yield put(checkRdStationConnectionSuccess(false));
		}
		yield put(
			checkRdStationConnectionFailure(
				new Error("Erro inesperado. Por favor, tente mais tarde.")
			)
		);
	}
}

function* disconnectRdStationSaga() {
	try {
		yield call(api.post, "/oauth/rd-station/disconnect");
		yield put(disconnectRdStationSuccess(false));
		//yield put(getApiInfo());
	} catch (error) {
		yield put(disconnectRdStationFailure(error.response?.data?.error));
	}
}
