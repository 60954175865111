import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from "~/components/common/breadcrumb";

import { getCompany, editCompany } from "~/states/modules/company";
import companyConstants from "~/states/modules/company/companyConstants";

import CompanyForm from "./companyForm";
import Modal from "~/components/common/modal";
import validate from "~/components/common/validate";

const CompanyInfo = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompany());
	}, [dispatch]);
	const company = useSelector(({ company }) => company.currentCompany.data);

	const [formInput, setFormInput] = useState(null);

	const [companyModal, setCompanyModal] = useState(false);

	const companyError = useSelector(
		({ company }) => company.currentCompany.error
	);
	const currentCompanyStatus = useSelector(
		({ company }) => company.currentCompany.status
	);
	const [editedCompanyFeedback, setEditedCompanyFeedback] = useState(false);
	const { status } = companyConstants;

	useEffect(() => {
		if (editedCompanyFeedback && currentCompanyStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedCompanyFeedback(false);
		} else if (
			editedCompanyFeedback &&
			currentCompanyStatus === status.FAILED
		) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(companyError);
		}
	}, [currentCompanyStatus]);

	const handleSubmitCompany = (input) => {
		if (!validate.anyNew(input, company)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setCompanyModal(true);
		}
	};

	const handleEditCompany = () => {
		setCompanyModal(false);
		setEditedCompanyFeedback(true);

		dispatch(editCompany(formInput));
	};

	return (
		<>
			<Breadcrumb current="Dados da Empresa" title="Dados da Empresa" />
			<div className="container-fluid">
				{company && (
					<CompanyForm company={company} onSubmit={handleSubmitCompany} />
				)}
			</div>
			<Modal
				isOpen={companyModal}
				onClose={() => setCompanyModal(false)}
				onAccept={handleEditCompany}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações da sua empresa?
			</Modal>
		</>
	);
};
export default CompanyInfo;
