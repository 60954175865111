import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { getApiUrl } from "~/services/envUtils";
import { ExternalLink, Check } from "react-feather";
import { Button } from "reactstrap";

import mailchimpLogo from "../../../../assets/images/mailchimp-logo.png";
import iuguLogo from "../../../../assets/images/iugu-logo.png";
import rdStationLogo from "../../../../assets/images/rdstation-logo.png";
import {
	checkRdStationConnection,
	disconnectRdStation,
} from "~/states/modules/apiInfo";

const OtherIntegrationForm = ({ onSubmit, apiInfo, companyId }) => {
	const dispatch = useDispatch();

	const { register, handleSubmit } = useForm({ defaultValues: apiInfo });

	const isRdConnected = useSelector(
		({ apiInfo }) => apiInfo.isRdStationOn?.data
	);

	useEffect(() => {
		dispatch(checkRdStationConnection());
	}, [dispatch]);

	const _handleMessage = (event) => {
		if (event.data === "RD_STATION_CONNECTED") {
			dispatch(checkRdStationConnection());
		}
	};

	useEffect(() => {
		window.addEventListener("message", _handleMessage);
		return () => {
			window.removeEventListener("message", _handleMessage);
		};
	}, []);

	const launchRdPopup = () => {
		const w = window.innerWidth > 1200 ? 1100 : window.innerWidth * 0.95;
		const h = window.innerWidth > 1200 ? 800 : 600;
		const left = (window.outerWidth + window.screenX) / 2 - w / 2;
		const top = (window.outerHeight + window.screenY) / 2 - h / 2;
		const popup = window.open(
			`${getApiUrl()}/oauth/redirect?companyId=${companyId}`,
			"RD Station",
			`scrollbars=no,resizable=no,menubar=no,location=no,width=${w},height=${h},top=${top},left=${left}`
		);
	};

	const _handleDisconnect = (event) => {
		dispatch(disconnectRdStation());
	};

	return (
		<form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="card">
				<div className="card-header">
					<h5>Integrações</h5>
				</div>
				<div className="card-body">
					<img
						className="blur-up lazyloaded"
						src={mailchimpLogo}
						alt="Mailchimp"
					/>
					<div className="form-row">
						<div className="form-group col-12">
							<label className="col-form-label">API-Key</label>
							<input
								className="form-control"
								type="text"
								placeholder="********"
								ref={register}
								name="mailchimpApiKey"
								id="mailchimpApiKey"
							/>
						</div>
					</div>
					<br></br>
					<img className="blur-up lazyloaded" src={iuguLogo} alt="Iugu" />
					<div className="form-row">
						<div className="form-group col-12">
							<label className="col-form-label">API-Key</label>
							<input
								className="form-control"
								type="text"
								placeholder="********"
								ref={register}
								name="iuguApiKey"
								id="iuguApiKey"
							/>
						</div>
					</div>
					<br></br>
					<img
						className="blur-up lazyloaded"
						src={rdStationLogo}
						alt="RD Station"
					/>

					<div className="form-row">
						<div className="form-group col-12">
							<div className="social mt-3">
								<div className="form-group btn-showcase ">
									{!isRdConnected && (
										<Button outline color="primary" onClick={launchRdPopup}>
											<b>
												<ExternalLink size={14} />
											</b>{" "}
											Conectar
										</Button>
									)}
									{isRdConnected && (
										<>
											<button
												type="button"
												className="btn btn-outline-light"
												disabled
											>
												<Check className="mr-1" size={14} />
												Conectado
											</button>

											<button
												type="button"
												className="btn btn-outline-danger"
												onClick={_handleDisconnect}
											>
												Desconectar
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card-footer d-flex justify-content-end">
					<button type="submit" className="btn btn-primary mr-1">
						Salvar configurações
					</button>
				</div>
			</div>
		</form>
	);
};
export default OtherIntegrationForm;
