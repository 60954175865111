import React, { useState } from "react";
import { ChromePicker } from "react-color";

import "./style.scss";

/*

	Esse é o componente utilizado para seleção de cores dentro das páginas de configuração.

	Ele possui a estrutura:

	<label class= "col-form-label color-picker-label"/> ---> Texto acima do retângulo
	<div class="input-container">
		<ChromePicker/> ---> seletor (oculto por padrão, aparece ao clicarmos no círculo ou no hex)
		<span class="color-input-span"/> ---> Círculo clicável
		<p class="pl-2 color-input-value"></p> ---> Valor hex ao lado do círculo
	</div>

	A lógica de fazer o picker aparecer/sumir é feita pela função handlePickerOnBlur.

	TODO: esse picker poderia ser feito recebendo um array de objetos com as informações necessárias e usando um map() para criar os componentes...

*/
const doesCurrentColorContainTransparency = (hex) => {
	return hex.length !== 7 && hex[7] + hex[8] !== "ff";
};

const hexToRgbaConversor = (hex) => {
	// Creates a rgba object from the current string containing the hex value
	let rgba = {
		r: hex[1] + hex[2],
		g: hex[3] + hex[4],
		b: hex[5] + hex[6],
		a: hex[7] + hex[8],
	};
	let transparency = (parseInt(rgba.a, 16) / 256).toFixed(2);
	// converts the hex values to decimal
	rgba.r = parseInt(rgba.r, 16);
	rgba.g = parseInt(rgba.g, 16);
	rgba.b = parseInt(rgba.b, 16);
	rgba.a = transparency;

	return rgba;
};

const ColorPicker = ({ description, color, onChange }) => {
	const [showColorPicker, setShowColorPicker] = useState(false);

	const handlePickerOnBlur = (e) => {
		if (e.relatedTarget == null) {
			setShowColorPicker(false);
			return;
		}
		if (
			e.target.nodeName === "DIV" &&
			e.relatedTarget.id.includes("rc-editable-input")
		) {
			return;
		}
		if (
			e.target.id.includes("rc-editable-input") &&
			e.relatedTarget.nodeName === "DIV" &&
			!e.currentTarget.contains(e.relatedTarget)
		) {
			setShowColorPicker(false);
			return;
		}
		if (
			e.target.id.includes("rc-editable-input") &&
			e.relatedTarget.nodeName === "DIV" &&
			e.currentTarget.contains(e.relatedTarget)
		) {
			return;
		}
		if (
			e.target.id.includes("rc-editable-input") &&
			e.relatedTarget.id.includes("rc-editable-input")
		) {
			return;
		}
		setShowColorPicker(false);
	};

	let doesColorHaveTransparency = doesCurrentColorContainTransparency(color);

	let rgbaValues;

	if (doesColorHaveTransparency === true) {
		rgbaValues = hexToRgbaConversor(color);
	}

	const returnColorStringForDisplay = () => {
		if (doesColorHaveTransparency) {
			const rgbaString =
				"rgba(" +
				rgbaValues.r +
				", " +
				rgbaValues.g +
				", " +
				rgbaValues.b +
				", " +
				rgbaValues.a +
				")";

			return rgbaString;
		} else {
			return color;
		}
	};

	return (
		<div className="color-picker-container">
			<label className="col-form-label color-picker-label">{description}</label>
			<div
				className="input-container"
				tabIndex={1}
				onBlur={(e) => {
					e.persist();
					handlePickerOnBlur(e);
				}}
			>
				{showColorPicker && (
					<ChromePicker
						className="color-input"
						color={color}
						onChange={onChange}
					/>
				)}
				<span
					style={{
						backgroundColor: color,
					}}
					className="color-input-span"
					onClick={() => setShowColorPicker(!showColorPicker)}
				/>
				<p
					className="pl-2 color-input-value"
					onClick={() => setShowColorPicker(!showColorPicker)}
				>
					{returnColorStringForDisplay()}
				</p>
			</div>
		</div>
	);
};

export default ColorPicker;
