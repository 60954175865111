import extend from "lodash/extend";
import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";

import { selectAllTickets, selectTicket } from "./selectors";

const initialState = {
	createdTicket: { error: null, loading: false, data: null },
	tickets: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: [{ id: "createdAt", desc: true }],
			filters: [],
		},
	},
	singleTicket: { error: null, loading: false, data: null },
	statusFlow: { error: null, loading: false, data: null },
	ticketsPerAssigned: { error: null, loading: false, data: [] },
	ticketHistory: { error: null, loading: false, data: [] },
	ticketReportDownload: { error: null, loading: false, data: null },
	ticketsForChart: { error: null, loading: false, data: [] },
	ticketsTypes: { error: null, loading: false, data: [] },
	ticketsStatus: { error: null, loading: false, data: [] },
};

const { actions, reducer } = createSlice({
	name: "tickets",
	initialState,
	reducers: {
		eraseSingleTicket: (state) =>
			extend(state, {
				singleTicket: { error: null, loading: false, data: null },
			}),
		getTickets: (state, { payload }) =>
			extend(state, {
				tickets: {
					...state.tickets,
					tableParams: payload,
					error: null,
					loading: true,
				},
			}),
		getTicketsFailure: (state, { payload }) =>
			extend(state, {
				tickets: {
					...state.tickets,
					error: payload,
					loading: false,
				},
			}),
		getTicketsSuccess: (state, { payload }) => {
			extend(state, {
				tickets: {
					...state.tickets,
					error: null,
					loading: false,
					//data: selectAllTickets(payload.data),
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			});
		},
		showTicket: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: true,
				},
			}),
		showTicketFailure: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: payload,
					loading: false,
				},
			}),
		showTicketSuccess: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: false,
					data: selectTicket(payload.data),
				},
			}),
		createTicket: (state, { payload }) =>
			extend(state, {
				createdTicket: {
					error: null,
					loading: true,
				},
			}),
		createTicketFailure: (state, { payload }) =>
			extend(state, {
				createdTicket: {
					error: payload,
					loading: false,
				},
			}),
		createTicketSuccess: (state, { payload }) =>
			extend(state, {
				createdTicket: {
					error: null,
					loading: false,
					data: selectTicket(payload.data.ticket),
				},
			}),
		getStatusFlow: (state) =>
			extend(state, {
				statusFlow: {
					error: null,
					loading: true,
					//data: null,
				},
			}),
		getStatusFlowFailure: (state, { payload }) =>
			extend(state, {
				statusFlow: {
					error: payload,
					loading: false,
					data: null,
				},
			}),
		getStatusFlowSuccess: (state, { payload }) =>
			extend(state, {
				statusFlow: {
					error: null,
					loading: false,
					data: payload,
				},
			}),
		updateTicket: (state) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: true,
				},
			}),
		updateTicketFailure: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: payload,
					loading: false,
				},
			}),
		updateTicketSuccess: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: false,
					data: selectTicket(payload),
				},
			}),
		getTicketChart: (state) =>
			extend(state, {
				ticketsForChart: {
					error: null,
					loading: true,
					data: state.ticketsForChart.data,
				},
			}),
		getTicketChartFailure: (state, { payload }) =>
			extend(state, {
				ticketsForChart: {
					error: payload,
					loading: false,
					data: state.ticketsForChart.data,
				},
			}),
		getTicketChartSuccess: (state, { payload }) =>
			extend(state, {
				ticketsForChart: {
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		getTicketsPerAssigned: (state) =>
			extend(state, {
				ticketsPerAssigned: {
					error: null,
					loading: true,
					data: state.ticketsPerAssigned.data,
				},
			}),
		getTicketsPerAssignedFailure: (state, { payload }) =>
			extend(state, {
				ticketsPerAssigned: {
					error: payload,
					loading: false,
					data: state.ticketsPerAssigned.data,
				},
			}),
		getTicketsPerAssignedSuccess: (state, { payload }) =>
			extend(state, {
				ticketsPerAssigned: {
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		getTicketHistory: (state) =>
			extend(state, {
				ticketHistory: {
					error: null,
					loading: true,
					data: [],
				},
			}),
		getTicketHistoryFailure: (state, { payload }) =>
			extend(state, {
				ticketHistory: {
					error: payload,
					loading: false,
					data: state.ticketHistory.data,
				},
			}),
		getTicketHistorySuccess: (state, { payload }) =>
			extend(state, {
				ticketHistory: {
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		handleEvent: (state) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: true,
					data: state.singleTicket.data,
				},
			}),
		handleEventFailure: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: payload,
					loading: false,
					data: state.singleTicket.data,
				},
			}),
		handleEventSuccess: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: false,
					data: selectTicket(payload),
				},
			}),
		handleEvent: (state) =>
			extend(state, {
				singleTicket: {
					error: null,
					loading: true,
					data: state.singleTicket.data,
				},
			}),
		handleEventFailure: (state, { payload }) =>
			extend(state, {
				singleTicket: {
					error: payload,
					loading: false,
					data: state.singleTicket.data,
				},
			}),
		downloadTicketReport: (state) =>
			merge(state, {
				ticketReportDownload: { loading: true },
			}),
		downloadTicketReportSuccess: (state, { payload }) =>
			merge(state, {
				ticketReportDownload: { loading: false },
			}),
		downloadTicketReportFailure: (state, { payload }) =>
			merge(state, {
				ticketReportDownload: { loading: false, error: payload },
			}),
		getTicketsTypes: (state) => {
			extend(state, {
				ticketsTypes: {
					error: null,
					loading: true,
					data: state.ticketsTypes.data,
				},
			});
		},
		getTicketsTypesFailure: (state, { payload }) => {
			extend(state, {
				ticketsTypes: {
					error: payload,
					loading: false,
					data: state.ticketsTypes.data,
				},
			});
		},
		getTicketsTypesSuccess: (state, { payload }) => {
			extend(state, {
				ticketsTypes: {
					error: null,
					loading: false,
					data: payload.data,
				},
			});
		},
		getTicketsStatus: (state) => {
			extend(state, {
				ticketsStatus: {
					error: null,
					loading: true,
					data: state.ticketsStatus.data,
				},
			});
		},
		getTicketsStatusFailure: (state, { payload }) => {
			extend(state, {
				ticketsStatus: {
					error: payload,
					loading: false,
					data: state.ticketsStatus.data,
				},
			});
		},
		getTicketsStatusSuccess: (state, { payload }) => {
			extend(state, {
				ticketsStatus: {
					error: null,
					loading: false,
					data: payload.data,
				},
			});
		},
	},
});

export const {
	eraseSingleTicket,
	getTickets,
	getTicketsSuccess,
	getTicketsFailure,
	showTicket,
	showTicketFailure,
	showTicketSuccess,
	createTicket,
	createTicketFailure,
	createTicketSuccess,
	getStatusFlow,
	getStatusFlowFailure,
	getStatusFlowSuccess,
	updateTicket,
	updateTicketFailure,
	updateTicketSuccess,
	getTicketsPerAssigned,
	getTicketsPerAssignedSuccess,
	getTicketsPerAssignedFailure,
	getTicketHistory,
	getTicketHistorySuccess,
	getTicketHistoryFailure,
	getTicketChart,
	getTicketChartSuccess,
	getTicketChartFailure,
	handleEvent,
	handleEventSuccess,
	handleEventFailure,
	downloadTicketReport,
	downloadTicketReportSuccess,
	downloadTicketReportFailure,
	getTicketsTypes,
	getTicketsTypesFailure,
	getTicketsTypesSuccess,
	getTicketsStatus,
	getTicketsStatusFailure,
	getTicketsStatusSuccess,
} = actions;

export { default as ticketsSaga } from "./sagas";

export default reducer;
