import React, { Fragment } from "react";

import TicketTable from "./ticketTable";
import WidgetTotals from "./widgetTotals";

const TicketsComponent = ({ dateRange }) => {
	return (
		<Fragment>
			<WidgetTotals />

			<div className="card">
				<div className="card-header">
					<h5>Tabela de pedidos</h5>
					<p>
						Tabela com a contagem de pedidos finalizados, abertos,
						expirados e próximos à data de expiração
					</p>
				</div>
				<div className="card-body">
					<div className="user-status table-responsive">
						<TicketTable />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TicketsComponent;
