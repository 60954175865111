import React, { Fragment, useState } from "react";
import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Dropdown,
} from "reactstrap";
import { ChevronDown } from "react-feather";

const TableDropdown = ({ tag, categories, removedTag, onChange }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen(!dropdownOpen);

	const changeTagCategory = (label) => {
		onChange(tag, label);
	};

	const DropdownToggleCustom = ({ name, ...restOptions }) => (
		<DropdownToggle {...restOptions}>
			{name} <ChevronDown style={{ marginTop: "4px" }} size={15} />
		</DropdownToggle>
	);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggleCustom
				name={tag.category}
				style={{
					display: "inline-flex",
					width: "170px",
				}}
				className="dropdown-tags"
				color="tansparent"
				disabled={removedTag}
			/>
			{categories && (
				<DropdownMenu
					style={{ width: "200px", maxHeight: "200px", overflowY: "auto" }}
				>
					{categories.map((category, key) => (
						<Fragment key={key}>
							<DropdownItem onClick={() => changeTagCategory(category.label)}>
								{category.label}
							</DropdownItem>
						</Fragment>
					))}
				</DropdownMenu>
			)}
		</Dropdown>
	);
};

export default TableDropdown;
