import React, { Fragment, useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Copy2Clipboard = ({ text }) => {
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 3000);

		return () => {
			window.clearTimeout(timeout);
		};
	}, [copied]);

	return (
		<Fragment>
			<CopyToClipboard text={text} onCopy={() => setCopied(true)}>
				{copied ? (
					<div>
						<i className="icofont icofont-ui-check">{"  "}</i> Copiado
					</div>
				) : (
					<div>
						<i className="icofont icofont-ui-copy">{"  "}</i> Copiar
					</div>
				)}
			</CopyToClipboard>
		</Fragment>
	);
};

export default Copy2Clipboard;
