export const selectAllTickets = (payload) =>
	payload.map((ticket) => ({
		id: parseInt(ticket.id, 10),
		ticketNumber: ticket.ticketNumber,
		companyId: ticket.companyId,
		code: ticket.code,
		type: ticket.type,
		status: ticket.status,
		expirationDate: ticket.expirationDate
			? new Date(ticket.expirationDate)
			: null,
		updatedAt: ticket.updatedAt ? new Date(ticket.updatedAt) : null,
		createdAt: ticket.createdAt ? new Date(ticket.createdAt) : null,
		client: ticket.client
			? {
					id: ticket.client.id,
					email: ticket.client.email,
					name: ticket.client.name,
					cpf: ticket.client.cpf?.cpf,
					phone: ticket.client.phone,
			  }
			: undefined,
		ticketType: ticket.ticketType,
		user: ticket.user,
		ticketHistories: ticket.ticketHistories,
		authAnswers: ticket.authAnswers,
	}));

export const selectTicket = (ticket) => ({
	id: parseInt(ticket.id, 10),
	ticketNumber: ticket.ticketNumber,
	companyId: ticket.companyId,
	clientId: ticket.clientId,
	code: ticket.code,
	type: ticket.type,
	status: ticket.status,
	expirationDate: ticket.expirationDate
		? new Date(ticket.expirationDate)
		: null,
	updatedAt: ticket.updatedAt ? new Date(ticket.updatedAt) : null,
	createdAt: ticket.createdAt ? new Date(ticket.createdAt) : null,
	client: ticket.client
		? {
				id: ticket.client.id,
				email: ticket.client.email,
				name: ticket.client.name,
				cpf: ticket.client.cpf?.cpf,
				phone: ticket.client.phone,
		  }
		: undefined,
	ticketType: ticket.ticketType,
	user: ticket.user,
	ticketHistories: ticket.ticketHistories,
	authAnswers: ticket.authAnswers,
});
