import extend from "lodash/extend";
import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";
import usersConstants from "./usersConstants";
import { selectCompanies } from "../company/selectors";

const { TO_FETCH, TO_EDIT, EDITED, FETCHED, FAILED } = usersConstants.status;

const initialState = {
	currentUser: { data: null, error: false, loading: false, status: null },
	users: [],
	//
	user: { error: null, loading: false, data: null },
	//
	editedUser: { error: null, loading: false, data: null },
	removedUser: { error: null, loading: false, data: null },
	createdUser: { error: null, loading: false, data: null },
	companies: { error: null, loading: false, data: null },
};

const { actions, reducer } = createSlice({
	name: "users",
	initialState,
	reducers: {
		eraseUser: (state) =>
			extend(state, {
				user: { error: null, loading: false, data: null },
			}),
		//
		getUsers: (state) => state,
		getUsersFailure: (state) => state,
		getUsersSuccess: (state, { payload }) =>
			extend(state, {
				users: payload.data,
			}),
		//
		getCurrentUser: (state) =>
			merge({}, state, {
				currentUser: {
					error: false,
					loading: true,
					status: TO_FETCH,
				},
			}),
		getCurrentUserFailure: (state) =>
			extend(state, {
				currentUser: {
					data: null,
					error: true,
					loading: false,
					status: FAILED,
				},
			}),
		getCurrentUserSuccess: (state, { payload }) =>
			extend(state, {
				currentUser: {
					data: payload.user,
					error: false,
					loading: false,
					status: FETCHED,
				},
			}),
		//
		editCurrentUser: (state) =>
			extend(state, {
				currentUser: {
					data: null,
					error: false,
					loading: true,
					status: TO_EDIT,
				},
			}),
		editCurrentUserFailure: (state) =>
			extend(state, {
				currentUser: {
					data: null,
					error: true,
					loading: false,
					status: FAILED,
				},
			}),
		//
		editCurrentUser: (state) => state,
		editCurrentUserFailure: (state) => state,
		editCurrentUserSuccess: (state, { payload }) =>
			extend(state, {
				currentUser: {
					data: payload.data,
					error: false,
					loading: false,
					status: EDITED,
				},
			}),
		//
		editUser: (state, { payload }) =>
			extend(state, {
				editedUser: {
					loading: true,
					error: null,
				},
			}),
		editUserFailure: (state, { payload }) =>
			extend(state, {
				editedUser: {
					loading: false,
					error: payload,
				},
			}),
		editUserSuccess: (state, { payload }) => {
			const data = payload.data;
			// in the case we edit the same user in other states (currentuser and user), let's update then:
			if (
				state.currentUser.data &&
				state.currentUser.data.id === data.id
			) {
				state.currentUser.data = data;
			}
			if (state.user.data && state.user.data.id === data.id) {
				state.user.data = data;
			}

			return extend(state, {
				editedUser: {
					loading: false,
					error: null,
					data,
				},
			});
		},
		//
		removeUser: (state, { payload }) => {
			return extend(state, {
				removedUser: {
					loading: true,
					error: null,
				},
			});
		},
		removeUserFailure: (state, { payload }) => {
			return extend(state, {
				removedUser: {
					loading: false,
					error: payload,
				},
			});
		},
		removeUserSuccess: (state, { payload }) => {
			const id = payload; // the id deleted

			// in the case we edit the same user in other states (currentuser and user), let's update then:
			if (state.currentUser.data && state.currentUser.data.id === id) {
				state.currentUser.data = null;
			}
			if (state.user.data && state.user.data.id === id) {
				state.user.data = null;
			}
			state.users = state.users.filter((user) => user.id !== id);

			return extend(state, {
				removedUser: {
					loading: false,
					error: null,
					data: id,
				},
			});
		},
		//
		createUser: (state, { payload }) =>
			extend(state, {
				createdUser: {
					loading: true,
					error: null,
				},
			}),
		createUserFailure: (state, { payload }) =>
			extend(state, {
				createdUser: {
					loading: false,
					error: payload,
				},
			}),
		createUserSuccess: (state, { payload }) => {
			const data = payload.data;
			return extend(state, {
				createdUser: {
					loading: false,
					error: null,
					data: data.user,
				},
			});
		},
		//
		getUser: (state, { payload }) =>
			extend(state, {
				user: {
					loading: true,
					error: null,
				},
			}),
		getUserFailure: (state, { payload }) =>
			extend(state, {
				user: {
					loading: false,
					error: payload,
				},
			}),
		getUserSuccess: (state, { payload }) =>
			extend(state, {
				user: {
					loading: false,
					error: null,
					data: payload.data,
				},
			}),
		//
		getCompanies: (state) =>
			extend(state, {
				companies: {
					loading: true,
					error: null,
				},
			}),
		getCompaniesFailure: (state, { payload }) =>
			extend(state, {
				companies: {
					loading: false,
					error: payload,
				},
			}),
		getCompaniesSuccess: (state, { payload }) =>
			extend(state, {
				companies: {
					data: selectCompanies(payload.data),
					loading: false,
					error: null,
				},
			}),
	},
});

export const {
	eraseUser,
	getUsers,
	getUsersSuccess,
	getUsersFailure,
	getCurrentUser,
	getCurrentUserFailure,
	getCurrentUserSuccess,
	editCurrentUser,
	editCurrentUserFailure,
	editCurrentUserSuccess,
	editUser,
	editUserFailure,
	editUserSuccess,
	getUser,
	getUserFailure,
	getUserSuccess,
	removeUser,
	removeUserFailure,
	removeUserSuccess,
	createUser,
	createUserFailure,
	createUserSuccess,
	getCompanies,
	getCompaniesSuccess,
	getCompaniesFailure,
} = actions;

export { default as usersSaga } from "./sagas";

export default reducer;
