import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumb from "~/components/common/breadcrumb";
import Form from "./Form";

import { edit, eraseOne, getOne } from "~/states/modules/agents";
import CompanyAccess from "~/components/common/companyAccess";

const Edit = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const [actionFeedback, setActionFeedback] = useState(false);
	const { data: currentAgent } = useSelector(({ agents }) => agents.one);
	const {
		data: editedAgent,
		loading,
		error,
	} = useSelector(({ agents }) => agents.edited);

	useEffect(() => {
		dispatch(getOne(id));
	}, [dispatch]);

	const returnToAgentsList = () =>
		history.push(`${process.env.PUBLIC_URL}/company/integrations/agents`);

	const editAgent = (form) => {
		// Since we removed the default password, that is hashed, if none is provided, we must remove the empty string to avoid validation errors in the back end
		if (!form.password) delete form.password;
		setActionFeedback(true);
		dispatch(edit({ id, form }));
	};

	useEffect(() => {
		if (actionFeedback && !loading) {
			if (error) {
				toast.error(error.message);
			} else if (editedAgent) {
				toast.success("Agente Editado Com Sucesso.");
				returnToAgentsList();
			}
			setActionFeedback(false);
		}
	}, [loading, error, editedAgent]);

	return (
		<CompanyAccess action={eraseOne} companyId={currentAgent?.companyId}>
			{/* <Breadcrumb
				parent="Empresa"
				current="Agentes"
				title="Editar Agente"
			/> */}
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						{currentAgent && currentAgent.id == id && (
							<Form
								onSubmit={editAgent}
								defaultValues={{
									...currentAgent,
									password: undefined,
								}}
								loading={loading}
								required={false}
							/>
						)}
					</div>
				</div>
			</div>
		</CompanyAccess>
	);
};

export default Edit;
