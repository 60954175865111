import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";
import {
	getSdkConfig,
	getSdkConfigFailure,
	getSdkConfigSuccess,
	editSdkConfig,
	editSdkConfigFailure,
	editSdkConfigSuccess,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getSdkConfig, getSdkConfigSaga);
	yield takeEvery(editSdkConfig, editSdkConfigSaga);
}

function* getSdkConfigSaga() {
	try {
		const { data } = yield call(api.get, "/sdk/");
		yield put(getSdkConfigSuccess(data));
	} catch (error) {
		yield put(getSdkConfigFailure(error.response?.data?.error));
	}
}

function* editSdkConfigSaga({ payload }) {
	try {
		const { data } = yield call(api.post, "/sdk/", payload);
		yield put(editSdkConfigSuccess(data));
	} catch (error) {
		yield put(editSdkConfigFailure(error.response?.data?.error));
	}
}
