import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Breadcrumb from "~/components/common/breadcrumb";
import { Button, Badge, Modal, ModalBody } from "reactstrap";
//import { Search } from "react-feather";
import TagsTable from "./tagsTable";
import {
	getCompany,
	scanCompanyTags,
	createTags,
	getTags,
	removeTags,
} from "~/states/modules/company";
import { getCookiesCategories } from "~/states/modules/cookiesCategories";
import "./style.scss";

const Tags = () => {
	const dispatch = useDispatch();
	const scannedTags = useSelector(({ company }) => company.scannedTags);
	const scannedRemovedTags = useSelector(
		({ company }) => company.removedTags
	);
	const tags = useSelector(({ company }) => company.tags);
	const company = useSelector(({ company }) => company.currentCompany.data);
	const loadingFoundTags = useSelector(
		({ company }) => company.scannedTags.loading
	);
	const cookiesCategories = useSelector(
		({ cookiesCategories }) => cookiesCategories.data
	);
	const newTags = useSelector(({ company }) => company.newTags);

	const [showSaveButton, setShowSaveButton] = useState(false);
	const [foundTags, setFoundTags] = useState(
		scannedTags.data ? [...scannedTags.data] : []
	);
	const [categorizedTags, setCategorizedTags] = useState(
		tags.data ? [...tags.data] : []
	);
	const [removedTags, setRemovedTags] = useState([]);
	//const [filteredTags, setFilteredTags] = useState(categorizedTags);
	const [filter, setFilter] = useState([
		{ name: "Todas", quantity: 0 },
		{ name: "Necessárias", quantity: 0 },
		{ name: "Performance", quantity: 0 },
		{ name: "Funcionais", quantity: 0 },
		{ name: "Marketing", quantity: 0 },
		{ name: "Estatísticas", quantity: 0 },
		{ name: "Desconhecido", quantity: 0 },
	]);
	const [showToast, setShowToast] = useState(false);

	// Remove tags from scanned tags table and puts on removed tags table
	const onRemoveCategorizedTags = (tag) => {
		setShowSaveButton(true);
		const newRemovedTags = [];
		const result = categorizedTags.filter((t) => t != tag);
		setCategorizedTags(result);
		removedTags.map((t) => {
			newRemovedTags.push(t);
		});
		newRemovedTags.push(tag);
		setRemovedTags(newRemovedTags);
	};

	const onRemoveRemovedTags = (tag) => {
		const newCategorizedTags = [];
		const result = removedTags.filter((t) => t != tag);
		setRemovedTags(result);
		categorizedTags.map((t) => {
			newCategorizedTags.push(t);
		});
		newCategorizedTags.push(tag);
		setCategorizedTags(newCategorizedTags);
	};

	const onRemoveFoundTags = (tag) => {
		const result = foundTags.filter((t) => t != tag);
		setFoundTags(result);
	};

	const onChangeCategorizedTags = (tag, label) => {
		setShowSaveButton(true);
		const newCategorizedTags = [];
		categorizedTags.map((t) => {
			if (t.name === tag.name) {
				const updatedTag = { ...tag, category: label };
				newCategorizedTags.push(updatedTag);
			} else {
				newCategorizedTags.push(t);
			}
		});
		setCategorizedTags(newCategorizedTags);
	};

	const onChangeFoundTags = (tag, label) => {
		const newFoundTags = [];
		foundTags.map((t) => {
			if (t.name === tag.name) {
				const updatedTag = { ...tag, category: label };
				newFoundTags.push(updatedTag);
			} else {
				newFoundTags.push(t);
			}
		});
		setFoundTags(newFoundTags);
	};

	const scanTags = () => {
		if (company?.siteUrl) {
			setShowSaveButton(true);
			dispatch(scanCompanyTags());
		} else
			toast.error(
				"Campo de URL do site está vazio, por favor preencher o formulário em configurações de empresa"
			);
	};

	const saveTags = () => {
		setShowToast(true);
		setShowSaveButton(false);
		categorizedTags.forEach((tag) =>
			dispatch(createTags({ payload: tag }))
		);
		foundTags.forEach((tag) => dispatch(createTags({ payload: tag })));
		removedTags.forEach((tag) => dispatch(removeTags({ payload: tag.id })));
		company && dispatch(getTags(company.id));
		setRemovedTags([]);
		setFoundTags([]);
	};

	const onCancel = () => {
		//setShowSaveButton(false);
		setFoundTags(scannedTags.data ? [...scannedTags.data] : []);
		setCategorizedTags(tags.data ? [...tags.data] : []);
		setRemovedTags(
			scannedRemovedTags.data ? [...scannedRemovedTags.data] : []
		);
	};

	// TODO: Implement filter for table
	// const filterTags = (category) => {
	// 	const newFilteredTags = [];
	// 	setFilteredTags(categorizedTags);
	// 	if (category == "Todas") return;
	// 	filteredTags.forEach((tag) => {
	// 		if (tag.category === category) newFilteredTags.push(tag);
	// 	});
	// 	setFilteredTags(newFilteredTags);
	// };

	useEffect(() => {
		if (showToast) {
			if (newTags && newTags.error) {
				toast.error("Um Erro Inesperado Ocorreu");
				setShowToast(false);
			}
			if (newTags && newTags.data) {
				toast.success("Cookies Salvos com Sucesso");
				setShowToast(false);
			}
		}
	}, [newTags]);

	useEffect(() => {
		dispatch(getCookiesCategories());
		dispatch(getCompany());
	}, [dispatch]);

	useEffect(() => {
		company && dispatch(getTags(company.id));
	}, [company]);

	useEffect(() => {
		if (removedTags) {
			let removeCategorizedTag = [];
			removedTags.forEach((tag) => {
				categorizedTags.map((t, index) => {
					if (t.name == tag.name) categorizedTags.splice(index, 1);
				});
				removeCategorizedTag = [...categorizedTags];
				setCategorizedTags(removeCategorizedTag);
			});
		}
	}, [removedTags]);

	useEffect(() => {
		if (categorizedTags) {
			const newFilter = filter.map(({ name }) => {
				let i = 0;
				categorizedTags.forEach(
					({ category }) => name === category && i++
				);
				return { name, quantity: i };
			});
			newFilter[0].quantity = categorizedTags.length;
			setFilter(newFilter);
		}
	}, [categorizedTags]);

	/** BEGIN: Awaits for tags to load */
	useEffect(() => {
		setFoundTags(scannedTags.data ? [...scannedTags.data] : []);
	}, [scannedTags.data]);

	useEffect(() => {
		setCategorizedTags(tags.data ? [...tags.data] : []);
	}, [tags.data]);

	useEffect(() => {
		setRemovedTags(
			scannedRemovedTags.data ? [...scannedRemovedTags.data] : []
		);
	}, [scannedRemovedTags.data]);

	return (
		<>
			<Breadcrumb
				parent="Gestão de Cookies"
				current="Categorização de Cookies"
				title="Categorização de Cookies"
			/>
			<div className="container-fluid">
				<div className="row mb-5">
					<div className="col-sm-12">
						<Button
							color="primary"
							className="scan-button"
							style={{ float: "right" }}
							onClick={() => scanTags()}
						>
							Escanear cookies
						</Button>
					</div>
				</div>
				{/* SEARCH */}
				{/* <div className="row justify-content-end">
					<div className="col-sm-4">
						<div className="input-group" style={{ marginBottom: "20px" }}>
							<div className="input-group-prepend">
								<span
									className="input-group-text"
									style={{ backgroundColor: "#FFFFFF" }}
								>
									<Search />
								</span>
							</div>
							<input
								className="form-control"
								id="tagSearch"
								type="search"
								name="search"
								placeholder="Procurar Tag"
							/>
						</div>
					</div>
				</div> */}
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						{filter.map((tagCategories, key) => (
							<Fragment key={key}>
								<Button
									color="transparent"
									className="tag-buttons"
									//onClick={() => filterTags(tagCategories.name)}
								>
									{tagCategories.name + "  "}
									<Badge className="badge-pill badge-light">
										{tagCategories.quantity}
									</Badge>
								</Button>
							</Fragment>
						))}
					</div>
				</div>
				{/* BEGIN: Tags Categorizadas */}
				{categorizedTags.length > 0 && (
					<div className="row">
						<div className="col-sm-12">
							<TagsTable
								tags={categorizedTags}
								title="Cookies Categorizados"
								categories={cookiesCategories}
								onRemove={onRemoveCategorizedTags}
								onChange={onChangeCategorizedTags}
							/>
						</div>
					</div>
				)}
				{/* END: Tags Categorizadas */}
				{/* BEGIN: Tags Encontradas */}
				{!loadingFoundTags ? (
					foundTags?.length > 0 && (
						<div className="row">
							<div className="col-sm-12">
								<TagsTable
									tags={foundTags}
									title="Cookies Encontrados"
									newTag={true}
									categories={cookiesCategories}
									onRemove={onRemoveFoundTags}
									onChange={onChangeFoundTags}
								/>
							</div>
						</div>
					)
				) : (
					<Modal isOpen={true} centered={true}>
						<ModalBody className="p-5">
							<div className="row">
								<div className="col-sm-12 text-center m-5">
									<div className="loader bg-white">
										<div className="whirly-loader"></div>
									</div>
									<h5 className="mt-5">
										Estamos escaneando o seu site. Isso pode
										durar alguns minutos.
									</h5>
								</div>
							</div>
						</ModalBody>
					</Modal>
				)}
				{/* END: Tags Encontradas */}
				{/* BEGIN: Tags Removidas */}
				{removedTags.length > 0 && (
					<div className="row">
						<div className="col-sm-12">
							<TagsTable
								tags={removedTags}
								title="Cookies Removidos"
								removedTag={true}
								categories={cookiesCategories}
								onRemove={onRemoveRemovedTags}
							/>
						</div>
					</div>
				)}
				{/* END: Tags Removidas */}
				{/* BEGIN: buttons */}
				<div className="row text-right mb-5">
					{showSaveButton && (
						<div className="col-sm-12 btn-showcase">
							<Button outline color="primary" onClick={onCancel}>
								Cancelar
							</Button>
							<Button color="primary" onClick={saveTags}>
								Salvar configurações
							</Button>
						</div>
					)}
				</div>

				{/* END: buttons */}
			</div>
		</>
	);
};

export default Tags;
