import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../assets/customPackages/react-switch-lang";
import Breadcrumb from "../../common/breadcrumb";
import {
	showTicket,
	getStatusFlow,
	getTicketHistory,
	eraseSingleTicket,
} from "~/states/modules/tickets";
import { getCompany } from "~/states/modules/company";

import TicketForm from "./ticketForm";
import TicketFlow from "./ticketFlow";
import TicketHistory from "./ticketHistory";
import Modal from "~/components/common/modal";
import TicketApproval from "./ticketApproval";
import CompanyAccess from "~/components/common/companyAccess";

const Ticket = ({ t }) => {
	const dispatch = useDispatch();

	const [modal, setModal] = useState(false);
	const [data, setData] = useState(null);

	let { ticketId } = useParams();

	const company = useSelector(({ company }) => company.currentCompany.data);
	const ticket = useSelector(({ tickets }) => tickets.singleTicket?.data);
	const statusFlow = useSelector(({ tickets }) => tickets.statusFlow?.data);
	const ticketHistory = useSelector(
		({ tickets }) => tickets.ticketHistory?.data
	);

	useEffect(() => {
		dispatch(getCompany());
		dispatch(showTicket({ ticketId }));
	}, [ticketId, dispatch]);

	useEffect(() => {
		dispatch(getStatusFlow(ticketId));
		dispatch(getTicketHistory(ticketId));
	}, [ticket, dispatch]);

	const handleSubmit = (input) => {
		setData(input);
		setModal(true);
	};

	const handleEditCompany = () => {
		//dispatch(editCompany({ companyId: currentUser.companyId, data }));
		// console.log(data);
		setModal(false);
	};

	return (
		<CompanyAccess companyId={ticket?.companyId} action={eraseSingleTicket}>
			<>
				<Breadcrumb
					title={t("PAGE.TICKET_DETAIL.TITLE")}
					parent={t("PAGE.TICKET_LIST.TITLE")}
					current={t("PAGE.TICKET_DETAIL.TITLE")}
				/>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							{ticket && (
								<TicketForm
									ticket={ticket}
									onSubmit={handleSubmit}
									offeredRequests={company?.offeredRequests}
								/>
							)}

							{ticket?.authAnswers && (
								<TicketApproval
									authAnswers={ticket.authAnswers}
									ticketStatus={ticket.status}
								/>
							)}

							<div className="card">
								{/* <Timeline /> */}
								<div className="card-body">
									{statusFlow && (
										<TicketFlow statusFlow={statusFlow} ticketId={ticketId} />
									)}
								</div>
							</div>

							{ticketHistory?.length > 0 && (
								<div className="card">
									<div className="card-header">
										<h5>Histórico</h5>
									</div>
									<div className="card-body">
										<TicketHistory ticketHistories={ticketHistory} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<Modal
					isOpen={modal}
					onClose={() => setModal(false)}
					onAccept={handleEditCompany}
					title="Editar"
				>
					Tem certeza que deseja editar as informações do pedido{" "}
					<strong>{ticket?.ticketNumber}</strong>?
				</Modal>
			</>
		</CompanyAccess>
	);
};

export default translate(Ticket);
