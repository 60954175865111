import React, { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";

import "react-dropzone-uploader/dist/styles.css";

import Breadcrumb from "../../../common/breadcrumb";

import { getConsentTypes } from "~/states/modules/consentTypes";
import { importClientConsent } from "~/states/modules/company";

import useYupResolver from "../../../common/yupResolver";
import { ErrorMessage } from "@hookform/error-message";

import { debounce } from "../../../../utils/operators";

import yupSchema from "./yupSchema";

const customStyles = (stylesOverride = {}) => {
	return {
		control: (base) => ({
			...base,
			...stylesOverride,
		}),
	};
};

const AddClientConsent = () => {
	const dispatch = useDispatch();
	const [showMessage, setShowMessage] = useState(false);

	const { register, handleSubmit, errors, control, reset, trigger } = useForm({
		mode: "onChange",
		resolver: useYupResolver(yupSchema),
	});

	const validateFields = debounce(async (fields) => {
		for (const field of fields) {
			await trigger(field);
		}
	});

	const { data: consentTypesList } = useSelector(
		({ consentTypes }) => consentTypes.list
	);

	useEffect(() => {
		dispatch(getConsentTypes());
	}, [dispatch]);

	const list = consentTypesList.map((consentType) => ({
		value: consentType.id,
		label: consentType.name,
	}));

	const consentTypeSelect = useRef();

	const { loading, error, data } = useSelector(
		({ company }) => company.importedClientConsent
	);

	const resetForm = () => {
		reset();
		consentTypeSelect.current && consentTypeSelect.current.clear();
	};

	useEffect(() => {
		if (showMessage) {
			if (data) {
				resetForm();
				if (data.rowsInserted.length === 0)
					toast.error(`Não houveram consentimentos criados`);
				else if (data.rowsRejected.length > 0) {
					toast.warn(data.generalMessage);
				} else {
					toast.success(`Consentimentos criados com sucesso!`);
				}
			} else if (error) {
				toast.error(error.message);
			}
		}
	}, [data, error]);

	const onSubmit = async (data) => {
		const {
			consentTypeId,
			checkboxEmail,
			checkboxSms,
			checkboxSendNow,
			cpf,
			email,
			name,
			phone,
		} = data;

		setShowMessage(true);

		dispatch(
			importClientConsent({
				clients: [{ cpf, email, name, phone, externalId: nanoid() }],
				force: true,
				consentTypeId,
				email: checkboxEmail,
				sms: checkboxSms,
				sendNow: checkboxSendNow,
			})
		);
	};

	return (
		<>
			<Breadcrumb
				parent="Listagem De Clientes"
				title="Novo Consentimento do Cliente"
				current="Novo Consentimento do Cliente"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<form
							className="needs-validation"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="card">
								<div className="card-body">
									<div className="form-group col-md-12 col-sm-12">
										<div className="form-row">
											<div className="col-12 mb-3" style={{ width: "100%" }}>
												<label
													className="col-form-label"
													htmlFor="inputConsentTypes"
												>
													Tipo de Consentimento:
													<span className="auth--required-symbol">*</span>
												</label>
												<Controller
													render={({ onChange, onBlur, value }) => (
														<Select
															styles={customStyles({
																width: "100%",
																minHeight: "32px",
																height: "100%",
															})}
															options={list}
															placeholder="Selecione um tipo..."
															value={list.find((c) => c.value === value)}
															onChange={(val) => onChange(val.value)}
															className="input-group input-group-sm"
															onBlur={onBlur}
															inputRef={consentTypeSelect}
														/>
													)}
													name="consentTypeId"
													control={control}
													onFocus={() => consentTypeSelect.current?.focus()}
												/>
												<ErrorMessage
													errors={errors}
													name="consentTypeId"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="col-sm-12 mb-3">
												<label className="col-form-label" htmlFor="inputName">
													Nome:
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													id="inputName"
													name="name"
													type="text"
													placeholder="Nome..."
													ref={register}
												/>
												<ErrorMessage
													errors={errors}
													name="name"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="form-group col-8">
												<label className="col-form-label" htmlFor="inputEmail">
													E-mail:
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													id="inputEmail"
													ref={register}
													type="email"
													name="email"
													onChange={() => validateFields(["phone"])}
												/>
												<ErrorMessage
													errors={errors}
													name="email"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
											<div className="form-group col-4">
												<label className="col-form-label" htmlFor="inputPhone">
													Telefone:
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													id="inputPhone"
													name="phone"
													type="text"
													placeholder=""
													ref={register}
													onChange={() => validateFields(["email"])}
												/>
												<ErrorMessage
													errors={errors}
													name="phone"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="col-sm-12 mb-3">
												<label className="col-form-label" htmlFor="inputCPF">
													CPF:
													<span className="auth--required-symbol">*</span>
												</label>
												<input
													className="form-control"
													id="inputCPF"
													name="cpf"
													type="text"
													placeholder=""
													ref={register}
												/>
												<ErrorMessage
													errors={errors}
													name="cpf"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="col-sm-12">
												<label className="col-form-label" htmlFor="checkbox">
													Canais de Envio:
													<span className="auth--required-symbol">*</span>
												</label>
												<div className="form-row col">
													<div className="checkbox checkbox-primary col-md-6 col-sm-12">
														<input
															name="checkboxSms"
															id="checkboxSms"
															type="checkbox"
															ref={register}
															onChange={() => validateFields(["checkboxEmail"])}
														/>
														<label htmlFor="checkboxSms">SMS</label>
													</div>
													<div className="checkbox checkbox-primary col-md-6 col-sm-12">
														<input
															name="checkboxEmail"
															id="checkboxEmail"
															type="checkbox"
															ref={register}
															onChange={() => validateFields(["checkboxSms"])}
														/>
														<label htmlFor="checkboxEmail">E-Mail</label>
													</div>
												</div>
												<ErrorMessage
													errors={errors}
													name="checkboxEmail"
													as={<span className="auth--validation-alert" />}
												/>
												<ErrorMessage
													errors={errors}
													name="checkboxSms"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="col-sm-12">
												<label
													className="col-form-label"
													htmlFor="checkboxSendNow"
												>
													Envio:
												</label>
												<div className="form-row col">
													<div className="checkbox checkbox-primary col-md-6 col-sm-12">
														<input
															name="checkboxSendNow"
															id="checkboxSendNow"
															type="checkbox"
															ref={register}
														/>
														<label htmlFor="checkboxSendNow">
															Envio imediato
														</label>
													</div>
												</div>
												<ErrorMessage
													errors={errors}
													name="checkboxSendNow"
													as={<span className="auth--validation-alert" />}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="text-right col-sm-12">
										<button
											className={"btn btn-primary"}
											type="submit"
											disabled={loading || errors.length > 0}
										>
											{!loading ? (
												"Criar"
											) : (
												<div className="loader">
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
												</div>
											)}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddClientConsent;
