import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import Breadcrumb from "~/components/common/breadcrumb";
import ControlledTable from "~/components/common/controlledTable";
import DetailsModal from "./detailsModal";
import { getConsents } from "~/states/modules/company";
import { pt } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { defaultStaticRanges, defaultInputRanges } from "./defaultRanges";

const DEFAULT_START_DATE = 0;

const CookieConsents = () => {
	const consents = useSelector(({ company }) => company.consents);
	const { loading: isLoadingData } = consents;

	const initialDate = moment().subtract(DEFAULT_START_DATE, "days").toDate();

	const [startDate, setStartDate] = useState(initialDate);
	const [endDate, setEndDate] = useState(new Date());

	const dispatch = useDispatch();

	const tableParams = useSelector(
		({ company }) => company.consents?.tableParams
	);

	useEffect(() => {
		dispatch(
			getConsents({
				startDate,
				endDate,
			})
		);
	}, [dispatch, endDate, startDate]);

	if (!consents) {
		return null;
	}

	const data = consents.data?.map((c) => ({
		id: c.id,
		ip: c.ip,
		updatedAt: moment(c.date).format("DD/MM/YYYY HH:mm").toString(),
		details: c,
	}));

	if (!data) {
		return null;
	}

	const columns = [
		{
			Header: <span>{"Data/Hora"}</span>,
			accessor: "updatedAt",
			disableFilters: true,
			minWidth: 30,
			width: 200,
			Cell: (data) => {
				return <div>{data.row.original["updatedAt"]}</div>;
			},
		},
		{
			Header: <span>{"Endereço IP"}</span>,
			accessor: "ip",
			disableFilters: true,
			minWidth: 30,
			width: 200,
			Cell: (data) => {
				return <div>{data.row.original["ip"]}</div>;
			},
		},
		{
			Header: <>{"Detalhes"}</>,
			accessor: "details",
			disableFilters: true,
			minWidth: 30,
			width: 200,
			disableSortBy: true,

			Cell: (data) => {
				return (
					<div>
						<DetailsModal data={data.row.original.details} />
					</div>
				);
			},
		},
	];

	const handleSelect = (ranges) => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
	};

	const selectionRange = {
		startDate,
		endDate,
		key: "selection",
	};

	return (
		<>
			<Breadcrumb
				parent="Gestão de Cookies"
				current="Base de Consentimentos"
				title="Base de Consentimentos"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body p-0">
							<form className="form">
								<div className="input-group input-group-sm">
									<DateRangePicker
										ranges={[selectionRange]}
										onChange={handleSelect}
										locale={pt}
										staticRanges={defaultStaticRanges}
										inputRanges={defaultInputRanges}
									/>
								</div>
							</form>
								<ControlledTable
									data={data}
									isLoadingData={isLoadingData}
									columns={columns}
									pageCount={Math.ceil(
										consents.totalCount / tableParams.pageSize
									)}
									totalCount={consents.totalCount}
									globalTableParams={tableParams}
									isPaginated={false}
									// getData={() => {}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CookieConsents;
