import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";
import sessionConstants from "~/states/modules/session/sessionConstants";

const { errorSources, status } = sessionConstants;

const initialState = {
	token: null,
	refreshToken: null,
	error: null,
	signed: false,
	status: null,
	loading: false,
};

const { actions, reducer } = createSlice({
	name: "session",
	initialState,
	reducers: {
		login: (state) => state,
		loginSuccess: (state, { payload }) =>
			extend(state, {
				token: payload.data.token,
				refreshToken: payload.data.refreshToken,
				error: null,
				signed: true,
			}),
		loginFailure: (state, { payload }) =>
			extend(state, {
				error: { source: errorSources.LOGIN, ...payload },
			}),
		logout: (state) => state,
		logoutSuccess: (state) =>
			extend(state, {
				token: null,
				refreshToken: null,
				signed: false,
			}),
		logoutFailure: (state) => state,
		forgetPassword: (state) =>
			extend(state, {
				error: null,
				status: status.TO_RECLAIM_PASSWORD,
				loading: true,
			}),
		forgetPasswordSuccess: (state) =>
			extend(state, {
				error: null,
				status: status.EMAIL_SENT,
				loading: false,
			}),
		forgetPasswordFailure: (state, { payload }) =>
			extend(state, {
				error: { source: errorSources.FORGET_PASSWORD, ...payload },
				status: status.FAILED,
				loading: false,
			}),
		changePassword: (state) =>
			extend(state, {
				error: null,
				status: status.TO_CHANGE_PASSWORD,
				loading: true,
			}),
		changePasswordSuccess: (state) => {
			extend(state, {
				error: null,
				status: status.CHANGED_PASSWORD,
				loading: false,
			});
		},
		changePasswordFailure: (state, { payload }) => {
			extend(state, {
				error: {
					source: errorSources.VALID_CHANGE_PASSWORD,
					...payload,
				},
				status: status.FAILED,
				loading: false,
			});
		},
		changeCompanyTo: (state) => state,
		setNewRefreshTokenSuccess: (state, { payload }) => {
			extend(state, {
				token: payload.data.token,
				refreshToken: payload.data.refreshToken,
				signed: true,
				error: null,
			});
		},
		setError: (state, { payload }) =>
			extend(state, {
				error: payload,
			}),
		setStatus: (state, { payload }) =>
			extend(state, {
				status: payload,
			}),
	},
});

export const {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutFailure,
	logoutSuccess,
	forgetPassword,
	forgetPasswordSuccess,
	forgetPasswordFailure,
	changePassword,
	changePasswordSuccess,
	changePasswordFailure,
	setNewRefreshTokenSuccess,
	setError,
	setStatus,
	changeCompanyTo,
	setNewRefreshTokenByChangeCompanySuccess,
	setNewRefreshTokenByChangeCompanyFailure,
} = actions;

export { default as sessionSaga } from "./sagas";

export default reducer;
