const _formatSortBy = (sortArray) => {
	if (Array.isArray(sortArray)) {
		const [sort = null] = sortArray;
		if (sort) {
			return {
				[sort.id]: sort.desc ? "DESC" : "ASC",
			};
		}
	}
	return null;
};

const _formatFilters = (filters) =>
	filters?.reduce((acc, cur) => {
		return { ...acc, [cur.id]: cur.value };
	}, {});

export const formatPayload = (payload) => {
	const { pageIndex, sortBy, filters, pageSize } = payload;

	const formattedPayload = {
		page: pageIndex,
		pageSize,
		order: _formatSortBy(sortBy),
		filters: _formatFilters(filters),
	};

	return formattedPayload;
};

/**
 * Formats filter values to be used in the API due to the iLike filter not inserting '%%' in its values.
 *
 * @param {*} params  An object containing all the ControlledTable params (filter, order, pageSize)
 * @param {*} fields An array of strings containing the fields accessors in the "filter" object  inside the "params" object
 * @returns
 */
export const formatFilterValuesForILikeFilter = (params, fields) => {
	fields.forEach((field) => {
		if (params?.filter[field]?.iLike) {
			params.filter[field].iLike = `%${params.filter[field].iLike}%`;
		}
	});

	return params;
};
