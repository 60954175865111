import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	all: { data: [], loading: false, error: null },
	removed: { data: null, loading: false, error: null, targetId: null },
	added: { data: null, loading: false, error: null },
	edited: { data: null, loading: false, error: null },
	one: { data: null, loading: false, error: null },
};

const { actions, reducer } = createSlice({
	name: "agents",
	initialState,
	reducers: {
		eraseOne: (state) =>
			merge(state, {
				one: { ...state.one, data: null, loading: false, error: null },
			}),
		getAll: (state) =>
			merge(state, {
				all: { ...state.all, loading: true },
			}),
		getAllSuccess: (state, { payload }) =>
			merge(state, {
				all: { ...state.all, loading: false, data: payload.data },
			}),
		getAllFailure: (state, { payload }) =>
			merge(state, {
				all: { ...state.all, loading: false, error: payload },
			}),
		remove: (state, { payload: targetId }) =>
			merge(state, {
				removed: { ...state.removed, loading: true, targetId },
			}),
		removeSuccess: (state, { payload }) => {
			state.all.data = state.all.data.filter(
				(agent) => agent.id !== state.removed.targetId
			);
			if (state.one.data?.id === state.removed.targetId) {
				state.one.data = null;
			}
			return merge(state, {
				removed: { loading: false, data: payload.data, targetId: null },
			});
		},
		removeFailure: (state, { payload }) =>
			merge(state, {
				removed: {
					...state.removed,
					loading: false,
					error: payload,
					targetId: null,
				},
			}),
		add: (state) =>
			merge(state, {
				added: { ...state.added, loading: true },
			}),
		addSuccess: (state, { payload }) =>
			merge(state, {
				added: { ...state.added, loading: false, data: payload.data },
			}),
		addFailure: (state, { payload }) =>
			merge(state, {
				added: { ...state.added, loading: false, error: payload },
			}),
		edit: (state) =>
			merge(state, {
				edited: { ...state.edited, loading: true },
			}),
		editSuccess: (state, { payload }) => {
			if (state.one.data.id == payload.data.id) {
				state.one.data = payload.data;
			}
			return merge(state, {
				edited: { loading: false, data: payload.data },
			});
		},
		editFailure: (state, { payload }) =>
			merge(state, {
				edited: { ...state.edited, loading: false, error: payload },
			}),
		getOne: (state) =>
			merge(state, {
				one: { ...state.one, loading: true },
			}),
		getOneSuccess: (state, { payload }) =>
			merge(state, {
				one: { ...state.one, loading: false, data: payload.data },
			}),
		getOneFailure: (state, { payload }) =>
			merge(state, {
				one: { ...state.one, loading: false, error: payload },
			}),
	},
});

export const {
	eraseOne,
	getAll,
	getAllSuccess,
	getAllFailure,
	remove,
	removeSuccess,
	removeFailure,
	add,
	addSuccess,
	addFailure,
	edit,
	editSuccess,
	editFailure,
	getOne,
	getOneSuccess,
	getOneFailure,
} = actions;

export { default as sagas } from "./sagas";

export default reducer;
