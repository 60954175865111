import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Collapse } from "reactstrap";

import validate from "../../../../common/validate";

import constants from "../../constants";
const { DIALECTS } = constants;

const EditDatabaseForm = ({ database, onSubmit }) => {
	const {
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		getValues,
		setValue,
	} = useForm({
		defaultValues: database,
		mode: "onChange",
	});
	const history = useHistory();

	useEffect(() => {
		reset(database);
	}, [database]);

	const isSshTunnel = watch("isSshTunnel");
	const dialect = watch("dialect");

	const hasErrors = () => Object.keys(errors).length > 0;
	const isSqlite = () => dialect == DIALECTS[2];
	const goBack = () => history.goBack();

	const encodeText = () => {
		const inputedSshKey = getValues().encodedSshPrivateKey;

		if (!!inputedSshKey) setValue("sshPrivateKey", inputedSshKey);

		const savedSshKey = getValues().sshPrivateKey;
		setValue("encodedSshPrivateKey", hideText(savedSshKey));
	};
	const removeText = () => {
		setValue("encodedSshPrivateKey", "");
	};
	const getText = () => {
		setValue("encodedSshPrivateKey", hideText(getValues().sshPrivateKey));
	};

	const hideText = (txt) => {
		let hiddenTxt = "";
		if (!!txt) for (let i = 0; i < txt.length; i++) hiddenTxt += "*";
		return hiddenTxt;
	};

	const onSubmitWrapper = (data) => {
		delete data.encodedSshPrivateKey;
		onSubmit(data);
	};

	return (
		<form
			className="needs-validation"
			noValidate=""
			onSubmit={handleSubmit(onSubmitWrapper)}
		>
			<div className="card">
				<div className="card-header">
					<h5>
						Edição do Banco de Dados: <strong>{database.database}</strong>
					</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<input
							type="hidden"
							name="id"
							id="id"
							value={database.id}
							ref={register}
						/>
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="database">
									Nome <span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="text"
									name="database"
									id="database"
									ref={register}
								/>
								<span>{errors.database?.message}</span>
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="host">
									Host<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="text"
									name="host"
									id="host"
									ref={register}
								/>
								<span>{errors.host?.message}</span>
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="port">
									Porta<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="text"
									name="port"
									id="port"
									ref={register}
								/>
								<span>{errors.port?.message}</span>
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="dialect">
									Dialeto
									<span className="auth--required-symbol">*</span>
								</label>
								<select
									className="form-control digits"
									id="dialect"
									name="dialect"
									ref={register}
								>
									{DIALECTS.map((dialect, index) => (
										<option key={`dialect-${index}`}>{dialect}</option>
									))}
								</select>
								<span>{errors.dialect?.message}</span>
							</div>
						</div>
					</div>
					<Collapse isOpen={isSqlite()}>
						<div className="form-row">
							<div className="col-md-12 col-sm-12">
								<div className="form-group">
									<label className="col-form-label" htmlFor="storage">
										Armazenamento
										<span className="auth--required-symbol">*</span>
									</label>
									<input
										className="form-control"
										type="text"
										name="storage"
										id="storage"
										ref={register}
									/>
									<span>{errors.storage?.message}</span>
								</div>
							</div>
						</div>
					</Collapse>
					<div className="form-row">
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="username">
									Usuário
									<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="text"
									name="username"
									id="username"
									ref={register}
								/>
								<span>{errors.username?.message}</span>
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="form-group">
								<label className="col-form-label" htmlFor="password">
									Senha<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									type="password"
									name="password"
									id="password"
									ref={register}
								/>
								<span>{errors.password?.message}</span>
							</div>
						</div>
					</div>
					<hr className="mt-4" />
					<div className="form-row">
						<div className="checkbox  checkbox-primary col-3 ml-3">
							<input
								className="form-control"
								type="checkbox"
								name="isSshTunnel"
								id="isSshTunnel"
								ref={register}
							/>
							<label className="col-form-label" htmlFor="isSshTunnel">
								Conexão via SSH
							</label>
						</div>
					</div>
					<Collapse isOpen={isSshTunnel}>
						<div className="form-row">
							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label className="col-form-label" htmlFor="sshUsername">
										Usuário SSH
										<span className="auth--required-symbol">*</span>
									</label>
									<input
										className="form-control"
										type="text"
										name="sshUsername"
										id="sshUsername"
										ref={register}
									/>
									<span>{errors.sshUsername?.message}</span>
								</div>
								<div className="form-group">
									<label className="col-form-label" htmlFor="sshHost">
										Host SSH
										<span className="auth--required-symbol">*</span>
									</label>
									<input
										className="form-control"
										type="text"
										name="sshHost"
										id="sshHost"
										ref={register}
									/>
									<span>{errors.sshHost?.message}</span>
								</div>
							</div>
							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label className="col-form-label" htmlFor="sshPrivateKey">
										Chave SSH
										<span className="auth--required-symbol">*</span>
									</label>
									<textarea
										className="form-control d-none"
										id="sshPrivateKey"
										name="sshPrivateKey"
										wrap="hard"
										ref={register}
									></textarea>
									<textarea
										className="form-control"
										id="encodedSshPrivateKey"
										rows="4"
										name="encodedSshPrivateKey"
										wrap="hard"
										ref={register}
										defaultValue={hideText(database.sshPrivateKey)}
										onFocus={removeText}
										onBlur={() => {
											encodeText();
											getText();
										}}
									></textarea>

									<span>{errors.sshPrivateKey?.message}</span>
								</div>
							</div>
						</div>
					</Collapse>
				</div>
				<div className="card-footer text-right">
					<button
						className="btn btn-primary mr-4"
						type="button"
						onClick={goBack}
					>
						Voltar
					</button>
					<button
						className={`btn btn-primary ${hasErrors() && "disabled"}`}
						type="submit"
					>
						Salvar
					</button>
				</div>
			</div>
		</form>
	);
};

export default EditDatabaseForm;
