import React, { useState } from "react";
import { Button } from "reactstrap";
import { Trash2 } from "react-feather";
// import "react-table/react-table.css";
import { toast } from "react-toastify";
import validate from "../../common/validate";
import regex from "../../common/regex";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import AddButton from "~/components/common/AddButton";

const UserToBeAddedTable = ({
	register,
	allRoles,
	errors,
	rolesRef,
	control,
	getValues,
	reset,
}) => {
	const [users, setUsers] = useState([
		{
			email: "suporte@nimbusprivacy.com.br",
			roles: [allRoles[0]],
			rights: [],
		},
	]);

	// Lida com a adição
	const handleNewUser = () => {
		const { email, roles, ...rest } = getValues();
		let error = false;

		users.forEach((user) => {
			if (email === user.email) {
				error = "Usuário já foi adicionado";
			}
		});

		if (!email || !email.match(regex.email()))
			error = "Insira um e-mail válido";
		else if (!roles || roles.length <= 0)
			error = "Insira pelo menos um grupo ao usuário";

		if (!error) {
			setUsers([...users, { email: email, roles: roles, rights: [] }]);

			reset(rest);
			rolesRef.current.clear();
		} else toast.error(error);
	};

	const handleDeleteUser = (removedIndex) => {
		const newUsers = [];

		users.forEach((user, index) => {
			if (index !== removedIndex) {
				newUsers.push(user);
			}
		});

		setUsers(newUsers);
	};

	const makesRoles = (roles) => {
		let rolesNamesString = "";

		roles.forEach((role, index) => {
			if (roles.length === index + 1) rolesNamesString += role.name;
			else rolesNamesString += role.name + ",";
		});

		return rolesNamesString;
	};

	return (
		<>
			<div className="table-responsive" style={{ overflow: "visible" }}>
				<table
					className="table table-striped"
					style={{ overflowY: "visible" }}
				>
					<thead>
						<tr>
							<th className="email">E-mail</th>
							<th className="roles">Grupos</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{users.map(({ email, roles }, index) => (
							<tr key={index}>
								<td className="col-6">
									{email}
									<input
										type="hidden"
										ref={register}
										name={`email-${index}`}
										value={email}
									/>
								</td>
								<td className="col-5">
									{roles.map(({ label }, id) => (
										<span
											key={id}
											className="badge badge-light"
										>
											{label}
										</span>
									))}
									<input
										type="hidden"
										ref={register}
										name={`roles-${index}`}
										value={makesRoles(roles)}
									/>
								</td>
								<td className="text-right">
									<Button
										color="link"
										onClick={() => handleDeleteUser(index)}
									>
										<Trash2 size={18} />
									</Button>
								</td>
							</tr>
						))}
						<tr>
							<td className="col-6">
								<input
									className="form-control"
									type="text"
									name="email"
									ref={register}
									placeholder="Insira o e-mail a ser convidado"
								/>
								<ErrorMessage
									errors={errors}
									name="email"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</td>
							<td className="col-5">
								<Controller
									name="roles"
									control={control}
									defaultValue=""
									onFocus={() => rolesRef.current?.focus()}
									rules={validate.requiredTypeahead()}
									render={(
										{ onChange, onBlur, name, ref },
										{}
									) => (
										<Typeahead
											id="roles"
											ref={rolesRef}
											labelKey="label"
											name={name}
											clearButton
											multiple
											placeholder="Escolha os grupos..."
											onChange={onChange}
											onBlur={onBlur}
											options={allRoles}
										/>
									)}
								/>
								<input type="hidden" />
								<ErrorMessage
									errors={errors}
									name="roles"
									as={
										<span className="auth--validation-alert" />
									}
								/>
								<span role="alert">
									{errors.roles?.message}
								</span>
							</td>
							<td className="justify-content-center text-right">
								<AddButton
									color="primary"
									onClick={handleNewUser}
									toolTipMsg="Adicionar usuário"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default UserToBeAddedTable;
