import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	clientConsents: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: [{ id: "name", desc: false }],
			filters: [],
		},
	},
	requests: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: [
				{
					id: "createdAt",
					desc: true,
				},
			],
			filters: [],
		},
	},
	counter: { data: null, loading: false, error: null },
	clientConsentsInformation: {
		error: null,
		loading: false,
		data: null,
		requests: null,
	},
	downloadClientConsentsCSV: {
		error: null,
		loading: false,
	},
};

const { actions, reducer } = createSlice({
	name: "dataConsent",
	initialState,
	reducers: {
		getClientConsents: (state, { payload }) =>
			extend(state, {
				clientConsents: {
					...state.clientConsents,
					tableParams: payload,
					error: null,
					loading: true,
				},
			}),
		getClientConsentsFailure: (state, { payload }) =>
			extend(state, {
				clientConsents: {
					...state.clientConsents,
					error: payload,
					loading: false,
				},
			}),
		getClientConsentsSuccess: (state, { payload }) =>
			extend(state, {
				clientConsents: {
					...state.clientConsents,
					error: null,
					loading: false,
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			}),

		getClientConsentsInformation: (state) =>
			extend(state, {
				clientConsentsInformation: {
					...state.clientConsentsInformation,
					error: null,
					loading: true,
				},
			}),
		getClientConsentsInformationSuccess: (state, { payload }) =>
			extend(state, {
				clientConsentsInformation: {
					error: null,
					loading: false,
					data: payload,
					requests: state.clientConsentsInformation.requests,
				},
			}),
		getClientConsentsInformationFailure: (state, { payload }) =>
			extend(state, {
				clientConsentsInformation: {
					...state.clientConsentsInformation,
					error: payload,
					loading: false,
				},
			}),

		getConsentRequests: (state, { payload }) => {
			extend(state, {
				requests: {
					...state.requests,
					tableParams: payload,
					error: null,
					loading: true,
				},
			});
		},
		getConsentRequestsFailure: (state, { payload }) =>
			extend(state, {
				requests: {
					...state.requests,
					error: payload,
					loading: false,
				},
			}),
		getConsentRequestsSuccess: (state, { payload }) => {
			extend(state, {
				requests: {
					...state.requests,
					error: null,
					loading: false,
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			});
		},
		getCounter: (state) =>
			extend(state, {
				counter: {
					...state.counter,
					loading: true,
					error: null,
				},
			}),
		getCounterSuccess: (state, { payload }) =>
			extend(state, {
				counter: {
					...state.counter,
					loading: false,
					data: payload,
				},
			}),
		getCounterFailure: (state, { payload }) =>
			extend(state, {
				counter: {
					...state.counter,
					loading: false,
					error: payload,
				},
			}),
		downloadClientConsentsCSV: (state, { payload }) =>
			extend(state, {
				downloadClientConsentCSV: {
					...state.downloadClientConsentsCSV,
					loading: true,
					error: null,
				},
			}),
		downloadClientConsentsCSVSuccess: (state, { payload }) =>
			extend(state, {
				downloadClientConsentCSV: {
					...state.downloadClientConsentsCSV,
					loading: false,
					error: null,
				},
			}),
		downloadClientConsentsCSVFailure: (state, { payload }) =>
			extend(state, {
				downloadClientConsentCSV: {
					...state.downloadClientConsentsCSV,
					loading: false,
					error: payload,
				},
			}),
	},
});

export const {
	getClientConsents,
	getClientConsentsSuccess,
	getClientConsentsFailure,
	getClientConsentsInformation,
	getClientConsentsInformationSuccess,
	getClientConsentsInformationFailure,
	getConsentRequests,
	getConsentRequestsSuccess,
	getConsentRequestsFailure,
	updateConsentRequestsTableParams,
	getCounter,
	getCounterSuccess,
	getCounterFailure,
	downloadClientConsentsCSV,
	downloadClientConsentsCSVSuccess,
	downloadClientConsentsCSVFailure,
} = actions;

export { default as dataConsentSaga } from "./sagas";

export default reducer;
