import React, { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { Tooltip } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import Breadcrumb from "../../common/breadcrumb";

import { getApiUrl } from "~/services/envUtils";

import { getConsentTypes } from "~/states/modules/consentTypes";
import { importClientConsent } from "~/states/modules/company";

import * as yup from "yup";
import useYupResolver from "../../common/yupResolver";
import { ErrorMessage } from "@hookform/error-message";

import { debounce } from "../../../utils/operators";

const customStyles = (stylesOverride = {}) => {
	return {
		control: (base) => ({
			...base,
			...stylesOverride,
		}),
	};
};

const ImportClientConsents = () => {
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		consentTypeId: yup
			.string()
			.required("Campo Tipo de Consentimento é obrigatório"),
		files: yup.array().required("Campo Importe é obrigatório"),
		sms: yup.bool().when("email", {
			is: undefined || false,
			then: yup.bool().test({
				message: "Campo Canal é obrigatório",
				test: (value, email) => value === true || email === true,
			}),
		}),
		email: yup.bool(),
	});

	const { register, handleSubmit, errors, control, reset, trigger } = useForm({
		resolver: useYupResolver(schema),
		mode: "onChange",
	});

	const validateFields = debounce(async (fields) => {
		for (const field of fields) {
			await trigger(field);
		}
	});

	const consentTypeSelect = useRef();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const { data: consentTypesList, tableParams } = useSelector(
		({ consentTypes }) => consentTypes.list
	);

	const { loading, error, data } = useSelector(
		({ company }) => company.importedClientConsent
	);

	const [showMessage, setShowMessage] = useState(false);

	const list = consentTypesList.map((consentType) => ({
		value: consentType.id,
		label: consentType.name,
	}));

	useEffect(() => {
		dispatch(getConsentTypes(tableParams));
	}, [dispatch]);

	const handleControlledDropzonChangeStatus = (status, allFiles, setFiles) => {
		setTimeout(() => {
			if (["done", "removed"].includes(status)) {
				setFiles([...allFiles]);
			}
		}, 0);
	};

	const resetForm = () => {
		reset();
		consentTypeSelect.current && consentTypeSelect.current.clear();
	};

	// Listening for submition
	useEffect(() => {
		if (showMessage) {
			if (data) {
				resetForm();
				if (data.rowsInserted.length === 0)
					toast.error(`Não houveram consentimentos criados`);
				else {
					toast.success(
						`${data.rowsInserted} consentimentos foram criados com sucesso!`
					);
				}
				if (data.rowsRejected.length > 0) {
					toast.warn(data.generalMessage);
				}
			} else if (error) {
				toast.error(error.message);
			}
		}
	}, [data, error]);

	const onSubmit = (data) => {
		const { consentTypeId, files, email, sms, checkboxSendNow } = data;

		const clients = files[0].file;
		const formData = new FormData();
		formData.append("clients", clients);
		formData.append("consentTypeId", consentTypeId);
		formData.append("sms", sms);
		formData.append("email", email);
		formData.append("sendNow", checkboxSendNow);

		setShowMessage(true);

		dispatch(importClientConsent(formData));
	};

	return (
		<Fragment>
			<Breadcrumb
				title="Importação de Consentimento de Usuario"
				current="Importação de Consentimento de Usuario"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<form
							className="needs-validation"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="card">
								<div className="card-body">
									<div className="form-row">
										<div className="col-12 mb-3" style={{ width: "100%" }}>
											<label htmlFor="inputConsentTypes">
												Tipo de Consentimento:
												<span className="auth--required-symbol">*</span>
											</label>
											<Controller
												render={({ onChange, onBlur, value }) => (
													<Select
														styles={customStyles({
															width: "100%",
															minHeight: "32px",
															height: "100%",
														})}
														options={list}
														placeholder="Selecione um tipo..."
														value={list.find((c) => c.value === value)}
														onChange={(val) => onChange(val.value)}
														className="input-group input-group-sm"
														onBlur={onBlur}
														inputRef={consentTypeSelect}
													/>
												)}
												name="consentTypeId"
												control={control}
												onFocus={() => consentTypeSelect.current?.focus()}
											/>
											<ErrorMessage
												errors={errors}
												name="consentTypeId"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="dz-message needsclick col-sm-12">
											<label htmlFor="inputFile">
												Importe:
												<span className="auth--required-symbol">*</span>
											</label>
											<Controller
												control={control}
												name="files"
												render={({ onChange }) => (
													<Dropzone
														maxFiles={1}
														inputContent="Arraste seu CSV ou clique aqui"
														onChangeStatus={(file, status, allFiles) => {
															handleControlledDropzonChangeStatus(
																status,
																allFiles,
																onChange
															);
														}}
													/>
												)}
											/>
											<ErrorMessage
												errors={errors}
												name="files"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
										<div className="text-right col-sm-12">
											<a
												id="template"
												href={`${getApiUrl("/static/templateConsent.csv")}`}
												download="templateConsent.csv"
											>
												Download template
											</a>
											<Tooltip
												target="template"
												placement="bottom"
												isOpen={tooltipOpen}
												toggle={toggle}
											>
												Clique aqui para baixar o template do csv que deve ser
												importado
											</Tooltip>
										</div>
									</div>
									<div className="form-row">
										<div className="col-sm-12">
											<label htmlFor="inputFile">
												Canais de Envio:
												<span className="auth--required-symbol">*</span>
											</label>
											<div className="form-row col">
												<div className="checkbox checkbox-primary col-md-6 col-sm-12">
													<input
														name="sms"
														id="sms"
														type="checkbox"
														ref={register}
														onChange={() => validateFields(["email"])}
													/>
													<label htmlFor="sms">SMS</label>
												</div>
												<div className="checkbox checkbox-primary col-md-6 col-sm-12">
													<input
														name="email"
														id="email"
														type="checkbox"
														ref={register}
														onChange={() => validateFields(["sms"])}
													/>
													<label htmlFor="email">E-Mail</label>
												</div>
											</div>
											<ErrorMessage
												errors={errors}
												name="email"
												as={<span className="auth--validation-alert" />}
											/>
											<ErrorMessage
												errors={errors}
												name="sms"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="col-sm-12">
											<label
												className="col-form-label"
												htmlFor="checkboxSendNow"
											>
												Envio:
											</label>
											<div className="form-row col">
												<div className="checkbox checkbox-primary col-md-6 col-sm-12">
													<input
														name="checkboxSendNow"
														id="checkboxSendNow"
														type="checkbox"
														ref={register}
													/>
													<label htmlFor="checkboxSendNow">
														Envio imediato
													</label>
												</div>
											</div>
											<ErrorMessage
												errors={errors}
												name="checkboxSendNow"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="text-right col-sm-12">
										<button
											className={"btn btn-primary"}
											type="submit"
											disabled={loading || errors.length > 0}
										>
											{!loading ? (
												"Importar"
											) : (
												<div className="loader">
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
													<div className="line bg-light"></div>
												</div>
											)}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ImportClientConsents;
