import React from "react";
import { useForm } from "react-hook-form";
import validate from "../../common/validate";

const ChangePasswordForm = ({ onSubmit, user }) => {
	const { register, handleSubmit, watch, errors } = useForm({
		defaultValues: user,
		mode: "onChange",
	});

	const watchedPassword = watch("password");

	return (
		<form
			className="theme-form"
			noValidate=""
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="card">
				<div className="card-header">
					<h5>Alterar Senha</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-sm-12 col-md mb-3">
							<label htmlFor="passwordInput">Senha</label>
							<input
								className="form-control"
								type="password"
								name="password"
								id="passwordInput"
								placeholder="********"
								ref={register(validate.password())}
							/>
							<span className="auth--validation-alert">
								{errors.password?.message}
							</span>
						</div>
						<div className="col-sm-12 col-md mb-3">
							<label htmlFor="confirmPasswordInput">Confirme a Senha</label>
							<input
								className="form-control"
								type="password"
								name="confirmPassword"
								id="confirmPasswordInput"
								placeholder="********"
								ref={register(validate.confirmPassword(watchedPassword))}
							/>
							<span className="auth--validation-alert">
								{errors.confirmPassword?.message}
							</span>
						</div>
					</div>
				</div>
				<div className="card-footer text-right">
					<button
						className={`btn btn-primary ${
							errors.password || errors.confirmPassword ? "disabled" : ""
						}`}
						type="submit"
					>
						Alterar
					</button>
				</div>
			</div>
		</form>
	);
};
export default ChangePasswordForm;
