import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	allFonts: { data: null, loading: false, error: null },
};

const { actions, reducer } = createSlice({
	name: "fonts",
	initialState,
	reducers: {
		getAllFonts: (state) =>
			merge(state, {
				allFonts: { ...state.allFonts, loading: true },
			}),
		getAllFontsSuccess: (state, { payload }) =>
			merge(state, {
				allFonts: {
					...state.allFonts,
					loading: false,
					data: payload.data,
				},
			}),
		getAllFontsFailure: (state, { payload }) =>
			merge(state, {
				allFonts: { ...state.allFonts, loading: false, error: payload },
			}),
	},
});

export const { getAllFonts, getAllFontsFailure, getAllFontsSuccess } = actions;

export { default as fontsSaga } from "./sagas";

export default reducer;
