import regex from "./regex";
import { phone } from "phone";
import isIp from "is-ip";
import isValidDomain from "is-valid-domain";

const validator = {
	password: () => {
		return {
			required: "Senha obrigatória",
			minLength: {
				value: 6,
				message: "Sua senha deve conter pelo menos 6 caracteres",
			},
			maxLength: {
				value: 30,
				message: "Sua senha deve conter no máximo 30 caracteres",
			},
			validate: {
				hasUpperCase: (value) =>
					/[a-zA-Z0-9]*[A-Z][a-zA-Z0-9]*/.test(value) ||
					"Deve conter pelo menos uma letra maiúscula",
				hasNumber: (value) =>
					/[a-zA-Z0-9]*[0-9][a-zA-Z0-9]*/.test(value) ||
					"Deve conter pelo menos um número",
				hasLowerCase: (value) =>
					/[a-zA-Z0-9]*[a-z][a-zA-Z0-9]*/.test(value) ||
					"Deve conter pelo menos uma letra minúscula",
			},
		};
	},

	confirmPassword: (newPassword, required = true) => {
		return {
			required: required && "Confirmação de senha obrigatória",
			validate: {
				isEqual: (passwordToConfirm) =>
					passwordToConfirm === newPassword || "Senhas não conferem",
			},
		};
	},
	onlyNumbers: (required = true) => {
		return {
			required: "Campo obrigatório",
			pattern: {
				value: /^[0-9]*$/,
				message: "Valor inválido, apenas números são aceitos",
			},
		};
	},
	onlyNumbersCPF: (required = true) => {
		return {
			required: required && "CPF obrigatório",
			pattern: {
				value: /^[0-9]{3}[/.][0-9]{3}[/.][0-9]{3}[/-][0-9]{2}$/,
				message: "CPF inválido, lembre que apenas números são aceitos",
			},
		};
	},
	onlyNumbersPhoneNumber: (required = true) => {
		let validate = {
			isPhoneNumber: (number) =>
				phone(number, { country: "BRA" }).isValid ||
				"Telefone inválido, lembre que apenas números são aceitos",
		};

		return {
			required: required && "Telefone Obrigatório",
			validate,
		};
	},
	onlyIp(required = true) {
		let validate = {
			isIPAdress: (IPAdress) => isIp(IPAdress) || "IP inválido",
		};

		return {
			required: required && "IP obrigatório",
			validate,
		};
	},
	email: (currentEmail) => {
		let validate = undefined;

		if (currentEmail) {
			validate = {
				isNotEqual: (email) =>
					email != currentEmail || "Nenhuma alteração de e-mail a ser salva",
			};
		}

		return {
			required: "Email obrigatório",
			pattern: {
				value: regex.email(),
				message: "Email inválido",
			},
			maxLength: {
				value: 256,
				message: "Seu email deve conter no máximo 256 caracteres",
			},
			validate,
		};
	},
	required: () => {
		return {
			required: "Campo obrigatório",
			// If input is an array
			validate: {
				isNotEmptyArray: (array) =>
					!Array.isArray(array) || array.length > 0 || "Campo obrigatório",
			},
		};
	},
	notRequired: () => {
		return {
			required: false,
		};
	},
	name: (currentName) => {
		let validate = undefined;

		if (currentName) {
			validate = {
				isNotEqual: (name) =>
					name != currentName || "Nenhuma alteração de nome a ser salva",
			};
		}

		return {
			required: "Nome obrigatório",
			maxLength: {
				value: 50,
				message: "Seu nome deve conter no máximo 50 caracteres",
			},
			validate,
		};
	},
	url: (required = true) => {
		return {
			required: required && "URL obrigatória",
			pattern: {
				value: regex.weburl(),
				message: "URL inválida",
			},
		};
	},

	ipOrUrl: (required = true) => {
		let validate = {
			isIPOrURL: (input) => {
				return (
					isIp(input) || regex.weburl().test(input) || "IP ou URL inválido"
				);
			},
		};

		return {
			required: required && "IP ou URL obrigatório",
			validate,
		};
	},

	ipOrDomain: (required = true) => {
		let validate = {
			isIpOrDomain: (input) =>
				isIp(input) || isValidDomain(input) || "Domínio ou IP inválido",
		};
		return {
			required: required && "Host obrigatório",
			validate,
		};
	},
	mailtoOrUrl: (required = true) => {
		return {
			required: required && "Url ou mailto obrigatórios",
			pattern: {
				value: regex.mailtoOrUrl(),
				message: "URL ou mailto inválidos",
			},
		};
	},
	requiredTypeahead: (errorMsg) => {
		return {
			validate: {
				hasAnySelected: (arrayValue) =>
					!!arrayValue.length || errorMsg || "Selecione pelo menos uma opção",
			},
		};
	},
	// It's not a validator, it's only a function that returns a boolean if any of the current values is new when comparing to some old values
	anyNew: (currentValues, oldValues) => {
		return Object.keys(currentValues).some((key) => {
			return oldValues[key] != currentValues[key];
		});
	},
};

export default validator;
