import React, { Fragment, useEffect } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, /* Mail, Lock, Settings, */ LogOut } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentUser } from "~/states/modules/users";
import { logout } from "~/states/modules/session";

import InitialLetters from "../initialLetters";


const UserMenu = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const currentUser = useSelector(({ users }) => users.currentUser?.data);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	const logOut = () => {
		dispatch(logout());
		history.push("/login");
	};
	const toProfile = () => {
		history.push(`${process.env.PUBLIC_URL}/profile`);
	}
	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media align-items-center">
					{/* <img
						className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
						src={man}
						alt="header-user"
					/>
					<div className="dotted-animation">
						<span className="animate-circle"></span>
						<span className="main-circle"></span>
					</div> */}
					<InitialLetters 
					name={currentUser?.name}
					style={{
						width:"40px",
						height:"40px",
						fontSize:"1em",
						lineHeight:"40px"
					}}
					 />
				</div>
				<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" style={{width:"fit-content",whiteSpace:"nowrap"}}>
					<li onClick={toProfile}>
						<div className="media">
							<InitialLetters 
							name={currentUser?.name}
							/>
							<div className="media-body ml-2" >
								<h5><strong>{currentUser?.name}</strong></h5>
								<small>{currentUser?.email}</small>
							</div>
						</div>
						
						<button type="button" className="btn btn-outline-primary btn-pill btn-block btn-xs" style={{marginTop: "10px"}}>
							Editar perfil
						</button>
					</li>
					<li>
						<div className="dropdown-divider"></div>
					</li>
					<li>
						<a onClick={logOut} href="#!">
							<LogOut /> Sair
						</a>
					</li>
					{/* 
                    <li><a href="#javascript"><User />Edit Profile</a></li>
                    <li><a href="#javascript"><Mail />Inbox</a></li>
                    <li><a href="#javascript"><Lock />Lock Screen</a></li>
                    <li><a href="#javascript"><Settings />Settings</a></li>
                    <li><a href="#javascript"><LogOut /> Log out</a></li>           
                    */}
				</ul>
			</li>
		</Fragment>
	);
};

export default UserMenu;
