import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Trash2, User } from "react-feather";
import { Button, Badge } from "reactstrap";

import Modal from "~/components/common/modal";

import { removeUser as removeAction, getCurrentUser } from "~/states/modules/users";
import AccessControl, { isAllowed } from "~/components/common/accessControl";

function UserTable({ usersList }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [modal, setModal] = useState({ show: false, user: {} });
	const [userId, setUserId] = useState(null);

	const [showMessage, setShowMessage] = useState(false);

	const currentUser = useSelector(({ users }) => users.currentUser?.data);
	const removedUser = useSelector(({ users }) => users.removedUser);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	useEffect(() => {
		if (showMessage) {
			if (removedUser && removedUser.error) {
				const validationErrors = removedUser.error["validation-errors"];

				if (validationErrors) {
					validationErrors.map((error) => {
						toast.error(error.msg);
					});
				} else {
					toast.error(removedUser.error.message);
				}
			}
		}
	}, [removedUser?.error]);

	useEffect(() => {
		if (showMessage) {
			if (removedUser && removedUser.data) {
				toast.success(`Usuário removido com sucesso!`);
			}
		}
	}, [removedUser?.data]);

	const goToEditUser = (userId) => (e) => {
		history.push(`${process.env.PUBLIC_URL}/users/${userId}`);
	};

	const removeUser = (user) => (e) => {
		e.stopPropagation();
		setUserId(user.id);
		setModal({ show: true, user: user });
	};

	const removeUserHandler = () => {
		setModal({ show: false });
		console.log("Removing user with id: " + userId);
		setShowMessage(true);
		dispatch(removeAction({ userId }));
	};

	return (
		<Fragment>
			<div className="table-responsive">
				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Grupos</th>
							<AccessControl requiredPermissions={[`user:delete`]}>
								<th className="text-center">Excluir</th>
							</AccessControl>
						</tr>
					</thead>
					<tbody
						style={
							isAllowed(currentUser, [`user:edit`]) ? { cursor: "pointer" } : null
						}
					>
						{usersList.map((user) => (
							<tr
								key={user.id}
								onClick={
									isAllowed(currentUser, [`user:edit`])
										? goToEditUser(user.id)
										: null
								}
							>
								<td data-testid="user-name">
									<User
										size={16}
										style={{ display: "inline-block", verticalAlign: "top" }}
									/>
									&nbsp;
									{user.name}
								</td>
								<td>{user.email}</td>
								<td>
									{user?.roles?.map((r) => (
										<span key={r.id} className="badge badge-light">
											{r.label}
										</span>
									))}
								</td>
								<AccessControl requiredPermissions={[`user:delete:${user.id}`]}>
									<td className="text-center">
										<Button
											color="link"
											disabled={user.id === currentUser?.id}
											onClick={removeUser(user)}
										>
											<Trash2 size={18} />
										</Button>
									</td>
								</AccessControl>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Modal
				isOpen={modal.show}
				onClose={() => setModal({ show: false })}
				onAccept={removeUserHandler}
				title="Remover Usuário"
			>
				Tem certeza que deseja excluir o usuário{" "}
				<Badge color="warning">{modal.user?.name}</Badge> ?
			</Modal>
		</Fragment>
	);
}

export default UserTable;
