import React, { useState, useEffect } from "react";
import { Edit } from "react-feather";
import AddOrEditPolicyTypeModal from "../../AddOrEditTypeModal";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

const limit = 40;

const smallSize = 100;
const mediumSize = 150;

const EditButton = (policyType) => {
	//? This may be bad solution due to bad encapsulation. Each button should not control the state of the modal or the toast
	const [showMessage, setShowMessage] = useState(false);

	const [editPolicyTypeModal, setEditPolicyTypeModal] = useState(false);

	const editedPolicyType = useSelector(
		({ policyTypes }) => policyTypes.editedPolicyType
	);
	useEffect(() => {
		if (showMessage) {
			if (editedPolicyType && editedPolicyType.data) {
				toast.success(
					`Tipo de política ${editedPolicyType.data.label} editado com sucesso!`
				);
			}
		}
	}, [editedPolicyType?.data]);

	useEffect(() => {
		if (showMessage) {
			if (editedPolicyType && editedPolicyType.error) {
				toast.error(editedPolicyType.error.message);
			}
		}
	}, [editedPolicyType?.error]);

	const openEditPolicyTypeModal = () => {
		setEditPolicyTypeModal(true);
	};

	return (
		<>
			<button
				onClick={() => openEditPolicyTypeModal()}
				className="btn btn-link primary-color"
			>
				<Edit />
			</button>
			<AddOrEditPolicyTypeModal
				isOpen={editPolicyTypeModal}
				onClose={() => setEditPolicyTypeModal(false)}
				onAccept={() => {
					setShowMessage(true);
					setEditPolicyTypeModal(false);
				}}
				policyType={policyType.policyType}
			/>
		</>
	);
};

export const columns = [
	{
		Header: <span>{"Rótulo para Tipo de Política"}</span>,
		accessor: "label",
		minWidth: limit,
		width: mediumSize,
		Cell: (data) => <div>{data.row.original["label"]}</div>,
	},
	{
		Header: <span>{"Nome para Tipo de Política"}</span>,
		accessor: "name",
		minWidth: limit,
		width: mediumSize,
		Cell: (data) => <div>{data.row.original["name"]}</div>,
	},
	{
		Header: <span>{"Intervalo de Frequência de atualização"}</span>,
		accessor: "updateFrequencyInterval",
		minWidth: limit,
		width: mediumSize,
		Cell: (data) => {
			switch (data.row.original["updateFrequencyInterval"]) {
				case "NEVER":
					return <div>nunca</div>;
				default:
					return <div>{data.row.original["updateFrequencyInterval"]}</div>;
			}
		},
	},
	{
		Header: <span>{"Frequência de atualização"}</span>,
		accessor: "updateFrequency",
		minWidth: limit,
		width: mediumSize,
		Cell: (data) => {
			switch (data.row.original["updateFrequency"]) {
				case "YEAR":
					return <div>Ano(s)</div>;
				case "MONTH":
					return <div>Mês(es)</div>;
				case "WEEK":
					return <div>Semana(s)</div>;
				case "DAY":
					return <div>Dia(s)</div>;
				case "NEVER":
					return <div>Nunca</div>;
				default:
					return <div>Dia(s)</div>;
			}
		},
	},
	{
		Header: <span>{""}</span>,
		accessor: "actions",
		minWidth: limit,
		width: smallSize,
		Cell: (data) => (
			<div>
				<EditButton policyType={data.row.original} />
			</div>
		),
	},
];
