import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";
import { selectCookiesCategories } from "./selectors";

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const { actions, reducer } = createSlice({
	name: "cookiesCategories",
	initialState,
	reducers: {
		getCookiesCategories: (state) =>
			extend(state, {
				loading: true,
			}),
		getCookiesCategoriesFailure: (state, { payload }) =>
			extend(state, {
				loading: false,
				error: payload,
			}),
		getCookiesCategoriesSuccess: (state, { payload }) =>
			extend(state, {
				data: selectCookiesCategories(payload.data),
				loading: false,
			}),
	},
});

export const {
	getCookiesCategories,
	getCookiesCategoriesFailure,
	getCookiesCategoriesSuccess,
} = actions;

export { default as cookiesCategoriesSaga } from "./sagas";

export default reducer;
