import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { User, Lock} from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

import { getCurrentUser, editCurrentUser } from "~/states/modules/users";
import { changePassword, setStatus, setError } from "~/states/modules/session";
import sessionConstants from "~/states/modules/session/sessionConstants";
import usersConstants from "~/states/modules/users/usersConstants";

import { toast } from "react-toastify";
import Breadcrumb from "../common/breadcrumb";
import Modal from "~/components/common/modal";
import ProfileForm from "./profileForm";
import ChangePasswordForm from "./changePasswordForm";
import ProfileSummary from "./profileSummary"

const Profile = () => {
	const dispatch = useDispatch();

	const [tabIndex, setTabIndex] = useState(0);

	const _onSelectHandler = (index) => {
				setTabIndex(index); 
			};

	const [editUserModal, setEditUserModal] = useState({
		show: false,
		title: "",
		text: "",
	});
	const [changePasswordModal, setChangePasswordModal] = useState({
		show: false,
		title: "",
		text: "",
	});

	const [data, setData] = useState(null);

	const { status: sessionStatus } = sessionConstants;
	const { status: usersStatus } = usersConstants;

	const currentUser = useSelector(({ users }) => users.currentUser);
	const currentSessionStatus = useSelector(({ session }) => session.status);

	useEffect(() => {
		if (currentSessionStatus === sessionStatus.CHANGED_PASSWORD) {
			toast.success("Informações Editadas com Sucesso");
			dispatch(setStatus(null));
		}
	}, [currentSessionStatus]);

	useEffect(() => {
		if (currentUser.status === usersStatus.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			dispatch(setStatus(null));
		}
	}, [currentUser.status]);

	useEffect(() => {
		if (currentUser.error) {
			toast.error("Um Erro Inesperado Ocorreu");
		}
	}, [currentUser.error]);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	const changeProfileSubmit = (input) => {
		if (currentUser.data) {
			setData(input);
			setEditUserModal({
				show: true,
				title: "Editar",
				text: "Tem certeza de que deseja editar suas informaçoões pessoais?",
			});
		} else {
			toast.error("Um Erro Inesperado Ocorreu");
		}
	};

	const changePasswordSubmit = (input) => {
		setData(input);
		if (currentUser.data) {
			setChangePasswordModal({
				show: true,
				title: "Alterar Senha",
				text: "Tem certeza de que deseja alterar sua senha?",
			});
		} else {
			toast.error("Um Erro Inesperado Ocorreu");
		}
	};

	const handleChangePassword = () => {
		dispatch(
			changePassword({
				password: data.password,
				// refreshToken: currentUser.data?.refreshToken,
			})
		);
		setChangePasswordModal({ show: false });
	};

	const handleEditUser = () => {
		dispatch(editCurrentUser({ userId: currentUser.data?.id, data }));
		setEditUserModal({ show: false });
	};

	return (
		<>
			<Breadcrumb current="Minha Conta" title="Minha Conta" />
			<div className="container-fluid">
				<div className='row'>
					<div className="col-xl-3 col-md-4 offset-md-0 col-sm-6 offset-sm-3 col-6 offset-3" >
						<ProfileSummary />
					</div>
					<div className='col-xl-9 col-md-8 col-sm-12 col-xs-12'>
						<div className="row theme-tab">
							<Tabs className="col" onSelect={_onSelectHandler}>
								<TabList className="tabs tab-title">
									<Tab className="current">
										<User />
										Informações Pessoais
									</Tab>
									<Tab>
										<Lock />
										Senha
									</Tab>
								</TabList>
								<div className="tab-content-cls">
									<TabPanel>
										{currentUser.data && (
											<ProfileForm
												user={currentUser.data}
												onSubmit={changeProfileSubmit}
											/>
										)}
									</TabPanel>
									<TabPanel>
										{currentUser.data && (
											<ChangePasswordForm
												user={currentUser.data}
												onSubmit={changePasswordSubmit}
											/>
											)}
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={editUserModal.show}
				onClose={() => setEditUserModal({ show: false })}
				onAccept={handleEditUser}
				title={editUserModal.title}
			>
				{editUserModal.text}
			</Modal>
			<Modal
				isOpen={changePasswordModal.show}
				onClose={() => setChangePasswordModal({ show: false })}
				onAccept={handleChangePassword}
				title={changePasswordModal.title}
			>
				{changePasswordModal.text}
			</Modal>
		</>
	);
};

export default Profile;
