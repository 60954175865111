import * as yup from "yup";
import { phone } from "phone";
import { validateBr } from "js-brasil";

const yupSchema = yup.object().shape(
	{
		consentTypeId: yup
			.string()
			.required("Campo Tipo de Consentimento é obrigatório"),

		name: yup.string().required("Campo Nome é obrigatório"),

		cpf: yup
			.string()
			.test(
				"cpf",
				"CPF invalido",
				(value) => validateBr.cnpj(value) || validateBr.cpf(value)
			)
			.required("Campo CPF é obrigatório"),

		checkboxSms: yup.bool().when("checkboxEmail", {
			is: undefined || false,
			then: yup.bool().test({
				message: "Campo Canal é obrigatório",
				test: (value, checkboxEmail) =>
					value === true || checkboxEmail === true,
			}),
		}),

		checkboxEmail: yup.bool(),

		checkboxSendNow: yup.bool(),

		email: yup
			.string()
			.when("phone", {
				is: (phone) => !phone,
				then: yup
					.string()
					.required("Campo Telefone ou E-mail obrigatório")
					.email("E-mail invalido"),
				otherwise: yup.string().email("E-mail invalido"),
			})
			.when("checkboxEmail", {
				is: true,
				then: yup.string().required("Informe Email"),
			}),

		phone: yup
			.string()
			.when("email", {
				is: (email) => !email,
				then: yup
					.string()
					.required("Campo Telefone ou E-mail obrigatório")
					.test({
						message: "Telefone Invalido",
						test: (value) => !value || phone(value, { country: "BRA" }).isValid,
					}),
				otherwise: yup.string().test({
					message: "Telefone Invalido",
					test: (value) => !value || phone(value, { country: "BRA" }).isValid,
				}),
			})
			.when("checkboxSms", {
				is: true,
				then: yup.string().required("Informe Telefone"),
			}),
	},
	[["phone", "email"]]
);

export default yupSchema;
