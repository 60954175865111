import React, { useState } from "react";
import moment from "moment";
import CheckboxSelect from "~/components/common/checkboxSelect";
import CustomDatePicker from "~/components/common/CustomDatePicker";
import { useSelector } from "react-redux";

const ChannelFilter = ({ column: { filterValue, setFilter } }) => {
	//! TODO Por enquanto não é a melhor solução pois não vale a pena percorrer o banco inteiro para pegar duas informações
	// const requests = useSelector(({ dataConsent }) => dataConsent.requests?.data);
	// const options = [
	// 	...new Set(
	// 		requests.map((request) => ({
	// 			value: request.channel,
	// 			label: request.channel,
	// 		}))
	// 	),
	// ];

	const options = [
		{ value: "SMS", label: "SMS" },
		{ value: "EMAIL", label: "EMAIL" },
	];

	return (
		<div className="input-group input-group-sm" style={{ width: "100%" }}>
			<CheckboxSelect
				defaultValue={filterValue}
				styles={{ width: "100%", minHeight: "32px", height: "32px" }}
				options={options}
				placeholder={
					filterValue?.length > 0
						? filterValue.length === 1
							? "Selecionado 1 canal"
							: `Selecionados ${filterValue.length} canais`
						: "Selecione os Canais..."
				}
				onChangeCallback={React.useCallback(
					(response) => {
						setFilter(
							response
								? response.map((item) => ({
										type: item.value,
										...item,
								  }))
								: undefined
						);
					},
					[setFilter]
				)}
				className="input-group input-group-sm"
			/>
		</div>
	);
};

const AcceptFilter = ({ column: { filterValue, setFilter } }) => {
	//! TODO Por enquanto não é a melhor solução pois não vale a pena percorrer o banco inteiro para pegar duas informações
	const options = [
		{ value: "CLOSED", label: "CLOSED" },
		{ value: "OPEN", label: "OPEN" },
		{ value: "SENT", label: "SENT" },
		{ value: "FAILED", label: "FAILED" },
	];

	return (
		<div className="input-group input-group-sm" style={{ width: "100%" }}>
			<CheckboxSelect
				defaultValue={filterValue}
				styles={{ width: "100%", minHeight: "32px", height: "32px" }}
				options={options}
				placeholder={
					filterValue?.length > 0
						? filterValue.length === 1
							? "Selecionado 1 status"
							: `Selecionados ${filterValue.length} status`
						: "Selecione os Status..."
				}
				onChangeCallback={React.useCallback(
					(response) => {
						setFilter(
							response
								? response.map((item) => ({
										type: item.value,
										...item,
								  }))
								: undefined
						);
					},
					[setFilter]
				)}
				className="input-group input-group-sm"
			/>
		</div>
	);
};

const SentDateColumnFilter = ({ column: { setFilter } }) => {
	const { tableParams } = useSelector(
		({ dataConsent }) => dataConsent.clientConsents
	);

	const [date, setDate] = useState(
		_returnDateFilterObjectFromTableParams(tableParams, "requestAt")
	);

	return (
		<div className="input-group input-group-sm">
			<CustomDatePicker
				onChange={(date) => {
					setDate(date);
					const formattedDate = date?.toISOString().replace("T", " ") || null;
					setFilter(formattedDate);
				}}
				placeholderText="Filtrar..."
				selected={date}
				toolTipMsg="Selecione uma data para ver os envios de pedidos de consentimentos emitidos a partir desta data"
			/>
		</div>
	);
};

const AcceptedDateColumnFilter = ({ column: { setFilter } }) => {
	const { tableParams } = useSelector(
		({ dataConsent }) => dataConsent.clientConsents
	);

	const [date, setDate] = useState(
		_returnDateFilterObjectFromTableParams(tableParams, "acceptedAt")
	);

	return (
		<div className="input-group input-group-sm">
			<CustomDatePicker
				onChange={(date) => {
					setDate(date);
					const formattedDate = date?.toISOString().replace("T", " ") || null;
					setFilter(formattedDate);
				}}
				placeholderText="Filtrar..."
				selected={date}
				toolTipMsg="Selecione uma data para ver os envios de pedidos de consentimentos emitidos a partir desta data"
			/>
		</div>
	);
};

const CreatedAtDateColumnFilter = ({ column: { setFilter } }) => {
	const { tableParams } = useSelector(
		({ dataConsent }) => dataConsent.clientConsents
	);

	const [date, setDate] = useState(
		_returnDateFilterObjectFromTableParams(tableParams, "createdAt")
	);

	return (
		<div className="input-group input-group-sm">
			<CustomDatePicker
				onChange={(date) => {
					setDate(date);
					const formattedDate = date?.toISOString().replace("T", " ") || null;
					setFilter(formattedDate);
				}}
				placeholderText="Filtrar..."
				selected={date}
				toolTipMsg="Selecione uma data para ver os envios de pedidos de consentimentos emitidos a partir desta data"
				popperModifiers
			/>
		</div>
	);
};

function _returnDateFilterObjectFromTableParams(tableParams, filterId) {
	let dateFilterValue = null;
	let ISOFormattedDate = null;

	tableParams.filters.forEach((filter) => {
		// search the array of objects until you find the date filter object
		if (filter.id === filterId) {
			dateFilterValue = filter.value;
		}
	});
	ISOFormattedDate = dateFilterValue?.replace(" ", "T") || null;

	return ISOFormattedDate === null
		? ISOFormattedDate
		: new Date(ISOFormattedDate);
}

const smallLimit = 70;
const mediumLimit = 80;
const smallSize = 100;
const mediumSize = 150;

export const columns = [
	{
		Header: <span>{"Nome"}</span>,
		accessor: "companiesClientConsents.clientConsents.name",
		minWidth: smallLimit,
		width: smallSize,
		Cell: (data) => <div>{data.row.original["name"]}</div>,
	},
	{
		Header: <span>{"Data de Recebimento"}</span>,
		accessor: "requestAt",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => {
			return (
				<div>
					{moment(data.row.original["requestAt"]).format("DD/MM/YYYY HH:mm")}
				</div>
			);
		},
		Filter: SentDateColumnFilter,
	},
	{
		Header: <span>{"Canal"}</span>,
		accessor: "channel",
		minWidth: smallLimit,
		width: mediumSize,
		Filter: ChannelFilter,
		Cell: (data) => (
			<div className="badge badge-secondary">
				{data.row.original["channel"]}
			</div>
		),
	},
	{
		Header: <span>{"Status"}</span>,
		accessor: "status",
		minWidth: smallLimit,
		width: mediumSize,
		Cell: (data) => {
			switch (data.row.original["status"]) {
				case "CLOSED":
					return (
						<div className="badge badge-success">
							{data.row.original["status"]}
						</div>
					);
				case "FAILED":
					return (
						<div className="badge badge-danger ">
							{data.row.original["status"]}
						</div>
					);
				case "SENT":
					return (
						<div className="badge badge-dark ">
							{data.row.original["status"]}
						</div>
					);
				case "OPEN":
					return (
						<div className="badge badge-primary ">
							{data.row.original["status"]}
						</div>
					);
				default:
					return (
						<div className="badge badge-dark">
							{data.row.original["status"]}
						</div>
					);
			}
		},
		Filter: AcceptFilter,
	},
	{
		Header: <span>{"Data de Aceite"}</span>,
		accessor: "updatedAt",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => {
			const acceptedAt = data.row.original["acceptedAt"]
				? moment(data.row.original["acceptedAt"]).format("DD/MM/YYYY HH:mm")
				: "-";
			return <div>{acceptedAt}</div>;
		},
		Filter: AcceptedDateColumnFilter,
	},
	{
		Header: <span>{"Data de Criação"}</span>,
		accessor: "createdAt",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => {
			return (
				<div style={{ width: "200px" }}>
					{moment(data.row.original["createdAt"]).format("DD/MM/YYYY HH:mm")}
				</div>
			);
		},
		Filter: CreatedAtDateColumnFilter,
	},
];
