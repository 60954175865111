import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../common/breadcrumb";
import { getDatabases } from "~/states/modules/databases";
import DatabasesTable from "./databasesTable";
import AddButton from "~/components/common/AddButton";
import { Button } from "reactstrap";

const DatabasesList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const databases = useSelector(({ databases }) => databases.databases);

	useEffect(() => {
		dispatch(getDatabases());
	}, [dispatch]);

	const goToAddDatabase = () =>
		history.push(
			`${process.env.PUBLIC_URL}/company/integrations/databases/add`
		);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col">
										<h5>Bancos de Dados</h5>
									</div>
									<div className="col text-right">
										<Button color="primary" onClick={goToAddDatabase}>
											Adicionar banco de dados
										</Button>
									</div>
								</div>
							</div>
							<div className="card-body">
								<DatabasesTable databasesList={databases} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DatabasesList;
