import React, { Fragment, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick-theme-override.css";
import { useDispatch, useSelector } from "react-redux";
import { getTicketChart } from "~/states/modules/tickets";
import moment from "moment";
import {
	Search,
	CheckCircle,
	RefreshCw,
	Trash2,
	Delete,
	UserX,
	Package,
	BookOpen,
} from "react-feather";
import CountUp from "react-countup";

import { getInfoBar } from "../selectors";

export default function TicketInfoSlider({ dateRange }) {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: dateRange ? dateRange : "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);


	const infoBar = getInfoBar(ticketsForChart);


	const counterPerLabel = (label) => {
			const found = infoBar.dataset.find((element) => element.label === label);
			return found ? found.count : 0;
		},
		items = [
			{
				id: 1,
				label: "Confirmação de Tratamento e Utilização",
				icon: <CheckCircle />,
				count: counterPerLabel(
					"Confirmação de Tratamento e Utilização"
				),
			},
			{
				id: 2,
				label: "Pesquisa de Dados Pessoais",
				icon: <Search />,
				count: counterPerLabel("Pesquisa de Dados Pessoais"),
			},
			{
				id: 3,
				label: "Atualização de Cadastro",
				icon: <RefreshCw />,
				count: counterPerLabel("Atualização de Cadastro"),
			},
			{
				id: 4,
				label: "Informações sobre Processamento",
				icon: <BookOpen />,
				count: counterPerLabel("Informações sobre Processamento"),
			},
			{
				id: 5,
				label: "Portabilidade ",
				icon: <Package />,
				count: counterPerLabel("Portabilidade"),
			},
			{
				id: 6,
				label: "Eliminação Parcial ",
				icon: <Delete />,
				count: counterPerLabel("Eliminação Parcial"),
			},
			{
				id: 7,
				label: "Eliminação Integral ",
				icon: <Trash2 />,
				count: counterPerLabel("Eliminação Integral"),
			},
			{
				id: 8,
				label: "Revogação do Consentimento",
				icon: <UserX />,
				count: counterPerLabel("Revogação do Consentimento"),
			},
		];

	const countNoEmptyTypes = (items) =>
		items
			.filter((item) => item.count > 0)
			.reduce((total, item) => (total = ++total), 0);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow:countNoEmptyTypes(items) > 3 ? 2 : 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		rows: countNoEmptyTypes(items) < 2 ? 1 : 2,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					rows: 1,
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: countNoEmptyTypes(items) > 3 ? 2 : 1,
					rows: countNoEmptyTypes(items) < 2 ? 1 : 2,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					rows: countNoEmptyTypes(items) < 2 ? 1 : 2,

				},
			},
		],
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						<div className="card">
							<div className="card-header">
								<h5>Total de Pedidos</h5>
								<p>Número total de tickets de cada tipo.</p>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="px-md-3 col-md-12 ecommerce-slider">
										<Slider {...settings}>
											{items
												.filter(
													(item) => item.count > 0
												)
												.map((item) => (
													<div
														className="item"
														key={item.id}
													>
														<div
															className="card bg-light"
															style={{
																height: "175px",
															}}
														>
															<span className="rounded">
																<div className="card-body ecommerce-icons text-center">
																	{item.icon}
																	<div
																		style={{
																			height:
																				"75px",
																		}}
																	>
																		<span>
																			{
																				item.label
																			}
																		</span>
																	</div>
																	<h4 className="font-primary mb-0">
																		<CountUp
																			className="counter"
																			end={
																				item.count
																			}
																		/>
																	</h4>
																</div>
															</span>
														</div>
													</div>
												))}
										</Slider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
