import React from "react";
import { Alert /*, UncontrolledAlert*/ } from "reactstrap";

const AlertMessage = ({ alert }) => {
	return !!alert ? (
		<Alert
			color="primary"
			color={alert.type}
			fade={true}
			className="dark text-center"
		>
			{alert.text}
		</Alert>
	) : null;
};

// alert com close button:
// -----------------------
// <UncontrolledAlert color={alert.type} fade={true} className="text-center">
// 	{alert.text}
// </UncontrolledAlert>

export default AlertMessage;
