import React, { useEffect, Fragment } from "react";
import { Radar, Line, Bar, Doughnut, Polar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsPerAssigned } from "~/states/modules/tickets";

import man from "../../../assets/images/dashboard/user.png";
import { XCircle, User } from "react-feather";

export default function AssignedRank() {
	const dispatch = useDispatch();

	const ticketsPerAssigned = useSelector(
		({ tickets }) => tickets.ticketsPerAssigned?.data
	);

	useEffect(() => {
		dispatch(getTicketsPerAssigned());
	}, [dispatch]);

	const url = "";

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col">
					<div className="card" data-intro="This is Our Topper List">
						<div className="card-header">
							<div style={{ marginBottom: 8 }}>
								<h5>Resumo dos responsáveis</h5>
							</div>
							<div>
								<p>
									Lista dos responsáveis pelos pedidos com
									suas respectivas taxas de aproveitamento.
								</p>
							</div>
						</div>
						<div className="card-body">
							{ticketsPerAssigned.map((value, index) => (
								<div key={index} className="row m-10">
									<div className="col-6">
										<div className="row flex-nowrap">
											{/* <img
												className="img-radius img-40 align-top m-r-15 rounded-circle"
												src={url ? url : man}
												alt=""
											/> */}
											{url ? (
												<img
													className="img-radius img-40 align-top m-r-15 rounded-circle"
													src={url ? url : man}
													alt=""
												/>
											) : value["user.name"] ? (
												<User
													size={36}
													style={{
														display: "inline-block",
														verticalAlign: "middle",
														marginRight: "0.8em",
													}}
												/>
											) : (
												<XCircle
													size={36}
													style={{
														display: "inline-block",
														verticalAlign: "middle",
														marginRight: "0.8em",
													}}
												/>
											)}
											<div className="d-inline-block ">
												<span className="f-w-400">
													{value["user.name"] ||
														"Deletado"}
												</span>
												<p>
													{value["user.email"] || "-"}
												</p>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div
											className="progress-title"
											style={{ marginBottom: 9 }}
										>
											<span
												style={{
													fontWeight: 400,
													fontSize: 14,
												}}
											>
												Pedidos fechados / Total de
												pedidos
											</span>
											<span className="f-w-600 pull-right">
												{parseInt(
													value.closedTicketsCount,
													10
												)}{" "}
												/{" "}
												{parseInt(
													value.ticketsCount,
													10
												)}
											</span>
										</div>
										<div
											className="progress"
											style={{ height: 6 }}
										>
											<div
												className="progress-bar bg-primary"
												role="progressbar"
												style={{
													width: `${
														(parseInt(
															value.closedTicketsCount,
															10
														) /
															parseInt(
																value.ticketsCount,
																10
															)) *
														100
													}%`,
												}}
											></div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
