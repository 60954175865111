import React from "react";
import { useForm } from "react-hook-form";
import validate from "../../common/validate";

const ProfileForm = ({ onSubmit, user }) => {
	const { register, handleSubmit, errors, watch } = useForm({
		defaultValues: user,
		mode: "onChange",
	});

	return (
		<form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="card">
				<div className="card-header">
					<h5>Edite suas informações pessoais abaixo</h5>
				</div>
				<div className="card-body">
					<div className="form-group row">
						<label className="col-sm-3 col-form-label" htmlFor="input2">
							Nome
						</label>
						<div className="col-sm-9">
							<input
								className="form-control"
								id="input2"
								type="text"
								placeholder="Nome"
								ref={register(validate.name(user.name))}
								name="name"
							/>
							<span className="auth--validation-alert">
								{errors.name?.message}
							</span>
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-3 col-form-label" htmlFor="input3">
							Email
						</label>
						<div className="col-sm-9">
							<p className="form-control-static">{user.email}</p>
							{/* <input
								className="form-control"
								id="input3"
								type="text"
								placeholder="Email"
								ref={register}
								name="email"
								readOnly
							/> */}
						</div>
					</div>
				</div>
				<div className="card-footer text-right">
					<button
						className={`btn btn-primary ${errors.name ? "disabled" : ""}`}
						type="submit"
					>
						Salvar
					</button>
				</div>
			</div>
		</form>
	);
};
export default ProfileForm;
