console.log(process.env.API_URL)

export const getApiUrl = (domain) => {
	const result = "https://api.dadoslegais.com/api" || `//${window.location.hostname}${domain || "/api"}`
		.replace("127.0.0.1", "127.0.0.1:4000")
		.replace("localhost", "localhost:4000")
		.replace("admin", "api");
	return result;
};

export const getAppUrl = () => {
	const result = `//${window.location.hostname}`
		.replace("127.0.0.1", "127.0.0.1:1234")
		.replace("localhost", "localhost:1234")
		.replace("admin", "app");
	return result;
}
