import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import { Tooltip } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

import "./style.scss";
/**
 * Custom Date picker component.
 *
 * Below are some examples of props accepted by this component:
 *
 * @param {function} onChange => setState hook to change the current selected date
 * @param {Date Object} selectedDate => date object constant used on setState hook passed on OnChange prop.
 * @param {constant} dateFormat => pt, en...
 * @param {boolean} isClearable => Defines if filter should have a "clear" button next to it.
 * @param {string} placeHolderText => String text to be displayed on "clear" effect.
 * @param {function} onCalendarClose => function to be called on "close" action (this prop may receive the updateTableParams function, for example).
 * @param {function} onCalendarOpen => function to be called on "open" action.
 *
 * more props on react-datepicker docs: https://reactdatepicker.com/#example-select-time
 */

const getId = (function () {
	let i = 0;
	return () => ++i;
})();

const CustomDatePicker = ({
	dateFormat,
	isClearable,
	placeholderText,
	onCalendarClose,
	id,
	toolTipMsg,
	...props
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);

	const _id = `ct-date-picker-${useMemo(() => getId(), [toolTipMsg])}`;
	id = id || _id;

	return (
		<>
			<DatePicker
				className="dl-date-picker"
				dateFormat={dateFormat || "dd/MM/yyyy"}
				isClearable={isClearable || true}
				placeholderText={placeholderText || "Insira uma data..."}
				id={id}
				autoComplete="off"
				{...props}
			/>
			{toolTipMsg && (
				<Tooltip
					target={id}
					isOpen={tooltipOpen}
					toggle={toggle}
					delay={{ show: 900, hide: 0 }}
				>
					{toolTipMsg}
				</Tooltip>
			)}
		</>
	);
};

export default CustomDatePicker;
