import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import slugify from "@sindresorhus/slugify";

import * as yup from "yup";
import useYupResolver from "../../../common/yupResolver";
import { getAppUrl } from "~/services/envUtils";

const schema = yup.object().shape({
	name: yup.string().required("Campo Nome é obrigatório"),
	slug: yup.string().required("Campo Slug é obrigatório"),
	logo: yup
		.string()
		.required("Campo Logo é obrigatório")
		.url("Campo deve ser URL"),
	privacyPolicyLink: yup
		.string()
		.required("Campo Link Política de Privacidade é obrigatório")
		.url("Campo deve ser URL"),
	siteUrl: yup
		.string()
		.required("Campo Link do Site da Empresa é obrigatório")
		.url("Campo deve ser URL"),
	require_email: yup.bool(),
	require_cpf: yup.bool(),
	require_phoneNumber: yup.bool(),
	autoEventsFlow: yup.bool(),
});
const CompanyForm = ({ onSubmit, company }) => {
	const { register, handleSubmit, watch, errors, setValue } = useForm({
		mode: "onChange",
		defaultValues: company,
		resolver: useYupResolver(schema),
	});
	const [slugText, setSlugText] = useState(company.slug || "");

	const companyName = watch("name");
	const watchedSlugField = watch("slug");
	const watchedLogo = watch("logo");

	useEffect(() => {
		const slug = slugify(companyName || "", {
			customReplacements: [["&", "e"]],
		});
		setSlugText(slug);
		setValue("slug", slug);
	}, [companyName, setValue]);

	useEffect(() => {
		const slug = watchedSlugField;
		setValue("slug", slug);
		setSlugText(slug);
	}, [watchedSlugField, setValue]);

	return (
		<div className="tab-content active default" id="tab-1">
			<form className="theme-form mega-form " onSubmit={handleSubmit(onSubmit)}>
				<div className="card">
					<div className="card-header">
						<h5 className="font-weight-bold">Informações da Empresa</h5>
					</div>
					<div className="card-body">
						<div className="form-row">
							<div className="form-group col-md-6 col-sm-12">
								<label className="col-form-label" htmlFor="companyName">
									Nome
									<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control"
									id="companyName"
									type="text"
									name="name"
									ref={register}
								/>
								<ErrorMessage
									errors={errors}
									name="name"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
							<div className="form-group col-md-6 col-sm-12">
								<label className="col-form-label" htmlFor="slug">
									Slug
									<span className="auth--required-symbol">*</span>
								</label>
								<input
									className="form-control mb-2"
									id="slug"
									type="text"
									name="slug"
									ref={register}
								/>
								<a
									style={{ color: "#727f96" }}
									href={`${getAppUrl()}/${slugText}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<strong>App URL: </strong>
									{`${getAppUrl()}/${slugText}`.replace("//", "")}
								</a>
								<ErrorMessage
									errors={errors}
									name="slug"
									as={<span className="auth--validation-alert" />}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-12 col-sm-12">
								<div className="form-row">
									<div className="form-group col-md-6 col-sm-12">
										<label className="col-form-label" htmlFor="logo">
											Logo
											<span className="auth--required-symbol">*</span>
										</label>
										<p>
											Imagem institucional, estará presente nos emails, no
											portal de administração e relatórios.
										</p>
										<input
											className="form-control mb-3"
											id="logo"
											type="text"
											name="logo"
											ref={register}
										/>
										<p>Deve ser no formato de url.</p>
										<ErrorMessage
											errors={errors}
											name="logo"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
									<div
										className="form-group col-md-6 col-sm-12 pt-2"
										style={{ display: "flex", alignItems: "center" }}
									>
										<img
											className="img-fluid align-middle"
											style={{ display: "flex" }}
											src={watchedLogo}
											alt={`${company.name} logo`}
											width="200px"
											height="100px"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-6 ">
								<label className="col-form-label" htmlFor="privacyPolicyLink">
									Link da Política de Privacidade
									<span className="auth--required-symbol">*</span>
								</label>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="icofont icofont-link"></i>
										</span>
									</div>
									<input
										className="form-control"
										id="privacyPolicyLink"
										type="text"
										ref={register}
										name="privacyPolicyLink"
									/>
								</div>
								<div>
									<ErrorMessage
										errors={errors}
										name="privacyPolicyLink"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
							<div className="form-group col-6">
								<label className="col-form-label" htmlFor="privacyPolicyLink">
									Link do site
									<span className="auth--required-symbol">*</span>
								</label>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="icofont icofont-link"></i>
										</span>
									</div>
									<input
										className="form-control"
										id="siteUrl"
										type="text"
										ref={register}
										name="siteUrl"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer text-right">
						<button className="btn btn-primary" type="submit">
							Salvar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
export default CompanyForm;
