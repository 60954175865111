import React from "react";

/* Animated loading indicator component */

import "./style.scss";
import "./override.scss";

function BallLoader() {
	return (
		<div className="la-ball-pulse-sync">
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

export default BallLoader;
