import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Bar } from "react-chartjs-2";
import moment from "moment";

import { getTicketChart } from "~/states/modules/tickets";

import { getInfoBar } from "../selectors";

export default function TicketTypeBar({ legend, dateRange }) {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: dateRange ? dateRange : "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);


	const infoBar = getInfoBar(ticketsForChart);

	const barChartData = {
			labels: infoBar.datalabels,
			datasets: infoBar.dataset,
			plugins: {
				datalabels: {
					display: false,
					color: "white",
				},
			},
		},
		barChartOptions = {
			maintainAspectRatio: true,
			legend: {
				display: legend ? legend : false,
			},
			scales: {
				xAxes: [
					{
						gridLines: {
							color: "#aaa",
							borderDash: [0, 1],
						},
						display: true,
						stacked: true,
					},
				],
				yAxes: [
					{
						gridLines: {
							color: "#aaa",
							borderDash: [0, 1],
						},
						display: true,
						stacked: true,
					},
				],
			},
			plugins: {
				datalabels: {
					display: false,
				},
			},
		};

	return (
		<div className="card">
			<div className="card-header">
				<div>
					<h5>Histórico de pedidos</h5>
				</div>
				<div>
					<p>Pedidos dos últimos 12 meses</p>
				</div>
			</div>
			<div className="card-body chart-block">
				<Bar
					data={barChartData}
					options={barChartOptions}
					width={1024}
					height={250}
				/>
			</div>
		</div>
	);
}
