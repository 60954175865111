const apiInfoConstants = {
	status: {
		TO_FETCH: "toFetch",
		FETCHED: "fetched",
		TO_EDIT: "toEdit",
		EDITED: "edited",
		FAILED: "failed",
	},
};

export default apiInfoConstants;
