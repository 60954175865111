import api from "./api";
import qs from "qs";
import generateSearchRequest from "../../utils/sequelize-search-builder-client";
import {
	formatFilterValuesForILikeFilter,
	formatPayload,
} from "~/utils/CTPayloadFormatter";
import { selectAllTickets } from "../../states/modules/tickets/selectors";

class TicketsService {
	async getTickets(payload) {
		const formattedPayload = formatPayload(payload),
			settings = {
				where: {
					ticketNumber: {
						as: "ticketNumber",
						type: "like",
					},
					type: {
						as: "ticketType.id",
						type: "in",
						itemBy: "type",
					},
					"client.email": {
						as: "client.email",
						type: "like",
					},
					status: {
						as: "status",
						type: "in",
						itemBy: "type",
					},
					"user.name": {
						as: "user.name",
						type: "iLike",
					},
				},
				order: formattedPayload?.order,
			},
			request = generateSearchRequest(formattedPayload?.filters, settings);
		let params = {
			page: formattedPayload?.page || 0,
			size: formattedPayload?.pageSize || 20,
			...request,
		};

		params = formatFilterValuesForILikeFilter(params, ["user.name"]);

		return api
			.get("/tickets", {
				paramsSerializer: function (params) {
					return qs.stringify(params, { arrayFormat: "brackets" });
				},
				params,
			})
			.then((response) => response.data)
			.then(payload => {
				payload.data = selectAllTickets(payload.data);
				return payload;
			})
			.catch((error) => {
				console.log(
					"fail when getTickets in TicketsService.",
					error.response?.data?.error
				);
				throw error.response?.data?.error;
			});
	}
}
export default new TicketsService();
