import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "~/states/modules/users";
import { getCompany } from "~/states/modules/company";
import { createTicket } from "~/states/modules/tickets";
import validate from "~/components/common/validate";
import InputMask from "react-input-mask";
import Breadcrumb from "../../common/breadcrumb";
import { useForm, Controller } from "react-hook-form";

import { toast } from "react-toastify";

const NewTicket = () => {
	const dispatch = useDispatch();

	const { register, handleSubmit, errors, reset, control } = useForm();

	const currentUser = useSelector(({ users }) => users.currentUser?.data);
	const createdTicket = useSelector(({ tickets }) => tickets.createdTicket);
	const company = useSelector(({ company }) => company.currentCompany.data);

	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		dispatch(getCompany());
		dispatch(getCurrentUser());
	}, [dispatch]);

	useEffect(() => {
		if (showMessage) {
			if (createdTicket && createdTicket.error) {
				setLoading(false);
				toast.error(createdTicket.error.message);
			}
			if (createdTicket && createdTicket.data) {
				setLoading(false);
				toast.success(
					`Ticket ${createdTicket.data.ticketNumber} criado com sucesso!`
				);
			}
		}
	}, [createdTicket]);

	const parseValue = (value) => (value ? value.replace(/[^\d]/g, "") : null);

	const onSubmit = (data) => {
		console.log(errors);
		setLoading(true);
		// Just start watching for changes about createdticket data after submition was triggered:
		setShowMessage(true);

		const info = {
			name: data.name,
			email: data.email,
			phone: parseValue(data.phoneNumber),
			cpf: parseValue(data.cpf),
			type: data.type,
			companyId: currentUser.companyId,
		};

		// dispatch action for create tickets with its info:
		dispatch(createTicket(info));

		// reset form after submit cleaning all values from inputs and other form elements:
		reset();
	};

	const hasError = () => Object.keys(errors).length > 0;

	return (
		<Fragment>
			<Breadcrumb
				parent="Lista de tickets"
				current="Novo Pedido"
				title="Novo Pedido"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form
							className="needs-validation"
							noValidate=""
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col">
											<h5>
												Crie um novo pedido preenchendo
												as informações abaixo.
											</h5>
										</div>
									</div>
								</div>
								<div className="card-body">
									{company && company.require_name && (
										<div className="form-row">
											<div className="col-md-12 mb-3">
												<label htmlFor="inputName">
													Nome Completo:
													<span className="auth--required-symbol">
														*
													</span>
												</label>
												<input
													className="form-control"
													id="inputName"
													name="name"
													type="name"
													placeholder="Nome"
													ref={register(
														validate.name()
													)}
												/>
												<span>
													{errors.name?.message}
												</span>
											</div>
										</div>
									)}
									<div className="form-row">
										<div className="col-md-12 mb-3">
											<label htmlFor="inputEmail">
												E-mail:
												<span className="auth--required-symbol">
													*
												</span>
											</label>
											<input
												className="form-control"
												id="inputEmail"
												name="email"
												type="email"
												placeholder="email@website.com"
												ref={register(validate.email())}
											/>
											<span>{errors.email?.message}</span>
										</div>
									</div>
									{company && company.require_cpf && (
										<div className="form-row">
											<div className="col-md-12 mb-3">
												<label htmlFor="inputCPF">
													CPF (Apenas Números):
													<span className="auth--required-symbol">
														*
													</span>
												</label>
												<Controller
													as={InputMask}
													control={control}
													mask="999.999.999-99"
													alwaysShowMask={true}
													permanents={[3, 7, 11]}
													rules={validate.onlyNumbersCPF()}
													className="form-control"
													id="inputCPF"
													name="cpf"
													type="text"
													defaultValue={""}
												/>
												<span>
													{errors.cpf?.message}
												</span>
											</div>
										</div>
									)}
									{company && company.require_phoneNumber && (
										<div className="form-row">
											<div className="col-md-12 mb-3">
												<label htmlFor="inputPhoneNumber">
													Telefone: (Apenas Números):
													<span className="auth--required-symbol">
														*
													</span>
												</label>
												<Controller
													as={InputMask}
													control={control}
													mask="(99) 99999-9999"
													alwaysShowMask={true}
													permanents={[0, 3, 4, 10]}
													rules={validate.onlyNumbersPhoneNumber()}
													className="form-control"
													id="inputPhoneNumber"
													name="phoneNumber"
													type="text"
													defaultValue={""}
												/>

												<span>
													{
														errors.phoneNumber
															?.message
													}
												</span>
											</div>
										</div>
									)}
									<div className="form-row">
										<div className="col-md-12 mb-3">
											<label htmlFor="inputType">
												Tipo:
												<span className="auth--required-symbol">
													*
												</span>
											</label>
											<select
												id="inputType"
												name="type"
												className="form-control"
												placeholder="Tipo"
												ref={register(
													validate.required()
												)}
											>
												<option key={""} value={""}>
													--- Selecione um Tipo ---
												</option>
												{company
													? company.offeredRequests.options.map(
															(option) =>
																option.active ? (
																	<option
																		key={
																			option.value
																		}
																		value={
																			option.value
																		}
																	>
																		{
																			option.label
																		}
																	</option>
																) : null
													  )
													: null}
											</select>
											<input type="hidden"></input>
											<span>{errors.type?.message}</span>
										</div>
									</div>
								</div>
								<div className="card-footer text-right">
									<button
										className={`btn btn-primary ${
											hasError() || loading
												? "disabled"
												: ""
										}`}
										type="submit"
									>
										{!loading ? (
											"Criar"
										) : (
											<div className="loader">
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
											</div>
										)}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NewTicket;
