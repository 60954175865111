import api from "./api";

class SdkService {
	getSDKIntallationCheck() {
		return api
			.get("/sdk/test")
			.then((res) => res.data)
			.then((data) => data.data)
			.catch((error) => {
				throw error.response?.data?.error;
			});
	}
}

export default new SdkService();
