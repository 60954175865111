import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Breadcrumb from "~/components/common/breadcrumb";

import { getCompany } from "~/states/modules/company";
import { getSdkConfig, editSdkConfig } from "~/states/modules/sdkConfig";
import companyConstants from "~/states/modules/company/companyConstants";

import CookiebarForm from "./cookiebarForm";

import Modal from "~/components/common/modal";
import validate from "~/components/common/validate";

const CookiebarConfig = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompany());
		dispatch(getSdkConfig());
	}, [dispatch]);

	const [formInput, setFormInput] = useState(null);

	const companyError = useSelector(
		({ company }) => company.currentCompany.error
	);
	const currentCompanyStatus = useSelector(
		({ company }) => company.currentCompany.status
	);
	const [editedCompanyFeedback, setEditedCompanyFeedback] = useState(false);
	const { status } = companyConstants;

	useEffect(() => {
		if (editedCompanyFeedback && currentCompanyStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedCompanyFeedback(false);
		} else if (
			editedCompanyFeedback &&
			currentCompanyStatus === status.FAILED
		) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(companyError);
		}
	}, [currentCompanyStatus]);

	/////////////////////////// Sdk ///////////////////////////
	const [sdkModal, setSdkModal] = useState(false);
	const {
		data: sdkConfig,
		error: sdkConfigError,
		status: sdkConfigStatus,
	} = useSelector(({ sdkConfig }) => sdkConfig);
	const [editedSdkConfigFeedback, setEditedSdkConfigFeedback] = useState(false);

	useEffect(() => {
		if (editedSdkConfigFeedback && sdkConfigStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedSdkConfigFeedback(false);
		} else if (editedSdkConfigFeedback && sdkConfigStatus === status.FAILED) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(sdkConfigError);
		}
	}, [sdkConfigStatus]);

	const handleSubmitSdk = (input) => {
		if (!validate.anyNew(input, sdkConfig)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setSdkModal(true);
		}
	};

	const handleEditSdk = () => {
		setSdkModal(false);
		setEditedSdkConfigFeedback(true);
		dispatch(editSdkConfig(formInput));
	};

	return (
		<>
			<Breadcrumb
				parent="Gestão de Cookies"
				current="Configurações"
				title="Configurações"
			/>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="row theme-tab">
							<div className="col-sm-12">
								{sdkConfig && (
									<CookiebarForm
										sdkConfig={sdkConfig}
										onSubmit={handleSubmitSdk}
									/>
								)}
								<Modal
									isOpen={sdkModal}
									onClose={() => setSdkModal(false)}
									onAccept={handleEditSdk}
									title="Editar"
								>
									Tem certeza de que deseja editar as informações da sua
									empresa?
								</Modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CookiebarConfig;
