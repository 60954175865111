import React, { Fragment } from "react";

const InitialLetters = ({ name , style}) => {
	const getFisrtLetters = (name) => {
		name = name || "";
		return name
			.split(/\s/)
			.reduce((response, word) => (response += word.slice(0, 1)), "")
			.toUpperCase();
	};

	return (
		<Fragment>
			<div className="text-center">
				<span
					className="rounded-circle bg-primary"
					style={{
						// backgroundColor: "#898989",
						//background: "linear-gradient(-10deg, #022854, #23d3c5)",
						color: "#FFF",
						display: "block",
						margin: "auto",
						width: "50px",
						height: "50px",
						lineHeight: "50px",
						fontWeight: "semi-bold",
						fontSize: "1.1em",
						WebkitTouchCallout: "none",
						WebkitUserSelect: "none",
						KhtmlUserSelect: "none",
						MozUserSelect: "none",
						msUserSelect: "none",
						userSelect: "none",
						...style
					}}
				>
					{getFisrtLetters(name)}
				</span>
			</div>
		</Fragment>
	);
};

export default InitialLetters;
