import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import api, { policyService } from "~/services/api";
// import generateSearchRequest from "sequelize-search-builder-client";
// import qs from "qs";
import { formatPayload } from "~/utils/CTPayloadFormatter";

import {
	getPolicies,
	getPoliciesSuccess,
	getPoliciesFailure,
	createPolicy,
	createPolicySuccess,
	createPolicyFailure,
	updatePolicy,
	updatePolicySuccess,
	updatePolicyFailure,
	getPolicyById,
	getPolicyByIdSuccess,
	getPolicyByIdFailure,
} from ".";

function* rootSaga() {
	yield takeLatest(getPolicies, getPoliciesSaga);
	yield takeEvery(createPolicy, createPolicySaga);
	yield takeEvery(updatePolicy, updatePolicySaga);
	yield takeEvery(getPolicyById, getPolicyByIdSaga);
}

function* getPoliciesSaga({ payload }) {
	try {
		const formattedPayload = formatPayload(payload);
		const policies = yield call(policyService.getPolicies, {
			tableParams: {
				page: formattedPayload.page || 0,
				size: formattedPayload.pageSize || 10,
				// ...request,
			},
		});
		yield delay(200);
		yield put(getPoliciesSuccess(policies));
	} catch (error) {
		yield put(getPoliciesFailure(error));
	}
}

function* createPolicySaga({ payload }) {
	try {
		const data = yield call(policyService.createPolicy, payload);
		yield put(createPolicySuccess(data));
	} catch (error) {
		yield put(createPolicyFailure(error));
	}
}

function* updatePolicySaga({ payload }) {
	try {
		const data = yield call(policyService.editPolicy, payload);
		yield put(updatePolicySuccess(data));
	} catch (error) {
		yield put(updatePolicyFailure(error));
	}
}

function* getPolicyByIdSaga({ payload }) {
	try {
		const data = yield call(policyService.getPolicyById, payload);
		yield put(getPolicyByIdSuccess(data));
	} catch (error) {
		yield put(getPolicyByIdFailure(error));
	}
}

export default rootSaga;
