import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Trash2, Edit, Database } from "react-feather";
import { Button, Badge } from "reactstrap";

import Modal from "~/components/common/modal";

import { removeDatabase as removeDatabaseAction } from "~/states/modules/databases";

function DatabasesTable({ databasesList }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [modal, setModal] = useState({ show: false, database: {} });
	const [databaseId, setDatabaseId] = useState(null);
	const [showMessage, setShowMessage] = useState(false);

	const removedDatabase = useSelector(
		({ databases }) => databases.removedDatabase
	);

	useEffect(() => {
		if (showMessage) {
			if (removedDatabase && removedDatabase.error) {
				toast.error(removedDatabase.error.message);
			}
		}
	}, [removedDatabase?.error]);

	useEffect(() => {
		if (showMessage) {
			if (removedDatabase && removedDatabase.data) {
				toast.success(`Banco de Dados Removido com Sucesso.`);
			}
		}
	}, [removedDatabase?.data]);

	const goToEditDatabase = (databaseId) => (e) => {
		history.push(
			`${process.env.PUBLIC_URL}/company/integrations/databases/${databaseId}`
		);
	};

	const removeDatabase = (database) => (e) => {
		console.log(e);
		e.stopPropagation();
		setDatabaseId(database.id);
		setModal({ show: true, database });
	};

	const removeDatabaseHandler = () => {
		setModal({ show: false });
		console.log("Removing database with id: " + databaseId);
		setShowMessage(true);
		dispatch(removeDatabaseAction({ databaseId }));
	};

	return (
		<Fragment>
			<div className="table-responsive">
				<table className="table table-striped table-hover table-ellipsis">
					<thead>
						<tr>
							<th className="col-2 text-center align-middle">Nome</th>
							<th className="col-1 text-center align-middle">Usuário</th>
							<th className="col-1 text-center align-middle">Senha</th>
							<th className="col-1 text-center align-middle">Host</th>
							<th className="col-0.5 text-center align-middle">Porta</th>
							<th className="col-1 text-center align-middle">Dialeto</th>
							<th className="col-1 text-center align-middle">Armazenamento</th>
							<th className="col-0.5 text-center align-middle">
								Conexão via SSH
							</th>
							<th className="col-1 text-center align-middle">Usuário SSH</th>
							<th className="col-1 text-center align-middle">Chave SSH</th>
							<th className="col-1 text-center align-middle">Host SSH</th>
							<th className="col-1 text-center align-middle">Editar</th>
							<th className="col-1 text-center align-middle">Deletar</th>
						</tr>
					</thead>
					<tbody>
						{databasesList.map((database) => (
							<tr key={database.id}>
								<td className="text-center">
									<Database
										size={16}
										style={{ display: "inline-block", verticalAlign: "top" }}
									/>
									&nbsp;
									{database.database}
								</td>
								<td className="text-center">{database.username}</td>
								<td className="text-center">
									<input
										type="password"
										className="form-control-plaintext"
										readOnly
										key={`password ${database.id}`}
										value={database.password}
									/>
								</td>
								<td className="text-center">{database.host}</td>
								<td className="text-center">{database.port}</td>
								<td className="text-center">{database.dialect}</td>
								<td className="text-center">{database.storage}</td>
								<td className="text-center">
									<input
										type="checkbox"
										defaultChecked={database.isSshTunnel}
										disabled="disabled"
										key={database.id}
									/>
								</td>
								<td className="text-center">{database.sshUsername}</td>
								<td className="text-center">
									<input
										type="password"
										className="form-control-plaintext"
										readOnly
										key={`sshPrivateKey ${database.id}`}
										value={database.sshPrivateKey}
									/>
								</td>
								<td className="text-center">{database.sshHost}</td>
								<td className="text-center">
									<Button color="link" onClick={goToEditDatabase(database.id)}>
										<Edit size={18} />
									</Button>
								</td>
								<td className="text-center">
									<Button color="link" onClick={removeDatabase(database)}>
										<Trash2 size={18} />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Modal
				isOpen={modal.show}
				onClose={() => setModal({ show: false })}
				onAccept={removeDatabaseHandler}
				title="Remover Banco de Dados"
			>
				Tem certeza de que deseja excluir o banco de dados{" "}
				<Badge color="warning">{modal.database?.database}</Badge> ?
			</Modal>
		</Fragment>
	);
}

export default DatabasesTable;

// Revisar alterações em _tables.scss - dados-legais-admin/src/assets/scss/bootstrap/_tables.scss
