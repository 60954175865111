import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import Modal from "~/components/common/modal";
import validate from "~/components/common/validate";

import { getCompany } from "~/states/modules/company";
import { getApiInfo, editApiInfo } from "~/states/modules/apiInfo";
import apiInfoConstants from "~/states/modules/apiInfo/apiInfoConstants";

import IntegrationForm from "./otherIntegrationForm";

const OtherIntegrations = () => {
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [formData, setFormData] = useState(null);
	const [editedApiInfoFeedback, setEditedApiInfoFeedback] = useState(false);

	const apiInfo = useSelector(({ apiInfo }) => apiInfo.data);
	const apiInfoError = useSelector(({ apiInfo }) => apiInfo.error);
	const apiInfoStatus = useSelector(({ apiInfo }) => apiInfo.status);

	const company = useSelector(({ company }) => company.currentCompany.data);

	const { status } = apiInfoConstants;

	//Add a dispatch to a action that verifies if the company is connected with rd and stores that info in the store
	useEffect(() => {
		dispatch(getApiInfo());
		dispatch(getCompany());
	}, [dispatch]);

	useEffect(() => {
		if (editedApiInfoFeedback && apiInfoStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedApiInfoFeedback(false);
		} else if (editedApiInfoFeedback && apiInfoStatus === status.FAILED) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(apiInfoError);
		}
	}, [apiInfoStatus]);

	const handleSubmit = (input) => {
		if (!validate.anyNew(input, apiInfo)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormData(input);
			setModal(true);
		}
	};

	const handleEditApiInfo = () => {
		setModal(false);
		setEditedApiInfoFeedback(true);
		dispatch(editApiInfo(formData));
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						{company && (
							<IntegrationForm
								apiInfo={apiInfo || {}}
								onSubmit={handleSubmit}
								companyId={company.id}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				isOpen={modal}
				onClose={() => setModal(false)}
				onAccept={handleEditApiInfo}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações da sua empresa?
			</Modal>
		</>
	);
};

export default OtherIntegrations;
