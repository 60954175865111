import React, { useEffect, useState } from "react";
import { translate } from "../../../../assets/customPackages/react-switch-lang";

const TicketHistory = ({ ticketHistories, t }) => {
	const formatDate = (date) => {
		return new Date(date).toLocaleDateString("PT-br", {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});
	};

	return (
		<div className="table-responsive">
			<table className="table table-striped">
				<thead>
					<tr>
						<th>{t("TICKET_HISTORY.DATE")}</th>
						<th>{t("TICKET_HISTORY.OLD_STATUS")}</th>
						<th>{t("TICKET_HISTORY.NEW_STATUS")}</th>
						<th>{t("TICKET_HISTORY.USER")}</th>
					</tr>
				</thead>
				<tbody>
					{ticketHistories?.map((history, index) => {
						return (
							<tr key={index}>
								<td>
									{history.createdAt !== null
										? formatDate(history.createdAt)
										: ""}
								</td>
								<td>{t(`TICKET_TYPE.${history.statusOld}`)}</td>
								<td>{t(`TICKET_TYPE.${history.statusNew}`)}</td>
								<td>
									{(history.user && history.user.name) || t("SYSTEM")}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default translate(TicketHistory);
