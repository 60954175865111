import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Doughnut } from "react-chartjs-2";
import moment from "moment";

import { getTicketChart } from "~/states/modules/tickets";

import { getInfoBar, getcolors } from "../selectors";

export default function TicketTypeDouhnut({
	legend,
	dateRange,
	numberOfMonths,
}) {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: dateRange ? dateRange : "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);

	const infoBar = getInfoBar(ticketsForChart);

	const infoDoughnut = {
		labels: [],
		data: [],
	};
	const reducer = (accumulator, currentValue) => accumulator + currentValue;

	infoBar.dataset.forEach((info) => {
		infoDoughnut.labels.push(info.label);
		infoDoughnut.data.push(
			info.data
				.slice(0, numberOfMonths ? numberOfMonths : 1)
				.reduce(reducer)
		);
	});

	const doughnutData = {
		labels: infoDoughnut.labels,
		datasets: [
			{
				data: infoDoughnut.data,
				backgroundColor: getcolors().reverse(),
			},
		],
	};
	const doughnutOption = {
		maintainAspectRatio: false,
		legend: {
			display: legend ? legend : false,
		},
		plugins: {
			datalabels: {
				display: false,
				color: "white",
			},
		},
	};

	return (
		<div className="card">
			<div className="card-header">
				<div>
					<h5>Pedidos de {moment().format("MMMM")}</h5>
				</div>
				<div>
					<p>Pedidos do mês atual</p>
				</div>
			</div>
			<div className="card-body chart-block">
				<div className="carddoughnutData, doughnutOption,-body">
					<Doughnut
						data={doughnutData}
						options={doughnutOption}
						width={400}
						height={367}
					/>
				</div>
			</div>
		</div>
	);
}
