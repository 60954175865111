import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller } from "react-hook-form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ColorPicker from "~/components/common/colorPicker/index";
import colorPickerHexConversor from "~/components/common/colorPicker/colorPickerHexConversor";

import * as yup from "yup";
import useYupResolver from "../../../common/yupResolver";

const schema = yup.object().shape({
	supportEmail: yup
		.string()
		.email("Insira um e-mail válido")
		.required("Campo E-mail de Suporte é obrigatório"),
	bccSupportEmail: yup.string().email("Insira um e-mail válido"),
});

const EmailConfigForm = ({ onSubmit, emailConfig }) => {
	const { register, handleSubmit, errors, setValue, control } = useForm({
		defaultValues: emailConfig,
		mode: "onChange",
		resolver: useYupResolver(schema),
	});

	const onSubmitController = (input) => {
		// Pop out selected font since typeahead stores an array
		if (Array.isArray(input.textFont)) input.textFont = input.textFont[0];
		if (Array.isArray(input.supportEmailFont))
			input.supportEmailFont = input.supportEmailFont[0];
		onSubmit(input);
	};

	const defaultColor = "#000000";

	const [textColor, setTextColor] = useState(
		emailConfig?.textColor || defaultColor
	);

	const [supportEmailColor, setSupportEmailColor] = useState(
		emailConfig?.supportEmailColor || defaultColor
	);

	const [bgColor, setBgColor] = useState(
		emailConfig?.bgColor || defaultColor
	);

	const [backdropColor, setBackdropColor] = useState(
		emailConfig?.backdropColor || defaultColor
	);

	const [headerColor, setHeaderColor] = useState(
		emailConfig?.headerColor || defaultColor
	);

	return (
		<div className="tab-content active default" id="tab-1">
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(onSubmitController)}
			>
				<div className="card">
					<div className="card-body">
						<h5>E-mail de Suporte</h5>
						<p>
							Insira o endereço de e-mail para suporte ao cliente.
						</p>
						<div className="form-row">
							<label
								className="col-form-label"
								htmlFor="supportEmail"
							>
								E-mail
								<span className="auth--required-symbol">*</span>
							</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<i className="icofont icofont-ui-email"></i>
									</span>
								</div>
								<input
									className="form-control"
									id="supportEmail"
									type="text"
									ref={register}
									name="supportEmail"
								/>
							</div>
							<span className="auth--validation-alert">
								{errors.supportEmail?.message}
							</span>
						</div>
						<br />

						<h5>E-mail de Back Up</h5>
						<p>
							Insira aqui um e-mail que receberá as mensagens de
							atualização dos atendimentos das solicitações, com a
							finalidade de prover maior transparência e segurança
							através de um canal a mais, caso necessário.
							Recomendamos que seja utilizado um e-mail
							exclusivamente dedicado à tal finalidade.
						</p>
						<div className="form-row">
							<label
								className="col-form-label"
								htmlFor="bccSupportEmail"
							>
								E-mail
							</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<i className="icofont icofont-ui-email"></i>
									</span>
								</div>
								<input
									className="form-control"
									id="bccSupportEmail"
									type="text"
									ref={register}
									name="bccSupportEmail"
								/>
							</div>
							<span className="auth--validation-alert">
								{errors.bccSupportEmail?.message}
							</span>
						</div>

						{/************************************************************/}
						{/* <hr className="mt-4 mb-8" />

						<div className="form-row mt-3">
							{/* <div className="form-group col-6">
								<label
									onClick={() =>
										supportEmailFontTypeahead.current?.focus()
									}
								>
									Fonte
									<span className="auth--required-symbol">
										*
									</span>
								</label>
								<Controller
									render={(
										{ onChange, onBlur, value, name, ref },
										{ invalid, isTouched, isDirty }
									) => (
										<Typeahead
											id="supportEmailFont"
											defaultSelected={
												emailConfig.supportEmailFont
													? [
															emailConfig.supportEmailFont,
													  ]
													: []
											}
											clearButton
											options={fonts}
											maxResults={10}
											ref={supportEmailFontTypeahead}
											onChange={onChange}
											onBlur={onBlur}
										/>
									)}
									name="supportEmailFont"
									defaultValue=""
									control={control}
									onFocus={() =>
										supportEmailFontTypeahead.current?.focus()
									}
									rules={validate.requiredTypeahead()}
								/>
								<span className="auth--validation-alert">
									{errors.supportEmailFont?.message}
								</span>
							</div>
						</div> */}
						{/************************************************************/}
						<hr className="mt-4 mb-4" />
						<h5>Cores</h5>
						<p>
							Escolha as cores que vão compor o e-mail para todas
							as etapas de criação de pedido.
						</p>
						<div className="form-row">
							<div className="form-group col-md-4 col-sm-12">
								<Controller
									name="textColor"
									defaultValue={textColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do texto"
											color={textColor}
											onChange={(color) => {
												setTextColor(
													colorPickerHexConversor(
														color
													)
												);
												setValue(
													"textColor",
													colorPickerHexConversor(
														color
													)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="textColor"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</div>
							<div className="form-group col-md-4 col-sm-12">
								<Controller
									name="supportEmailColor"
									defaultValue={supportEmailColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do e-mail de suporte"
											color={supportEmailColor}
											onChange={(color) => {
												setSupportEmailColor(
													colorPickerHexConversor(
														color
													)
												);
												setValue(
													"supportEmailColor",
													colorPickerHexConversor(
														color
													)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="supportEmailColor"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</div>
							<div className="form-group col-md-4 col-sm-12">
								<Controller
									name="bgColor"
									defaultValue={bgColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do corpo do e-mail"
											color={bgColor}
											onChange={(color) => {
												setBgColor(
													colorPickerHexConversor(
														color
													)
												);
												setValue(
													"bgColor",
													colorPickerHexConversor(
														color
													)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="bgColor"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-4 col-sm-12">
								<Controller
									name="backdropColor"
									defaultValue={backdropColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor de plano de fundo"
											color={backdropColor}
											onChange={(color) => {
												setBackdropColor(
													colorPickerHexConversor(
														color
													)
												);
												setValue(
													"backdropColor",
													colorPickerHexConversor(
														color
													)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="backdropColor"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</div>
							<div className="form-group col-md-4 col-sm-12">
								<Controller
									name="headerColor"
									defaultValue={headerColor}
									control={control}
									rules={{ required: true }}
									render={() => (
										<ColorPicker
											description="Cor do cabeçalho"
											color={headerColor}
											onChange={(color) => {
												setHeaderColor(
													colorPickerHexConversor(
														color
													)
												);
												setValue(
													"headerColor",
													colorPickerHexConversor(
														color
													)
												);
											}}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name="headerColor"
									as={
										<span className="auth--validation-alert" />
									}
								/>
							</div>
						</div>
						{/************************************************************/}
						<hr className="mt-4 mb-4" />
						<h5>Alinhamentos</h5>
						{/* Rever esses textos */}
						<p>
							Escolha como deve ser o alinhamento da logo, texto e
							corpo do e-mail.
						</p>
						<div className="form-row">
							<div className="form-group col-md-4 col-sm-12">
								<label className="col-form-label">Texto</label>
								<div className="form-group m-t-15 custom-radio-ml mt-4">
									<div className="radio radio-primary">
										<input
											id="textAlign1"
											type="radio"
											name="textAlign"
											value="left"
											ref={register}
										/>
										<label htmlFor="textAlign1">
											Esquerda
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="textAlign2"
											type="radio"
											name="textAlign"
											value="center"
											ref={register}
										/>
										<label htmlFor="textAlign2">
											Centro
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="textAlign3"
											type="radio"
											name="textAlign"
											value="right"
											ref={register}
										/>
										<label htmlFor="textAlign3">
											Direita
										</label>
									</div>
								</div>
							</div>

							<div className="form-group col-md-4 col-sm-12">
								<label
									className="col-form-label"
									htmlFor="logoAlign1"
								>
									Logo
								</label>
								<div className="form-group m-t-15 custom-radio-ml mt-4">
									<div className="radio radio-primary">
										<input
											id="logoAlign1"
											type="radio"
											name="logoAlign"
											value="left"
											ref={register}
										/>
										<label htmlFor="logoAlign1">
											Esquerda
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="logoAlign2"
											type="radio"
											name="logoAlign"
											value="center"
											ref={register}
										/>
										<label htmlFor="logoAlign2">
											Centro
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="logoAlign3"
											type="radio"
											name="logoAlign"
											value="right"
											ref={register}
										/>
										<label htmlFor="logoAlign3">
											Direita
										</label>
									</div>
								</div>
							</div>
							<div className="form-group col-md-4 col-sm-12">
								<label
									className="col-form-label"
									htmlFor="exampleFormControlSelect9"
								>
									Corpo do E-mail
								</label>
								<div className="form-group m-t-15 custom-radio-ml mt-4">
									<div className="radio radio-primary">
										<input
											id="tabelAlign1"
											type="radio"
											name="tabelAlign"
											value="left"
											ref={register}
										/>
										<label htmlFor="tabelAlign1">
											Esquerda
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="tabelAlign2"
											type="radio"
											name="tabelAlign"
											value="center"
											ref={register}
										/>
										<label htmlFor="tabelAlign2">
											Centro
										</label>
									</div>
									<div className="radio radio-primary">
										<input
											id="tabelAlign3"
											type="radio"
											name="tabelAlign"
											value="right"
											ref={register}
										/>
										<label htmlFor="tabelAlign3">
											Direita
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer text-right">
						<button className="btn btn-primary" type="submit">
							Salvar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
export default EmailConfigForm;
