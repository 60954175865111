import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logoBlackAndLight from "~/assets/images/logo-black-and-white.png";
import "./style.css";

//import * as THREE from "three";
import VANTA_ANIMATION from "vanta/dist/vanta.net.min";

const AuthWrapper = ({ children }) => {
	const [vantaEffect, setVantaEffect] = useState(0);
	const vantaRef = useRef(null);

	const signed = useSelector(({ session }) => session.signed);
	const history = useHistory();

	useEffect(() => {
		if (!vantaEffect) {
			setVantaEffect(
				VANTA_ANIMATION({
					el: vantaRef.current,
					color: "#148491",
					backgroundColor: "#09323e",
					points: 10,
					maxDistance: 20,
					spacing: 15,
					showDots: true,
				})
			);
		}
		return () => {
			if (vantaEffect) vantaEffect.destroy();
		};
	}, [vantaEffect]);

	useEffect(() => {
		if(signed) {
			history.push(`${process.env.PUBLIC_URL}/`);
		}
	});

	return (
		<Fragment>
			<div className="page-wrapper">
				<div className="auth-bg-vanta" ref={vantaRef}>
					<div className="authentication-box">
						{/* BEGIN: logo */}
						<div className="text-center">
							<img src={logoBlackAndLight} alt="logo preto e branco" />
						</div>
						{/* END: logo */}
						<Fragment>{children}</Fragment>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AuthWrapper;
