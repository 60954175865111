import {
	call,
	put,
	takeEvery,
	takeLatest,
	delay,
	takeLeading,
} from "redux-saga/effects";

import api from "~/services/api";
import {
	getUsers,
	getUsersSuccess,
	getUsersFailure,
	getCurrentUser,
	getCurrentUserFailure,
	getCurrentUserSuccess,
	editCurrentUser,
	editCurrentUserFailure,
	editCurrentUserSuccess,
	editUser,
	editUserFailure,
	editUserSuccess,
	getUser,
	getUserFailure,
	getUserSuccess,
	removeUser,
	removeUserFailure,
	removeUserSuccess,
	createUser,
	createUserFailure,
	createUserSuccess,
	getCompanies,
	getCompaniesFailure,
	getCompaniesSuccess,
} from ".";

function* rootSaga() {
	yield takeEvery(getUsers, getUsersSaga);
	yield takeLatest(getCurrentUser, getCurrentUserSaga);
	yield takeEvery(editCurrentUser, editCurrentUserSaga);
	yield takeEvery(editUser, editUserSaga);
	yield takeEvery(getUser, getUserSaga);
	yield takeEvery(removeUser, removeUserSaga);
	yield takeEvery(createUser, createUserSaga);
	yield takeEvery(getCompanies, getCompaniesSaga);
}

function* getUsersSaga({ payload }) {
	try {
		const { data } = yield call(api.get, "/users");
		yield put(getUsersSuccess(data));
	} catch (error) {
		yield put(getUsersFailure(error.response?.data?.error));
	}
}

function* getCurrentUserSaga({ payload }) {
	try {
		yield delay(500);
		const { data } = yield call(api.get, "/users/me");
		yield put(getCurrentUserSuccess(data));
	} catch (error) {
		yield put(getCurrentUserFailure(error.response?.data?.error));
	}
}

function* editCurrentUserSaga({ payload }) {
	const { userId, data: body } = payload;
	try {
		const { data } = yield call(api.patch, `/users/${userId}`, body);
		yield put(editCurrentUserSuccess(data));
	} catch (error) {
		yield put(editCurrentUserFailure(error.response?.data?.error));
	}
}

function* editUserSaga({ payload }) {
	const { userId, data: body } = payload;
	try {
		const { data } = yield call(api.patch, `/users/${userId}`, body);
		yield put(editUserSuccess(data));
	} catch (error) {
		yield put(editUserFailure(error.response?.data?.error));
	}
}

function* getUserSaga({ payload }) {
	const { userId } = payload;
	try {
		const { data } = yield call(api.get, `/users/${userId}`);
		yield put(getUserSuccess(data));
	} catch (error) {
		yield put(getUserFailure(error.response?.data?.error));
	}
}

function* createUserSaga({ payload }) {
	try {
		const { data } = yield call(api.post, `/users`, payload);
		yield put(createUserSuccess(data));
	} catch (error) {
		yield put(createUserFailure(error.response?.data?.error));
	}
}

function* removeUserSaga({ payload }) {
	const { userId } = payload;
	try {
		const { data } = yield call(api.delete, `/users/${userId}`);
		yield put(removeUserSuccess(userId));
	} catch (error) {
		yield put(removeUserFailure(error.response?.data?.error));
	}
}

function* getCompaniesSaga() {
	try {
		yield delay(500);
		const { data } = yield call(api.get, `/users/me/companies`);
		yield put(getCompaniesSuccess(data));
	} catch (error) {
		yield put(getCompaniesFailure(error.response?.data?.error));
	}
}

export default rootSaga;
