import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Collapse } from "reactstrap";

import validate from "~/components/common/validate";
import {
	ckeditorRegexReplacer,
	editorFormatOnReady,
	configToolBar,
} from "~/components/common/ckeditorTools";

import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import ColorPicker from "~/components/common/colorPicker/index.js";
import colorPickerHexConversor from "~/components/common/colorPicker/colorPickerHexConversor";

const CookiebarForm = ({ onSubmit, sdkConfig }) => {
	const { register, handleSubmit, watch, setValue, errors, control } = useForm({
		defaultValues: sdkConfig,
	});

	const defaultColor = "#000000";

	const [cookiebarTxtColor, setCookiebarTxtColor] = useState(
		sdkConfig?.cookiebarTxtColor || defaultColor
	);

	const [cookiebarBgColor, setCookiebarBgColor] = useState(
		sdkConfig?.cookiebarBgColor || defaultColor
	);
	const [cookiebarBtnTxtColor, setCookiebarBtnTxtColor] = useState(
		sdkConfig?.cookiebarBtnTxtColor || defaultColor
	);
	const [cookiebarBtnColor, setCookiebarBtnColor] = useState(
		sdkConfig?.cookiebarBtnColor || defaultColor
	);
	const [cookiebarLinkColor, setCookiebarLinkColor] = useState(
		sdkConfig?.cookiebarLinkColor || defaultColor
	);

	const onSubmitWrapper = (data) => {
		if (!!data.cookiebarDescription) {
			// Remove whitespace from both sides of a string - Replace all 3 types of line breaks with a space - Replace all double white spaces with single spaces
			data.cookiebarDescription = ckeditorRegexReplacer(
				data.cookiebarDescription
			);
		}
		onSubmit(data);
	};

	const handleTemplateHtmlChange = (event, editor) =>
		setValue("cookiebarDescription", editor.getData());

	const requiredIfFieldIsOpen = (isOpen) =>
		isOpen ? validate.required() : validate.notRequired();

	const watchedSwitch = watch("cookiebar");
	return (
		<div className="tab-content active default" id="tab-1">
			<form
				className="theme-form mega-form "
				onSubmit={handleSubmit(onSubmitWrapper)}
			>
				<div className="card">
					<div className="card-body">
						<div className="form-row">
							<div className="checkbox checkbox-primary col-12">
								<input
									name="cookiebar"
									id="cookiebar"
									type="checkbox"
									ref={register}
								/>
								<label htmlFor="cookiebar">Ativar Barra de Cookies</label>
							</div>
						</div>
						<hr className="mt-4 mb-4" />
						<Collapse isOpen={watchedSwitch}>
							<h5 className="mb-4">Geral</h5>
							<p>
								Adicione os textos para a descrição da sua barra de cookies, a
								url da Política de Privacidade da empresa, o texto para o botão
								de confirmação e o texto para o link da Política de Privacidade.
							</p>
							<div className="form-row">
								<div className="form-group col-12">
									<label
										htmlFor="cookiebarDescriptionEditor"
										className="col-form-label"
									>
										Descrição
										<span className="auth--required-symbol">*</span>
									</label>
									<Controller
										name="cookiebarDescription"
										defaultValue=""
										control={control}
										rules={requiredIfFieldIsOpen(watchedSwitch)}
										render={({ name }) => (
											<CKEditorWrappered
												id="cookiebarDescription"
												name={name}
												data={sdkConfig.cookiebarDescription}
												onChange={handleTemplateHtmlChange}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="cookiebarDescription"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
								<div className="form-group col-12">
									<label className="col-form-label" htmlFor="cookiebarLinkTxt">
										Texto para Política de Privacidade
									</label>
									<span className="auth--required-symbol">*</span>
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="icofont icofont-paper"></i>
											</span>
										</div>
										<input
											className="form-control"
											id="cookiebarLinkTxt"
											name="cookiebarLinkTxt"
											type="text"
											ref={register(requiredIfFieldIsOpen(watchedSwitch))}
										/>
									</div>
									<ErrorMessage
										errors={errors}
										name="cookiebarLinkTxt"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
							<hr className="mt-4 mb-4" />
							<h5 className="mb-4">Cores</h5>
							<p>
								Escolha as cores da barra de cookies por meio da cor para o
								texto da descrição, cor de fundo, do texto e fundo do botão de
								confirmação, e do texto da Política de Privacidade.
							</p>

							<div className="form-row">
								<div className="form-group col-md-4">
									<Controller
										name="cookiebarTxtColor"
										defaultValue={cookiebarTxtColor}
										control={control}
										rules={{
											required: true,
										}}
										render={({ value }) => (
											<ColorPicker
												description="Texto da descrição"
												color={value}
												onChange={(color) => {
													setCookiebarTxtColor(colorPickerHexConversor(color));
													setValue(
														"cookiebarTxtColor",
														colorPickerHexConversor(color)
													);
												}}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="cookiebarTxtColor"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
								<div className="form-group col-md-4">
									<Controller
										name="cookiebarBgColor"
										defaultValue={cookiebarBgColor}
										control={control}
										rules={{
											required: true,
										}}
										render={() => (
											<ColorPicker
												description="Fundo da descrição"
												color={cookiebarBgColor}
												onChange={(color) => {
													setCookiebarBgColor(colorPickerHexConversor(color));
													setValue(
														"cookiebarBgColor",
														colorPickerHexConversor(color)
													);
												}}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="cookiebarBgColor"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
								<div className="form-group col-md-4">
									<Controller
										name="cookiebarBtnTxtColor"
										defaultValue={cookiebarBtnTxtColor}
										control={control}
										rules={{
											required: true,
										}}
										render={() => (
											<ColorPicker
												description="Texto do botão"
												color={cookiebarBtnTxtColor}
												onChange={(color) => {
													setCookiebarBtnTxtColor(
														colorPickerHexConversor(color)
													);
													setValue(
														"cookiebarBtnTxtColor",
														colorPickerHexConversor(color)
													);
												}}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="btnTextColor"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-4">
									<Controller
										name="cookiebarBtnColor"
										defaultValue={cookiebarBtnColor}
										control={control}
										rules={{
											required: true,
										}}
										render={() => (
											<ColorPicker
												description="Fundo do botão"
												color={cookiebarBtnColor}
												onChange={(color) => {
													setCookiebarBtnColor(colorPickerHexConversor(color));
													setValue(
														"cookiebarBtnColor",
														colorPickerHexConversor(color)
													);
												}}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="btnBgColor"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
								<div className="form-group col-md-4">
									<Controller
										name="cookiebarLinkColor"
										defaultValue={cookiebarLinkColor}
										control={control}
										rules={{
											required: true,
										}}
										render={({ value }) => (
											<ColorPicker
												description="Política de Privacidade"
												color={cookiebarLinkColor}
												onChange={(color) => {
													setCookiebarLinkColor(colorPickerHexConversor(color));
													setValue(
														"cookiebarLinkColor",
														colorPickerHexConversor(color)
													);
												}}
											/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="cookiebarLinkColor"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
							<hr className="mt-4 mb-4" />
							<h5 className="mb-4">Alinhamento</h5>
							<p>
								Escolha o alinhamento para o campo da descrição da barra de
								cookies.
							</p>
							<div className="form-row">
								<div className="form-group col-12">
									<label className="col-form-label">Descrição</label>
									<span className="auth--required-symbol">*</span>

									<div className="form-group m-checkbox-inline custom-radio-ml">
										<div className="radio radio-primary col-md-12">
											<input
												id="cookiebarTxtAlign1"
												type="radio"
												name="cookiebarTxtAlign"
												value="left"
												ref={register}
											/>
											<label htmlFor="cookiebarTxtAlign1">Esquerda</label>
										</div>
										<div className="radio radio-primary col-md-12">
											<input
												id="cookiebarTxtAlign2"
												type="radio"
												name="cookiebarTxtAlign"
												value="center"
												ref={register(requiredIfFieldIsOpen(watchedSwitch))}
											/>
											<label htmlFor="cookiebarTxtAlign2">Centro</label>
										</div>
										<div className="radio radio-primary col-md-12">
											<input
												id="cookiebarTxtAlign3"
												type="radio"
												name="cookiebarTxtAlign"
												value="right"
												ref={register}
											/>
											<label htmlFor="cookiebarTxtAlign3">Direita</label>
										</div>
									</div>
									<ErrorMessage
										errors={errors}
										name="cookiebarTxtAlign"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
								<div className="form-group col-md-6">
									<label className="col-form-label">
										Posição da Barra de Cookies
									</label>
									<span className="auth--required-symbol">*</span>

									<div className="form-group m-checkbox-inline custom-radio-ml">
										<div className="radio radio-primary col-md-12">
											<input
												id="cookiebarPosition1"
												type="radio"
												name="cookiebarPosition"
												value="left"
												ref={register(requiredIfFieldIsOpen(watchedSwitch))}
											/>
											<label htmlFor="cookiebarPosition1">Esquerda</label>
										</div>
										<div className="radio radio-primary col-md-12">
											<input
												id="cookiebarPosition2"
												type="radio"
												name="cookiebarPosition"
												value="right"
												ref={register}
											/>
											<label htmlFor="cookiebarPosition2">Direita</label>
										</div>
									</div>
									<ErrorMessage
										errors={errors}
										name="cookiebarPosition"
										as={<span className="auth--validation-alert" />}
									/>
								</div>
							</div>
						</Collapse>
					</div>

					<div className="card-footer text-right">
						<button className="btn btn-primary" type="submit">
							Salvar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
export default CookiebarForm;
