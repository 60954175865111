import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

import {
	selectAllCompanyTags,
	selectTags,
	selectAllConsents,
} from "./selectors";

import companyConstants from "~/states/modules/company/companyConstants";

const { TO_EDIT, EDITED, FAILED } = companyConstants.status;

const initialState = {
	createdCompany: { data: null, loading: false, error: null },
	currentCompany: { data: null, status: null, loading: false, error: null },
	newTags: { status: null, error: null, loading: false, data: [] },
	scannedTags: { error: null, loading: false, data: [] },
	removedTags: { error: null, loading: false, data: [] },
	deleteTags: { error: null, loading: false, data: [] },
	consents: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: [{ id: "updatedAt", desc: true }],
			filters: [],
		},
	},
	tags: { error: null, loading: false, data: [] },
	importedClientConsent: {
		data: { rowsInserted: [], rowsRejected: [] },
		loading: false,
		error: null,
	},
};

const { actions, reducer } = createSlice({
	name: "company",
	initialState,
	reducers: {
		getCompany: (state) => state,
		getCompanyFailure: (state) => state,
		getCompanySuccess: (state, { payload }) => {
			extend(state, {
				currentCompany: { data: payload, loading: false, error: null },
			});
		},

		editCompany: (state) =>
			extend(state, {
				currentCompany: {
					data: null,
					status: TO_EDIT,
					loading: true,
					error: null,
				},
			}),
		editCompanyFailure: (state, { payload }) =>
			extend(state, {
				currentCompany: {
					data: null,
					status: FAILED,
					loading: false,
					error: payload,
				},
			}),
		editCompanySuccess: (state, { payload }) =>
			extend(state, {
				currentCompany: {
					data: payload,
					status: EDITED,
					loading: false,
					error: null,
				},
			}),

		scanCompanyTags: (state) =>
			extend(state, {
				scannedTags: { loading: true },
			}),
		scanCompanyTagsFailure: (state, { payload }) =>
			extend(state, {
				scannedTags: {
					loading: false,
					error: payload,
				},
			}),
		scanCompanyTagsSuccess: (state, { payload }) =>
			extend(state, {
				scannedTags: {
					data: selectAllCompanyTags(payload.data.cookies),
					loading: false,
				},
				removedTags: {
					data: selectTags(payload.data.removedCookies),
				},
			}),

		createTags: (state) =>
			extend(state, {
				newTags: { error: null, loading: true },
			}),
		createTagsFailure: (state, { payload }) =>
			extend(state, {
				newTags: { error: payload, loading: false },
			}),
		createTagsSuccess: (state, { payload }) =>
			extend(state, {
				newTags: { data: payload.data, loading: false },
			}),

		getTags: (state) =>
			extend(state, {
				tags: { error: null, loading: true },
			}),
		getTagsFailure: (state, { payload }) =>
			extend(state, {
				tags: { loading: false, error: payload },
			}),
		getTagsSuccess: (state, { payload }) =>
			extend(state, {
				tags: { loading: false, data: selectTags(payload.data) },
			}),

		removeTags: (state) =>
			extend(state, {
				deleteTags: { error: null, loading: true },
			}),
		removeTagsFailure: (state, { payload }) =>
			extend(state, {
				deleteTags: {
					error: payload,
					loading: false,
				},
			}),

		getConsents: (state, { payload }) =>
			extend(state, {
				consents: {
					...state.consents,
					tableParams: payload,
					error: null,
					loading: true,
				},
			}),
		getConsentsFailure: (state, { payload }) =>
			extend(state, {
				consents: {
					...state.consents,
					error: payload,
					loading: false,
				},
			}),
		getConsentsSuccess: (state, { payload }) => {
			extend(state, {
				consents: {
					...state.consents,
					error: null,
					loading: false,
					data: selectAllConsents(payload.data),
					totalCount: payload.meta.totalCount,
				},
			});
		},

		createCompany: (state) =>
			extend(state, {
				createdCompany: { data: null, loading: true, error: null },
			}),
		createCompanySuccess: (state, { payload }) =>
			extend(state, {
				createdCompany: { data: payload, loading: false, error: null },
			}),
		createCompanyFailure: (state, { payload }) =>
			extend(state, {
				createdCompany: {
					loading: false,
					error: payload,
				},
			}),

		eraseCreatedCompany: (state) =>
			extend(state, {
				createdCompany: { data: null, loading: false, error: null },
			}),

		importClientConsent: (state) => {
			extend(state, {
				importedClientConsent: {
					error: null,
					loading: true,
					data: null,
				},
			});
		},
		importClientConsentSuccess: (state, { payload }) => {
			extend(state, {
				importedClientConsent: {
					error: null,
					loading: false,
					data: payload,
				},
			});
		},
		importClientConsentFailure: (state, { payload }) => {
			extend(state, {
				importedClientConsent: {
					data: null,
					loading: false,
					error: payload,
				},
			});
		},
	},
});

export const {
	getCompany,
	getCompanySuccess,
	getCompanyFailure,
	editCompany,
	editCompanyFailure,
	editCompanySuccess,
	scanCompanyTags,
	scanCompanyTagsSuccess,
	scanCompanyTagsFailure,
	createTags,
	createTagsSuccess,
	createTagsFailure,
	getTags,
	getTagsSuccess,
	getTagsFailure,
	removeTags,
	removeTagsSuccess,
	removeTagsFailure,
	getConsents,
	getConsentsFailure,
	getConsentsSuccess,
	createCompany,
	createCompanySuccess,
	createCompanyFailure,
	eraseCreatedCompany,
	importClientConsent,
	importClientConsentSuccess,
	importClientConsentFailure,
} = actions;

export { default as companySaga } from "./sagas";

export default reducer;
