import React, { Fragment, useState } from "react";
import { Button, Tooltip } from "reactstrap";

const getId = (function () {
	let i = 0;
	return () => ++i;
})();

const id = `btn-add-${getId()}`;

const AddButton = ({ onClick, toolTipMsg }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<Fragment>
			<Button
				id={id}
				color="primary"
				onClick={onClick || (() => null)}
			>
				<i className={`fa fa-plus fa-lg`}></i>
			</Button>
			{toolTipMsg && (
				<Tooltip
					target={id}
					placement="top"
					isOpen={tooltipOpen}
					toggle={toggle}
				>
					{toolTipMsg}
				</Tooltip>
			)}
		</Fragment>
	);
};

export default AddButton;
