import React, { useState } from "react";
import { Eye } from "react-feather";
import moment from "moment";
import ViewHTMLContentModal from "~/components/common/viewHTMLContentModal";

const EyeButton = ({ onClick }) => {
	return (
		<>
			<button onClick={onClick} className="btn btn-link primary-color">
				<Eye />
			</button>
		</>
	);
};

function PolicyHistoryCard({ histories }) {
	const [isContentModalOpen, setIsContentModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalTitle, setModalTitle] = useState("");

	const HistoriesTable = () => {
		return (
			<table className="table table-striped">
				<thead>
					<tr>
						<th>Versão</th>
						<th>Nome do autor</th>
						<th>Email do autor</th>
						<th>Data da criação</th>
						<th>E-mail</th>
						<th>Conteúdo</th>
						<th>Visibilidade</th>
					</tr>
				</thead>
				<tbody>
					{histories.map((history, index) => {
						return (
							<tr key={index}>
								<td>
									{history.majorVersion}.{history.minorVersion}
								</td>
								<td>{history.author.name}</td>
								<td>{history.author.email}</td>
								<td>{moment(history.createdAt).format("DD/MM/YYYY HH:mm")}</td>
								<td>
									<EyeButton
										onClick={() => {
											setIsContentModalOpen(true);
											setModalContent(history.emailMessage);
											setModalTitle(history.emailSubject);
										}}
									/>
								</td>
								<td>
									<EyeButton
										onClick={() => {
											setIsContentModalOpen(true);
											setModalContent(history.content);
											setModalTitle(history.name);
										}}
									/>
								</td>
								<td>
									{history.visibility === "PUBLIC" ? "Pública" : "Privada"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	return (
		<>
			<div className="card">
				<div className="card-header">
					<h4>Histórico da Política</h4>
				</div>
				<div className="card-body">
					{histories.length !== 0 ? (
						<HistoriesTable />
					) : (
						<p>Essa política ainda não recebeu atualizações.</p>
					)}
				</div>
			</div>
			<ViewHTMLContentModal
				isOpen={isContentModalOpen}
				onClose={() => {
					setIsContentModalOpen(false);
					setModalContent("");
					setModalTitle("");
				}}
				content={modalContent}
				title={modalTitle}
			/>
		</>
	);
}

export default PolicyHistoryCard;
