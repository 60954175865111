import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Breadcrumb from "~/components/common/breadcrumb";

import { getCompany, editCompany } from "~/states/modules/company";
import { getEmailConfig, editEmailConfig } from "~/states/modules/emailConfig";
import {
	getEmailTemplates,
	editEmailTemplate,
	deleteEmailTemplate,
} from "~/states/modules/emailTemplates";
import { getAllFonts } from "~/states/modules/fonts";

import { getAppConfig, editAppConfig } from "~/states/modules/appConfig";

import AuthQuestionsForm from "./authQuestionsForm";
import EmailConfigForm from "./emailConfigForm";
import RequestSelectionForm from "./requestSelectionForm";
import EmailTemplateForm from "./emailTemplateForm";

import AppSelectionForm from "./appSelectionForm";

import AccessControl from "~/components/common/accessControl";

import Modal from "~/components/common/modal";
import validate from "~/components/common/validate";

import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

import companyConstants from "~/states/modules/company/companyConstants";

const Company = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompany());
		dispatch(getEmailConfig());
		dispatch(getEmailTemplates());
		dispatch(getAllFonts());
		dispatch(getAppConfig());
	}, [dispatch]);

	const [formInput, setFormInput] = useState(null);

	//Fonts
	const { data: fonts } = useSelector(({ fonts }) => fonts.allFonts);

	/////////////////////////// Company ///////////////////////////
	const [companyModal, setCompanyModal] = useState(false);
	const company = useSelector(({ company }) => company.currentCompany.data);
	const companyError = useSelector(
		({ company }) => company.currentCompany.error
	);
	const currentCompanyStatus = useSelector(
		({ company }) => company.currentCompany.status
	);
	const [editedCompanyFeedback, setEditedCompanyFeedback] = useState(false);
	const { status } = companyConstants;

	useEffect(() => {
		if (editedCompanyFeedback && currentCompanyStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedCompanyFeedback(false);
		} else if (
			editedCompanyFeedback &&
			currentCompanyStatus === status.FAILED
		) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(companyError);
		}
	}, [currentCompanyStatus]);

	const handleEditCompany = () => {
		setCompanyModal(false);
		setRequestModal(false);
		setEditedCompanyFeedback(true);

		dispatch(editCompany(formInput));
	};
	///////////////////////// "App" - > AppConfig e AuthQuestionsForm ///////////////////////////

	const {
		data: appConfig,
		loading: appConfigLoading,
		error: appConfigError,
	} = useSelector(({ appConfig }) => appConfig.current);

	const [appConfigModal, setAppConfigModal] = useState(false);
	const [editedAppConfigFeedback, setEditedAppConfigFeedback] =
		useState(false);

	useEffect(() => {
		if (editedAppConfigFeedback && !appConfigLoading) {
			if (appConfigError) {
				toast.error("Erro ao Registrar Alterações");
				console.log(appConfigError);
				setEditedCompanyFeedback(false);
			} else {
				toast.success("Informações Editadas com Sucesso");
				setEditedCompanyFeedback(false);
			}
		}
	}, [appConfigLoading]);

	const handleSubmitAppConfig = (input) => {
		if (appConfig && !validate.anyNew(input, appConfig)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setAppConfigModal(true);
		}
	};

	const handleSubmitAuthQuestions = (input) => {
		setFormInput(input);
		setCompanyModal(true);
	};

	const handleEditAppConfig = () => {
		setAppConfigModal(false);
		setRequestModal(false);
		setEditedAppConfigFeedback(true);
		dispatch(editAppConfig(formInput));
	};

	/////////////////////////// "Email" -> EmailConfig ///////////////////////////
	const [emailConfigModal, setEmailConfigModal] = useState(false);
	const emailConfig = useSelector(({ emailConfig }) => emailConfig.data);
	const emailConfigError = useSelector(
		({ emailConfig }) => emailConfig.error
	);
	const emailConfigStatus = useSelector(
		({ emailConfig }) => emailConfig.status
	);
	const [editedEmailConfigFeedback, setEditedEmailConfigFeedback] =
		useState(false);

	useEffect(() => {
		if (editedEmailConfigFeedback && emailConfigStatus === status.EDITED) {
			toast.success("Informações Editadas com Sucesso");
			setEditedEmailConfigFeedback(false);
		} else if (
			editedEmailConfigFeedback &&
			emailConfigStatus === status.FAILED
		) {
			toast.error("Um Erro Inesperado Ocorreu");
			console.log(emailConfigError);
		}
	}, [emailConfigStatus]);

	const handleSubmitEmailConfig = (input) => {
		if (!validate.anyNew(input, emailConfig)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setEmailConfigModal(true);
		}
	};

	const handleEditEmailConfig = () => {
		setEmailConfigModal(false);
		setEditedEmailConfigFeedback(true);
		dispatch(editEmailConfig(formInput));
	};

	/////////////////////////// "Templates de Email" -> EmailTemplates ///////////////////////////
	const [editEmailTemplatesModal, setEditEmailTemplatesModal] =
		useState(false);
	const [resetEmailTemplatesModal, setResetEmailTemplatesModal] =
		useState(false);
	const emailTemplates = useSelector(
		({ emailTemplates }) => emailTemplates.all
	);
	const editedEmailTemplate = useSelector(
		({ emailTemplates }) => emailTemplates.edited
	);

	const deletedEmailTemplate = useSelector(
		({ emailTemplates }) => emailTemplates.deleted
	);

	const [editedEmailTemplatesFeedback, setEditedEmailTemplatesFeedback] =
		useState(false);

	const [resetedEmailTemplatesFeedback, setResetedEmailTemplatesFeedback] =
		useState(false);

	useEffect(() => {
		if (
			editedEmailTemplatesFeedback &&
			!editedEmailTemplate.loading &&
			!editedEmailTemplate.error
		) {
			toast.success("Template Editado com Sucesso");
			setEditedEmailTemplatesFeedback(false);
		} else if (
			editedEmailTemplatesFeedback &&
			!editedEmailTemplate.loading &&
			editedEmailTemplate.error
		) {
			toast.error("Um Erro Inesperado Ocorreu");
			setEditedEmailTemplatesFeedback(false);
			console.log(editedEmailTemplate.error);
		}
	}, [editedEmailTemplatesFeedback, editedEmailTemplate]);

	useEffect(() => {
		if (
			resetedEmailTemplatesFeedback &&
			!deletedEmailTemplate.loading &&
			!deletedEmailTemplate.error
		) {
			toast.success("Template Resetado com Sucesso");
			setResetedEmailTemplatesFeedback(false);
		} else if (
			resetedEmailTemplatesFeedback &&
			!deletedEmailTemplate.loading &&
			deletedEmailTemplate.error
		) {
			if (deletedEmailTemplate.error.status == 404)
				toast.warning(
					"Esse template já se encontra em seu formato original e portanto não pode ser resetado."
				);
			else toast.error("Um Erro Inesperado Ocorreu");
			setResetedEmailTemplatesFeedback(false);
			console.log(deletedEmailTemplate.error.message);
		}
	}, [resetedEmailTemplatesFeedback, deletedEmailTemplate]);

	const handleSubmitEmailTemplate = (input) => {
		const currentEmailTemplate = emailTemplates.data.find(
			({ name }) => name == input.name
		);
		// Since first templates were saved in the db with text null, in order to verify if there are any new values, we must replace the empty string with a null value
		if (!input.text) input.text = null;

		if (!validate.anyNew(input, currentEmailTemplate)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setEditEmailTemplatesModal(true);
		}
	};

	const handleEditEmailTemplate = () => {
		setEditEmailTemplatesModal(false);
		setEditedEmailTemplatesFeedback(true);
		dispatch(editEmailTemplate(formInput));
	};

	const handleResetEmailTemplate = (emailTemplate) => {
		setFormInput(emailTemplate);
		setResetEmailTemplatesModal(true);
	};

	const handleDeleteEmailTemplate = () => {
		setResetEmailTemplatesModal(false);
		setResetedEmailTemplatesFeedback(true);
		dispatch(deleteEmailTemplate(formInput));
	};

	/////////////////////////// "Lista de Opções" -> OfferedRequests ///////////////////////////
	const [requestModal, setRequestModal] = useState(false);

	const handleSubmitRequestSelection = (input) => {
		if (!validate.anyNew(input, company)) {
			toast.warning("Nenhum Valor a Ser Alterado");
		} else {
			setFormInput(input);
			setRequestModal(true);
		}
	};

	return (
		<>
			<Breadcrumb
				parent="Empresa"
				current="Configurações"
				title="Configurações"
			/>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="row theme-tab">
							<Tabs className="col-sm-12">
								<TabList className="tabs tab-title">
									<Tab>App</Tab>
									<Tab>Email</Tab>
									<Tab>Lista de Opções</Tab>
									<AccessControl
										requiredPermissions={[
											"emailTemplate:upsert",
											"emailTemplate:delete",
										]}
										options={{ every: false }}
									>
										<Tab>Templates de Email</Tab>
									</AccessControl>
								</TabList>
								<div className="tab-content-cls">
									<TabPanel>
										{company && (
											<AuthQuestionsForm
												enableAuthQuestions={
													company.enableAuthQuestions
												}
												company={company}
												companyAuthQuestions={
													(company.authQuestions &&
														[
															...company.authQuestions,
														].sort(
															(
																{
																	order: orderA,
																},
																{
																	order: orderB,
																}
															) => orderA - orderB
														)) ||
													[]
												}
												onSubmit={
													handleSubmitAuthQuestions
												}
											/>
										)}
										{!appConfigLoading && fonts && (
											<AppSelectionForm
												fonts={fonts}
												appConfig={appConfig}
												onSubmit={handleSubmitAppConfig}
											/>
										)}
									</TabPanel>
									<TabPanel>
										{company && emailConfig && fonts && (
											<EmailConfigForm
												emailConfig={emailConfig}
												onSubmit={
													handleSubmitEmailConfig
												}
												fonts={fonts}
											/>
										)}
									</TabPanel>
									<TabPanel>
										{company && (
											<RequestSelectionForm
												company={company}
												onSubmit={
													handleSubmitRequestSelection
												}
											/>
										)}
									</TabPanel>
									<AccessControl
										requiredPermissions={[
											"emailTemplate:upsert",
											"emailTemplate:delete",
										]}
										options={{ every: false }}
									>
										<TabPanel>
											{emailTemplates && (
												<EmailTemplateForm
													emailTemplates={
														emailTemplates.data
													}
													onSubmit={
														handleSubmitEmailTemplate
													}
													resetTemplate={
														handleResetEmailTemplate
													}
												/>
											)}
										</TabPanel>
									</AccessControl>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={companyModal}
				onClose={() => setCompanyModal(false)}
				onAccept={handleEditCompany}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações da sua empresa?
			</Modal>
			<Modal
				isOpen={emailConfigModal}
				onClose={() => setEmailConfigModal(false)}
				onAccept={handleEditEmailConfig}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações da sua empresa?
			</Modal>
			<Modal
				isOpen={requestModal}
				onClose={() => setRequestModal(false)}
				onAccept={handleEditCompany}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações da sua empresa?
			</Modal>
			<Modal
				isOpen={appConfigModal}
				onClose={() => setAppConfigModal(false)}
				onAccept={handleEditAppConfig}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações do seu
				aplicativo de direitos?
			</Modal>
			<Modal
				isOpen={editEmailTemplatesModal}
				onClose={() => setEditEmailTemplatesModal(false)}
				onAccept={handleEditEmailTemplate}
				title="Editar"
			>
				Tem certeza de que deseja editar as informações do seu template?
			</Modal>
			<Modal
				isOpen={resetEmailTemplatesModal}
				onClose={() => setResetEmailTemplatesModal(false)}
				onAccept={handleDeleteEmailTemplate}
				title="Resetar"
			>
				Tem certeza de que deseja resetar as informações do seu
				template? Ele retornará ao seu formato original e a configuração
				atual
				<font color="blue">
					<b> não poderá ser recuperada.</b>
				</font>
			</Modal>
		</>
	);
};

export default Company;
