import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
//import AlertMessage from "../common/alertMessage";
import SweetAlert from "react-bootstrap-sweetalert";
import validate from "../../common/validate";
import sessionConstants from "~/states/modules/session/sessionConstants";

import { forgetPassword, setStatus, setError } from "~/states/modules/session";

const ForgetPassword = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { handleSubmit, register, errors } = useForm({ mode: "onChange" });

	const [forgetPasswordMessage, setforgetPasswordMessage] = useState(null);

	const error = useSelector(({ session }) => session.error);
	const currentStatus = useSelector(({ session }) => session.status);
	const { errorSources, status } = sessionConstants;

	useEffect(() => {
		if (error && error.source === errorSources.FORGET_PASSWORD) {
			console.log("error: ", error.message);
			setforgetPasswordMessage({
				type: "danger",
				text:
					error.response?.status === 404
						? "Email não Encontrado"
						: "Um Erro Inesperado Ocorreu",
			});
			dispatch(setError(null));
		}
	}, [error, errorSources]);

	useEffect(() => {
		if (currentStatus === status.EMAIL_SENT) {
			setforgetPasswordMessage({
				type: "success",
				text: "Email enviado com sucesso",
			});
			dispatch(setStatus(null));
		}
	}, [currentStatus, status]);

	const onSubmit = (data, e) => {
		dispatch(
			forgetPassword({
				email: data.email,
			})
		);
	};

	const onClick = () => {
		history.push("/login");
	};

	const hideAlert = () => setforgetPasswordMessage(null);

	const onConfirm = () => {
		hideAlert();
		history.push("/login");
	};

	return (
		<Fragment>
			<div className="card mt-4 auth--card">
				<div className="card-body">
					<div className="text-center">
						<h4>RECUPERAÇÃO DE CONTA</h4>
						<h6>
							Enviaremos um email para prosseguir com o processo.
						</h6>
					</div>
					<br />
					<form
						className="theme-form"
						noValidate=""
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="form-group">
							<label
								htmlFor="emailInput"
								className="col-form-label pt-0"
							>
								Email
								<span className="auth--required-symbol">*</span>
							</label>
							<input
								className="form-control"
								type="email"
								name="email"
								id="emailInput"
								placeholder="you@yourwebsite.com"
								ref={register(validate.email())}
							/>
							<span className="auth--validation-alert">
								{errors.email?.message}
							</span>
						</div>
						<div className="form-group form-row mt-3 mb-0">
							<button
								className="btn btn-primary btn-block"
								type="submit"
							>
								Enviar
							</button>
						</div>
						<br />
						<div className="text-center">
							<button
								className="btn btn-link btn-block text-capitalize"
								onClick={onClick}
							>
								Voltar para Login
							</button>
						</div>
					</form>
				</div>
			</div>
			<SweetAlert
				show={
					forgetPasswordMessage &&
					forgetPasswordMessage.type === "success"
				}
				type={"success"}
				title={"Email Enviado com Sucesso"}
				onConfirm={onConfirm}
			></SweetAlert>
			<SweetAlert
				show={
					forgetPasswordMessage &&
					forgetPasswordMessage.type === "danger"
				}
				type={"danger"}
				title={forgetPasswordMessage?.text || "Dummy Text"}
				onConfirm={hideAlert}
			></SweetAlert>
		</Fragment>
	);
};

export default ForgetPassword;
