import { call, put, takeEvery, delay } from "redux-saga/effects";
import api from "~/services/api";
import {
	getCookiesCategories,
	getCookiesCategoriesSuccess,
	getCookiesCategoriesFailure,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getCookiesCategories, getCookiesCategoriesSaga);
}

function* getCookiesCategoriesSaga() {
	try {
		yield delay(500);
		const { data } = yield call(api.get, `/cookies-category`);
		yield put(getCookiesCategoriesSuccess(data));
	} catch (error) {
		yield put(getCookiesCategoriesFailure(error.response?.data?.error));
	}
}
