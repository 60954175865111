import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	list: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 5,
			// sortBy: [{ id: "name", desc: false }],
			// filters: [],
		},
	},
	createdPolicy: {
		error: null,
		loading: false,
		data: null,
	},
	updatedPolicy: {
		error: null,
		loading: false,
		data: null,
	},
	policy: {
		error: null,
		loading: false,
		data: null,
	},
};

const { actions, reducer } = createSlice({
	name: "policies",
	initialState,
	reducers: {
		getPolicies: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					tableParams: payload,
					error: null,
					loading: true,
				},
			}),

		getPoliciesFailure: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					error: payload,
					loading: false,
				},
			}),
		getPoliciesSuccess: (state, { payload }) =>
			extend(state, {
				list: {
					...state.list,
					error: null,
					loading: false,
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			}),

		getPolicyById: (state, { payload }) =>
			extend(state, {
				policy: {
					...state.policy,
					error: null,
					loading: true,
				},
			}),
		getPolicyByIdFailure: (state, { payload }) =>
			extend(state, {
				policy: {
					...state.policy,
					loading: false,
					error: payload,
				},
			}),
		getPolicyByIdSuccess: (state, { payload }) =>
			extend(state, {
				policy: {
					...state.policy,
					error: null,
					loading: false,
					data: payload.data,
				},
			}),

		createPolicy: (state) =>
			extend(state, {
				createdPolicy: {
					error: null,
					loading: true,
				},
			}),
		createPolicySuccess: (state, { payload }) =>
			extend(state, {
				createdPolicy: {
					...state.createdPolicy,
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		createPolicyFailure: (state, { payload }) =>
			extend(state, {
				createdPolicy: {
					...state.createdPolicy,
					loading: false,
					error: payload,
				},
			}),

		updatePolicy: (state) =>
			extend(state, {
				updatedPolicy: {
					...state.updatedPolicy,
					error: null,
					loading: true,
				},
			}),
		updatePolicySuccess: (state, { payload }) =>
			extend(state, {
				updatedPolicy: {
					...state.updatedPolicy,
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		updatePolicyFailure: (state, { payload }) =>
			extend(state, {
				updatedPolicy: {
					...state.updatedPolicy,
					loading: false,
					error: payload,
				},
			}),
	},
});

export const {
	getPolicies,
	getPoliciesSuccess,
	getPoliciesFailure,
	createPolicy,
	createPolicySuccess,
	createPolicyFailure,
	updatePolicy,
	updatePolicySuccess,
	updatePolicyFailure,
	getPolicyById,
	getPolicyByIdSuccess,
	getPolicyByIdFailure,
} = actions;
export { default as policiesSaga } from "./sagas";
export default reducer;
