import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import AlertMessage from "../../common/alertMessage";
import sessionConstants from "~/states/modules/session/sessionConstants";

import { login, setError } from "~/states/modules/session";
import { Link, withRouter } from "react-router-dom";

import useYupResolver from "../../common/yupResolver";
import * as yup from "yup";

const schema = yup.object().shape({
	email: yup
		.string()
		.email("E-mail inválido")
		.required("E-mail obrigatório")
		.max(256, "Seu e-mail deve conter no máximo 256 caracteres"),
	password: yup.string().required("Senha obrigatória"),
});

const Login = ({ history }) => {
	const dispatch = useDispatch();
	const resolver = useYupResolver(schema);
	const { handleSubmit, register, errors } = useForm({
		mode: "onChange",
		resolver,
	});
	const [loginError, setLoginError] = useState(null);
	const error = useSelector(({ session }) => session.error);
	const { errorSources } = sessionConstants;

	useEffect(() => {
		if (error && error.source === errorSources.LOGIN) {
			console.log("error: ", error.response);
			setLoginError({
				type: "danger",
				text:
					error.response?.status === 404 ||
					error.response?.status === 401
						? "E-mail ou Senha inválidos"
						: "Um erro inesperado ocorreu",
			});
		}
	}, [error, errorSources]);

	const onSubmit = (data, e) => {
		const next = history.location?.state?.next;
		setLoginError(null);
		dispatch(
			login({
				password: data.password,
				email: data.email,
				history,
				next,
			})
		);
	};

	const toForgetPassword = () => {
		dispatch(setError(null));
	};

	return (
		<Fragment>
			<div className="card mt-4 auth--card">
				<div className="card-body">
					<div className="text-center">
						<h4>LOGIN</h4>
						<h6>Entre com sua conta Nimbus Privacy </h6>
					</div>
					<br />
					<AlertMessage alert={loginError}></AlertMessage>
					<form
						className="theme-form"
						noValidate=""
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="form-group">
							<label
								htmlFor="emailInput"
								className="col-form-label pt-0"
							>
								Email
								<span className="auth--required-symbol">*</span>
							</label>
							<input
								className="form-control"
								type="email"
								name="email"
								id="emailInput"
								placeholder="you@yourwebsite.com"
								ref={register}
							/>
							<span className="auth--validation-alert">
								{errors.email?.message}
							</span>
						</div>
						<div className="form-group">
							<label
								htmlFor="passwordInput"
								className="col-form-label"
							>
								Senha
								<span className="auth--required-symbol">*</span>
							</label>
							<input
								className="form-control"
								type="password"
								name="password"
								id="passwordInput"
								placeholder="********"
								ref={register}
							/>
							<span className="auth--validation-alert">
								{errors.password?.message}
							</span>
						</div>
						<div className="form-group form-row mt-3 mb-0">
							<button
								className="btn btn-primary btn-block"
								type="submit"
							>
								Entrar
							</button>
						</div>
						<br />
						<div className="text-center">
							<Link
								className="btn btn-link text-capitalize"
								to="/login/esqueci-senha"
								onClick={toForgetPassword}
							>
								Esqueceu sua senha?
							</Link>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default withRouter(Login);
