import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../common/breadcrumb";
import { getCurrentUser, getUsers } from "~/states/modules/users";
import UserTable from "./userTable";
import AddButton from "~/components/common/AddButton";
import AccessControl from "~/components/common/accessControl";

const UserList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const users = useSelector(({ users }) => users.users);

	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	const goToAddUser = () => history.push(`${process.env.PUBLIC_URL}/users/add`);

	return (
		<>
			<Breadcrumb title="Gestão de Usuários" current="Gestão de Usuários" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col">
										<h5>Gestão de usuários da sua empresa.</h5>
									</div>
									<div className="col text-right">
										<AccessControl requiredPermissions={[`user:create`]}>
											<AddButton
												onClick={goToAddUser}
												toolTipMsg={"Adicionar Um Novo Usuário"}
											></AddButton>
										</AccessControl>
									</div>
								</div>
							</div>
							<div className="card-body">
								<UserTable usersList={users} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserList;
