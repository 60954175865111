import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

const Option = (props) => (
	<div>
		<components.Option {...props}>
			<input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
			<label>{props.label}</label>
		</components.Option>
	</div>
);

const MultiValue = (props) => {
	return (
		<components.MultiValue {...props}>
			<span>
				{props.selectProps.controlShouldRenderValue ? props.children : null}
			</span>
		</components.MultiValue>
	);
};

/**
 * This methods merge the base config styles and custom styles to override passed as parameter.
 *
 * @param {*} stylesOverride  the parameter eg.: { height: "35px", minHeight: "35px" }
 * @returns The returned merged style
 */
const customStyles = (stylesOverride = {}) => {
	return {
		control: (base) => ({
			...base,
			...stylesOverride,
		}),
		indicatorsContainer: (base) => ({
			height: "32px",
			...base,
		}),
		valueContainer: (base) => ({
			height: "32px",
			...base,
		}),
	};
};

class CheckboxSelect extends PureComponent {
	static propTypes = {
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.node,
				label: PropTypes.node,
			})
		).isRequired,
	};

	static defaultProps = {
		options: [],
	};

	render() {
		const { options, onChangeCallback, styles, ...otherProps } = this.props;
		return (
			<Select
				closeMenuOnSelect={false}
				isMulti
				components={{ Option, MultiValue }}
				options={options}
				hideSelectedOptions={false}
				backspaceRemovesValue={false}
				controlShouldRenderValue={false}
				styles={customStyles(styles)}
				onChange={(e) => {
					return onChangeCallback(e);
				}}
				{...otherProps}
			/>
		);
	}
}

export default CheckboxSelect;
