import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createCompany, eraseCreatedCompany } from "~/states/modules/company";
import Breadcrumb from "../../common/breadcrumb";

import * as yup from "yup";
import useYupResolver from "../../common/yupResolver";

import { toast } from "react-toastify";

import CompanyForm from "./companyForm";
import UserToBeAddedTable from "./userToBeAddedTable";

import { companyService } from "~/services/api";

const hasSlugValidation = (slug) => {
	return companyService.hasCompanyBySlug(slug)
		.then(response => !response)
		.catch(error => false);
};

const schema = yup.object().shape({
	name: yup.string().required("Campo Nome é obrigatório"),
	slug: yup
		.string()
		.required("Campo Slug é obrigatório")
		.test("hasSlug", "Campo Slug já está sendo utilizado", hasSlugValidation),
	logo: yup
		.string()
		.required("Campo Logo é obrigatório")
		.url("Campo deve ser URL"),
	privacyPolicyLink: yup
		.string()
		.required("Campo Link Política de Privacidade é obrigatório")
		.url("Campo deve ser URL"),
	siteUrl: yup
		.string()
		.required("Campo Link do Site da Companhia é obrigatório")
		.url("Campo deve ser URL"),
});

// Setting allRoles
const allRoles = [
	{ name: "admin", label: "Administrador" },
	{ name: "analyst", label: "Analista" },
	{ name: "dataManager", label: "Gerente de Dados" },
];

const CreateCompany = () => {
	// Get and set react-hook-form
	const {
		register,
		handleSubmit,
		errors,
		reset,
		getValues,
		watch,
		setValue,
		control,
	} = useForm({
		resolver: useYupResolver(schema),
	});

	// Ref to typeAhead
	const rolesRef = useRef();

	// Inicializing dispatch
	const dispatch = useDispatch();

	// To compare with newValues after submit and if it worked, in case o success, toaster is triggered
	const createdCompany = useSelector(({ company }) => company.createdCompany);

	// Inicialize newValues to handle the reset and getValues
	let newValues = {};

	// When the creation works
	useEffect(() => {
		if (createdCompany?.data && createdCompany.data.slug === newValues.slug) {
			toast.success("Companhia criada com sucesso!");
			newValues = {};
			dispatch(eraseCreatedCompany());
		}
	}, [dispatch, createdCompany]);

	// When the creation fails
	useEffect(() => {
		if (createdCompany && createdCompany.error)
			toast.error(createdCompany.error.message);
	}, [createdCompany]);

	// OnSubmit button trigger
	const onSubmitWrapper = (input) => {
		// Inicialize variables to get the Object Users
		const {
			logo,
			name,
			privacyPolicyLink,
			require_cpf,
			require_email,
			require_name,
			require_phoneNumber,
			siteUrl,
			slug,
			roles,
			email,
			...newObjUsers
		} = input;

		// Build the users to how the api accepts the request
		const newUsers = buildUsers(newObjUsers);

		// Set newValues to send to api
		newValues = {
			logo,
			name,
			privacyPolicyLink,
			require_cpf,
			require_email,
			require_name,
			require_phoneNumber,
			siteUrl,
			slug,
			users: newUsers,
		};

		// Send the request to api
		dispatch(createCompany(newValues));
	};

	// OBS: There are 2 card-headers and 2 card-body
	return (
		<>
			<Breadcrumb title="Nova Companhia" current="Criação de Companhia" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form
							className="theme-form mega-form"
							onSubmit={handleSubmit(onSubmitWrapper)}
						>
							<div className="card">
								{/* ************************************************** */}

								<div className="card-header">
									<h5>Informações da nova Companhia</h5>
								</div>

								<div className="card-body">
									<CompanyForm
										register={register}
										errors={errors}
										watch={watch}
										setValue={setValue}
									/>

									{/* ************************************************** */}
									<hr className="mt-4 mb-4" />
									{/* ************************************************** */}
									<div className="card">
										<div className="card-header">
											<h5>Usuários a serem convidados</h5>
										</div>

										<div className="card-body">
											<UserToBeAddedTable
												register={register}
												allRoles={allRoles}
												rolesRef={rolesRef}
												control={control}
												errors={errors}
												getValues={getValues}
												reset={reset}
											/>
										</div>
									</div>
								</div>
								{/* ************************************************** */}

								<div className="card-footer text-right">
									<button
										className="btn btn-primary"
										type="submit"
										disabled={createdCompany?.loading}
									>
										Salvar
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateCompany;

const buildUsers = (obj, removedIndex = -1) => {
	// Inicializa arrays vazios
	const currentEmails = [],
		currentRoles = [];
	// Inicializa contadores para email e roles
	let iEmail = 0,
		iRole = 0;

	// Itera o obj com os valores do form
	for (let value in obj) {
		// Verifica se o valor lido tem nome igual a email ${index}
		if (`${value}` === `email-${iEmail}`) {
			// Verifica se é o index a ser removido, se não for, ok
			if (iEmail !== removedIndex) currentEmails.push({ email: obj[value] });
			iEmail++;
		}

		// Verifica se o valor lido tem nome igual a role ${index}
		if (`${value}` === `roles-${iRole}`) {
			// Verifica se é o index a ser removido, se não for, ok
			if (iRole !== removedIndex) {
				const names = obj[value]
					.split(",")
					.map((roleName) => ({ name: roleName }));
				currentRoles.push({ roles: names });
			}
			iRole++;
		}
	}

	// Retorna o array de usuários com os valores corretos
	return currentEmails.map((email, index) => ({
		email: currentEmails[index].email,
		roles: currentRoles[index].roles,
		rights: [],
	}));
};
