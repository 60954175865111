import React from "react";
import { Button, Badge, Table } from "reactstrap";
import TableDropdown from "./tableDropdown";
import { X } from "react-feather";
import "./style.scss";

const TagsTable = ({
	tags,
	title,
	newTag,
	removedTag,
	categories,
	onRemove,
	onChange,
}) => {
	return (
		<>
			{tags.length > 0 && (
				<>
					<div style={{ marginTop: "40px", marginBottom: "20px" }}>
						<h5>{title + ":"}</h5>
					</div>
					<div className="card">
						<div className="card-body">
							<Table style={{ overflowY: "visible" }}>
								<thead>
									<tr>
										<th>Cookie</th>
										<th>Categoria</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{tags.map((tag, index) => (
										<tr key={index}>
											<td>
												{tag.name + "  "}
												{(newTag || removedTag) && (
													<Badge
														className="badge badge-light"
														style={{
															backgroundColor: newTag ? "#B2F5EA" : "#FED7D7",
															color: newTag ? "#234E52" : "#583234",
														}}
													>
														{newTag ? "NOVO" : "NÃO ENCONTRADO"}
													</Badge>
												)}
											</td>
											<td>
												<TableDropdown
													tag={tag}
													categories={categories}
													removedTag={removedTag}
													onChange={onChange}
												/>
											</td>
											<td>
												<Button
													color="transparent"
													style={{ padding: 0, backgroundColor: "#FFFFFF" }}
													onClick={() => onRemove(tag)}
												>
													<X size={15} />
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default TagsTable;
