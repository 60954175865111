import { call, put, takeLeading } from "redux-saga/effects";

import api from "~/services/api";
import {
	getAll,
	getAllSuccess,
	getAllFailure,
	remove,
	removeSuccess,
	removeFailure,
	add,
	addSuccess,
	addFailure,
	edit,
	editSuccess,
	editFailure,
	getOne,
	getOneSuccess,
	getOneFailure,
} from ".";

export default function* rootSaga() {
	yield takeLeading(getAll, getAllSaga);
	yield takeLeading(remove, removeSaga);
	yield takeLeading(add, addSaga);
	yield takeLeading(edit, editSaga);
	yield takeLeading(getOne, getOneSaga);
}

function* getAllSaga() {
	try {
		const { data } = yield call(api.get, "/agents");
		yield put(getAllSuccess(data));
	} catch (error) {
		yield put(getAllFailure(error.response?.data?.error));
	}
}

function* removeSaga({ payload: id }) {
	try {
		const { data } = yield call(api.delete, `/agents/${id}`);
		yield put(removeSuccess(data));
	} catch (error) {
		yield put(removeFailure(error.response?.data?.error));
	}
}

function* addSaga({ payload: body }) {
	try {
		const { data } = yield call(api.post, `/agents`, body);
		yield put(addSuccess(data));
	} catch (error) {
		yield put(addFailure(error.response?.data?.error));
	}
}
function* editSaga({ payload }) {
	try {
		const { id, form: body } = payload;
		const { data } = yield call(api.patch, `/agents/${id}`, body);
		yield put(editSuccess(data));
	} catch (error) {
		yield put(editFailure(error.response?.data?.error));
	}
}

function* getOneSaga({ payload: id }) {
	try {
		const { data } = yield call(api.get, `/agents/${id}`);
		yield put(getOneSuccess(data));
	} catch (error) {
		yield put(getOneFailure(error.response?.data?.error));
	}
}
