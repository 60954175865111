import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";
import AdminRoute from "~/services/AdminRoute";

const getRoutersFrom = (tabs, path) => {
	return tabs.map((tab, index) => {
		const {
			title,
			exact = false,
			path: tabPath,
			component: Component,
			...restTab
		} = tab;

		let finalPath = Array.isArray(tabPath) ? tabPath : [tabPath];
		finalPath = finalPath.map((p) => `${path}${p}`);

		return (
			<AdminRoute
				{...restTab}
				exact={exact}
				path={finalPath}
				component={Component}
				key={index}
			/>
		);
	});
};

const TabPane = ({ tabs }) => {
	const { path } = useRouteMatch();
	return <Switch>{getRoutersFrom(tabs, path)}</Switch>;
};

export default withRouter(TabPane);
