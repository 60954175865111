import React, { useState } from "react";
import Breadcrumb from "~/components/common/breadcrumb";
import { Download } from "react-feather";
import { Tooltip } from "reactstrap";
import { useDispatch } from "react-redux";
import { downloadClientConsentsCSV } from "~/states/modules/dataConsent";
import RequestListTable from "./resquestListTable";

function RequestList() {
	const dispatch = useDispatch();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);
	const handleDownloadCSVButton = () => dispatch(downloadClientConsentsCSV());

	return (
		<div className="container-fluid">
			<Breadcrumb
				title="Listagem de Requisições"
				parent="Gestão de Consentimento"
			/>
			<div className="card">
				<div className="card-header">
					<div className="row justify-content-between">
						<div className="col">
							<h4>Listagem de Requisições</h4>
						</div>
						<div className="col text-right align-middle">
							<button
								className={"btn btn-link"}
								onClick={handleDownloadCSVButton}
								id="downloadClientConsentsButton"
							>
								<Download />
							</button>
							<Tooltip
								target="downloadClientConsentsButton"
								isOpen={tooltipOpen}
								toggle={toggle}
							>
								Clique aqui para baixar os dados dos consentimentos dos seus
								clientes em formato .csv
							</Tooltip>
						</div>
					</div>
					<div className="row">
						<br />
						<p>
							Na tabela a seguir, são exibidas informações a respeito dos envios
							de pedidos de consentimentos de cada cliente. São exibidos os
							nomes, datas de envio de consentimento, os canais que foram
							enviados e recebidos, assim como se foi aceito e quando foi
							aceito.
						</p>
					</div>
				</div>
				<div className="card-body p-0">
					<RequestListTable />
				</div>
			</div>
		</div>
	);
}

export default RequestList;
