import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	policyTypes: {
		error: null,
		loading: false,
		data: [],
		totalCount: 0,
		tableParams: {
			pageIndex: 0,
			pageSize: 10,
			// sortBy: [{ id: "name", desc: false }],
			// filters: [],
		},
	},
	createdPolicyType: {
		data: null,
		loading: false,
		error: null,
	},
	editedPolicyType: {
		data: null,
		loading: false,
		error: null,
	},
};

const { actions, reducer } = createSlice({
	name: "policyTypes:",
	initialState,
	reducers: {
		getPolicyTypes: (state, { payload }) =>
			extend(state, {
				policyTypes: {
					...state.policyTypes,
					tableParams: payload,
					error: null,
					loading: true,
				},
			}),
		getPolicyTypesFailure: (state, { payload }) =>
			extend(state, {
				policyTypes: {
					...state.policyTypes,
					error: payload,
					loading: false,
				},
			}),
		getPolicyTypesSuccess: (state, { payload }) =>
			extend(state, {
				policyTypes: {
					...state.policyTypes,
					error: null,
					loading: false,
					data: payload.data,
					totalCount: payload.meta.totalCount,
				},
			}),
		createPolicyType: (state, { payload }) =>
			extend(state, {
				createdPolicyType: {
					...state.createdPolicyType,
					error: null,
					loading: true,
				},
			}),
		createPolicyTypeFailure: (state, { payload }) =>
			extend(state, {
				createdPolicyType: {
					...state.createdPolicyType,
					error: payload,
					loading: false,
				},
			}),
		createPolicyTypeSuccess: (state, { payload }) =>
			extend(state, {
				createdPolicyType: {
					...state.createdPolicyType,
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
		editPolicyType: (state, { payload }) =>
			extend(state, {
				editedPolicyType: {
					...state.editedPolicyType,
					error: null,
					loading: true,
				},
			}),
		editPolicyTypeFailure: (state, { payload }) =>
			extend(state, {
				editedPolicyType: {
					...state.editedPolicyType,
					error: payload,
					loading: false,
				},
			}),
		editPolicyTypeSuccess: (state, { payload }) =>
			extend(state, {
				editedPolicyType: {
					...state.editedPolicyType,
					error: null,
					loading: false,
					data: payload.data,
				},
			}),
	},
});

export const {
	getPolicyTypes,
	getPolicyTypesFailure,
	getPolicyTypesSuccess,
	createPolicyType,
	createPolicyTypeSuccess,
	createPolicyTypeFailure,
	editPolicyType,
	editPolicyTypeFailure,
	editPolicyTypeSuccess,
} = actions;
export { default as policyTypesSaga } from "./sagas";
export default reducer;
