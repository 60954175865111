import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

import emailConfigConstants from "~/states/modules/emailConfig/emailConfigConstants";

const { TO_FETCH, TO_EDIT, EDITED, FETCHED, FAILED } =
	emailConfigConstants.status;

const initialState = {
	data: null,
	status: null,
	loading: false,
	error: null,
};

const { actions, reducer } = createSlice({
	name: "emailConfig",
	initialState,
	reducers: {
		getEmailConfig: (state) => state,
		getEmailConfigFailure: (state) => state,
		getEmailConfigSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
			}),
		editEmailConfig: (state) =>
			extend(state, {
				status: TO_EDIT,
				loading: true,
				error: null,
			}),
		editEmailConfigFailure: (state, { payload }) =>
			extend(state, {
				status: FAILED,
				loading: false,
				error: payload,
			}),
		editEmailConfigSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
				status: EDITED,
				loading: false,
				error: null,
			}),
	},
});

export const {
	getEmailConfig,
	getEmailConfigSuccess,
	getEmailConfigFailure,
	editEmailConfig,
	editEmailConfigFailure,
	editEmailConfigSuccess,
} = actions;

export { default as emailConfigSaga } from "./sagas";

export default reducer;
