import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import Breadcrumb from "../../common/breadcrumb";
import EditUserForm from "./EditUserForm";

import { getUser, editUser, eraseUser } from "~/states/modules/users";
import CompanyAccess from "~/components/common/companyAccess";

const EditUser = () => {
	const dispatch = useDispatch();
	const { userId } = useParams();
	const history = useHistory();

	const selectedUser = useSelector(({ users }) => users.user.data);
	const editedUser = useSelector(({ users }) => users.editedUser);

	const [userCompanyId, setUserCompanyId] = useState(
		selectedUser?.roles[0]?.companyId
	);

	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		dispatch(getUser({ userId }));
	}, [userId, dispatch]);

	useEffect(() => {
		if (selectedUser && selectedUser.roles.length === 0)
			setUserCompanyId(-1);
	}, [selectedUser, userCompanyId]);

	// Listening for submition failures:
	useEffect(() => {
		if (showMessage) {
			if (editedUser && editedUser.error) {
				toast.error(editedUser.error.message);
			}
		}
	}, [editedUser?.error]);

	// Listening for submition success:
	useEffect(() => {
		if (showMessage) {
			if (editedUser && editedUser.data) {
				toast.success(
					`Usuário ${editedUser.data.name} atualizado com sucesso!`
				);
				history.push(`${process.env.PUBLIC_URL}/users`);
			}
		}
	}, [editedUser?.data]);

	const onSubmit = (data) => {
		const { id, ...rest } = data;

		// Just start watching for changes about editedUser after submition was triggered:
		setShowMessage(true);

		dispatch(editUser({ userId: id, data: rest }));
	};

	return (
		<CompanyAccess companyId={userCompanyId} action={eraseUser}>
			<Breadcrumb
				parent="Gestão De Usuários"
				current="Edição de Usuário"
				title="Edição de Usuário"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						{selectedUser && (
							<EditUserForm
								user={selectedUser}
								onSubmit={onSubmit}
							/>
						)}
					</div>
				</div>
			</div>
		</CompanyAccess>
	);
};

export default EditUser;
