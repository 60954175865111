import * as yup from "yup";

const yupSchema = yup.object().shape({
	name: yup.string().required("Campo Nome da Politica é obrigatório"),

	policyTypeId: yup.string().required("Campo Tipo de Politica é obrigatório"),

	visibility: yup.string().required("Campo Visibilidade é obrigatório"),

	email: yup
		.string()
		.email("E-mail invalido")
		.required("Campo E-mail do responsável pela atualização é obrigatório"),

	content: yup.string().required("Campo Conteúdo da Politica é obrigatório"),

	emailSubject: yup
		.string()
		.required(
			"Campo Assunto do e-mail de atualização de política é obrigatório"
		),

	emailMessage: yup
		.string()
		.required(
			"Campo Conteúdo do e-mail de atualização de política é obrigatório"
		),
});

export default yupSchema;
