import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getCounter } from "~/states/modules/dataConsent";

import { debounceTime } from "~/utils/operators";

import { widgetsTotals } from "./ClientConsentWidgetsConstants";

import Widgets from "~components/common/Widgets";

const ClientConsentsWidgets = () => {
	const dispatch = useDispatch();

	const counter = useSelector(({ dataConsent }) => dataConsent.counter.data);

	const [info, setInfo] = useState(null);
	const [width, setWidth] = useState(window.document.body.offsetWidth);
	const [allCounts, setAllCounts] = useState(null);

	const handleResize = debounceTime(250, () =>
		setWidth(window.document.body.offsetWidth)
	);

	useEffect(() => {
		dispatch(getCounter());
	}, [dispatch]);

	useEffect(() => {
		counter && setAllCounts(widgetsTotals(counter));
	}, [counter]);

	useEffect(() => {
		if (allCounts && width) {
			if (width < 977 && width > 752) setInfo(allCounts.different);
			else setInfo(allCounts.regular);
		}
	}, [allCounts, width]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return <Widgets info={info} />;
};

export default ClientConsentsWidgets;
