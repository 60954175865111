import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getTicketChart } from "~/states/modules/tickets";

const LastRow = () => {
	const dispatch = useDispatch();

	const ticketsForChart = useSelector(
		({ tickets }) => tickets.ticketsForChart?.data
	);

	const info = {
		date: moment().format("YYYY-MM-DD"),
		period: "12M",
	};

	useEffect(() => {
		dispatch(getTicketChart(info));
	}, [dispatch]);

	function countCorrect(ticket, object) {
		const expirationDate = moment(ticket.expirationDate);
		const updatedAt = moment(ticket.updatedAt);

		const timeBetweenExpirationAndLastUpdate =
			expirationDate.diff(updatedAt);

		const timeBetweenExpirationAndNow = expirationDate.diff(
			moment(),
			"days"
		);

		if (ticket.status === "CLOSED") {
			if (timeBetweenExpirationAndLastUpdate > 0)
				object[0].count === "-"
					? (object[0].count = 1)
					: object[0].count++;
			else
				object[1].count === "-"
					? (object[1].count = 1)
					: object[1].count++;
		} else {
			if (timeBetweenExpirationAndNow < 0)
				object[5].count === "-"
					? (object[5].count = 1)
					: object[5].count++;
			else if (timeBetweenExpirationAndNow <= 1)
				object[4].count === "-"
					? (object[4].count = 1)
					: object[4].count++;
			else if (timeBetweenExpirationAndNow <= 3)
				object[3].count === "-"
					? (object[3].count = 1)
					: object[3].count++;
			else if (timeBetweenExpirationAndNow <= 5)
				object[2].count === "-"
					? (object[2].count = 1)
					: object[2].count++;
		}
	}

	const widgetsTotals = [
		{
			label: "Fechados no prazo",
			count: "-",
			className: "digits",
		},
		{
			label: "Fechados fora do prazo",
			count: "-",
			className: "digits toRight color-darkgold",
		},
		{
			label: "A vencer em 5 dias",
			count: "-",
			className: "digits",
		},
		{
			label: "A vencer em 3 dias",
			count: "-",
			className: "digits",
		},
		{
			label: "A vencer em 1 dia",
			count: "-",
			className: "digits",
		},
		{
			label: "Pedidos abertos expirados",
			count: "-",
			className: "digits text-danger",
		},
	];

	ticketsForChart.forEach((month) =>
		month.tickets.forEach((ticket) => {
			countCorrect(ticket, widgetsTotals);
		})
	);

	return (
		<Fragment>
			{widgetsTotals.map((total) => (
				<td key={total.label} className={total.className}>
					<strong>{total.count}</strong>
				</td>
			))}
		</Fragment>
	);
};

export default LastRow;
