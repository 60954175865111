const events = new Map();

export const EventEmitter = {
	on(event, listener) {
		if (!events.has(event)) events.set(event, []);
		events.get(event).push(listener);
	},

	emit(event, data) {
		const listeners = events.get(event);
		if (listeners && Array.isArray(listeners)) {
			listeners.forEach((listener) => {
				try {
					listener(data);
				} catch (e) {
					console.log("error processing listener.", e);
				}
			});
		}
	},

	off(event, listenerToRemove) {
		if (events.has(event) && events.get(event)) {
			const removed = events
				.get(event)
				.filter((listener) => listener !== listenerToRemove);
			events.set(event, removed);
		}
	},
};

//
// Usage:
// ------
// EventEmitter.emit('some_topic', "the info")
//
