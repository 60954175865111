import React from "react";

function generateTableRows(page, prepareRow) {
	return page.map((row) => {
		prepareRow(row);
		return (
			<tr className="dl-table-row" {...row.getRowProps()}>
				{generateTableCell(row)}
			</tr>
		);
	});
}

function generateTableCell(row) {
	return row.cells.map((cell) => {
		return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
	});
}

function CTBody({ getTableBodyProps, page, prepareRow, fewItems }) {
	return fewItems ? (
		<tbody className="few-items" {...getTableBodyProps()}>
			{generateTableRows(page, prepareRow)}
		</tbody>
	) : (
		<tbody {...getTableBodyProps()}>
			{generateTableRows(page, prepareRow)}
		</tbody>
	);
}

export default CTBody;
