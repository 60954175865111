import { call, put, takeLatest, delay } from "redux-saga/effects";

import { consentDataService } from "~/services/api";
import {
	getClientConsents,
	getClientConsentsSuccess,
	getClientConsentsFailure,
	getConsentRequests,
	getConsentRequestsSuccess,
	getConsentRequestsFailure,
	getCounter,
	getCounterSuccess,
	getCounterFailure,
	getClientConsentsInformation,
	getClientConsentsInformationSuccess,
	getClientConsentsInformationFailure,
	downloadClientConsentsCSV,
	downloadClientConsentsCSVFailure,
	downloadClientConsentsCSVSuccess,
} from ".";
import { selectClientWithCompanyClientId } from "./selectors";

function* rootSaga() {
	yield takeLatest(getClientConsents, getClientConsentsSaga);
	yield takeLatest(getConsentRequests, getConsentRequestsSaga);
	yield takeLatest(getCounter, getCounterSaga);
	yield takeLatest(
		getClientConsentsInformation,
		getClientConsentsInformationSaga
	);
	yield takeLatest(downloadClientConsentsCSV, downloadClientConsentCSVSaga);
}

function* getClientConsentsSaga({ payload }) {
	try {
		yield delay(300);
		const clientConsents = yield call(
			consentDataService.getClientConsents,
			payload
		);
		yield put(getClientConsentsSuccess(clientConsents));
	} catch (error) {
		console.log(error);
		yield put(getClientConsentsFailure(error));
	}
}

function* getClientConsentsInformationSaga({ payload }) {
	try {
		const { data } = yield call(
			consentDataService.getClientConsentsInformation,
			payload
		);
		yield put(
			getClientConsentsInformationSuccess(selectClientWithCompanyClientId(data))
		);
	} catch (error) {
		yield put(getClientConsentsInformationFailure(error));
	}
}

function* getConsentRequestsSaga({ payload }) {
	try {
		yield delay(300);
		const { data } = yield call(consentDataService.getConsentRequests, payload);
		yield put(getConsentRequestsSuccess(data));
	} catch (error) {
		yield put(getConsentRequestsFailure(error.response?.data?.error));
	}
}

function* getCounterSaga() {
	try {
		const { data } = yield call(consentDataService.getCounter);
		yield put(getCounterSuccess(data));
	} catch (error) {
		yield put(getCounterFailure(error));
	}
}

function* downloadClientConsentCSVSaga() {
	try {
		const { data } = yield call(consentDataService.downloadClientConsentCSV);
		yield put(downloadClientConsentsCSVSuccess(data));
	} catch (error) {
		yield put(downloadClientConsentsCSVFailure(error));
	}
}
export default rootSaga;
