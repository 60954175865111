import axios from "axios";
import { getLanguage } from "../../assets/customPackages/react-switch-lang";
import { getApiUrl } from "../envUtils";

import { EventEmitter } from "../../utils/event-emitter";
import { retry } from "../../utils/promise-helpers";

const url = getApiUrl(),
	// Axios intances: one to use in application and other to use for reset and renew token pourpose
	api = axios.create({ baseURL: url }),
	apiBackground = axios.create({ baseURL: url }),
	// functions:
	logOut = () => EventEmitter.emit("logOut"),
	getSessionState = () => {
		const { session } = JSON.parse(
			localStorage.getItem("persist:dados-legais")
		);
		return JSON.parse(session);
	},
	refreshTokenDispatch = (refreshToken) =>
		apiBackground.post("/users/token", { refreshToken }),
	updateStore = (response) => {
		if (response && response.data) {
			const data = response?.data;
			const token = data?.data.token;

			EventEmitter.emit("setRefreshToken", data);
			api.defaults.headers.Authorization = `Bearer ${token}`;

			return token;
		}
	};

// lang interceptor
api.interceptors.request.use(
	(request) => {
		const lang = getLanguage();
		console.log("lang: " + lang);
		request.params = { ...request.params, lang };

		// const cookieLanguage = getCookie("language");
		// console.log("cookieLanguage: " + cookieLanguage);

		// if (cookieLanguage) {
		// 	request.headers.Cookie = request.headers.Cookie
		// 		? request.headers.Cookie + `language=${cookieLanguage};`
		// 		: `language=${cookieLanguage};`;

		// 	console.log(request.headers.Cookie);
		// }
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

//An unauthorized request means the token has expired. We will try to get a new one with our valid refreshToken.
//If we succeed the new token and refreshToken wll be saved in LocalStore and we resend the request.
//Otherwise, if it fails more than 3 times or if the refreshToken is expired the user will be logged out.
const responseInterceptor = api.interceptors.response.use(
	(response) => response,
	(error) => {
		const { status, config } = error.response;
		const { refreshToken, signed } = getSessionState();
		if (status === 401 && signed) {
			return retry(3, 500, refreshTokenDispatch, refreshToken)
				.then((response) => updateStore(response))
				.then((token) => {
					if (token) {
						config.headers.Authorization = `Bearer ${token}`;
						return api.request(config);
					}
				})
				.catch((err) => {
					logOut();
					throw err;
				});
		}

		return Promise.reject(error);
	}
);

export default api;
