import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import { createPolicy } from "~/states/modules/policies";
import { getPolicyTypes } from "~/states/modules/policyTypes";
import Breadcrumb from "~/components/common/breadcrumb";
import RoundedAddButton from "~/components/common/roundedAddButton";

import useYupResolver from "../../common/yupResolver";
import { ErrorMessage } from "@hookform/error-message";

import yupSchema from "./yupSchema";

import AddOrEditPolicyTypeModal from "../AddOrEditTypeModal";

const customStyles = (stylesOverride = {}) => {
	return {
		control: (base) => ({
			...base,
			...stylesOverride,
		}),
	};
};
const PolicyTypeSelector = ({
	list,
	value,
	onBlur,
	policyTypeSelect,
	onChange,
}) => {
	return (
		<Select
			styles={customStyles({
				width: "100%",
				minHeight: "32px",
				height: "100%",
			})}
			options={list}
			placeholder="Selecione um tipo..."
			value={list.find((c) => c.value === value)}
			onChange={(val) => onChange(val.value)}
			className="input-group input-group-sm"
			onBlur={onBlur}
			inputRef={policyTypeSelect}
		/>
	);
};

const VisibilitySelector = ({
	list,
	value,
	onBlur,
	visibilitySelect,
	onChange,
}) => {
	return (
		<Select
			styles={customStyles({
				width: "100%",
				minHeight: "32px",
				height: "100%",
			})}
			options={list}
			placeholder="Selecione um tipo..."
			value={list.find((c) => c.value === value)}
			onChange={(val) => onChange(val.value)}
			className="input-group input-group-sm"
			onBlur={onBlur}
			inputRef={visibilitySelect}
		/>
	);
};

function AddPolicy() {
	const dispatch = useDispatch();
	const [showMessage, setShowMessage] = useState(false);
	const [addPolicyTypeModal, setAddPolicyTypeModal] = useState(false);

	const { register, handleSubmit, setValue, errors, control, reset, trigger } =
		useForm({
			mode: "onChange",
			resolver: useYupResolver(yupSchema),
		});

	const handleContentChange = (event, editor) =>
		setValue("content", editor.getData());

	const handleEmailMessageChange = (event, editor) =>
		setValue("emailMessage", editor.getData());

	const { data: policyTypesList, tableParams } = useSelector(
		({ policyTypes }) => policyTypes.policyTypes
	);

	const policyTypesSelectorList = policyTypesList.map((policyType) => ({
		value: policyType.id,
		label: policyType.label,
	}));

	const VisibilitySelectorList = [
		{ value: "PUBLIC", label: "Pública" },
		{ value: "PRIVATE", label: "Privada" },
	];

	const { data, error } = useSelector(({ policies }) => policies.createdPolicy);
	const createdPolicyType = useSelector(
		({ policyTypes }) => policyTypes.createdPolicyType
	);

	useEffect(() => {
		dispatch(getPolicyTypes(tableParams));
		if (showMessage) {
			if (createdPolicyType && createdPolicyType.data) {
				toast.success(
					`Tipo de política ${createdPolicyType.data.label} criado com sucesso!`
				);
			}
		}
	}, [createdPolicyType?.data]);

	// displays error toast on a failed creation
	useEffect(() => {
		if (showMessage) {
			if (createdPolicyType && createdPolicyType.error) {
				if (createdPolicyType.error.name === "SequelizeUniqueConstraintError") {
					toast.error(
						"Sua companhia já possui um tipo de política com esse nome!"
					);
				} else {
					toast.error(createdPolicyType.error.message);
				}
			}
		}
	}, [createdPolicyType?.error]);

	const policyTypeSelect = useRef();
	const visibilitySelect = useRef();

	const resetForm = () => {
		reset();
		policyTypeSelect.current && policyTypeSelect.current.clear();
	};

	useEffect(() => {
		if (showMessage) {
			resetForm();
			if (data) {
				toast.success(`Política criada com sucesso!`);
			}
		}
	}, [data]);

	useEffect(() => {
		if (showMessage) {
			if (error) {
				if (error.name === "SequelizeUniqueConstraintError") {
					toast.error(
						"Sua companhia já possui uma política com esse nome e visibilidade!"
					);
				} else {
					toast.error(error.message);
				}
			}
		}
	}, [error]);

	const onSubmit = (data) => {
		setShowMessage(true);
		dispatch(createPolicy(data));
	};

	return (
		<>
			<Breadcrumb
				parent="Listagem De Politicas"
				title="Nova Política"
				current="Nova Política"
			/>
			<div className="container-fluid">
				<form
					className="needs-validation"
					noValidate=""
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="card">
						<div className="card-header">
							<h5>Cadastre uma nova política</h5>
						</div>
						<div className="card-body">
							<div className="col-12">
								<div className="form-row">
									{/* NOME POLÍTICA */}
									<div className="col-md-5 mb-3 col-sm-12">
										<label className="col-form-label" htmlFor="inputName">
											Nome da Política:
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="inputName"
											name="name"
											type="text"
											placeholder="Termo de Uso do Cartão"
											ref={register}
										/>
										<ErrorMessage
											errors={errors}
											name="name"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
									{/* TIPO DE POLÍTICA */}
									<div className="form-group col-md-4 col-sm-12">
										<label
											className="col-form-label"
											htmlFor="inputPolicyTypeId"
										>
											Tipo de Política:
											<span className="auth--required-symbol">*</span>
										</label>
										<div className="d-flex flex-row">
											<Controller
												render={({ onChange, onBlur, value }) => (
													<PolicyTypeSelector
														list={policyTypesSelectorList}
														value={value}
														onBlur={onBlur}
														policyTypeSelect={policyTypeSelect}
														onChange={onChange}
													/>
												)}
												name="policyTypeId"
												className="d-flex"
												control={control}
												defaultValue={""}
												onFocus={() => policyTypeSelect.current?.focus()}
											/>
											<RoundedAddButton
												toolTipMsg={"Adicionar um novo Tipo de Política"}
												onClick={() => setAddPolicyTypeModal(true)}
											/>
										</div>
										<ErrorMessage
											errors={errors}
											name="policyTypeId"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
									{/* VISIBILIDADE */}
									<div className="form-group col-md-3 col-sm-12">
										<label className="col-form-label" htmlFor="inputVisibility">
											Visibilidade da Política:
											<span className="auth--required-symbol">*</span>
										</label>
										<div className="d-flex flex-row">
											<Controller
												render={({ onChange, onBlur, value }) => (
													<VisibilitySelector
														list={VisibilitySelectorList}
														value={value}
														onBlur={onBlur}
														visibilitySelect={visibilitySelect}
														onChange={onChange}
													/>
												)}
												name="visibility"
												control={control}
												defaultValue={""}
												onFocus={() => visibilitySelect.current?.focus()}
											/>
										</div>
										<ErrorMessage
											errors={errors}
											name="visibility"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-12 mb-3">
										<label className="col-form-label" htmlFor="inputEmail">
											E-mail do responsável pela atualização:
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="inputEmail"
											ref={register}
											type="email"
											name="email"
											placeholder="email@email.com"
										/>
										<ErrorMessage
											errors={errors}
											name="email"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-12 mb-3">
										<label className="col-form-label" htmlFor="inputContent">
											Conteúdo da Política:
											<span className="auth--required-symbol">*</span>
										</label>
										<Controller
											name="content"
											control={control}
											defaultValue={""}
											render={({ name }) => (
												<CKEditorWrappered
													id="content"
													name={name}
													data={""}
													onChange={handleContentChange}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="content"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-12 mb-3">
										<label
											className="col-form-label"
											htmlFor="inputEmailSubject"
										>
											Assunto do e-mail de atualização de política:
											<span className="auth--required-symbol">*</span>
										</label>
										<input
											className="form-control"
											id="inputEmailSubject"
											name="emailSubject"
											type="text"
											placeholder="Atualizamos a Política do Cartão"
											ref={register}
										/>
										<ErrorMessage
											errors={errors}
											name="emailSubject"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-12 mb-3">
										<label
											className="col-form-label"
											htmlFor="inputEmailMessage"
										>
											Mensagem e-mail de atualização de política:
											<span className="auth--required-symbol">*</span>
										</label>
										<Controller
											name="emailMessage"
											defaultValue={""}
											control={control}
											// ref={register}
											render={({ name /* ref  */ }) => (
												<CKEditorWrappered
													id="emailMessage"
													name={name}
													/* ref={ref} */
													data={""}
													onChange={handleEmailMessageChange}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="emailMessage"
											as={<span className="auth--validation-alert" />}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="text-right col-sm-12">
								<button className={"btn btn-primary"} type="submit">
									Criar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<AddOrEditPolicyTypeModal
				isOpen={addPolicyTypeModal}
				onAccept={() => {
					setShowMessage(true);
					setAddPolicyTypeModal(false);
				}}
				onClose={() => setAddPolicyTypeModal(false)}
			/>
		</>
	);
}

export default AddPolicy;
