import React, { useState } from "react";
import { Eye, Edit } from "react-feather";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ViewHTMLContentModal from "~/components/common/viewHTMLContentModal";

const smallLimit = 40;
const mediumLimit = 60;

const mediumSize = 150;
const ActionButton = ({ policyId, view, edit, policyContent, policyName }) => {
	const [isContentModalOpen, setIsContentModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalTitle, setModalTitle] = useState("");
	const history = useHistory();

	const redirectToEditPage = () => {
		return () =>
			history.push(`${process.env.PUBLIC_URL}/policies/edit/${policyId}`);
	};
	// TODO add tooltips?
	return (
		<>
			{edit && (
				<>
					<button
						className="btn btn-link primary-color m-1 p-1"
						onClick={redirectToEditPage()}
					>
						<Edit />
					</button>
				</>
			)}
			{view && (
				<>
					<button
						className="btn btn-link primary-color m-1 p-1"
						onClick={() => {
							setIsContentModalOpen(true);
							setModalContent(policyContent);
							setModalTitle(policyName);
						}}
					>
						<Eye />
					</button>
					<ViewHTMLContentModal
						isOpen={isContentModalOpen}
						onClose={() => {
							setIsContentModalOpen(false);
							setModalContent("");
							setModalTitle("");
						}}
						content={modalContent}
						title={modalTitle}
					/>
				</>
			)}
		</>
	);
};

const PublicBadge = ({ policyId }) => {
	const history = useHistory();
	const redirectToHistoryPage = () => {
		return () =>
			history.push(`${process.env.PUBLIC_URL}/policies/${policyId}/details`);
	};

	return (
		<div
			className="badge badge-primary"
			style={{ cursor: "pointer" }}
			onClick={redirectToHistoryPage()}
		>
			Pública
		</div>
	);
};

const PrivateBadge = ({ policyId }) => {
	const history = useHistory();
	const redirectToHistoryPage = () => {
		return () =>
			history.push(`${process.env.PUBLIC_URL}/policies/${policyId}/details`);
	};

	return (
		<div
			className="badge badge-dark"
			style={{ cursor: "pointer" }}
			onClick={redirectToHistoryPage()}
		>
			Privada
		</div>
	);
};

const GoToDetails = ({ value, policyId }) => {
	const history = useHistory();
	const redirectToHistoryPage = () => {
		return () =>
			history.push(`${process.env.PUBLIC_URL}/policies/${policyId}/details`);
	};

	return (
		<>
			<div style={{ cursor: "pointer" }} onClick={redirectToHistoryPage()}>
				{value}
			</div>
		</>
	);
};

export const columns = [
	{
		Header: <span>{"Nome"}</span>,
		accessor: "name",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => (
			<GoToDetails
				value={data.row.original["name"]}
				policyId={data.row.original["id"]}
			/>
		),
	},
	{
		Header: <span>{"Tipo"}</span>,
		accessor: "type",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => (
			<GoToDetails
				value={data.row.original["type"].label}
				policyId={data.row.original["id"]}
			/>
		),
	},
	{
		Header: <span>{"Visibilidade"}</span>,
		accessor: "visibility",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => {
			switch (data.row.original["visibility"]) {
				case "PUBLIC":
					return <PublicBadge policyId={data.row.original["id"]} />;
				case "PRIVATE":
					return <PrivateBadge policyId={data.row.original["id"]} />;

				default:
					return "";
			}
		},
	},
	{
		Header: <span>{"Última Atualização"}</span>,
		accessor: "updatedAt",
		minWidth: mediumLimit,
		width: mediumSize,
		Cell: (data) => (
			<GoToDetails
				value={moment(data.row.original["updatedAt"]).format(
					"DD/MM/YYYY HH:mm"
				)}
				policyId={data.row.original["id"]}
			/>
		),
	},
	{
		Header: <span>{""}</span>,
		accessor: "actions",
		minWidth: smallLimit,
		width: mediumSize,
		Cell: (data) => (
			<div>
				<ActionButton
					view
					policyId={data.row.original["id"]}
					policyContent={data.row.original["content"]}
					policyName={data.row.original["name"]}
				/>
				<ActionButton edit policyId={data.row.original["id"]} />
			</div>
		),
	},
];
