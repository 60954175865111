import OtherIntegrations from "~/components/company/otherIntegrations";
import DatabaseComponent from "~/components/company/databases";
import AddDatabase from "~/components/company/databases/newDatabase";
import EditDatabase from "~/components/company/databases/editDatabase";
import AgentsComponent from "~/components/company/agents";
import AddAgent from "~/components/company/agents/Add";
import EditAgent from "~/components/company/agents/Edit";

const tabs = [
	{
		header: {
			component: "Agentes",
			path: `/agents`,
		},
		path: [``, `/agents`],
		exact: true,
		component: AgentsComponent,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
	{
		header: false,
		path: `/agents/add`,
		component: AddAgent,
		isPrivate: true,
	},
	{
		path: `/agents/:id`,
		component: EditAgent,
		exact: false,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
	{
		header: {
			component: "Bancos de Dados",
		},
		path: `/databases`,
		exact: true,
		component: DatabaseComponent,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
	{
		path: `/databases/add`,
		component: AddDatabase,
		isIntegrated: true,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
	{
		path: "/databases/:id",
		component: EditDatabase,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
	{
		header: {
			component: "Outras Integrações",
		},
		path: `/apis`,
		exact: true,
		component: OtherIntegrations,
		isPrivate: false,
		requiredPermissions: [],
		requiredRoles: [],
	},
];

export default tabs;
