import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	roles: { data: null, loading: false, error: null },
};

const { actions, reducer } = createSlice({
	name: "roles",
	initialState,
	reducers: {
		getAllRoles: (state) =>
			merge(state, {
				roles: { ...state.roles, loading: true },
			}),
		getAllRolesSuccess: (state, { payload }) =>
			merge(state, {
				roles: { ...state.roles, loading: false, data: payload.data },
			}),
		getAllRolesFailure: (state, { payload }) =>
			merge(state, {
				roles: { ...state.roles, loading: false, error: payload },
			}),
	},
});

export const { getAllRoles, getAllRolesSuccess, getAllRolesFailure } = actions;

export { default as rolesSaga } from "./sagas";

export default reducer;
