import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { getCompany } from "~/states/modules/company";
import CheckboxSelect from "~/components/common/checkboxSelect";
import ControlledTable from "~/components/common/controlledTable";
import {
	getTickets,
	getTicketsStatus,
	getTicketsTypes,
} from "~/states/modules/tickets";
import { t, translate } from "~/assets/customPackages/react-switch-lang";

const TypeColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const ticketsTypes = useSelector(({ tickets }) => tickets.ticketsTypes?.data);
	const options = ticketsTypes.map(({ label, id }) => ({
		value: id,
		label: label,
	}));

	return (
		<div className="input-group input-group-sm" style={{ width: "100%" }}>
			<CheckboxSelect
				defaultValue={filterValue}
				styles={{ width: "100%", minHeight: "32px", height: "32px" }}
				options={options}
				placeholder={
					filterValue?.length > 0
						? filterValue.length === 1
							? "Selecionado 1 tipo"
							: `Selecionados ${filterValue.length} tipos`
						: "Selecione os tipos..."
				}
				onChangeCallback={React.useCallback(
					(response) => {
						setFilter(
							response
								? response.map((item) => ({
										type: item.value,
										...item,
								  }))
								: undefined
						);
					},
					[setFilter]
				)}
				className="input-group input-group-sm"
			/>
		</div>
	);
};

const StatusColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const ticketsStatus = useSelector(
		({ tickets }) => tickets.ticketsStatus?.data
	);

	const options = ticketsStatus.map((status) => ({
		value: status,
		label: t(`TICKET_TYPE.${status}`),
	}));

	return (
		<div className="input-group input-group-sm" style={{ width: "100%" }}>
			<CheckboxSelect
				styles={{ width: "100%", minHeight: "32px", height: "32px" }}
				options={options}
				placeholder={
					filterValue?.length > 0
						? filterValue.length === 1
							? "Selecionado 1 tipo"
							: `Selecionados ${filterValue.length} tipos`
						: "Selecione os tipos..."
				}
				defaultValue={filterValue}
				onChangeCallback={React.useCallback(
					(response) => {
						setFilter(
							response
								? response.map((item) => ({
										type: item.value,
										...item,
								  }))
								: undefined
						);
					},
					[setFilter]
				)}
				className="input-group input-group-sm"
			/>
		</div>
	);
};

const TicketTable = ({ t }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const company = useSelector(({ company }) => company.currentCompany.data);
	// const ticketsList = useSelector(({ tickets }) => tickets.tickets?.data);
	const {
		data: ticketsList,
		loading: isLoadingData,
		totalCount,
		tableParams,
	} = useSelector(({ tickets }) => tickets.tickets);

	useEffect(() => {
		dispatch(getCompany());
		dispatch(getTicketsTypes());
		dispatch(getTicketsStatus());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTicketLabelFromOfferedRequests = (ticket) =>
		company.offeredRequests.options.find(
			(offeredRequest) => offeredRequest.value == ticket.ticketType.id
		)?.label;

	const getTicketLabelFromTicketTypes = (ticket) => ticket.ticketType.label;

	const data = ticketsList.map((ticket) => ({
		id: ticket.id,
		number: ticket.ticketNumber,
		type: company?.offeredRequests
			? getTicketLabelFromOfferedRequests(ticket)
			: getTicketLabelFromTicketTypes(ticket),
		email: ticket.client.email,
		responsible: ticket.user ? ticket.user.name : "-",
		status: t(`TICKET_TYPE.${ticket.status}`),
		createdAt: ticket.createdAt,
		expirationDate: ticket.expirationDate,
		sla: ticket.expirationDate,
	}));

	function createGotoView(ticketId) {
		return () => {
			history.push(`${process.env.PUBLIC_URL}/tickets/${ticketId}`);
		};
	}

	const columns = [
		{
			Header: <span>Número</span>,
			accessor: "ticketNumber",
			minWidth: 60,
			width: 180,
			style: { textAlign: "center" },
			Cell: (data) => {
				return (
					<div
						data-testid={"ticket-number"}
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer" }}
					>
						{data.row.original["number"]}
					</div>
				);
			},
		},
		{
			Header: <span>Tipo</span>,
			accessor: "type",
			minWidth: 120,
			width: 270,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer" }}
					>
						{data.row.original["type"]}
					</div>
				);
			},
			Filter: TypeColumnFilter,
		},
		{
			Header: <span>E-mail</span>,
			accessor: "client.email",
			minWidth: 60,
			width: 300,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer", textAlign: "left" }}
					>
						{data.row.original["email"]}
					</div>
				);
			},
		},
		{
			Header: <span>Estado</span>,
			accessor: "status",
			minWidth: 120,
			width: 280,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer", textAlign: "left" }}
					>
						{data.row.original["status"]}
					</div>
				);
			},
			Filter: StatusColumnFilter,
		},
		{
			Header: <span>Data de Criação</span>,
			accessor: "createdAt",
			minWidth: 60,
			width: 200,
			disableFilters: true,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer" }}
					>
						{moment(data.row.original["createdAt"]).format("DD/MM/YYYY")}
					</div>
				);
			},
		},
		{
			Header: <span>Prazo</span>,
			accessor: "expirationDate",
			minWidth: 60,
			width: 150,
			disableFilters: true,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer" }}
					>
						{moment(data.row.original["expirationDate"]).format("DD/MM/YYYY")}
					</div>
				);
			},
		},
		{
			Header: <span>SLA</span>,
			accessor: "sla",
			minWidth: 60,
			width: 130,
			disableFilters: true,
			disableSortBy: true,
			Cell: (data) => {
				const { id, status, expirationDate } = data.row.original;

				const dataSLA = moment(expirationDate);

				const isExpiredOrClosed =
					status === t("TICKET_TYPE.TICKET_EXPIRED") ||
					status === t("TICKET_TYPE.CLOSED");

				const formatedData = isExpiredOrClosed
					? "-"
					: dataSLA.fromNow().replace("em ", "").replace("há ", "-");

				const expired = moment(expirationDate) < moment().toDate();

				const color = !isExpiredOrClosed && expired ? "red" : null;

				return (
					<div
						onClick={createGotoView(id)}
						style={{ cursor: "pointer", color }}
					>
						{formatedData}
					</div>
				);
			},
		},
		{
			Header: <span>Responsável</span>,
			accessor: "user.name",
			minWidth: 60,
			width: 150,
			Cell: (data) => {
				return (
					<div
						onClick={createGotoView(data.row.original.id)}
						style={{ cursor: "pointer", textAlign: "left" }}
					>
						{data.row.original["responsible"]}
					</div>
				);
			},
		},
	];

	return (
		<Fragment>
			<ControlledTable
				data={data}
				isLoadingData={isLoadingData}
				columns={columns}
				pageCount={Math.ceil(totalCount / tableParams.pageSize)}
				totalCount={totalCount}
				globalTableParams={tableParams}
				getData={getTickets}
			/>
		</Fragment>
	);
};

export default translate(TicketTable);
