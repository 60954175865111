import React from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import "moment/locale/pt-br";

import { translate } from "../../../../assets/customPackages/react-switch-lang";
import { downloadTicketReport } from "~/states/modules/tickets";

moment.locale("pt-br");

const TicketForm = ({ ticket, t, offeredRequests }) => {
	const getTicketLabelFromOfferedRequests = (ticket) =>
		offeredRequests.options.find(
			(offeredRequest) => offeredRequest.value == ticket.type
		)?.label;

	const getTicketLabelFromTicketTypes = (ticket) => ticket.ticketType?.label;

	const ticketType = offeredRequests?.options
		? getTicketLabelFromOfferedRequests(ticket)
		: getTicketLabelFromTicketTypes(ticket);

	const dispatch = useDispatch();
	const handleDownloadButton = () => {
		console.log("ticket: \n", ticket);
		dispatch(downloadTicketReport(ticket));
	};

	return (
		<form className="needs-validation">
			<div className="card">
				<div className="card-header">
					<h5>{t("PAGE.TICKET_DETAIL.DESCRIPTION")}</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-12 col-sm-6 col-md-4 mb-3">
							<label>
								{t("TICKET.CLIENT_EMAIL")}:
							</label>
							<p className="form-control-static">
								{ticket.client?.email}
							</p>
						</div>
						<div className="col-12 col-sm-6 col-md-4 mb-3" data-testid="ticket-number">
							<label>
								{t("TICKET.NUMBER")}:
							</label>
							<p className="form-control-static">
								{ticket.ticketNumber}
							</p>
						</div>
						<div className="col-12 col-sm-6 col-md-4 mb-3">
							<label>{t("TICKET.TYPE")}:</label>
							<p className="form-control-static">{ticketType}</p>
						</div>
						{ticket.client?.cpf && (
							<div className="col-12 col-sm-6 col-md-4 mb-3">
								<label>
									{t("TICKET.CLIENT_CPF")}:
								</label>
								<p className="form-control-static">
									{ticket.client?.cpf}
								</p>
							</div>
						)}
						<div className="col-12 col-sm-6 col-md-4 mb-3">
							<label>{t("TICKET.ASSIGNED")}:</label>
							<p className="form-control-static">
								{ticket.user?.email}
							</p>
						</div>
						<div className="col-12 col-sm-6 col-md-4 mb-3">
							<label>{t("TICKET.STATUS")}:</label>
							<p className="form-control-static">
								{t(`TICKET_TYPE.${ticket.status}`)}
							</p>
						</div>
						{ticket.client?.name && (
							<div className="col-12 col-sm-6 col-md-4 mb-3">
								<label>
									{t("TICKET.CLIENT_NAME")}:
								</label>
								<p className="form-control-static">{ticket.client?.name}</p>
							</div>
						)}
						{ticket.client?.phone && (
							<div className="col-12 col-sm-6 col-md-4 mb-3">
								<label>
									{t("TICKET.CLIENT_PHONE")}:
								</label>
								<p className="form-control-static">
									{ticket.client?.phone}
								</p>
							</div>
						)}
						<div className="col-12 col-sm-6 col-md-4 mb-3">
							<label>{t("TICKET.EXPIRATION_DATE")}:</label>
							<p className="form-control-static">
								{moment(ticket.expirationDate).format(
									"DD/MM/YYYY"
								)}
							</p>
						</div>
					</div>
				</div>
				<div className="card-footer text-right">
					<button type="button" className="btn btn-primary" onClick={handleDownloadButton}>Relatório</button>

					{/* <a
						className="btn btn-primary"
						href={`${getApiUrl()}/tickets/${ticket.id}/report`}
						download
						role="button"
						style={{ color: "#FFF" }}
					>
						Relatório
					</a> */}
				</div>
			</div>
		</form>
	);
};
export default translate(TicketForm);
