import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { CheckCircle, XCircle, BookOpen, Clock } from "react-feather";
import { getClientConsentsInformation } from "~/states/modules/dataConsent";

import Breadcrumb from "../../../common/breadcrumb";

const mapConsentTypes = (consentRequests) => {
	const newTypes = [];
	const objTypes = {};
	for (const {
		consentType: { name },
	} of consentRequests) {
		objTypes[name] = name;
	}
	for (const name in objTypes) {
		newTypes.push({ value: name, label: name });
	}
	return newTypes;
};

const states = {
	READY: <Clock />,
	SENT: <Clock />,
	READ: <BookOpen />,
	FAILED: <XCircle color="red" />,
	CLOSED: <CheckCircle color="green" />,
};

const setCurrentState = (currentState) => {
	for (const state in states)
		if (state === currentState) return states[state];
	return null;
};

const mapTableInfo = (data, option) =>
	option &&
	data?.consentRequests.map((info, index) => {
		if (info.consentType.name === option.value) {
			return (
				<tr key={index}>
					<td>{info.channel}</td>
					<td>{info.status}</td>
					<td>{moment(info.createdAt).format("DD/MM/YY")}</td>
					<td>{moment(info.requestAt).format("DD/MM/YY")}</td>
					<td>
						{info.status === "CLOSED"
							? moment(info.updatedAt).format("DD/MM/YY")
							: "-"}
					</td>
					<td>{setCurrentState(info.status)}</td>
				</tr>
			);
		}
		return null;
	});

const ClientConsentsInformation = () => {
	const dispatch = useDispatch();

	const { data } = useSelector(
		({ dataConsent }) => dataConsent.clientConsentsInformation
	);

	const { id } = useParams();

	const [tableInfo, setTableInfo] = useState(null);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState({});

	useEffect(() => {
		dispatch(getClientConsentsInformation({ id }));
	}, [dispatch]);

	useEffect(() => {
		data && setOptions(mapConsentTypes(data.consentRequests));
	}, [data]);

	useEffect(() => {
		options && setSelectedOption(options[0]);
	}, [options]);

	useEffect(() => {
		setTableInfo(mapTableInfo(data, selectedOption));
	}, [selectedOption]);

	return (
		<>
			<Breadcrumb
				title="Gestão de Consentimento do Cliente"
				current="Gestão de Consentimento do Cliente"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						{data && (
							<div className="card">
								<div className="card-header">
									<h5>DETALHES</h5>
								</div>
								<div className="card-body">
									<div className="col mb-4">
										<div className="row">
											<div className="col-6">
												<div className="row">Nome:</div>
												<div className="row mt-2">
													<p>{data.name || "-"}</p>
												</div>
											</div>
											<div className="col-6">
												<div className="row">
													E-mail:
												</div>
												<div className="row">
													<p>{data.email || "-"}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col mb-4">
										<div className="row">
											<div className="col-6">
												<div className="row">CPF:</div>
												<div className="row">
													<p>
														{data.cpf?.cpf || "-"}
													</p>
												</div>
											</div>
											<div className="col-6">
												<div className="row">
													Telefone:
												</div>
												<div className="row">
													<p>{data.phone || "-"}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				{data && (
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5>SOBRE O CONSENTIMENTO</h5>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col">
											{selectedOption && (
												<Select
													options={options}
													defaultValue={
														selectedOption
													}
													onChange={(state) =>
														setSelectedOption(state)
													}
												/>
											)}
										</div>
									</div>
									<div className="row">
										<table className="table table-striped">
											<thead>
												<tr>
													<th>Canal</th>
													<th>Status</th>
													<th>Data de Criação</th>
													<th>Data de Envio</th>
													<th>Data de Aceite</th>
													<th>Aceite</th>
												</tr>
											</thead>
											<tbody>{tableInfo}</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ClientConsentsInformation;
