import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import Cookies from "js-cookie";

const subscribes = {};

let translations = {};
let defaultLanguage = "en";
let language = "en";
let count = 0;
let cookieName = "language";
let cookieOption = { path: "/", maxAge: 157680000 };

function subscribe(cb) {
	const newId = count;
	subscribes[newId] = cb;
	count += 1;
	return newId;
}

function unsubscribe(id) {
	delete subscribes[id];
}

function triggerSubscriptions() {
	Object.keys(subscribes).forEach((id) => {
		new Promise((resolve) => {
			subscribes[id]();
			resolve();
		}).then();
	});
}

function getLanguages() {
	return Object.keys(translations);
}

function getDefaultLanguage() {
	return defaultLanguage;
}

function getLanguage() {
	return language;
}

function setDefaultLanguage(lang) {
	defaultLanguage = lang;
	language = lang;
}

function setLanguage(newLanguage) {
	if (getLanguages().indexOf(newLanguage) === -1) return;
	language = newLanguage;
	triggerSubscriptions();
	Cookies.set(cookieName, language);
}

const hasTranslations = (lang) => getLanguages().includes(lang);

function setLanguageCookie() {
	const cookieLanguage = Cookies.get(cookieName);
	const navigatorLanguage = navigator.language?.split("-")[0];
	const lang = hasTranslations(cookieLanguage)
		? cookieLanguage
		: hasTranslations(navigatorLanguage)
		? navigatorLanguage
		: defaultLanguage;
	setLanguage(lang);
}

function setTranslations(userTranslations) {
	translations = userTranslations;
	triggerSubscriptions();
}

function setDefaultTranslations(userTranslations) {
	if (getLanguages().length !== 0) {
		setTranslations(userTranslations);
		return;
	}
	translations = userTranslations;
}

function getTranslation(lang) {
	return translations[lang];
}

function t(path, params, lang) {
	const selectLang = lang || language;

	function fallback() {
		if (selectLang !== defaultLanguage) {
			return t(path, params, defaultLanguage);
		}
		return path;
	}

	let translationObj = getTranslation(selectLang);

	if (!translationObj) {
		return fallback();
	}

	const translationKeys = path.split(".");
	let translation = "";

	translationKeys.forEach((key) => {
		const temp = translationObj[key];
		if (typeof translationObj[key] === "object") {
			translationObj = translationObj[key];
		} else if (typeof temp === "string") {
			translation = temp;
		}
	});

	if (!translation) {
		return fallback();
	}

	if (params) {
		Object.keys(params).forEach((key) => {
			const replace = `{${key}}`;
			translation = translation.replace(replace, params[key]);
		});
	}

	return translation;
}

function translate(Component) {
	class TranslatedComponet extends React.Component {
		componentDidMount() {
			this.id = subscribe(() => this.forceUpdate());
		}

		componentWillUnmount() {
			unsubscribe(this.id);
		}

		render() {
			return React.createElement(
				Component,
				Object.assign({}, this.props, {
					t: (key, args, lang) => t(key, args, lang),
				})
			);
		}
	}

	return hoistNonReactStatics(TranslatedComponet, Component);
}

export {
	getLanguage,
	setDefaultLanguage,
	setLanguage,
	setLanguageCookie,
	setTranslations,
	translate,
	t,
};
