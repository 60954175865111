import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TabList from "./tabList";
import TabPane from "./tabPane";
import { useHistory, useLocation, withRouter } from "react-router-dom";

import "./style.scss";

/**
 * Creates routed tabs used for user navigation and content display.
 * @example
 * Creating tabs for /company/integrations
 * <RoutedTabs tabs={tabs} basePath={"/company/integrations"}/>
 *
 * @param {Object[]} tabs 		This prop consists of a array of objects, with each object
 * 						  		representing a AdminRoute. These AdminRoutes may or may not
 * 								be a rendered tab. Since it recognizes many different fields,
 * 								check a already existing tabs-config.js file to see a example.
 * 								Further explanation may also be found in the RoutedTabs.propTypes
 * 								section of the routedTabs.js file.
 *
 *
 * @param {string} basePath 	This prop consists of a string containing the base path of the page
 * 								where the RoutedTabs component is being implemented
 * 								(Ex: integrations/company).
 */
const RoutedTabs = ({ tabs, basePath }) => {
	const history = useHistory();
	const location = useLocation();

	const isPathAnArray = (tab) => {
		return (
			Array.isArray(tab.path) &&
			tab.path.length > 1 &&
			(tab.path.includes("") || tab.path.includes("/"))
		);
	};

	const initialTab = tabs.filter(isPathAnArray)[0];

	useEffect(() => {
		if (
			[
				`${process.env.PUBLIC_URL}${basePath}`,
				`${process.env.PUBLIC_URL}${basePath}/`,
			].includes(location.pathname)
		) {
			history.push(
				`${process.env.PUBLIC_URL + basePath + initialTab.header.path}`
			);
		}
	});

	return (
		<>
			<div className="dl-routed-tabs-container">
				<TabList tabs={tabs} />
				<hr></hr>
				<TabPane tabs={tabs} />
			</div>
		</>
	);
};

RoutedTabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			header: PropTypes.oneOfType([
				PropTypes.bool,
				PropTypes.shape({
					component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
						.isRequired,
					path: PropTypes.string,
				}),
			]),
			path: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.arrayOf(PropTypes.string),
			]).isRequired,
			exact: PropTypes.bool,
			component: PropTypes.func,
			isPrivate: PropTypes.bool,
			requiredPermissions: PropTypes.arrayOf(PropTypes.string),
			requiredRoles: PropTypes.arrayOf(PropTypes.string),
		})
	).isRequired,
};

export default withRouter(RoutedTabs);
