import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import merge from "lodash/merge";
import validate from "../../../common/validate";

import { getAllRoles } from "~/states/modules/roles";

const EditUserForm = ({ user, onSubmit }) => {
	const {
		register,
		handleSubmit,
		errors,
		getValues,
		trigger,
		reset,
		control,
	} = useForm({
		defaultValues: user,
		mode: "onChange",
	});

	const dispatch = useDispatch();
	const allRoles = useSelector(({ roles }) => roles.roles?.data);
	const typeahead = useRef();
	const selectedRoles = user.roles?.map((role) => ({
		id: role.id,
		name: role.name,
		label: role.label,
	}));

	const hasErrors = () => Object.keys(errors).length > 0;

	useEffect(() => {
		dispatch(getAllRoles());
	}, [dispatch]);

	useEffect(() => {
		reset(user);
	}, [user]);

	const isNotEqual = () => {
		const _user = {
				name: user.name,
				email: user.email,
				roles: user.roles
					?.map((role) => ({ id: role.id, name: role.name, label: role.label }))
					.sort((a, b) => a.id - b.id),
			},
			formValues = getValues(),
			_userForm = {
				name: formValues.name,
				email: formValues.email,
				roles: formValues.roles
					.map((role) => ({ id: role.id, name: role.name, label: role.label }))
					.sort((a, b) => a.id - b.id),
			};

		return JSON.stringify(_user) !== JSON.stringify(_userForm);
	};

	const validator = {
		triggerIdValidation: () => {
			return {
				validate: {
					always: async (data) => {
						await trigger("id", true);
						return true;
					},
				},
			};
		},
		validateIsNotEqual: () => {
			return {
				validate: {
					isNotEqual: (data) => {
						return isNotEqual() || "Usuário igual!";
					},
				},
			};
		},
	};

	return (
		<form
			className="needs-validation"
			noValidate=""
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="card">
				<div className="card-header">
					<h5>Edite o formulário abaixo para alterar o usuário</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-md-12 mb-3">
							{errors.id?.message && (
								<Alert color="warning" fade={true} className="dark text-center">
									{errors.id.message}
								</Alert>
							)}
							<input
								type="hidden"
								name="id"
								value={user.id}
								ref={register(validator.validateIsNotEqual())}
							/>
						</div>
						<div className="col-md-12 mb-3">
							<label htmlFor="inputName">
								Nome:<span className="auth--required-symbol">*</span>
							</label>
							<input
								disabled
								className="form-control"
								id="inputName"
								name="name"
								type="text"
								placeholder="José da silva"
								ref={register(
									merge({}, validator.triggerIdValidation(), validate.name())
								)}
							/>
							<span>{errors.name?.message}</span>
						</div>
						<div className="col-md-12 mb-3">
							<label htmlFor="inputEmail">
								E-mail:<span className="auth--required-symbol">*</span>
							</label>
							<input
								disabled
								className="form-control"
								id="inputEmail"
								name="email"
								type="email"
								placeholder="email@website.com"
								ref={register(
									merge({}, validator.triggerIdValidation(), validate.email())
								)}
							/>
							<span>{errors.email?.message}</span>
						</div>

						<div className="col-md-12 mb-3">
							<label onClick={() => typeahead.current?.focus()}>
								Grupos:<span className="auth--required-symbol">*</span>
							</label>
							<Controller
								render={(
									{ onChange, onBlur, value, name, ref },
									{ invalid, isTouched, isDirty }
								) => (
									<Typeahead
										id="editUserTypeahead"
										defaultSelected={selectedRoles}
										labelKey="label"
										clearButton
										multiple
										options={Array.isArray(allRoles) ? allRoles : []}
										placeholder="Escolha um ou mais grupos..."
										ref={typeahead}
										onChange={onChange}
										onBlur={onBlur}
									/>
								)}
								name="roles"
								defaultValue=""
								control={control}
								onFocus={() => typeahead.current?.focus()}
								rules={merge(
									{},
									validator.triggerIdValidation(),
									validate.requiredTypeahead()
								)}
							/>
							<input type="hidden"></input>
							<span role="alert">{errors.roles?.message}</span>
						</div>
					</div>
				</div>
				<div className="card-footer text-right">
					<button
						className={`btn btn-primary ${hasErrors() && "disabled"}`}
						type="submit"
					>
						Salvar
					</button>
				</div>
			</div>
		</form>
	);
};

export default EditUserForm;
