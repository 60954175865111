import * as yup from "yup";

const yupSchema = yup.object().shape({
	label: yup
		.string()
		.required("Campo Rótulo do Tipo de Politica é obrigatório"),

	name: yup.string().required("Campo Nome do Tipo de Politica é obrigatório"),

	updateFrequencyInterval: yup
		.string()
		.required("Campo Intervalo de Frequência de Atualização é obrigatório"),

	updateFrequency: yup
		.string()
		.oneOf(["DAY", "WEEK", "MONTH", "YEAR", "NEVER"])
		.required("Campo Frequência de Atualização é obrigatório"),
});

export default yupSchema;
