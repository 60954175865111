import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAllowed } from "../components/common/accessControl";
import { render } from "mustache";

export default function RouteWrapper({
	component: Component,
	isPrivate = false,
	requiredPermissions,
	requiredRoles,
	...rest
}) {
	const history = useHistory();
	const params = rest.computedMatch.params;

	const currentUser = useSelector(({ users }) => users.currentUser?.data);
	const signed = useSelector(({ session }) => session.signed);

	// In the case we are trying to go to a private route and we are not authenticated, lets redirect to login url?
	if (!signed && isPrivate) {
		return (
			<Redirect
				to={{ pathname: "/login", state: { next: history.location.pathname } }}
			/>
		);
	}

	// In the case we are trying to go to a non private route are we are authenticated, let's redirect to / that is the start page of the dashboard.
	// if (signed && !isPrivate) {
	// 	return <Redirect to="/" />;
	// }

	//console.log("[before] requiredPermissions: ", requiredPermissions);
	// Changing mustache strings in requiredPermissions array items:
	if (requiredPermissions && Array.isArray(requiredPermissions)) {
		requiredPermissions = requiredPermissions.map((permission) =>
			render(permission, {
				...params,
				companyId: currentUser?.currentCompanyId,
			})
		);
	}
	//console.log("[after] requiredPermissions: ", requiredPermissions);

	// If trying to access a route that requires permissions or roles, and it has no enought rights, redirect to home page.
	if (
		signed &&
		isPrivate &&
		currentUser &&
		(requiredPermissions || requiredRoles) &&
		!isAllowed(currentUser, requiredPermissions, requiredRoles)
	) {
		return <Redirect to="/" />;
	}

	return <Route {...rest} component={Component} />;
}
