import React, { useState } from "react";
import { Download } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { downloadClientConsentsCSV } from "~/states/modules/dataConsent";
import Breadcrumb from "../../common/breadcrumb";
import { Tooltip } from "reactstrap";
import ClientConsentsTable from "~/components/consents/dataConsents/ClientConsentsTable";
import ClientConsentsWidgets from "~/components/consents/dataConsents/ClientConsentsWidgets";
import AddButton from "~/components/common/AddButton";
import AccessControl from "~/components/common/accessControl";

const UserList = () => {
	const dispatch = useDispatch();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const handleDownloadCSVButton = () => dispatch(downloadClientConsentsCSV());

	const history = useHistory();

	const goToAddTypeConsent = () =>
		history.push(`${process.env.PUBLIC_URL}/clientConsents/add`);

	return (
		<>
			<Breadcrumb
				title="Gestão de Clientes"
				current="Gestão de Consentimentos"
			/>
			<div className="container-fluid">
				{/* TODO */}
				{/*
				<div className="row">
					<div className="col-sm-12">
						<ConsentTypeSelect/>
					</div>
				</div>
				*/}
				<div className="row">
					<div className="col-sm-12">
						<ClientConsentsWidgets />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col">
										<h5>Listagem de Clientes</h5>
									</div>
									<div className="col text-right align-middle">
										<button
											className={"btn btn-link"}
											onClick={handleDownloadCSVButton}
											id="downloadClientConsentsButton"
										>
											<Download />
										</button>
										<Tooltip
											target="downloadClientConsentsButton"
											isOpen={tooltipOpen}
											toggle={toggle}
										>
											Clique aqui para baixar os dados dos consentimentos dos
											seus clientes em formato .csv
										</Tooltip>
										<AccessControl
											requiredPermissions={[`clientConsent:create`]}
										>
											<AddButton
												onClick={goToAddTypeConsent}
												toolTipMsg={"Adicionar um novo Cliente/Titular"}
											/>
										</AccessControl>
									</div>
								</div>
								<div className="row">
									<br />
									<p>
										Na tabela a seguir, são exibidas as informações de cada
										cliente. São exibidos os nomes, CPFs, endereços de e-mail,
										números de telefone, datas de envio de consentimento, os
										canais que foram enviados e recebidos, assim como se foi
										aceito e quando foi aceito. Para saber mais detalhes sobre o
										cliente, clique em uma de suas propriedades na tabela e será
										redirecionado para a respectiva página de detalhes.
									</p>
								</div>
							</div>
							<div className="card-body p-0">
								<ClientConsentsTable />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserList;
