import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";

import {
	getEmailConfig,
	getEmailConfigSuccess,
	getEmailConfigFailure,
	editEmailConfig,
	editEmailConfigFailure,
	editEmailConfigSuccess,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getEmailConfig, getEmailConfigSaga);
	yield takeEvery(editEmailConfig, editEmailConfigSaga);
}

function* getEmailConfigSaga() {
	try {
		const { data } = yield call(api.get, `/emailConfig/`);
		yield put(getEmailConfigSuccess(data));
	} catch (error) {
		yield put(getEmailConfigFailure(error.response?.data?.error));
	}
}

function* editEmailConfigSaga({ payload }) {
	try {
		const { data } = yield call(api.post, `/emailConfig/`, payload);
		yield put(editEmailConfigSuccess(data));
	} catch (error) {
		yield put(editEmailConfigFailure(error.response?.data?.error));
	}
}
