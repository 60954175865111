import React from "react";
import { AlertCircle } from "react-feather";

import "./style.scss";

function CTEmpty({ emptyMessage }) {
	const customEmptyMessage = emptyMessage || "Não há dados a serem exibidos.";

	return (
		<div className="ct-empty-data mt-2 mb-4">
			<div className="mb-3">
				<AlertCircle size={44} />
			</div>
			<span className="mb-2">{customEmptyMessage}</span>
		</div>
	);
}

export default CTEmpty;
