import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Route, Switch, Redirect } from "react-router-dom";

import Breadcrumb from "~/components/common/breadcrumb";
import AddButton from "~/components/common/AddButton";
import { Button } from "reactstrap";
import List from "./List";

import { getAll } from "~/states/modules/agents";
import AddAgent from "./Add";

const Agents = ({ url }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		data: agents,
		loading,
		error,
	} = useSelector(({ agents }) => agents.all);

	useEffect(() => {
		dispatch(getAll());
	}, [dispatch]);

	const goToAddAgent = () =>
		history.push(`${process.env.PUBLIC_URL}/company/integrations/agents/add`);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="d-flex">
									<div className="p-2">
										<h5>Gestão de Agentes</h5>
									</div>
									{/* <div className="row">
									<div className="col text-right"> */}
									<div className="ml-auto p-2">
										<Button color="primary" onClick={goToAddAgent}>
											Adicionar agente
										</Button>
									</div>
								</div>
								{/* </div>
								</div> */}
							</div>
							<div className="card-body">
								{!loading && <List agents={agents} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Agents;
