import React from "react";

const Footer = (props) => {
	return (
		<footer className="footer">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-6 footer-copyright"></div>
					<div className="col-md-6">
						<p className="pull-right mb-0">Powered By Nimbus Privacy</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
