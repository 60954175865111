import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import api from "~/services/api";
import { getAllRoles, getAllRolesSuccess, getAllRolesFailure } from ".";

function* rootSaga() {
	yield takeLatest(getAllRoles, getAllRolesSaga);
}

function* getAllRolesSaga() {
	try {
		const { data } = yield call(api.get, "/roles/");
		yield put(getAllRolesSuccess(data));
	} catch (error) {
		yield put(getAllRolesFailure(error.response?.data?.error));
	}
}

export default rootSaga;
