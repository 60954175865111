import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, UncontrolledTooltip, Collapse } from "reactstrap";

import Modal from "~/components/common/modal";
import { handleEvent } from "~/states/modules/tickets";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import "./TicketFlow.scss";

import CKEditorWrappered from "~/components/common/CKEditorWrappered";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
	editorFormatOnReady,
	ckeditorRegexReplacer,
	configToolBar,
} from "~/components/common/ckeditorTools";

const TicketFlow = ({ statusFlow, ticketId }) => {
	const dispatch = useDispatch();
	const { control, watch, register } = useForm({
		defaultValues: { files: [] },
		mode: "onChange",
	});

	const getCurrentStatus = (statusFlow) => {
		for (let i = 0; i < statusFlow.length; i++)
			if (statusFlow[i].status === "current") return statusFlow[i];

		return null;
	};

	const handleControlledDropzonChangeStatus = (
		status,
		allFiles,
		setFiles
	) => {
		setTimeout(() => {
			if (["done", "removed"].includes(status)) {
				setFiles([...allFiles]);
				setInputedFiles(allFiles.map((rawFile) => rawFile.file));
			}
		}, 0);
	};

	const [modal, setModal] = useState({ show: false, btn: {} });

	const [inputedFiles, setInputedFiles] = useState([]);

	const [eventFeedback, setEventFeedback] = useState(false);

	const { statusFlow: flow, buttons } = statusFlow;

	const currentStatus = getCurrentStatus(flow);
	const swtch = watch("unvalidate");
	const [description, setDescription] = useState(
		"<h5><b>JUSTIFICATIVA:</b></h5><p></p>"
	);

	const throwTicketEvent = ({ event: eventsNames, fileInput, optional }) => {
		setModal({ show: false, btn: {} });
		if (!fileInput && !optional)
			return dispatch(
				handleEvent({
					ticketId,
					body: {
						eventsNames,
						params: {
							message:
								swtch && ckeditorRegexReplacer(description),
						},
					},
				})
			);

		if (!inputedFiles.length && !optional)
			return toast.warn("Nenhum arquivo selecionado.");

		const formData = new FormData();
		inputedFiles.forEach((f) => formData.append("files", f));
		formData.append("eventsNames", JSON.stringify(eventsNames));

		dispatch(
			handleEvent({
				ticketId,
				body: formData,
			})
		);
		setEventFeedback(true);
	};

	const popUpModal = (btn) => (e) => {
		e.stopPropagation();
		if (!loading) setModal({ show: true, btn });
	};

	const validateFiles = (fileWithMeta) => {
		if (fileWithMeta.meta.size < 10485760) {
			return false;
		}
		toast.error(
			`Arquivo ${fileWithMeta.meta.name} acima do limite permitido de 10 MB.`
		);
		fileWithMeta.remove();
		return true;
	};

	const { loading, error } = useSelector(
		({ tickets }) => tickets.singleTicket
	);

	const handleTemplateHtmlChange = (event, editor) =>
		setDescription(editor.getData());

	// Listening for errors or success in event handling
	useEffect(() => {
		if (eventFeedback) {
			if (!loading) {
				if (error) {
					toast.error(`Falha ao executar ação.`);
				} else {
					toast.success(`Ação executada com sucesso.`);
				}
				setEventFeedback(false);
			}
		}
	}, [eventFeedback, loading, error]);

	return (
		<Fragment>
			<div
				className="row"
				style={{ flex: 1, justifyContent: "space-between" }}
			>
				{flow.map((step, index) => (
					<div
						key={index}
						// className={`u-pearl ${step.status} ${step.type}`}
						className={`u-pearl ${step.status}`}
						style={{ flex: 1 }}
					>
						<span className="u-pearl-number">{index + 1}</span>
						<span className="u-pearl-title">{step.label}</span>
					</div>
				))}
			</div>
			<br />
			<br />
			<br />
			<form
				encType="multipart/form-data"
				className="dropzone dropzone-info"
				id="dataForm"
			>
				{buttons.some(({ fileInput }) => !!fileInput) && (
					<div className="dz-message needsclick">
						<Controller
							control={control}
							name="files"
							render={({ onChange }) => (
								<Dropzone
									inputContent="Arraste seus arquivos ou clique aqui"
									inputWithFilesContent="Adicione outros arquivos"
									validate={validateFiles}
									onChangeStatus={(
										file,
										status,
										allFiles
									) => {
										handleControlledDropzonChangeStatus(
											status,
											allFiles,
											onChange
										);
									}}
								/>
							)}
						/>
					</div>
				)}
				<hr className="mt-4 mb-4" />
				<div className="form-row text-center">
					{buttons.map((button, index) => (
						<div key={index} className="col">
							<Button
								type="button"
								color={button.class}
								id={`ButtonEvent-${index}`}
								onClick={popUpModal(button)}
							>
								{loading ? (
									<div className="loader">
										<div className="line bg-light"></div>
										<div className="line bg-light"></div>
										<div className="line bg-light"></div>
										<div className="line bg-light"></div>
									</div>
								) : (
									button.label
								)}
								{loading ? (
									<UncontrolledTooltip
										placement="top"
										target={`ButtonEvent-${index}`}
									>
										Por favor, aguarde...
									</UncontrolledTooltip>
								) : null}
							</Button>
						</div>
					))}
				</div>
				{currentStatus?.hasDescription && (
					<div className="form-row pl-5 pr-5 pt-4 pb-3 d-block text-center">
						<div className="col-form">
							<input
								name="unvalidate"
								id="unvalidate"
								type="checkbox"
								className="form-check-input"
								ref={register}
							/>
							<label
								className="col-form-label pt-0"
								htmlFor="unvalidate"
							>
								Ao recusar a validação, adicionar mensagem
								personalizada
							</label>
							<Collapse isOpen={swtch}>
								<CKEditorWrappered
									data={description}
									onChange={handleTemplateHtmlChange}
								/>
							</Collapse>
						</div>
					</div>
				)}
			</form>
			<Modal
				isOpen={modal.show}
				onClose={() => setModal({ show: false, btn: {} })}
				onAccept={() => throwTicketEvent(modal.btn)}
				title="Confirmação de Ação"
			>
				Essa ação{" "}
				<font color="blue">
					<b>não pode ser desfeita</b>
				</font>
				, tem certeza de que deseja continuar?
			</Modal>
		</Fragment>
	);
};

export default TicketFlow;
