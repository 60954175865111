import React, { useState } from "react";
import { CheckCircle, XCircle, Clock } from "react-feather";
import CustomDatePicker from "~/components/common/CustomDatePicker";
import { useSelector } from "react-redux";

const SentDateColumnFilter = ({ column: { setFilter } }) => {
	const { tableParams } = useSelector(
		({ dataConsent }) => dataConsent.clientConsents
	);

	const [date, setDate] = useState(
		_returnDateFilterObjectFromTableParams(
			tableParams,
			"companiesClientConsents.consentRequests.requestAt"
		)
	);

	return (
		<div className="input-group input-group-sm">
			<CustomDatePicker
				onChange={(date) => {
					setDate(date);
					const formattedDate = date?.toISOString().replace("T", " ") || null;
					setFilter(formattedDate);
				}}
				placeholderText="Filtrar..."
				selected={date}
				toolTipMsg="Selecione uma data para ver os envios de pedidos de consentimentos emitidos a partir desta data"
			/>
		</div>
	);
};

function _returnDateFilterObjectFromTableParams(tableParams, filterId) {
	let dateFilterValue = null;
	let ISOFormattedDate = null;

	tableParams.filters.forEach((filter) => {
		// search the array of objects until you find the date filter object
		if (filter.id === filterId) {
			dateFilterValue = filter.value;
		}
	});
	ISOFormattedDate = dateFilterValue?.replace(" ", "T") || null;

	return ISOFormattedDate === null
		? ISOFormattedDate
		: new Date(ISOFormattedDate);
}

export const regularColumns = [
	{
		Header: <span>{"Nome"}</span>,
		accessor: "name",
		minWidth: 60,
	},
	{
		Header: <span>{"CPF"}</span>,
		accessor: "cpf",
		disableSortBy: true,
		disableFilters: true,
		minWidth: 60,
	},
	{
		Header: <span>{"E-mail"}</span>,
		accessor: "email",
		minWidth: 60,
		width: 390,
	},
	{
		Header: <span>{"Telefone"}</span>,
		accessor: "phone",
		minWidth: 60,
	},

	{
		Header: <span>{"Data de Envio"}</span>,
		accessor: "requestAt",
		id: "companiesClientConsents.consentRequests.requestAt",
		minWidth: 60,
		width: 200,
		Filter: SentDateColumnFilter,
	},
	{
		Header: <span>{"Recebido por SMS"}</span>,
		accessor: "smsRequest",
		disableFilters: true,
		disableSortBy: true,
		minWidth: 60,
	},
	{
		Header: <span>{"Recebido por E-mail"}</span>,
		accessor: "emailRequest",
		disableFilters: true,
		disableSortBy: true,
		minWidth: 60,
	},
	{
		Header: <span>{"Aceito"}</span>,
		accessor: "accepted",
		disableFilters: true,
		disableSortBy: true,
		minWidth: 60,
	},
	{
		Header: <span>{"Data de Aceite"}</span>,
		accessor: "acceptedAt",
		disableFilters: true,
		disableSortBy: true,
		minWidth: 60,
	},
];

export const states = (status, type = "") => {
	const requestOrAccept =
		type === "request" ? <CheckCircle color="green" /> : <Clock />;

	return {
		READY: <Clock />,
		SENT: requestOrAccept,
		READ: requestOrAccept,
		FAILED: <XCircle color="red" />,
		CLOSED: <CheckCircle color="green" />,
	}[status];
};
