import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import qs from "qs";
import generateSearchRequest from "../../../utils/sequelize-search-builder-client";

import { formatPayload } from "~/utils/CTPayloadFormatter";
import api, { companyService } from "~/services/api";
import {
	getCompany,
	getCompanySuccess,
	getCompanyFailure,
	editCompany,
	editCompanyFailure,
	editCompanySuccess,
	createTags,
	createTagsSuccess,
	createTagsFailure,
	scanCompanyTags,
	scanCompanyTagsSuccess,
	scanCompanyTagsFailure,
	getTags,
	getTagsSuccess,
	getTagsFailure,
	removeTags,
	removeTagsSuccess,
	removeTagsFailure,
	getConsents,
	getConsentsSuccess,
	getConsentsFailure,
	createCompany,
	createCompanySuccess,
	createCompanyFailure,
	importClientConsent,
	importClientConsentSuccess,
	importClientConsentFailure,
} from ".";

export default function* rootSaga() {
	yield takeLatest(getCompany, getCompanySaga);
	yield takeEvery(scanCompanyTags, scanCompanyTagsSaga);
	yield takeEvery(editCompany, editCompanySaga);
	yield takeEvery(createTags, createTagsSaga);
	yield takeEvery(getTags, getTagsSaga);
	yield takeEvery(removeTags, removeTagsSaga);
	yield takeEvery(getConsents, getConsentsSaga);
	yield takeEvery(createCompany, createCompanySaga);
	yield takeEvery(importClientConsent, importClientConsentSaga);
}

function* getCompanySaga() {
	try {
		const company = yield call(companyService.getCurrentCompany);
		yield put(getCompanySuccess(company));
	} catch (error) {
		yield put(getCompanyFailure(error));
	}
}

function* editCompanySaga({ payload }) {
	try {
		const data = yield call(companyService.editCompany, payload);
		yield put(editCompanySuccess(data));
	} catch (error) {
		yield put(editCompanyFailure(error));
	}
}

function* createTagsSaga({ payload }) {
	try {
		const { data } = yield call(api.post, `/cookies`, payload.payload);
		yield put(createTagsSuccess(data));
	} catch (error) {
		yield put(createTagsFailure(error.response?.data?.error));
	}
}

function* scanCompanyTagsSaga() {
	try {
		yield delay(500);
		const { data } = yield call(api.get, `/companies/current/scan-tags`);
		yield put(scanCompanyTagsSuccess(data));
	} catch (error) {
		yield put(scanCompanyTagsFailure(error.response?.data?.error));
	}
}

function* getTagsSaga({ payload }) {
	try {
		yield delay(500);
		const { data } = yield call(api.get, `/cookies/${payload}`);
		yield put(getTagsSuccess(data));
	} catch (error) {
		yield put(getTagsFailure(error.response?.data?.error));
	}
}

function* removeTagsSaga({ payload }) {
	try {
		const { data } = yield call(api.delete, `/cookies/${payload.payload}`);
		yield put(removeTagsSuccess(data));
	} catch (error) {
		yield put(removeTagsFailure(error.response?.data?.error));
	}
}

function* getConsentsSaga({ payload }) {
	try {
		const { data } = yield call(api.get, `/consents`, {
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: "brackets" });
			},
			params: {
				startDate: payload?.startDate || new Date(),
				endDate: payload?.endDate || new Date(),
			},
		});
		yield put(getConsentsSuccess(data));
	} catch (error) {
		yield put(getConsentsFailure(error.response?.data?.error));
	}
}

function* createCompanySaga({ payload }) {
	try {
		yield delay(500);
		const data = yield call(companyService.createCompany, payload);
		yield put(createCompanySuccess(data));
	} catch (error) {
		yield put(createCompanyFailure(error));
	}
}

function* importClientConsentSaga({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(api.post, "/companies/importClientConsents", payload);

		yield put(importClientConsentSuccess(data));
	} catch (error) {
		yield put(importClientConsentFailure(error.response?.data?.error));
	}
}
