import React from "react";
import { Tooltip } from "reactstrap";

const getId = (function () {
	let i = 0;
	return () => ++i;
})();

const id = `btn-${getId()}`;

const LoadingButton = ({ loading, child, onClick, type, style }) => {
	const _type = type || "submit";
	const className = _type == "button" ? "btn-link" : "btn-primary";

	return (
		<button
			type={_type}
			disabled={loading}
			id={id}
			className={`btn ${className} mr-1`}
			onClick={onClick}
			style={style}
		>
			{loading ? (
				<div className="loader">
					<div className="line bg-light"></div>
					<div className="line bg-light"></div>
					<div className="line bg-light"></div>
					<div className="line bg-light"></div>
				</div>
			) : (
				child || "Salvar"
			)}
			<Tooltip placement="top" isOpen={loading} target={id}>
				Por favor, aguarde...
			</Tooltip>
		</button>
	);
};

export default LoadingButton;
