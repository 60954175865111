import {
	Home,
	Users,
	Settings,
	List,
	UserCheck,
	Code,
	Copy,
	Command,
	FileText,
} from "react-feather";

// TODO check requiredRoles/permissions

export const MENUITEMS = [
	{
		title: "Dashboard",
		icon: Home,
		type: "link",
		path: "/",
		badgeType: "primary",
		active: true,
		requiredPermissions: null,
		requiredRoles: null,
	},
	{
		title: "Direitos dos Titulares",
		icon: Copy,
		type: "sub",
		active: false,
		badgeType: "primary",
		requiredRoles: null,
		requiredPermissions: null,
		children: [
			{
				path: "/tickets",
				title: "Lista de Pedidos",
				type: "link",
				requiredPermissions: null,
				requiredRoles: null,
			},
			{
				path: "/company/config",
				title: "Configurações",
				type: "link",
				requiredRoles: null,
				requiredPermissions: ["company:config"],
			},
			{
				path: "/company/integrations",
				title: "Integrações",
				type: "link",
				requiredRoles: null,
				requiredPermissions: ["company:config"],
			},
		],
	},
	{
		title: "Cookies",
		icon: UserCheck,
		type: "sub",
		active: false,
		badgeType: "primary",
		requiredRoles: null,
		requiredPermissions: null,
		children: [
			{
				path: "/consents/tags",
				title: "Categorização de Cookies",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
			{
				path: "/consents/config",
				title: "Configurações",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null, //TODO: Precisa criar a permissão ["consents:config"] ou algo do tipo
			},
			{
				path: "/consents/cookie-consents",
				title: "Base de Consentimentos",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
		],
	},
	{
		title: "Consentimento",
		icon: Command,
		type: "sub",
		active: false,
		badgeType: "primary",
		requiredRoles: null,
		requiredPermissions: null,
		children: [
			{
				path: "/clientConsents",
				title: "Lista de Usuários",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
			{
				path: "/clientConsents/requestList",
				title: "Lista de Requisições",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
			{
				path: "/importClientConsents",
				title: "Importação",
				type: "link",
				requiredRoles: null,
				requiredPermissions: ["clientConsent:create"],
			},
			{
				path: "/consentType",
				title: "Tipos de Consentimento",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
		],
	},
	{
		title: "Políticas",
		icon: FileText,
		type: "sub",
		active: false,
		badgeType: "primary",
		requiredRoles: null,
		requiredPermissions: null,
		children: [
			{
				path: "/policies",
				title: "Lista de Políticas",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
			{
				path: "/policies/types",
				title: "Tipos de Políticas",
				type: "link",
				requiredRoles: null,
				requiredPermissions: null,
			},
		],
	},
	{
		title: "Usuários",
		path: "/users",
		icon: Users,
		type: "link",
		requiredPermissions: null,
		requiredRoles: null,
		active: false,
	},
	{
		title: "Empresa",
		icon: Settings,
		type: "link",
		path: "/company/company-info",
		badgeType: "primary",
		active: true,
		requiredPermissions: null,
		requiredRoles: null,
	},
	{
		title: "Instalação da SDK",
		path: "/sdk/install",
		icon: Code,
		type: "link",
		active: false,
		requiredPermissions: null,
		requiredRoles: null,
	},
];
