import { call, put, takeEvery } from "redux-saga/effects";

import api from "~/services/api";

import {
	getAppConfig,
	getAppConfigSuccess,
	getAppConfigFailure,
	editAppConfig,
	editAppConfigFailure,
	editAppConfigSuccess,
} from ".";

export default function* rootSaga() {
	yield takeEvery(getAppConfig, getAppConfigSaga);
	yield takeEvery(editAppConfig, editAppConfigSaga);
}

function* getAppConfigSaga() {
	try {
		const { data } = yield call(api.get, `/appConfigs/current`);
		yield put(getAppConfigSuccess(data));
	} catch (error) {
		yield put(getAppConfigFailure(error.response?.data?.error));
	}
}

function* editAppConfigSaga({ payload }) {
	try {
		const { data } = yield call(api.put, `/appConfigs/`, payload);
		yield put(editAppConfigSuccess(data));
	} catch (error) {
		yield put(editAppConfigFailure(error.response?.data?.error));
	}
}
