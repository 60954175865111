import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Breadcrumb from "../../../common/breadcrumb";
import { useHistory } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { createConsentTypes } from "~/states/modules/consentTypes";
import * as yup from "yup";
import useYupResolver from "../../../common/yupResolver";
import { ErrorMessage } from "@hookform/error-message";

const ConsentTypeForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		name: yup.string().required("Campo Nome é obrigatório"),
		description: yup.string().required("Campo Descrição é obrigatório"),
		message: yup.string().required("Campo Mensagem é obrigatório"),
	});

	const { register, handleSubmit, errors, reset, watch, control } = useForm({
		mode: "onChange",
		resolver: useYupResolver(schema),
	});

	const goBack = () => history.push(`${process.env.PUBLIC_URL}/consentType`);

	// Vericar se é válido desconstruir componente para pegar a informação desejada
	const { data, error } = useSelector(
		({ consentTypes }) => consentTypes.createdConsentTypes
	);

	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);

	const select = useRef();

	const resetForm = () => {
		reset();
		select.current && select.current.clear();
	};

	// Listening for submition:
	useEffect(() => {
		if (showMessage) {
			if (data) {
				setLoading(false);
				resetForm();
				toast.success(`Tipo de Consentimento ${data.name} criado com sucesso!`);
			} else if (error) {
				setLoading(false);
				toast.warn(error.message);
			}
		}
	}, [data, error]);

	const onSubmit = (data) => {
		setLoading(true);

		const { name, description, message } = data;

		setShowMessage(true);

		dispatch(
			createConsentTypes({
				name,
				description,
				message,
			})
		);

		reset();
	};

	return (
		<Fragment>
			<Breadcrumb
				parent="Tipos de Consentimento"
				current="Novo Tipo de Consentimento"
				title="Novo Tipo de Consentimento"
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form
							className="needs-validation"
							noValidate=""
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col">
											<h5>
												Adicionar um novo Tipo de Consentimento preenchendo as
												informações abaixo.
											</h5>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="col-sm-12 mb-3">
											<label htmlFor="inputName">
												Nome:
												<span className="auth--required-symbol">*</span>
											</label>
											<input
												className="form-control"
												id="inputName"
												name="name"
												type="text"
												placeholder="Nome"
												ref={register}
											/>
											<ErrorMessage
												errors={errors}
												name="name"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="col-sm-12 mb-3">
											<label htmlFor="inputDescription">
												Descrição:
												<span className="auth--required-symbol">*</span>
											</label>
											<textarea
												className="form-control"
												id="inputDescription"
												name="description"
												type="text"
												placeholder="Descrição"
												ref={register}
											/>
											<ErrorMessage
												errors={errors}
												name="description"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="col-sm-12 mb-3">
											<label htmlFor="inputMessage">
												Mensagem:
												<span className="auth--required-symbol">*</span>
											</label>
											<textarea
												className="form-control"
												id="inputMessage"
												name="message"
												type="text"
												placeholder="Mensagem"
												ref={register}
											/>
											<ErrorMessage
												errors={errors}
												name="message"
												as={<span className="auth--validation-alert" />}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer btn-showcase text-right">
									<button
										className="btn btn-outline-light btn-sm txt-dark"
										type="button"
										onClick={goBack || (() => null)}
									>
										Voltar
									</button>
									<button
										// Test and ask yourself if hasErrors is essencial
										className={"btn btn-primary"}
										disabled={loading || errors.length > 0}
										type="submit"
									>
										{!loading ? (
											"Criar"
										) : (
											<div className="loader">
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
												<div className="line bg-light"></div>
											</div>
										)}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ConsentTypeForm;
