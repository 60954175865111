import React, { Fragment } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

const generateHeadersRow = (headerGroup) => {
	return headerGroup.headers.map((column) => (
		<th className="dl-headers-th" {...column.getHeaderProps()}>
			<div className="dl-headers-container">
				<div className="dl-headers-title-and-ordenator">
					<div
						className="clickable-sortby-title"
						{...column.getHeaderProps(column.getSortByToggleProps())}
					>
						{/* Header text */ column.render("Header")}
						{changeSortByIcon(column)}
					</div>
				</div>
				<div style={{ width: "100%" }}>
					{addColumnFilterWhenApplicable(column)}
				</div>
			</div>
			<div className="resizer" {...column.getResizerProps()} />
		</th>
	));
}

const changeSortByIcon = (column) => {
	if (column.isSorted === true) {
		if (column.isSortedDesc === true) {
			return <ChevronDown />;
		} else {
			return <ChevronUp />;
		}
	}
}

const addColumnFilterWhenApplicable = (column) => {
	if (column.canFilter === true) {
		return column.render("Filter");
	}
}

const CTHead = ({ headerGroups }) => {
	return (
		<thead>
			{headerGroups.map((headerGroup, index) => (
				<Fragment key={index}>
					<tr {...headerGroup.getHeaderGroupProps()}>
						{generateHeadersRow(headerGroup)}
					</tr>
				</Fragment>
			))}
		</thead>
	);
}

export default CTHead;
