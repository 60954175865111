import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { changeCompanyTo } from "~/states/modules/session";
import Modal from "~/components/common/modal";
import { DropdownMenu, DropdownItem } from "reactstrap";

import "./style.scss";

const CompanyChangeDropdownMenu = ({ currentCompany, companies }) => {
	const dispatch = useDispatch();

	const [selectedCompany, setSelectedCompany] = useState(currentCompany);
	const [modal, setModal] = useState(false);

	const modalOnAcceptHandler = () => {
			setModal(false);
			dispatch(changeCompanyTo({ id: selectedCompany?.id }));
		},
		modalOnCloseHandler = () => {
			setSelectedCompany(currentCompany);
			setModal(false);
		};

	const isCurrentCompany = (company) => currentCompany?.id === company.id,
		dropdownItemOnClickHandler = (company) => {
			if (!isCurrentCompany(company)) {
				setSelectedCompany(company);
				setModal(true);
			}
		},
		getDropdownItemStyle = (company) => {
			let style = { maxWidth: "250px", maxHeight: "50px" };
			return isCurrentCompany(company)
				? style
				: { ...style, cursor: "pointer" };
		},
		sortCompanies = (c1, c2) =>
			isCurrentCompany(c1) ? -1 : isCurrentCompany(c2) ? 1 : 0;

	return (
		<Fragment>
			<DropdownMenu
				style={{
					boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
					width: "250px",
					marginLeft: "-10px",
					marginTop: "15px",
					position: "absolute",
					maxHeight: "400px",
					overflowY: "auto",
				}}
			>
				{companies &&
					Array.isArray(companies) &&
					[...companies].sort(sortCompanies).map((company, key) => (
						<Fragment key={key}>
							<DropdownItemCustom
								company={company}
								disabled={isCurrentCompany(company)}
								style={getDropdownItemStyle(company)}
								onClick={() =>
									dropdownItemOnClickHandler(company)
								}
							/>
							{ isCurrentCompany(company) && <DropdownItem divider /> }
						</Fragment>
					))}
			</DropdownMenu>
			<Modal
				isOpen={modal}
				onAccept={modalOnAcceptHandler}
				onClose={modalOnCloseHandler}
				title={"Troca de workspace"}
			>
				Tem certeza de que quer trocar para{" "}
				{<b>{selectedCompany?.name}</b>}?
			</Modal>
		</Fragment>
	);
};

const DropdownItemCustom = ({ company, ...restOptions }) => (
	<DropdownItem {...restOptions}>
		<img
			src={company.logo}
			className="mr-1 w-25"
			style={{ maxHeight: "20px", paddingRight: "5px" }}
		/>
		<div className="dropdown">
			{company.name?.length < 21
				? company.name
				: company.name?.slice(0, 22) + "..."}
		</div>
	</DropdownItem>
);

export default CompanyChangeDropdownMenu;
