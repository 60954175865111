import extend from "lodash/extend";
import { createSlice } from "@reduxjs/toolkit";

import apiInfoConstants from "./apiInfoConstants";

const { TO_FETCH, TO_EDIT, EDITED, FETCHED, FAILED } = apiInfoConstants.status;

const initialState = {
	data: null,
	status: null,
	loading: false,
	error: null,
	//
	isRdStationOn: { data: false, loading: false, error: null }, // TODO >>> rafator the old state and handle the conflict when disconnect rdStation.
};

const { actions, reducer } = createSlice({
	name: "apiInfo",
	initialState,
	reducers: {
		getApiInfo: (state) => state,
		getApiInfoFailure: (state) => state,
		getApiInfoSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
			}),
		editApiInfo: (state) =>
			extend(state, {
				status: TO_EDIT,
				loading: true,
				error: null,
			}),
		editApiInfoFailure: (state, { payload }) =>
			extend(state, {
				status: FAILED,
				loading: false,
				error: payload.data,
			}),
		editApiInfoSuccess: (state, { payload }) =>
			extend(state, {
				data: payload.data,
				status: EDITED,
				loading: false,
				error: null,
			}),
		checkRdStationConnection: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: state.isRdStationOn.data,
					loading: true,
					error: null,
				},
			}),
		checkRdStationConnectionSuccess: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: payload,
					loading: false,
					error: null,
				},
			}),
		checkRdStationConnectionFailure: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: state.isRdStationOn.data,
					loading: false,
					error: payload,
				},
			}),
		disconnectRdStation: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: state.isRdStationOn.data,
					loading: true,
					error: null,
				},
			}),
		disconnectRdStationSuccess: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: payload,
					loading: false,
					error: null,
				},
			}),
		disconnectRdStationFailure: (state, { payload }) =>
			extend(state, {
				isRdStationOn: {
					data: state.isRdStationOn.data,
					loading: false,
					error: payload,
				},
			}),
	},
});

export const {
	getApiInfo,
	getApiInfoFailure,
	getApiInfoSuccess,
	editApiInfo,
	editApiInfoFailure,
	editApiInfoSuccess,
	checkRdStationConnection,
	checkRdStationConnectionSuccess,
	checkRdStationConnectionFailure,
	disconnectRdStation,
	disconnectRdStationSuccess,
	disconnectRdStationFailure,
} = actions;

export { default as apiInfoSaga } from "./sagas";

export default reducer;
