import React, { Fragment, useState } from "react";
import { Button, Tooltip } from "reactstrap";
import "./styles.scss";

const AddTicketbutton = ({ onClick }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<Fragment>
			<Button
				id="btnAddTicket"
				color="primary"
				onClick={onClick ? onClick : () => null}
			>
				<span>Adicionar pedido</span>
			</Button>
			<Tooltip
				target="btnAddTicket"
				placement="top"
				isOpen={tooltipOpen}
				toggle={toggle}
			>
				Clique para adicionar um novo pedido
			</Tooltip>
		</Fragment>
	);
};

export default AddTicketbutton;
