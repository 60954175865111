import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import validate from "~/components/common/validate";
import LoadingButton from "~/components/common/LoadingButton";

const Form = ({ onSubmit, defaultValues, loading, required }) => {
	const { register, handleSubmit, watch, errors } = useForm({
		defaultValues,
		mode: "onChange",
	});
	const watchedPassword = watch("password");

	const history = useHistory();
	const returnToAgentsList = () =>
		history.push(`${process.env.PUBLIC_URL}/company/integrations/agents`);

	return (
		<form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="card">
				<div className="card-body">
					<div className="form-row">
						<div className="form-group col-12">
							<label className="col-form-label" htmlFor="url">
								URL
							</label>
							<input
								className="form-control"
								type="text"
								placeholder="https://agent.seu-dominio.com"
								ref={register(validate.url(required))}
								name="url"
								id="url"
							/>
							<span className="auth--validation-alert">
								{errors.url?.message}
							</span>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-12">
							<label className="col-form-label" htmlFor="username">
								Usuário
							</label>
							<input
								className="form-control"
								type="text"
								ref={register(required && validate.required())}
								name="username"
								id="username"
							/>
							<span className="auth--validation-alert">
								{errors.username?.message}
							</span>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-6">
							<label className="col-form-label" htmlFor="password">
								Senha
							</label>
							<input
								className="form-control"
								type="password"
								placeholder="********"
								ref={register(required && validate.required())}
								name="password"
								id="password"
							/>
							<span className="auth--validation-alert">
								{errors.password?.message}
							</span>
						</div>
						<div className="form-group col-6">
							<label className="col-form-label" htmlFor="confirmPassword">
								Confirme a Senha
							</label>
							<input
								className="form-control"
								type="password"
								name="confirmPassword"
								id="confirmPassword"
								placeholder="********"
								ref={register(
									validate.confirmPassword(watchedPassword, required)
								)}
							/>
							<span className="auth--validation-alert">
								{errors.confirmPassword?.message}
							</span>
						</div>
					</div>
				</div>
				<div className="card-footer">
					<div className="row">
						<div className="col-6">
							<LoadingButton
								loading={loading}
								child="Cancelar"
								onClick={returnToAgentsList}
								type="button"
							/>
						</div>
						<div className="col-6 text-right">
							<LoadingButton loading={loading} />
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};
export default Form;
